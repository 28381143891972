import {
    FETCH_ALL_BUSINESS_INFO,
    FETCH_BUSINESS_INFO_BY_ID,
    CREATE_BUSINESS_INFO,
    UPDATE_BUSINESS_INFO,
    DELETE_BUSINESS_INFO,
    BUSINESS_INFO_ERROR,
} from '../actions/businessAction';

const initialState = {
    businessInfoList: [], // List of all business information
    selectedBusinessInfo: null, // Single business information
    error: null, // Error message
};

const businessReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BUSINESS_INFO:
            return {
                ...state,
                businessInfoList: action.payload,
                error: null,
            };
        case FETCH_BUSINESS_INFO_BY_ID:
            return {
                ...state,
                selectedBusinessInfo: action.payload,
                error: null,
            };
        case CREATE_BUSINESS_INFO:
            return {
                ...state,
                businessInfoList: [...state.businessInfoList, action.payload],
                error: null,
            };
        case UPDATE_BUSINESS_INFO:
            return {
                ...state,
                businessInfoList: state.businessInfoList.map((info) =>
                    info.business_id === action.payload.business_id ? action.payload : info
                ),
                selectedBusinessInfo: action.payload,
                error: null,
            };
        case DELETE_BUSINESS_INFO:
            return {
                ...state,
                businessInfoList: state.businessInfoList.filter(
                    (info) => info.business_id !== action.payload
                ),
                error: null,
            };
        case BUSINESS_INFO_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default businessReducer;
