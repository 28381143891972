import {
    FETCH_URL_REQUEST,
    FETCH_URL_SUCCESS,
    FETCH_URL_FAILURE,
} from '../actions/fetchImage';

// Initial state
const initialState = {
    loading: false,
    urls: {
        products: {}, // Holds pre-signed URLs for product entities
        categories: {}, // Holds pre-signed URLs for category entities
        banners: {}, // Holds pre-signed URLs for banner entities
        brands: {}, // Holds pre-signed URLs for brand entities
    },
    errors: {}, // Tracks errors for each entity and ID
};

// Reducer function
export const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_URL_REQUEST:
            return {
                ...state,
                loading: true, // Indicate loading is in progress
            };

        case FETCH_URL_SUCCESS: {
            const { entity, id, url } = action.payload;
            return {
                ...state,
                loading: false, // Stop loading
                urls: {
                    ...state.urls,
                    [entity]: {
                        ...state.urls[entity],
                        [id]: url, // Store the URL under the corresponding entity and ID
                    },
                },
                errors: {
                    ...state.errors,
                    [entity]: {
                        ...(state.errors[entity] || {}),
                        [id]: null, // Clear any previous error for this entity and ID
                    },
                },
            };
        }

        case FETCH_URL_FAILURE: {
            const { entity, id, error } = action.payload;
            return {
                ...state,
                loading: false, // Stop loading
                errors: {
                    ...state.errors,
                    [entity]: {
                        ...(state.errors[entity] || {}),
                        [id]: error, // Store the error message for the failed entity and ID
                    },
                },
            };
        }

        default:
            return state;
    }
};
