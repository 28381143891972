import React from "react";
import "./shipping.scss";

const ShippingDelivery = () => {
  return (
    <div className="terms-container">
      <div className="header">
        <h1>Shipping & Delivery Policy</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Delivery Commitment</h2>
          <p className="effective-date">Effective Date: February 28, 2025</p>
          <p>At Fresh & Pure Dairy & Agro Products, we are committed to delivering fresh milk and dairy products directly from our farm to your doorstep with care and punctuality.</p>

          <h3>Delivery Timelines</h3>
          <p><strong>Subscription Orders (Milk & Dairy Products):</strong> Delivered at the exact time slot selected during booking. Ensure someone is available to receive the order.</p>
          <p><strong>Other Product Orders (One-Time Orders):</strong> Delivery may take 1 hour to 2 days depending on availability, location, and time of order.</p>

          <h3>Delivery Method</h3>
          <p>Our in-house delivery team ensures proper handling, temperature-controlled transportation, and timely doorstep delivery.</p>

          <h3>Delivery Charges</h3>
          <p>Delivery is free for subscription customers. One-time orders may incur a delivery charge based on distance or order value, shown during checkout.</p>

          <h3>Missed Deliveries</h3>
          <p>If you miss a delivery, inform us within 2 hours for possible redelivery (subject to availability). Delays or misses from our end may be rescheduled or refunded.</p>

          <h3>Product Availability</h3>
          <p>Some seasonal or limited-stock products may be delayed. Customers will be notified with options to wait, modify the order, or receive a refund/store credit.</p>

          <h3>Contact Us</h3>
          <p>For issues with delivery timing, delays, or missing items, contact us:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> freshpure.in@gmail.com</li>
            <li><strong>Phone:</strong> 094429 17151</li>
          </ul>
        </div>

        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>Contact our delivery support team for clarification or concerns regarding your order.</p>
          <p className="contact-number">094429 17151</p>
          <p className="contact-email">freshpure.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default ShippingDelivery;
