// services/supplierService.js
import apiClient from '../apiClient';
import { supplier_endpoints } from '../endPoints';

export const fetchSuppliersFromAPI = async (supplierId, token) => {
  const endpoint =supplier_endpoints.GET_BULK_SUPPLIER;

  const response = await apiClient.get(endpoint, {
    headers: { Authorization: token },
  });
  //console.log(response)

  if (!response.data) throw new Error('Failed to fetch suppliers');
  return response.data.suppliers;
};

// Create a new supplier
export const createNewSupplierAPI = async (supplierData, token) => {
  const response = await apiClient.post(
    supplier_endpoints.GET_SUPPLIER,
    supplierData,
    {
      headers: { Authorization: token },
    }
  );

  if (!response.data) throw new Error('Failed to create supplier');
  return response.data;
};

// Update an existing supplier
export const updateSupplierInAPI = async (supplierData, token) => {
  const response = await apiClient.put(
    supplier_endpoints.GET_SUPPLIER,
    supplierData,
    {
      headers: { Authorization: token },
    }
  );

  if (response.status !== 200) throw new Error('Failed to update supplier');
  return response.data;
};

// Delete a supplier by ID
export const deleteSupplierAPI = async (supplierId, token) => {
  const response = await apiClient.delete(supplier_endpoints.GET_SUPPLIER, {
    params: { supplier_id: supplierId },
    headers: { Authorization: token },
  });

  if (response.status !== 200) throw new Error('Failed to delete supplier');
  return response.data;
};

// Create suppliers in bulk
export const createBulkSuppliersAPI = async (suppliersData, token) => {
  const response = await apiClient.post(
    supplier_endpoints.GET_BULK_SUPPLIER,
    suppliersData,
    {
      headers: { Authorization: token },
    }
  );

  if (response.status !== 201) throw new Error('Failed to create suppliers in bulk');
  return response.data;
};

// Update suppliers in bulk
export const updateBulkSuppliersAPI = async (suppliersData, token) => {
  const response = await apiClient.put(
    supplier_endpoints.GET_BULK_SUPPLIER,
    suppliersData,
    {
      headers: { Authorization: token },
    }
  );

  if (response.status !== 200) throw new Error('Failed to update suppliers in bulk');
  return response.data;
};

// Delete suppliers in bulk
export const deleteBulkSuppliersAPI = async (supplierIds, token) => {
  const response = await apiClient.delete(
    supplier_endpoints.GET_BULK_SUPPLIER,
    {
      data: supplierIds,
      headers: { Authorization: token },
    }
  );

  if (response.status !== 200) throw new Error('Failed to delete suppliers in bulk');
  return response.data;
};
