import {
  FETCH_COUPONS_REQUEST,
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAILURE,
  CLEAR_COUPONS,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILURE,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  BULK_DELETE_COUPONS_REQUEST,
  BULK_DELETE_COUPONS_SUCCESS,
  BULK_DELETE_COUPONS_FAILURE,
  BULK_UPDATE_COUPONS_REQUEST,
  BULK_UPDATE_COUPONS_SUCCESS,
  BULK_UPDATE_COUPONS_FAILURE,
  BULK_CREATE_COUPONS_REQUEST,
  BULK_CREATE_COUPONS_SUCCESS,
  BULK_CREATE_COUPONS_FAILURE,
} from '../actions/couponAction'; // Adjust the import path as necessary

const initialState = {
  couponList: [],
  timestamp: null,
  loading: false,
  error: null,
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: action.payload.coupons,
        timestamp: action.payload.timestamp,
      };

    case FETCH_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_COUPONS:
      return { ...state, couponList: [], timestamp: null };

    case DELETE_COUPON_REQUEST:
      return { ...state, loading: true, error: null };

    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: state.couponList.filter(coupon => coupon.coupon_id !== action.payload),
      };

    case DELETE_COUPON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_COUPON_REQUEST:
      return { ...state, loading: true, error: null };

    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: state.couponList.map(coupon =>
          coupon.coupon_id === action.payload.id ? action.payload : coupon
        ),
      };

    case UPDATE_COUPON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CREATE_COUPON_REQUEST:
      return { ...state, loading: true, error: null };

    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: [...state.couponList, action.payload],
      };

    case CREATE_COUPON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case BULK_DELETE_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case BULK_DELETE_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: state.couponList.filter(coupon => !action.payload.includes(coupon.coupon_id)),
      };

    case BULK_DELETE_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case BULK_UPDATE_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case BULK_UPDATE_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: state.couponList.map(coupon =>
          action.payload.find(updated => updated.id === coupon.id) || coupon
        ),
      };

    case BULK_UPDATE_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case BULK_CREATE_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case BULK_CREATE_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        couponList: [...state.couponList, ...action.payload],
      };

    case BULK_CREATE_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default couponReducer;
