import { login_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';

// Function to generate token
export const generateToken = async () => {
  const response = await apiClient.post(login_endpoints.GENERATE_TOKEN, {
    client_key: "jhasbd81e1dasdasd23jh21jh1",
    client_secret: "asdasasdasddasdasdasd212w123",
  });

  if (response && response.data.access_token) {
    //console.log(response.data);
    // Dispatch the setToken action to update the Redux store
    // toast.success('Token generated successfully');
    return response.data.access_token;

};
};

// Function for password-based admin login
export const adminLoginWithPassword = async (email, password) => {

    //console.log("Email:", email, "Password:", password);  // Log the credentials being sent
  try{

    const response = await apiClient.post(login_endpoints.ADMIN_LOGIN, {
      email: email,  
      password: password,  // Sending password in the request body
      role:"admin",
    },
  
  );
  //console.log(response,'----response')
  if (response.status === 200){
    //console.log(response.data)
    return response.data;
  }
}
  catch (error) {
  
    
    throw new Error('Invalid email or password');
  }
};

   



// Reset password service to call the reset password API
export const resetPassword = async (email) => {
  try {
    //console.log('Reset password for:', email);  // Debugging

    // Send password reset request
    const response = await apiClient.post(login_endpoints.RESET_PASSWORD, {
      email: email,  // Sending the email for password reset
    });

    if (response && response.status === 200 && response.data?.message) {
      //console.log('Password reset email sent:', response.data.message);
      toast.success('Password reset email sent');
      return response.data;  // Return the message
    } else {
      throw new Error('Password reset failed. Invalid response from the server.');
    }
  } catch (error) {
    //console.error('Error during password reset:', error);

    const errorMessage = error.response?.data?.message || 'An error occurred during password reset.';
    toast.error(errorMessage);

    // Re-throw the error for further handling
    throw new Error(errorMessage);
  }
};
