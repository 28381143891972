// src/components/LoadingSpinner.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './LoadingSpinner.scss'; // Import the CSS file for styling

const LoadingSpinner = () => {
  return (
    <div className="loading-container">
      <FontAwesomeIcon icon={faCircleNotch} spin size="3x" color="#007bff" /> {/* Blue spinner */}
    </div>
  );
};

export default LoadingSpinner;
