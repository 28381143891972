import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBXM71YLw1XHNZFD1ORr6TSHmweoZXzmIA",
  authDomain: "freshpure-app.firebaseapp.com",
  projectId: "freshpure-app",
  storageBucket: "freshpure-app.firebasestorage.app",
  messagingSenderId: "814939980757",
  appId: "1:814939980757:web:39c2008a390aef7c0d735c",
  measurementId: "G-4LB536DN4R"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export { RecaptchaVerifier, signInWithPhoneNumber };