import axios from 'axios';
import { toast } from 'react-hot-toast';

const apiClient = axios.create({
  baseURL: "https://freshpure.in/auth/",

  headers: {
    'Content-Type': 'application/json',
  },
});


// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor for global error handling
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      // toast.error(error.response.data.message || 'An error occurred');  // Show toast for server errors
      return Promise.reject(error.response.data.message || error.response.statusText);
    } else if (error.request) {
      // toast.error('Network error: No response from server');  // Show toast for network errors
      return Promise.reject('Network error: No response from server');
    } else {
      toast.error('Error: ' + error.message);  // General error
      return Promise.reject(error.message);
    }
  }
);

export default apiClient;
