import React from 'react';
import { useSelector } from 'react-redux';
import UserManagementNavbar from '../../components/layout/USerManagementNavbar/user-management-navbar';
import AdminDisplayPage from '../AdminAddAdminPage/adminlistPage';
import UserDisplayPage from '../AdminAddUser/userslistPage';
import DeliveryDisplayPage from '../add-delivery-person/deliverylistPage';

const AdminUserManagement = () => {
    // Get the navbar state from Redux
    const navbarState = useSelector((state) => state.usermanagenav);

    return (
        <div>
            <UserManagementNavbar />
            
            {/* {navbarState.adelidmin && <AdminDisplayPage/>} */}
            {navbarState.delivery && <DeliveryDisplayPage/>}
            {navbarState.user && <UserDisplayPage/>}
            {/* {navbarState.product && <AdminProductsPage />}
            {navbarState.coupon && <CouponDisplayPage/>}
            {navbarState.offer && <DiscountDisplayPage/>} */}

        </div>
    );
};

export default AdminUserManagement;
