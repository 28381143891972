import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkOrders } from '../../redux/actions/orderActions';
import './Orders.scss';
import noOrdersImage from '../../assets/images/no-orders.svg';
import orderCancelledIcon from '../../assets/images/order-cancelled.png';
import pendingIcon from '../../assets/images/pending.png';
import shippedIcon from '../../assets/images/shipped.png';
import deliveredIcon from '../../assets/images/success.png';

const Orders = () => {
  const [selectedFilter, setSelectedFilter] = useState('All'); // Default filter is "All"
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders || []);
  const orderItems = useSelector((state) => state.orders.orderItems || []);
  const userId = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        await dispatch(getBulkOrders(token));
        setApiError(null);
      } catch (error) {
        setApiError('Unable to fetch orders. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [dispatch, token]);

  // Filter orders based on selected filter
  const filteredOrders = orders
    .filter((order) => order.user_id === userId) // Filter user-specific orders
    .filter((order) =>
      selectedFilter === 'All'
        ? true
        : order.order_status?.toLowerCase() === selectedFilter.toLowerCase()
    );

  const handleTrackOrderClick = (orderId) => {
    navigate('/ordersuccess', { state: orderId });
  };

  const handleViewDetailsClick = (orderId) => {
    navigate('/myorderdetails', { state: orderId });
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div className="orders-page">
      <div className="orders-title">My Orders</div>

      {/* Filter Dropdown */}
      <div className="filter-dropdown">
        <select
          className="filter-select"
          value={selectedFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          <option value="All">All</option>
          <option value="delivered">Delivered</option>
          <option value="shipped">Shipped</option>
          <option value="pending">Pending</option>
          <option value="processed">Processed</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>

      {apiError ? (
        <div className="error-message">{apiError}</div>
      ) : (
        <>
          {isLoading ? (
            <div>Loading Orders...</div>
          ) : filteredOrders.length > 0 ? (
            filteredOrders.map((order) => (
              <div
                className={`order-container ${order.order_status.toLowerCase()}`}
                key={order.order_id}
              >
                <div className="order-header">
                  <div className="order-status-container">
                  <img
  src={
    order.order_status === 'cancelled'
      ? orderCancelledIcon
      : order.order_status === 'pending'
      ? pendingIcon
      : order.order_status === 'delivered'
      ? deliveredIcon
      : order.order_status === 'shipped'
      ? shippedIcon
      : ''// Fallback icon if the status doesn't match
  }
  alt={order.order_status}
  className="order-status-icon"
/>

                      
                    <div className="order-status-details">
                      <div
                        className={`order-status ${order.order_status.toLowerCase()}`}
                      >
                        {order.order_status.charAt(0).toUpperCase() +
                          order.order_status.slice(1)}
                      </div>
                      <div className="order-date">
                        {order.order_status === 'cancelled'
                          ? ''
                          : `Arriving on ${new Date(
                              order.delivery_date
                            ).toLocaleDateString()}`}
                      </div>
                    </div>
                  </div>
                  <div className="order-info-right">
                    <span className="order-id">
                      Order Placed: {new Date(order.order_date).toLocaleDateString()}
                    </span>
                    <span className="order-id">Order ID: {order.order_id}</span>
                    <span className="order-total">
                      ₹{parseFloat(order.total_amount || 0).toFixed(2)}
                    </span>
                  </div>
                </div>

                <div className="order-footer">
                  <button
                    className="track-order-btn"
                    onClick={() =>
                      (order.order_status === 'cancelled' || order.order_status=== 'delivered')
                        ? handleViewDetailsClick(order.order_id)
                        : handleTrackOrderClick(order.order_id)
                    }
                  >
                    {(order.order_status === 'cancelled' || order.order_status=== 'delivered')
                      ? 'View Details'
                      : 'Track Order'}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="no-orders-content">
              <img
                src={noOrdersImage}
                alt="No Orders"
                className="no-orders-image"
              />
              <div className="no-orders-text">No Orders yet. Start Ordering!</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;
