import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import "./coupontable.scss"; 
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkUpdateCoupons, bulkCreateCoupons } from "../../redux/actions/couponAction"; 
import { bulkCreateAllocatedCoupons,bulkUpdateAllocatedCoupons,fetchAllocatedCoupons } from "../../redux/actions/couponAllocationAction";
import toast from "react-hot-toast";
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const BulkAllocateCoupon = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [couponData, setCouponData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const statusOptions = ["active", "inactive", "expired"];
  const typeOptions = ['category', 'product'];

  const categories = useSelector(state => state.category.categoryList.categories || []); 
  const products = useSelector(state => state.products.basicProducts || []); 
  const allocatedCouponList = useSelector((state) => state.allocateCoupon.allocatedCoupons || []);
  
  const [entityOptions, setEntityOptions] = useState([]);
  
  const coupons = useSelector(state => state.coupon.couponList || []); 
  const token = useSelector(state => state.adminLogin.token);
  const initialCoupons = location.state || [];
  ////console.log(initialCoupons,'----avail couponsssss')
  const couponOptions = coupons.map(coupon => ({ label: coupon.code, id: coupon.coupon_id }));

  useEffect(() => {
     const categoryMap = categories.reduce((map, category) => ({ ...map, [category.category_id]: category.name }), {});
    const productMap = products.reduce((map, product) => ({ ...map, [product.product_id]: product.product_name }), {});
    const processCoupons = () => {
      if (initialCoupons.length === 0) {
        if (couponData.length === 0) {
          setCouponData([{ coupon_id: '', entity_type: '', entity_id: '', status: '' }]);
        }
      } else {
        if (typeof initialCoupons[0] === 'object') {
          const fetchedCoupons = initialCoupons.map(coupon => ({
            coupon_id: coupons.find(cat => cat.code === coupon.code)?.coupon_id ||'',
            entity_type: coupon.entity_type,
            entity_id: (coupon.entity_type === 'category' && categories.find(cat => cat.name === coupon.entity_name)?.category_id) ||
            (coupon.entity_type === 'product' && products.find(prod => prod.product_name === coupon.entity_name)?.product_id) ||
            '',
            status: coupon.status,
            entity_name:coupon.entity_name,
          }));
          setCouponData(fetchedCoupons);
        } else if (typeof initialCoupons[0] === 'string') {
          const fetchedCoupons = initialCoupons.map(id => {
            const coupon = allocatedCouponList.find(c => c.coupon_allocation_id === id);
            if (coupon) {
              const entityName = (coupon.entity_type === 'category' && categories.find(cat => cat.category_id === coupon.entity_id)?.name) ||
                                 (coupon.entity_type === 'product' && products.find(prod => prod.product_id === coupon.entity_id)?.product_name) ||
                                 '';
              return { ...coupon, entity_name: entityName };
            } else {
              return {
                coupon_allocation_id: id,
                coupon_id: '',
                entity_type: '',
                entity_id: '',
                status: '',
                entity_name: ''
              };
            }
          });
          setCouponData(fetchedCoupons);
        }
      }
    };

    processCoupons();
  }, [initialCoupons, coupons, categories, products]);

  const handleSaveClick = async () => {

    ////console.log(couponData,'--------alllocateee coupon ')


    const hasEmptyFields = couponData.some(coupon => !coupon.coupon_id || !coupon.entity_id || !coupon.status);
    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Coupon Code, Entity Name, and Status).");
      return;
    }

    setIsSaving(true);
    let responseStatus;
    ////console.log(couponData,'--------alllocateee coupon ')
    if (Array.isArray(initialCoupons) && initialCoupons.length > 0) {
      ////console.log(couponData,'--------alllocateee coupon ')
      if (typeof initialCoupons[0] === 'object') {
        responseStatus = await dispatch( bulkCreateAllocatedCoupons(couponData, token));
        
      } else if (typeof initialCoupons[0] === 'string') {
        ////console.log(couponData,'--------alllocateee coupon ')
        responseStatus = await dispatch(bulkUpdateAllocatedCoupons(couponData, token));
      }

    }

    setIsSaving(false);
    
    if (responseStatus) {
      navigate('/admin/coupon/allocate');
    } else {
      //console.error("Failed to save coupons");
    }
  };

  const handleEntityTypeChange = (index, selectedType) => {
    handleInputChange(index, 'entity_type', selectedType);
    setEntityOptions(
      selectedType === 'category' 
        ? categories.map(category => ({ label: category.name, id: category.category_id }))
        : products.map(product => ({ label: product.product_name, id: product.product_id }))
    );
  };

  const getCode = (identifier) => {
    // Check if the identifier is an ID or a code
    const coupon = coupons.find((coupon) => 
      coupon.coupon_id === identifier || coupon.code === identifier
    );
    ////console.log(coupon,'-----coupon')
    return coupon ? coupon.code : ''; // Return coupon code if found, otherwise return empty string
  };
  

  const getEntityName = (entityType, identifier) => {
    if (entityType === 'category') {
      // Check if identifier is an ID or a name
      const category = categories.find((category) => 
        category.category_id === identifier || category.name === identifier
      );
      ////console.log(category,'-----coupon')
      return category ? category.name : ''; // Return category name if found
    } else if (entityType === 'product') {
      // Check if identifier is an ID or a name
      const product = products.find((product) => 
        product.product_id === identifier || product.product_name === identifier
      );
      ////console.log(product,'-----coupon')
      return product ? product.product_name : ''; // Return product name if found
    }
   
    return ''; // Default empty string for unsupported entity types
  };
  
  
  const handleInputChange = (index, field, value) => {
    ////console.log(value,'---val')
    const updatedCoupons = [...couponData];
    updatedCoupons[index][field] = value;
    setCouponData(updatedCoupons);
    ////console.log(couponData,'---coupon')
  };

  const handleAddRow = () => {
    setCouponData(prevCoupons => [
      ...prevCoupons,
      { coupon_id: '', entity_type: '', entity_id: '', status: '' }
    ]);
  };

  const handleDelete = (index) => {
    const newCoupons = [...couponData];
    newCoupons.splice(index, 1);
    setCouponData(newCoupons);
  };

  return (
     <div className="bulk-allocate-coupon-main-page">
    

    <div className="breadcrumb-container">
     
        <Link to="/admin" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
        <span className="breadcrumb-text">Coupon list</span>
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/coupon/allocate" className="breadcrumb-text">
        <span className="breadcrumb-text">Allocate Coupon list</span>
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Bulk Allocate Coupon</span>
   </div>
   <div className="bulk-allocate-coupon-container"></div>
   <div className="bulk-allocate-coupon">
    <div className="coupon-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Allocate Coupon</h5>
   
      <div className="add-new-button">
      {typeof initialCoupons[0] === 'object' && (
        <ButtonComponent 
          text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
          onClick={handleAddRow} 
          className="add-button" 
        />


      )}
        
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} 
        />
      </div>
      </div>
      </div>

      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Coupon Code</th>
              <th>Entity Type</th>
              <th>Entity Name</th>
              <th>Status</th>
              <th className="small-column">Delete</th>
            </tr>
          </thead>
          <tbody>
            {couponData.map((coupon, index) => (
              <tr key={index}>
                <td>
                <SearchableDropdown
    options={couponOptions} // Filter invalid options
     placeholder="Search for coupons"
    value={getCode(coupon.coupon_id || coupon.code)} // Safely fetch the code
    onSelect={(selectedId) => handleInputChange(index, 'coupon_id', selectedId)}
  />
</td>

                <td>
                  <DropdownButton
                    label={coupon.entity_type || 'Select Type'}
                    options={typeOptions}
                    onSelect={selectedOption => handleEntityTypeChange(index, selectedOption)}
                  />
                </td>
              
                <td>
 <SearchableDropdown
  options={
    coupon.entity_type === 'category'
      ? categories
          .filter(category => products.some(product => product.category_id === category.category_id)) // Only categories in productlist
          .map(category => ({ label: category.name, id: category.category_id }))
      : products.map(product => ({ label: product.product_name, id: product.product_id }))
  }
  placeholder="Search for entities"
  value={getEntityName(coupon.entity_type, coupon.entity_id)} // Fetch the correct name
  onSelect={(selectedId) => handleInputChange(index, 'entity_id', selectedId)}
/>

</td>

                <td>
                  <DropdownButton
                    label={coupon.status || 'Status'}
                    options={statusOptions}
                    onSelect={selectedOption => handleInputChange(index, 'status', selectedOption)}
                  />
                </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
    
  );
};

export default BulkAllocateCoupon;
