import React from 'react';
import './Textbox.scss';

const Textbox = ({ label, name, value, onChange }) => {
  return (
    <div className="textbox-component">
      <label>{label}</label>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder=" "
      />
    </div>
  );
};

export default Textbox;
