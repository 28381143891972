import {
    fetchSubscriptions,
    addSubscription,
    updateSubscription,
    deleteSubscription,
    bulkAddSubscriptions,
    bulkFetchSubscriptions,
    bulkUpdateSubscriptions as bulkUpdateSubscriptionsAPI,
    bulkDeleteSubscriptions as bulkDeleteSubscriptionsAPI,
  } from '../../api/services/subscriptionService';
  
  export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
  export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR';
  export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
  export const ADD_SUBSCRIPTION_ERROR = 'ADD_SUBSCRIPTION_ERROR';
  export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
  export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';
  export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
  export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';
  export const BULK_ADD_SUBSCRIPTIONS_SUCCESS = 'BULK_ADD_SUBSCRIPTIONS_SUCCESS';
  export const BULK_ADD_SUBSCRIPTIONS_ERROR = 'BULK_ADD_SUBSCRIPTIONS_ERROR';
  export const BULK_GET_SUBSCRIPTIONS_SUCCESS = 'BULK_GET_SUBSCRIPTIONS_SUCCESS';
  export const BULK_GET_SUBSCRIPTIONS_ERROR = 'BULK_GET_SUBSCRIPTIONS_ERROR';
  export const BULK_UPDATE_SUBSCRIPTIONS_SUCCESS = 'BULK_UPDATE_SUBSCRIPTIONS_SUCCESS';
  export const BULK_UPDATE_SUBSCRIPTIONS_ERROR = 'BULK_UPDATE_SUBSCRIPTIONS_ERROR';
  export const BULK_DELETE_SUBSCRIPTIONS_SUCCESS = 'BULK_DELETE_SUBSCRIPTIONS_SUCCESS';
  export const BULK_DELETE_SUBSCRIPTIONS_ERROR = 'BULK_DELETE_SUBSCRIPTIONS_ERROR';
  
  // Fetch subscriptions
  export const getSubscriptions = (token, userId) => async (dispatch) => {
    try {
      const response = await fetchSubscriptions(token, userId);
      dispatch({ type: FETCH_SUBSCRIPTIONS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: FETCH_SUBSCRIPTIONS_ERROR, payload: error.message });
    }
  };
  
  // Add subscription
  export const createSubscription = (subscriptionData, token) => async (dispatch) => {
    try {
      const newSubscription = await addSubscription(subscriptionData, token);
      dispatch({ type: ADD_SUBSCRIPTION_SUCCESS, payload: newSubscription });
      //console.log(newSubscription,'--subscription response')

      return true
    } catch (error) {
      dispatch({ type: ADD_SUBSCRIPTION_ERROR, payload: error.message });

      return false;
    }
  };
  
  // Update subscription
  export const modifySubscription = (subscriptionId, subscriptionData, token) => async (dispatch) => {
    try {
      const updatedSubscription = await updateSubscription(subscriptionId, subscriptionData, token);
      dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: updatedSubscription });
    } catch (error) {
      dispatch({ type: UPDATE_SUBSCRIPTION_ERROR, payload: error.message });
    }
  };
  
  // Delete subscription
  export const removeSubscription = (subscriptionId, token) => async (dispatch) => {
    try {
      await deleteSubscription(subscriptionId, token);
      dispatch({ type: DELETE_SUBSCRIPTION_SUCCESS, payload: subscriptionId });
    } catch (error) {
      dispatch({ type: DELETE_SUBSCRIPTION_ERROR, payload: error.message });
    }
  };
  
  // Bulk Add Subscriptions
  export const bulkCreateSubscriptions = (subscriptionsData, token) => async (dispatch) => {
    try {
      const response = await bulkAddSubscriptions(subscriptionsData, token);
      dispatch({ type: BULK_ADD_SUBSCRIPTIONS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: BULK_ADD_SUBSCRIPTIONS_ERROR, payload: error.message });
    }
  };
  
  // Bulk Get Subscriptions
  export const bulkGetSubscriptions = (token) => async (dispatch) => {
    try {
      const response = await bulkFetchSubscriptions(token);
      dispatch({ type: BULK_GET_SUBSCRIPTIONS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: BULK_GET_SUBSCRIPTIONS_ERROR, payload: error.message });
    }
  };
  
  // Bulk update subscriptions
  export const bulkUpdateSubscriptions = (subscriptionsData, token) => async (dispatch) => {
    try {
      const response = await bulkUpdateSubscriptionsAPI(subscriptionsData, token);
      dispatch({ type: BULK_UPDATE_SUBSCRIPTIONS_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: BULK_UPDATE_SUBSCRIPTIONS_ERROR, payload: error.message });
    }
  };
  
  // Bulk delete subscriptions
  export const bulkDeleteSubscriptions = (subscriptionIds, token) => async (dispatch) => {
    try {
      await bulkDeleteSubscriptionsAPI(subscriptionIds, token);
      dispatch({ type: BULK_DELETE_SUBSCRIPTIONS_SUCCESS, payload: subscriptionIds });
    } catch (error) {
      dispatch({ type: BULK_DELETE_SUBSCRIPTIONS_ERROR, payload: error.message });
    }
  };
  