import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './AdminCategoryOffcanvas.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const AdminCategoryOffcanvas = ({ show, onClose, onCategorySelect }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const categories = useSelector((state) => state.category.categoryList.categories) || [];

  const handleCategoryClick = (category) => {
    const selectedCategoryId = category ? category.category_id : null;
    setActiveCategory(selectedCategoryId);

    if (typeof onCategorySelect === 'function') {
        onCategorySelect(selectedCategoryId);
    }

    if (typeof onClose === 'function') {
        onClose();
    }
};


  return (
    <div className={`offcanvas ${show ? 'show' : ''}`}>
      <div className="offcanvas-header">
        <h6 className="offcanvas-title">Categories</h6>
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="offcanvas-body">
        <h6 className="offcanvas-category-title">Select Category</h6>
        <ul className="category-list">
          <li
            className={`category-item ${activeCategory === null ? 'active' : ''}`}
            onClick={() => handleCategoryClick(null)}
          >
            All
          </li>
          {categories.map((category) => (
            <li
              key={category.category_id}
              className={`category-item ${activeCategory === category.category_id ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminCategoryOffcanvas;
