// services/categoryService.js
import { category_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';




export const fetchCategoryFromAPI = async (token) => {
    // const token = useSelector(state=>state.adminLogin.token);
    const response = await apiClient.get(category_endpoints.GET_BULK_CATEGORIES,
      {
        headers: {
          Authorization: token,

        }
      },    

    ); 
    //console.log("category response",response.data.categories)// Assuming categories are a part of the products API
    if (!response.data) {
      throw new Error('Failed to fetch categories');
    }
    return response.data.categories; // Return the list of categories
};
export const createNewCategoryAPI = async (categoryData,token) => {
  // try {
    //console.log("Category data adding:", categoryData);

    const response = await apiClient.post(
      category_endpoints.GET_CATEGORY,
      {    
                name:categoryData.name,
                description:categoryData.description,
                // parent_category_id:"abbfd3a0-41e5-49df-85b5-25c4dad384e7",
                image_url:categoryData.image_url,
                status:categoryData.status,
                sort_order:categoryData.sort_order
              },
      {
        headers: {
          Authorization: token,  // Replace with a dynamic token if possible
        },
      }
    );


};
export const deleteCategoryAPI = async (categoryId,token) => {
  //console.log("dlete cat",categoryId,category_endpoints.GET_CATEGORY)

    //console.log('Deleting category with ID:', categoryId);

    const response = await apiClient.delete(
      `${category_endpoints.GET_CATEGORY}`,  // Base endpoint
      {
        params: { category_id: categoryId },  // Add category_id as a query parameter
        headers: {
          Authorization:token,
        },
      }
    );
    //console.log('Category deleted successfully', response.data);
    return response.data;
};


export const updateCategoryInAPI = async (updatedData,token) => {
  //console.log("category data update ",updatedData)

  const response = await apiClient.put(
    category_endpoints.GET_CATEGORY, 
    {
      category_id:updatedData.category_id,
      name:updatedData.name,
      description:updatedData.description,
      parent_category_id:"abbfd3a0-41e5-49df-85b5-25c4dad384e7",
      image_url:updatedData.image_url,
      status:updatedData.status,
      sort_order:updatedData.sort_order

    },
    {
      headers: {
       
        Authorization: token,

      },
    }
  );

  if (response.status !== 200) {
    throw new Error(`Failed to update category with ID: ${updatedData.category_id}`);
  }

  //console.log('Category updated successfully', response.data);
  return response.data; // Optionally return the deleted category data

};


// create bulk categories
export const createCategoriesBulkAPI = async (categoriesData, token) => {
  //console.log("Bulk Category Data Adding:", categoriesData);

  const response = await apiClient.post(
    category_endpoints.GET_BULK_CATEGORIES,
    categoriesData, // Directly send array of category data
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (response.status !== 201) {
    throw new Error('Failed to create categories in bulk');
  }

  //console.log('Bulk categories created successfully', response.data);
  return response.data;
};

export const updateCategoriesBulkAPI = async (categoriesData, token) => {
  //console.log("Bulk Category Data Update:", categoriesData);

  const response = await apiClient.put(
    category_endpoints.GET_BULK_CATEGORIES,
    categoriesData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Failed to update categories in bulk');
  }

  //console.log('Bulk categories updated successfully', response.data);
  return response.data;
};


export const deleteCategoriesBulkAPI = async (categoryIds, token) => {
  //console.log("Bulk Delete Categories:", categoryIds);

  const response = await apiClient.delete(
    category_endpoints.GET_BULK_CATEGORIES,// Assuming this is your bulk delete endpoint
    {
      data: categoryIds, // Send array of category IDs in the request body
      headers: {
        Authorization: token,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Failed to delete categories in bulk');
  }

  //console.log('Bulk categories deleted successfully', response.data);
  return response.data;
};
