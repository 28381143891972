// src/redux/actions/adminRegisterActions.js

import {generateToken, adminRegister } from '../../api/services/adminRegisterService';
import { toast } from 'react-hot-toast';

export const GENERATE_TOKEN_REQUEST = 'GENERATE_TOKEN_REQUEST';
export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS';
export const GENERATE_TOKEN_FAILURE = 'GENERATE_TOKEN_FAILURE';
// Action Types
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGOUT = 'LOGOUT';

export const generateTokenAction = () => async (dispatch) => {
  dispatch({ type: GENERATE_TOKEN_REQUEST });
  try {
    const data = await generateToken();  // Call the token generation service
    dispatch({ type: GENERATE_TOKEN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GENERATE_TOKEN_FAILURE, payload: error.message });
  }
};
// Action to handle registration, including token generation
export const registerAction = (formData) => async (dispatch,getState) => {
  dispatch({ type: REGISTER_REQUEST });
  const { auth } = getState();
  try {

    // Perform the registration once token is successfully generated
    const data = await adminRegister( formData);

    // Dispatch success action
    dispatch({ type: REGISTER_SUCCESS, payload: data });
   return true;
  } catch (error) {
    // Handle any errors (token generation or registration)
    const errorMessage = error.response?.data?.message || error.message;
    dispatch({ type: REGISTER_FAILURE, payload: errorMessage });
    toast.error(`Registration failed: ${errorMessage}`);
    return false;
  }
};
export const logoutAction = () => (dispatch) => {
  localStorage.removeItem('token');  // Clear stored token
  dispatch({ type: LOGOUT });
};
