import {
    FETCH_BRANDS_REQUEST,
    FETCH_BRANDS_SUCCESS,
    FETCH_BRANDS_FAILURE,
    CLEAR_BRANDS,
    DELETE_BRAND_REQUEST,
    DELETE_BRAND_SUCCESS,
    DELETE_BRAND_FAILURE,
    UPDATE_BRAND_REQUEST,
    UPDATE_BRAND_SUCCESS,
    UPDATE_BRAND_FAILURE,
    CREATE_BRAND_REQUEST,
    CREATE_BRAND_SUCCESS,
    CREATE_BRAND_FAILURE,
    BULK_CREATE_BRANDS_REQUEST,
    BULK_CREATE_BRANDS_SUCCESS,
    BULK_CREATE_BRANDS_FAILURE,
    BULK_UPDATE_BRANDS_REQUEST,
    BULK_UPDATE_BRANDS_SUCCESS,
    BULK_UPDATE_BRANDS_FAILURE,
    BULK_DELETE_BRANDS_REQUEST,
    BULK_DELETE_BRANDS_SUCCESS,
    BULK_DELETE_BRANDS_FAILURE
  } from '../actions/brandAction'; // Adjust the path as needed
  
  const initialState = {
    brandList: {
      brands: [],
      loading: false,
      error: null,
      timestamp: null,
    },
  };
  
  const brandReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BRANDS_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case FETCH_BRANDS_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: action.payload.brands,
            timestamp: action.payload.timestamp, // Store the timestamp
            error: null,
          },
        };
      case FETCH_BRANDS_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
      case CLEAR_BRANDS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            brands: [],
            timestamp: null, // Clear timestamp as well
          },
        };
      case DELETE_BRAND_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case DELETE_BRAND_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: state.brandList.brands.filter(brand => brand.brand_id !== action.payload),
            error: null,
          },
        };
      case DELETE_BRAND_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
      case UPDATE_BRAND_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case UPDATE_BRAND_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: state.brandList.brands.map(brand =>
              brand.brand_id === action.payload.brand_id ? action.payload : brand
            ),
            error: null,
          },
        };
      case UPDATE_BRAND_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
      case CREATE_BRAND_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case CREATE_BRAND_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: [...state.brandList.brands, action.payload], // Add newly created brand
            error: null,
          },
        };
      case CREATE_BRAND_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
  
      // Bulk Create Brands
      case BULK_CREATE_BRANDS_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case BULK_CREATE_BRANDS_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: [...state.brandList.brands, ...action.payload], // Add multiple created brands
            error: null,
          },
        };
      case BULK_CREATE_BRANDS_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
  
      // Bulk Update Brands
      case BULK_UPDATE_BRANDS_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case BULK_UPDATE_BRANDS_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: state.brandList.brands.map(brand =>
              action.payload.find(updatedBrand => updatedBrand.brand_id === brand.brand_id) || brand
            ), // Update multiple brands
            error: null,
          },
        };
      case BULK_UPDATE_BRANDS_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
  
      // Bulk Delete Brands
      case BULK_DELETE_BRANDS_REQUEST:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: true,
            error: null,
          },
        };
      case BULK_DELETE_BRANDS_SUCCESS:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            brands: state.brandList.brands.filter(
              brand => !action.payload.includes(brand.brand_id)
            ), // Remove multiple brands
            error: null,
          },
        };
      case BULK_DELETE_BRANDS_FAILURE:
        return {
          ...state,
          brandList: {
            ...state.brandList,
            loading: false,
            error: action.payload,
          },
        };
  
      default:
        return state;
    }
  };
  
  export default brandReducer;
  