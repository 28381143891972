// redux/reducers/purchaseReducer.js

import {
    CREATE_PURCHASE,
    GET_PURCHASE,
    UPDATE_PURCHASE,
    DELETE_PURCHASE,
    GET_BULK_PURCHASES,
    DELETE_BULK_PURCHASES,
    CREATE_PURCHASE_ITEM,
    GET_PURCHASE_ITEM,
    UPDATE_PURCHASE_ITEM,
    DELETE_PURCHASE_ITEM,
    GET_BULK_PURCHASE_ITEMS,
    CREATE_BULK_PURCHASE_ITEMS,
    UPDATE_BULK_PURCHASE_ITEMS,
    DELETE_BULK_PURCHASE_ITEMS,
} from '../actions/purchaseActions';

const initialState = {
    purchases: [],
    purchaseItems: [],
    purchaseDetails: null,
    purchaseItemDetails: null,
};

const purchaseReducer = (state = initialState, action) => {
    switch (action.type) {
        // Single Purchase Actions
        case CREATE_PURCHASE:
            return { ...state, purchases: [...state.purchases, action.payload] };

        case GET_PURCHASE:
            return { ...state, purchaseDetails: action.payload };

        case UPDATE_PURCHASE:
            return {
                ...state,
                purchases: state.purchases.map(purchase =>
                    purchase.purchase_id === action.payload.purchase_id ? action.payload : purchase
                ),
            };

        case DELETE_PURCHASE:
            return {
                ...state,
                purchases: state.purchases.filter(purchase => purchase.purchase_id !== action.payload.purchaseId),
            };

        // Bulk Purchase Actions
        case GET_BULK_PURCHASES:
            //console.log(action.payload,'---payload')
            return { ...state, purchases: action.payload };

        case DELETE_BULK_PURCHASES:
            return {
                ...state,
                purchases: state.purchases.filter(purchase => !action.payload.deletedPurchaseIds.includes(purchase.purchase_id)),
            };

        // Single Purchase Item Actions
        case CREATE_PURCHASE_ITEM:
            return { ...state, purchaseItems: [...state.purchaseItems, action.payload] };

        case GET_PURCHASE_ITEM:
            return { ...state, purchaseItemDetails: action.payload };

        case UPDATE_PURCHASE_ITEM:
            return {
                ...state,
                purchaseItems: state.purchaseItems.map(item =>
                    item.purchase_item_id === action.payload.purchase_item_id ? action.payload : item
                ),
            };

        case DELETE_PURCHASE_ITEM:
            return {
                ...state,
                purchaseItems: state.purchaseItems.filter(item => item.purchase_item_id !== action.payload.purchaseItemId),
            };

        // Bulk Purchase Item Actions
        case GET_BULK_PURCHASE_ITEMS:
            return { ...state, purchaseItems: action.payload };

        case CREATE_BULK_PURCHASE_ITEMS:
            return { ...state, purchaseItems: [...state.purchaseItems, ...action.payload] };

        case UPDATE_BULK_PURCHASE_ITEMS:
            return {
                ...state,
                purchaseItems: state.purchaseItems.map(item => {
                    const updatedItem = action.payload.find(updated => updated.purchase_item_id === item.purchase_item_id);
                    return updatedItem ? updatedItem : item;
                }),
            };
 
        case DELETE_BULK_PURCHASE_ITEMS:
            return {
                ...state,
                purchaseItems: state.purchaseItems.filter(item => !action.payload.deletedItems.includes(item.purchase_item_id)),
            };

        default:
            return state;
    }
};

export default purchaseReducer;
