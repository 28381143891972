import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.scss';
import {
  FaHome,
  FaBox,
  FaClipboardList,
  FaChartBar,
  FaUser,
  FaMapMarkerAlt,
  FaEnvelope,
} from 'react-icons/fa';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null); // Track active item
  const navigate = useNavigate();

  const handleItemClick = (path, index) => {
    setActiveItem(index); // Set the active item index
    navigate(path);
  };

  return (
    <div
      className={`sidebar-container ${isOpen ? 'open' : ''}`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <ul className="sidebar-list">
        <li
          className={`sidebar-item ${activeItem === 0 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin/dashboard', 0)}
        >
          <FaHome className="sidebar-icon" />
          <span className="sidebar-text">Dashboard</span>
        </li>
        <li
          className={`sidebar-item ${activeItem === 1 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin/user-management', 1)}
        >
          <FaUser className="sidebar-icon" />
          <span className="sidebar-text">User Management</span>
        </li>
        <li
          className={`sidebar-item ${activeItem === 2 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin', 2)}
        >
          <FaBox className="sidebar-icon" />
          <span className="sidebar-text">Inventory</span>
        </li>
        <li
        
          className={`sidebar-item ${activeItem === 3 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin/order-management', 3)}
        >
          <FaClipboardList className="sidebar-icon" />
          <span className="sidebar-text">Orders</span>
        </li>
        <li
          className={`sidebar-item ${activeItem === 4 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin/banners', 4)}
        >
          <FaChartBar className="sidebar-icon" />
          <span className="sidebar-text">Banners</span>
        </li>
        <li
          className={`sidebar-item ${activeItem === 5 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin/location', 5)}
        >
          <FaMapMarkerAlt className="sidebar-icon" />
          <span className="sidebar-text">Location</span>
        </li>
        <li
          className={`sidebar-item ${activeItem === 6 ? 'active' : ''}`}
          onClick={() => handleItemClick('/admin/subscription-management', 6)}
        >
          <FaEnvelope className="sidebar-icon" />
          <span className="sidebar-text">Subscription</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
