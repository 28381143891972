import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas } from 'react-bootstrap';
import OffersCard from '../common/OffersCard/OffersCard';
import './OffcanvasComponent.scss';
import { fetchAllocatedCoupons } from '../../redux/actions/couponAllocationAction';
import { fetchCoupons } from '../../redux/actions/couponAction';

const OffcanvasComponent = ({ show, handleClose, onApplyCoupon }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token) || "";
  const userId = useSelector((state) => state.auth.user);
  const orders = useSelector((state) => state.orders.orders) || []; // Fetch orders from Redux
  const cartItems = useSelector((state) => state.cart.cartItems); // Adjust state path
  const couponAllocations = useSelector((state) => state.allocateCoupon.allocatedCoupons);
  const categories = useSelector((state) => state.products.basicProducts || []); // Adjust state path
  const couponlist = useSelector((state) => state.coupon.couponList);

  // Fetch allocated coupons and coupons
  useEffect(() => {
    dispatch(fetchAllocatedCoupons(token));
    dispatch(fetchCoupons(token));
  }, [dispatch, token]);

  // Extract category IDs for products in the cart
  const cartCategoryIds = cartItems
    .map((item) => {
      const productCategory = categories.find((category) => category.product_id === item.product_id);
      return productCategory ? productCategory.category_id : null;
    })
    .filter((categoryId) => categoryId !== null);

  // Combine product IDs and category IDs
  const combinedIds = [
    ...cartItems.map((item) => item.product_id),
    ...cartCategoryIds,
  ];

  // Filter applicable coupons based on combined IDs
  const applicableCoupons = couponAllocations.filter((coupon) =>
    combinedIds.includes(coupon.entity_id)
  );

  // Filter out expired coupons
  const currentDate = new Date();
  const validCoupons = couponlist.filter((coupon) => {
    const isApplicable = applicableCoupons.some((item) => item.coupon_id === coupon.coupon_id);
    const isValidDate = new Date(coupon.expiry_date) >= currentDate; // Check if coupon is not expired
    return isApplicable && isValidDate;
  });

  // State for filtered coupons
  const [filteredCoupons, setFilteredCoupons] = useState(validCoupons);

  useEffect(() => {
    setFilteredCoupons(validCoupons); // Initialize with all valid coupons
  }, [validCoupons]);

  // Determine offcanvas placement based on screen width
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (searchValue) => {
    const lowercasedSearchValue = searchValue.toLowerCase();
    const filtered = validCoupons.filter(
      (coupon) =>
        coupon.code.toLowerCase().includes(lowercasedSearchValue) ||
        coupon.description.toLowerCase().includes(lowercasedSearchValue)
    );
    setFilteredCoupons(filtered);
  };

  const [appliedCoupon, setAppliedCoupon] = useState(null);

  const handleApplyCoupon = (code) => {
    const validCoupon = couponlist.find((coupon) => coupon.code === code);
    if (validCoupon) {
      // Check how many times the coupon has been used
      const usedCount = orders.filter(
        (order) => order.coupon_id === validCoupon.coupon_id && order.user_id === userId
      ).length;

      // Compare with max allowed per user
      if (usedCount < validCoupon.usage_per_user) {
        setAppliedCoupon(validCoupon);
        onApplyCoupon(validCoupon.coupon_id); // Pass discount value to BillDetailsCard
      } else {
        alert(`This coupon can only be used ${validCoupon.usage_per_user} times.`);
      }
    } else {
      alert('Invalid coupon code');
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement={isMobile ? 'bottom' : 'end'}
      className={isMobile ? 'offcanvas-mobile' : 'offcanvas-desktop'}
    >
      <Offcanvas.Header closeButton>
        <p className="offcanvas-title">Coupons for you</p>
      </Offcanvas.Header>
      <Offcanvas.Body>
      

        <div className="card-offers">
          <h6 className="card-offers-available-title">Available Offers</h6>
          {filteredCoupons.length > 0 ? (
            filteredCoupons.map((coupon) => (
              <OffersCard
                key={coupon.coupon_id}
                offerCode={coupon.code}
                description={coupon.description}
                additionalInfo={coupon.additionalInfo}
                offerDetails={coupon}
                onApplyOffer={() => handleApplyCoupon(coupon.code)}
              />
            ))
          ) : (
            <p className="no-offers">No matching coupons found.</p>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasComponent;
