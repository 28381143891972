import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom'; // Import useNavigate and Router
import { Provider } from 'react-redux';
import store from './redux/store';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import PrimaryNavbar from './components/layout/PrimaryNavbar/PrimaryNavbar';
import Footer from './components/layout/Footer/Footer';
import Sidebar from './components/layout/Sidebar/Sidebar'; // Import Sidebar
import TokenProvider from './components/TokenGenerator/TokeGeneration';
import { Toaster } from 'react-hot-toast';
import './styles/global.scss';
import './App.css';
import BottomNavigation from './components/layout/BottomNavigation/BottomNavigation';
// Non-Admin Pages
import Homepage from './pages/HomePage/HomePage';
import Refer from './pages/ReferPage/ReferPage';
import SingleProductDetails from './pages/SingleProductDetails/SingleProductDetails';
import Categories from './pages/Categories/Categories';
import MyProfile from './pages/MyProfile/MyProfile';
import MyOrderDetails from './pages/MyOrderDetails/MyOrderDetails';
import Orders from './pages/Orders/Orders';
import OrderSuccessPage from './pages/OrderSuccessPage/OrderSuccessPage';
import Cart from './pages/cart/cart';
import ProductItemCards from './pages/ProductDetails/product';
import SubscriptionPage from './pages/subscription-page/subscription-page';
import SubscriptionDetails from './pages/subscription-details/subscription-details';


import AdminProductsPage from './pages/AdminProductsPage/AdminProductsPage';
import AdminImportPage from './pages/AdminImportPage/AdminImportPage';
import FormListPage from './pages/FormListPage/FormListPage';
import UserPage from './pages/userpage/UserPage';
import AddItemPage from './pages/AddItemPage/AddItemPage';
import PurchaseList from './pages/purchase-list/PurchaseList';
import NewManualStockList from './pages/new_manual_stock_list/new_manual_stock_list';
import RequestForPurchaseList from './pages/request_for_purchase_list/request_for_purchase_list';
import PurchaseOrderList from './pages/purchase_order_list/purchase_order_list';
import AddRequestForPurchase from './pages/add-request-for-purchase/add-request-for-purchase';
import BrandProducts from './pages/BrandProducts/BrandProducts';
import Addpurchase from './pages/add-purchase/AddPurchase';
import ClosingStock from './pages/closing-stock/ClosingStock';
import PersonalInformation from './pages/personal-information/personal-information';
import BusinessInformation from './pages/business-information/business-information';
import RefundPage from './pages/refundpage/RefundPage';
import CouponPage from './pages/CouponPage/CouponPage';
import OfferPage from './pages/OfferPage/OfferPage';
import BannerPage from './pages/Banner/BannerPage';
import AddDeliveryPerson from './pages/add-delivery-person/AddDeliveryPerson';
import DeliveryLocationConfiguration from './pages/delivery-location-configuration/DeliveryLocationConfiguration';
import AddLocation from './pages/add-location/AddLocation';
import BasicInfoDetailsPage from './pages/basic-info-details-page/BasicInfoDetailsPage';
import CustomDetails from './pages/custom-details/CustomDetails';
import AdminMainPage from './pages/AdminMainPage/admin_main_page';
import AdminCategories from './pages/AdminCategory/admin-categories';
import Brands from './pages/AdminBrandManagement/brands';
import AdminUserManagement from './pages/UserManagement/MainUserManagement';
import AdminUser from './pages/AdminAddUser/admin-user';
import AddAdmin from './pages/AdminAddAdminPage/createadminPage';
import CouponAllocationList from './pages/couponAllocation/couponAllocationListPage';
import AllocateCoupon from './pages/couponAllocation/AllocateCoupon';
import SupplierManagement from './pages/Supplier/supplier-management';
import SupplierDisplayPage from './pages/Supplier/supplierlistPage';
import AdminLogin from './pages/admin_login/adminlogin';
import AdminRegister from './pages/admin_login/adminregister';
import CategoryTable from './pages/buk-category/categorytable';
import BrandTable from './pages/bulk-brand/brandtable';
import BulkUsers from './pages/bulk-user/bulkuser';
import CouponTable from './pages/bulk-coupon/coupontable';
import BulkAllocateCoupon from './pages/bulk-coupon-allocation/coupontable';
import BulkDelivery from './pages/bulk-delivery/bulk-delivery';
import BulkProduct from './pages/bulk-products/bulk-product';
import SupplierTable from './pages/bulk-supplier/bulk-supplier';
import BannerDisplayPage from './pages/AdminBanner/bannerList';
import Banners from './pages/AdminBanner/Addbanner';
import ProfileForm from './pages/profilepage/profilepage';
import OfferTable from './pages/bulk-discount/bulk-discount';
import OfferAllocationList from './pages/AllocateDiscount/discountallocationdisplay';
import AllocateOffer from './pages/AllocateDiscount/AddDiscountAllocation';
import BulkAllocateOffer from './pages/bulk-discount-allocation/bulk-allocate-discount';
import LocationDisplayPage from './pages/LocationService/Locationservice';
import LocationPage from './pages/LocationService/AddLocation';
import BulkAllocateLocation from './pages/bulk-location/bulk-location';
import OrderManagement from './pages/orderAssingnation/orderAssign';
import AddOrder from './pages/orderAssingnation/orderEdit';
import OrderExportTable from './pages/OrderExport/orderExport';
import SubscriptionSection from './pages/Subscription-Checkout/subscription-checkout';
import ExpiryAlert from './pages/expiry-alert/expiry-alert';
import ExpiryAlertDisplayPage from './pages/expiry-alert/expiry-alert-displaypage';
import InventoryThreshold from './pages/inventory-threshold/inventory-threshold';
import InventoryDisplayPage from './pages/inventory-threshold/inventory-displaypage';

import DeliveryConfig from './pages/delivery-config/delivery-config';
import DeliveryConfigDisplayPage from './pages/delivery-config/delivery-config-displaypage';

import AddSubscription from './pages/admin-subscription/add-admin-subscription';
import SubscriptionDisplayPage from './pages/admin-subscription/admin-subscription-displaypage';

import AdminSubscriptionManagement from './pages/admin-subscriptionmanagement/admin-subscriptionmanagement';
import AddNotificationCreation from './pages/admin-notification-creation/add-notification-creation';
import AdminNotificationCreation from './pages/admin-notification-creation/notification-creation-displaypage';
import RefundPolicy from './pages/Refund/refund';
import TermsAndConditions from './pages/Terms/termsConditions';
import PrivacyPolicy from './pages/privacy/privacy';
import ShippingDelivery from './pages/shipping-delivery-policy/shipping';
import ScrollToTop from './components/scrollTop';
const AppContent = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Define navigation handler
  const handleNavigation = (path) => {
    navigate(path);
  };
  useEffect(() => {
    // Force scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, [location.pathname]); 

  useEffect(() => {
    const contentContainer = document.querySelector('.content-container');
    if (contentContainer) {
      contentContainer.scrollTop = 0; // Reset container scroll
    }
  }, []);

  // Check if the current route is an admin route
  const isAdminRoute =
    location.pathname.startsWith('/admin') &&
    location.pathname !== '/admin/login' &&
    location.pathname !== '/admin/register';

  // Define additional sidebar routes
  const additionalSidebarRoutes = [
    '/basic-info-details',
    '/custom-details',
    '/delivery/add-location',
    '/delivery/add-delivery-person',
  ];

  // Determine if the sidebar should be displayed
  const showSidebar =
  isAdminRoute ||
  additionalSidebarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );
  const hiddenLogoutRoutes = ['/admin/login', '/admin/register'];
  return (
    <div className="App">
      {/* Primary Navbar */}
      <TokenProvider/>
      {!hiddenLogoutRoutes.includes(location.pathname) && <PrimaryNavbar />}

      {/* <div className="main-containers">
      <div style={{ display: 'flex', Height: 'auto' }}> */}
        {/* Render sidebar only for admin routes */}
        {showSidebar && <Sidebar />}
       
        {/* <div style={{ flex: 1, overflow: 'auto' }}> */}

        {/* Main Content Area */}
        <div className={`content-container ${isAdminRoute || additionalSidebarRoutes.some(route => location.pathname.startsWith(route)) ? 'with-sidebar' : ''}`}>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <Routes>
              {/* Non-Admin Routes */}
              <Route path="/" element={<Homepage/>} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/singleproducts/:productname" element={<SingleProductDetails />} />
              <Route path="/brandproducts/:brand" element={<BrandProducts />} />
              <Route path="/categories/:category" element={<Categories />} />
              <Route path="/refer" element={<Refer />} />
              <Route path="/myprofile" element={<MyProfile />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/myorderdetails" element={<MyOrderDetails />} />
              <Route path="/ordersuccess" element={<OrderSuccessPage />} />
              <Route path="/products" element={<ProductItemCards/>}/>
              <Route path="/subscribed-product" element={<SubscriptionPage/>} />
              <Route path='/subscription-details' element={<SubscriptionDetails/>}/>
              <Route path='/subscritpion-checkout' elemment={<SubscriptionSection/>}/>
              {/* Admin Routes */}
              <Route path="/admin/products" element={<AdminProductsPage />} />
              <Route path="/admin/uploadpage" element={<AdminImportPage/>} />
              <Route path="/admin/formlist" element={<FormListPage />} />
              <Route path="/admin/dashboard" element={<UserPage />} />
              <Route path="/admin/additem" element={<AddItemPage />} />
              <Route path="/admin/purchase-list" element={<PurchaseList />} />
              <Route path="/admin/new_manual_stock_list" element={<NewManualStockList />} />
              <Route path="/admin/purchase_order_list" element={<PurchaseOrderList />} />
              <Route path="/admin/request_for_purchase_list" element={<RequestForPurchaseList />} />
              <Route path="/admin/add-request-for-purchase" element={<AddRequestForPurchase />} />
              <Route path="/admin/add-purchase" element={<Addpurchase />} />
              <Route path="/admin/closingstock" element={<ClosingStock />} />
              <Route path="/admin/business_information" element={<BusinessInformation />} />
              <Route path="/admin/personal_information" element={<PersonalInformation />} />
              <Route path="/admin/refund" element={<RefundPage />} />
              <Route path="/admin/add-coupon" element={<CouponPage />} />
              <Route path="/admin/offer" element={<OfferPage />} />
              <Route path="/admin/banner" element={<BannerPage />} />
              <Route path="/delivery/add-delivery-person" element={<AddDeliveryPerson />} />
              <Route path="/delivery/delivery-location-configuration" element={<DeliveryLocationConfiguration />} />
              <Route path="/delivery/add-location" element={<AddLocation />} />
              <Route path='/basic-info-details' element={<BasicInfoDetailsPage />} />
              <Route path='/custom-details' element={<CustomDetails />} />
              <Route path='/admin' element={<AdminMainPage />} />
              <Route path='/admin/add-category' element={<AdminCategories/>} />
              <Route path='/admin/add-brand' element={<Brands/>} />
              <Route path='/admin/user-management' element={<AdminUserManagement/>} />
              <Route path='/admin/add-user' element={<AdminUser/>}/>
              <Route path='/admin/add-admin' element={<AddAdmin/>}/>
              <Route path='/admin/coupon/allocate'element={<CouponAllocationList/>} />
              <Route path='/admin/coupon/add-allocate' element={<AllocateCoupon/>}/>
              <Route path='/admin/add-supplier' element={<SupplierManagement/>} />
              <Route path='/admin/supplier' element={<SupplierDisplayPage/>}/>
              <Route path='/admin/login' element={<AdminLogin/>}/>
              <Route path='/admin/bulk-category' element={<CategoryTable/>}/>
              <Route path='/admin/bulk-brand' element={<BrandTable/>}/>
              <Route path='/admin/bulk-coupon' element={<CouponTable/>}/>
              <Route path='/admin/bulk-allocate-coupon' element={<BulkAllocateCoupon/>}/>
              <Route path='/admin/bulk-delivery' element={<BulkDelivery/>}/>
              <Route path='/admin/bulk-user' element={<BulkUsers/>} />
              <Route path='/admin/bulk-products' element={<BulkProduct/>} />
              <Route path='/admin/bulk-supplier' element={<SupplierTable/>} />
              <Route path='/admin/register' element={<AdminRegister/>}/>
              <Route path='/admin/banners' element={<BannerDisplayPage/>}/>
              <Route path='/admin/add-banner' element={<Banners/>}/>
              <Route path='/admin/profile' element={<ProfileForm/>}/>
              <Route path='/admin/bulk-offer' element={<OfferTable/>}/>
              <Route path='/admin/offer-allocation' element={<OfferAllocationList/>}/>
              <Route path='/admin/add-offer-allocation' element={<AllocateOffer/>}/>
              <Route path='/admin/bulk-allocate-offer' element={<BulkAllocateOffer/>}/>
              <Route path='/admin/location' element={<LocationDisplayPage/>}/>
              <Route path='/admin/add-location' element={<LocationPage/>}/>
              <Route path='/admin/bulk-location' element={<BulkAllocateLocation/>}/>
              <Route path='/admin/order-management' element={<OrderManagement/>}/>
              <Route path='/admin/order-status-management' element={<AddOrder/>}/>
              <Route path='/admin/order-export-table' element={<OrderExportTable/>}/>
              <Route path= '/Subscription-checkout' element ={<SubscriptionSection/>}/>
              <Route path='/admin/expiry-alert' element={<ExpiryAlert/>}/>
             <Route path='/admin/expiry-alert-displaypage' element={< ExpiryAlertDisplayPage/>}/>
              <Route path='/admin/inventory-displaypage' element={<InventoryDisplayPage/>}/>
              <Route path='/admin/inventory-threshold' element={<InventoryThreshold/>}/>
              <Route path='/admin/subscription-displaypage' element={<SubscriptionDisplayPage/>}/>
              <Route path='/admin/add-subscription' element={<AddSubscription/>}/>
             

              <Route path='/admin/delivery-config' element={<DeliveryConfig/>}/>
              <Route path='/admin/delivery-config-displaypage' element={<DeliveryConfigDisplayPage/>}/>
              <Route path='/admin/subscription-management' element={<AdminSubscriptionManagement/>}/>
              <Route path='/admin/add-notification-creation' element={<AddNotificationCreation/>}/>
              <Route path='/admin/notification-creation-displaypage' element={<AdminNotificationCreation/>}/>
              <Route path='/terms_conditions' element={<TermsAndConditions/>}/>
              <Route path='/refund' element={<RefundPolicy/>}/>
              <Route path='/privacy' element={<PrivacyPolicy/>}/>
              <Route path="/shipping_delivery" element={<ShippingDelivery />} />

            </Routes>
          )}

{!hiddenLogoutRoutes.includes(location.pathname) && !isAdminRoute  &&!(location.pathname==='/login') && <BottomNavigation handleNavigation={handleNavigation} />}

{!hiddenLogoutRoutes.includes(location.pathname) && !isAdminRoute  &&!(location.pathname==='/login') &&<Footer />}
          
    
          
        </div>
      </div>
      
    //   </div>
    //   </div>
      
    // </div>
    
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
      <ScrollToTop /> 
        <Toaster />
        <AppContent />
      </Router>
    </Provider>
  );
};

export default App;