import {
    fetchAllExpiryAlerts,
    fetchExpiryAlertById,
    createExpiryAlert,
    updateExpiryAlertById, // Updated name
    deleteExpiryAlertById, // Updated name
    fetchBulkExpiryAlerts,
    createBulkExpiryAlerts,
    updateBulkExpiryAlerts,
    deleteBulkExpiryAlerts,
  } from '../../api/services/expiryAlertService';
  
  // Action Types
  export const EXPIRY_ALERTS_FETCH_SUCCESS = 'EXPIRY_ALERTS_FETCH_SUCCESS';
  export const EXPIRY_ALERTS_FETCH_ERROR = 'EXPIRY_ALERTS_FETCH_ERROR';
  export const EXPIRY_ALERT_CREATE_SUCCESS = 'EXPIRY_ALERT_CREATE_SUCCESS';
  export const EXPIRY_ALERT_CREATE_ERROR = 'EXPIRY_ALERT_CREATE_ERROR';
  export const EXPIRY_ALERT_UPDATE_SUCCESS = 'EXPIRY_ALERT_UPDATE_SUCCESS';
  export const EXPIRY_ALERT_UPDATE_ERROR = 'EXPIRY_ALERT_UPDATE_ERROR';
  export const EXPIRY_ALERT_DELETE_SUCCESS = 'EXPIRY_ALERT_DELETE_SUCCESS';
  export const EXPIRY_ALERT_DELETE_ERROR = 'EXPIRY_ALERT_DELETE_ERROR';
  export const EXPIRY_ALERTS_BULK_FETCH_SUCCESS = 'EXPIRY_ALERTS_BULK_FETCH_SUCCESS';
  export const EXPIRY_ALERTS_BULK_FETCH_ERROR = 'EXPIRY_ALERTS_BULK_FETCH_ERROR';
  export const EXPIRY_ALERTS_BULK_CREATE_SUCCESS = 'EXPIRY_ALERTS_BULK_CREATE_SUCCESS';
  export const EXPIRY_ALERTS_BULK_CREATE_ERROR = 'EXPIRY_ALERTS_BULK_CREATE_ERROR';
  export const EXPIRY_ALERTS_BULK_UPDATE_SUCCESS = 'EXPIRY_ALERTS_BULK_UPDATE_SUCCESS';
  export const EXPIRY_ALERTS_BULK_UPDATE_ERROR = 'EXPIRY_ALERTS_BULK_UPDATE_ERROR';
  export const EXPIRY_ALERTS_BULK_DELETE_SUCCESS = 'EXPIRY_ALERTS_BULK_DELETE_SUCCESS';
  export const EXPIRY_ALERTS_BULK_DELETE_ERROR = 'EXPIRY_ALERTS_BULK_DELETE_ERROR';
  
  // Fetch all expiry alerts
  export const getAllExpiryAlerts = (token) => async (dispatch) => {
    try {
      const data = await fetchAllExpiryAlerts(token);
      dispatch({ type: EXPIRY_ALERTS_FETCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERTS_FETCH_ERROR, payload: error.message });
    }
  };
  
  // Fetch a single expiry alert
  export const getExpiryAlertById = (alertId, token) => async (dispatch) => {
    try {
      const data = await fetchExpiryAlertById(alertId, token);
      dispatch({ type: EXPIRY_ALERTS_FETCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERTS_FETCH_ERROR, payload: error.message });
    }
  };
  
  // Create a new expiry alert
  export const createNewExpiryAlert = (alertData, token) => async (dispatch) => {
    try {
      const data = await createExpiryAlert(alertData, token);
      dispatch({ type: EXPIRY_ALERT_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERT_CREATE_ERROR, payload: error.message });
    }
  };
  
  // Update an expiry alert
  export const updateExpiryAlertByIdAction = (alertId, alertData, token) => async (dispatch) => {
    try {
      const data = await updateExpiryAlertById(alertId, alertData, token);
      dispatch({ type: EXPIRY_ALERT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERT_UPDATE_ERROR, payload: error.message });
    }
  };
  
  // Delete an expiry alert
  export const deleteExpiryAlertByIdAction = (alertId, token) => async (dispatch) => {
    try {
      await deleteExpiryAlertById(alertId, token);
      dispatch({ type: EXPIRY_ALERT_DELETE_SUCCESS, payload: alertId });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERT_DELETE_ERROR, payload: error.message });
    }
  };
  
  // Fetch bulk expiry alerts
  export const getBulkExpiryAlerts = (token) => async (dispatch) => {
    try {
      const data = await fetchBulkExpiryAlerts(token);
      dispatch({ type: EXPIRY_ALERTS_BULK_FETCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERTS_BULK_FETCH_ERROR, payload: error.message });
    }
  };
  
  // Bulk create expiry alerts
  export const createBulkExpiryAlertsAction = (alertData, token) => async (dispatch) => {
    try {
      const data = await createBulkExpiryAlerts(alertData, token);
      dispatch({ type: EXPIRY_ALERTS_BULK_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERTS_BULK_CREATE_ERROR, payload: error.message });
    }
  };
  
  // Bulk update expiry alerts
  export const updateBulkExpiryAlertsAction = (alertData, token) => async (dispatch) => {
    try {
      const data = await updateBulkExpiryAlerts(alertData, token);
      dispatch({ type: EXPIRY_ALERTS_BULK_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERTS_BULK_UPDATE_ERROR, payload: error.message });
    }
  };
  
  // Bulk delete expiry alerts
  export const deleteBulkExpiryAlertsAction = (alertIds, token) => async (dispatch) => {
    try {
      await deleteBulkExpiryAlerts(alertIds, token);
      dispatch({ type: EXPIRY_ALERTS_BULK_DELETE_SUCCESS, payload: alertIds });
    } catch (error) {
      dispatch({ type: EXPIRY_ALERTS_BULK_DELETE_ERROR, payload: error.message });
    }
  };
  