import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faSearch, faHome, faPencilAlt, faBars, faTrash } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DeletePopup from '../../components/common/deletepopup/deletepopup';

import AdminCategory from '../../components/AdminCategory/AdminCategory';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import { fetchProducts, deleteSingleProductWorkflow, deleteProductsWorkflow } from '../../redux/actions/productActions';
import './AdminProductsPage.scss';
import { formatDate } from '../../utils/schema/DatFormatter';
const AdminProductsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.adminLogin.token);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deletingProductId, setDeletingProductId] = useState(null);
  useEffect(() => {
    const fetchInitialProducts = () => {
      dispatch(fetchProducts(token));
    };
    fetchInitialProducts();
    
    const intervalId = setInterval(fetchInitialProducts, 180000);
    
    return () => clearInterval(intervalId);
  }, [dispatch, token]);
 
  const basicProducts = useSelector((state) => state.products.basicProducts) || [];
  const customProducts = useSelector((state) => state.products.customProducts) || [];
  const categoryOptions=useSelector((state) => state.category.categoryList.categories)|| []; 
 
  const brandOptions=useSelector((state) => state.brand.brandList.brands);
  
  const getCatName=(categoryId)=>{
    const cat_data = categoryOptions.find(cat=> cat.category_id == categoryId)
    if (cat_data){
      return cat_data.name
    }

  }

  const getbrandName=(brandId)=>{
    const brand_data = brandOptions.find(brand=> brand.brand_id == brandId)
    if (brand_data){
      return brand_data.name
    }

  }
  const navigationOptions = {
    gridPath: '/basic-info-details',
    sheetPath: '/admin/uploadpage',
    from: "products"
  };

  const combinedProducts = useMemo(() => {
    return basicProducts.map((basicProduct) => {
      const customProduct = customProducts.find((custom) => custom.product_id === basicProduct.product_id);
      return { ...basicProduct, ...customProduct };
    });
  }, [basicProducts, customProducts]);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const fileInputRef = useRef(null);

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one product to proceed.");
      return;
    }

    if (action === "edit") {
      navigate('/admin/bulk-products', { state: selectedIds });
      setSelectedIds([]);
    } else if (action === "delete") {
      if (window.confirm(`Are you sure you want to delete the selected products?`)) {
        dispatch(deleteProductsWorkflow(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };
  useEffect(() => {
    let products = combinedProducts;

    if (selectedCategory) {
        products = products.filter((product) => product.category_id === selectedCategory);
    }

    if (searchTerm) {
        products = products.filter((product) =>
            product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            getBrandName(product.brand_id).toLowerCase().includes(searchTerm.toLowerCase()) ||
            (product.prescription_required ? 'yes' : 'no').includes(searchTerm.toLowerCase())
        );
    }

    setFilteredProducts(products);
}, [selectedCategory, combinedProducts, searchTerm]);


  useEffect(() => {
    if (selectedCategory) {
      setFilteredProducts(combinedProducts.filter((product) => product.category_id === selectedCategory));
    } else {
      setFilteredProducts(combinedProducts);
    }
  }, [selectedCategory, combinedProducts]);

  const handleCategorySelect = (category) => {
    //console.log('selected category',category)
    setSelectedCategory(category);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = combinedProducts.map(item => item.product_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  const getBrandName = (brandId) => {
    const brandData = brandOptions.find(brand => brand.brand_id === brandId);
    return brandData ? brandData.name : 'Unknown';
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setSelectAll(selectedIds.length === combinedProducts.length);
  }, [selectedIds, combinedProducts.length]);

  const handleCheckboxChange = (productId) => {
    setSelectedIds(prev => (
      prev.includes(productId) ? prev.filter(id => id !== productId) : [...prev, productId]
    ));
  };

  const handleEditClick = (product) => {
    navigate('/basic-info-details', { state: product });
  };

  const handleDeleteClick = async (productId) => {
    setShowDeletePopup(true); // Show the popup
    setDeletingProductId(productId);

    try {
      await dispatch(deleteSingleProductWorkflow(productId, token)); // Delete the product
    } catch (error) {
      //console.error('Error deleting product:', error);
    } finally {
      setShowDeletePopup(false); // Hide the popup after deletion
      setDeletingProductId(null); // Clear the deleting product ID
    }
  };

  return (
    <div className="admin-page-layout">
    <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Product List
        </Link>
       
      </div>
      <div className="product-container">
              <button
                className="toggle-icon-btn"
                onClick={() => setShowOffcanvas(true)}
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
              <h5 className="text-heading5">Product List</h5>
              {showOffcanvas && (
                <AdminCategoryOffcanvas
                  show={showOffcanvas}
                  onHide={() => setShowOffcanvas(false)}
                />
              )}
            </div>
      <div className="admin-page-container">
        <div className="left-section">
          <AdminCategory onCategorySelect={handleCategorySelect} />
        </div>
        <div className="right-section">
          <div className="toolbar">
            <button className="justify-button" onClick={() => setShowOffcanvas(true)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input type="text" className="search-bar" placeholder="Search"value={searchTerm}
                onChange={handleSearchChange} />
            </div>
            <ButtonComponent text="Purchase Request" className="purchase-button" onClick={() => navigate('/admin/request_for_purchase_list')} />
            <ButtonComponent text="Stock Purchase"  className="stock-button" onClick={() => navigate('/admin/purchase-list')} />
            <ButtonComponent text="Supplier"  className="supplier-button" onClick={() => navigate('/admin/supplier')} />
          </div>

          <div className="item-controls">
            <ButtonComponent text="+ Add Items" className="add-items-button" onClick={() => setShowModal(true)} />
            <div className="actions-toolbar">
              <Dropdown>
                <Dropdown.Toggle  className="dropdown-toggle" id="actions-dropdown">Actions</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="table-container1">
            <table className="custom-table1">
              <thead>
                <tr>
                  <th><CheckboxComponent checked={selectAll} onChange={handleSelectAll} /></th>
                  <th>ID</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Description</th>
                  <th>Brand Name</th>
                  <th>MRP</th>
                  <th>Unit</th>
                  <th>Stock Level</th>
                 
                  <th>Actions</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr key={product.product_id}>
                    <td>
                      <CheckboxComponent checked={selectedIds.includes(product.product_id)} onChange={() => handleCheckboxChange(product.product_id)} />
                    </td>
                    <td>{index + 1}</td>
                    <td>{product.product_name}</td>
                    <td>{getCatName(product.category_id)}</td>
                    <td>{product.price}</td>
                    <td>{product.description}</td>
                    <td>{getbrandName(product.brand_id)}</td>
                    <td>{product.mrp}</td>
                    <td>{product.unit}</td>
                    <td>{product.stock_level}</td>
                    {/* <td>{product.prescription_required ? 'Yes' : 'No'}</td> */}
          
                    {/* <td>{formatDate(product.manufacturing_date)}</td>         
                    <td>{formatDate(product.expiry_date)}</td> */}
                    <td>
                      <button className="icon-button" onClick={() => handleEditClick(product)}><FontAwesomeIcon icon={faPencilAlt} /></button>
                    </td>
                    <td>
                    <button
                    className="icon-button"
                    onClick={() => handleDeleteClick(product.product_id)}
                    disabled={showDeletePopup && deletingProductId === product.product_id}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

         {/* Show the DeletePopup while deleting */}
      {showDeletePopup && deletingProductId && <DeletePopup />}
        
          <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} />
          <AdminCategoryOffcanvas
    show={showOffcanvas}
    onClose={() => setShowOffcanvas(false)}
    onCategorySelect={handleCategorySelect}  // Ensure this is passed
/>

          {/* <div className="showing-records-pagination">
            <div className="showing-records">
              <p>Showing {currentPage * 50 - 49} to {Math.min(currentPage * 50, totalRecords)} of {totalRecords} records</p>
            </div>
            <div className="pagination-section">
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminProductsPage;
