import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerAction } from '../../redux/actions/adminRegisterActions';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { uploadDocumentAPI } from '../../api/services/filestorageService'; // Import file upload function
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import './adminregister.scss';
import doctorImage from '../../assets/images/doctor.png';
import pharmaLogo from '../../assets/images/freshpure.png';
const AdminRegister = () => {
  const navigate=useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [internalId, setInternalId] = useState('');
  const [role, setRole] = useState('admin');
  const [phone, setPhone] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.adminRegister);
  const token = useSelector((state) => state.auth.token);
  //console.log(token,'token------')
  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileType = file?.name.split('.').pop().toLowerCase();
    if (['pdf', 'jpg', 'jpeg', 'png'].includes(fileType)) {
      setSelectedFile(file);
      setErrorMessage('');
    } else {
      setSelectedFile(null);
      setErrorMessage('Only .pdf, .jpg, .jpeg, and .png files are supported.');
    }
  };

  // Validate form fields
  const validateForm = () => {
    return (
      name &&
      email &&
      password &&
      confirmPassword &&
      phone &&
      internalId &&
      role &&
      password === confirmPassword
    );
  };

  // Handle form submission with file upload
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please ensure all fields are filled and passwords match.');
      return;
    }

    try {
      let proofUrl = '';
      // Upload proof file if selected
      if (selectedFile) {
        const response = await uploadDocumentAPI(selectedFile,'proof/', token);
        proofUrl = response.file_url; // Set uploaded file URL
      }

      // Prepare form data with proof URL
      const formData = {
        username: name,
        password,
        confirm_password: confirmPassword,
        email,
        mobile_number: phone,
        internal_id: internalId,
        role: "admin",
        proof: proofUrl, // Set proof URL
      };

      // Dispatch the registration action
      const response = await dispatch(registerAction(formData))
      if (response){
        toast.success("Registration successfull, Email has sent to your mail for verification");
          navigate('/admin/login');
      }
    
    } catch (error) {
      //console.error('File upload failed:', error);
      setErrorMessage('Failed to upload the proof file. Please try again.');
    }
  };

  return (
    <div className="admin-login-container">
         <div className="admin-login-logo">
        <img src={pharmaLogo} alt="Pharma" className="img-fluid login-pharma-logo" />
      </div>
      <div className="admin-login-box">
        <div className="admin-login-left">
          <img src={doctorImage} alt="Doctor" className="img-fluid login-doc-image" />
        </div>
        <div className="admin-login-right">
          <h6 className="sign-in-header">Register</h6>
          <p className="access-account">to access your account</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Username</label>
              <InputComponent
                type="text"
                className="form-control login-input"
                placeholder="Enter Username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <InputComponent
                type="email"
                className="form-control login-input"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <InputComponent
                type="number"
                className="form-control login-input"
                placeholder="Enter Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <InputComponent
                type="password"
                className="form-control login-input"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <InputComponent
                type="password"
                className="form-control login-input"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Internal ID</label>
              <InputComponent
                type="number"
                className="form-control login-input"
                placeholder="Enter Internal ID"
                value={internalId}
                onChange={(e) => setInternalId(e.target.value)}
                required
              />
            </div>
           
            <div className="form-group">
              <label>Upload Proof</label>
              <input
                type="file"
                className="form-control register-input"
                onChange={handleFileChange}
              />
              {selectedFile && <span>{selectedFile.name}</span>}
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
            <ButtonComponent text={loading ? 'Registering...' : 'Register'} className="login-btn" type="submit" disabled={loading}>
              {loading ? 'Registering...' : 'Register'}
            </ButtonComponent>
            {error && <div className="text-danger mt-3">{error}</div>}
            {success && <div className="text-success mt-3">Registration successful!</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminRegister;
