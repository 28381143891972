// src/redux/actions/deliveryActions.js

import { 
    fetchDeliveriesFromAPI, 
    updateDeliveryInAPI, 
    deleteDeliveryAPI, 
    createNewDeliveryAPI 
  } from '../../api/services/deliveryService'; // Assuming you have this API function
import { toast } from 'react-hot-toast';

export const FETCH_DELIVERIES_REQUEST = 'FETCH_DELIVERIES_REQUEST';
export const FETCH_DELIVERIES_SUCCESS = 'FETCH_DELIVERIES_SUCCESS';
export const FETCH_DELIVERIES_FAILURE = 'FETCH_DELIVERIES_FAILURE';
export const CLEAR_DELIVERIES = 'CLEAR_DELIVERIES';

export const DELETE_DELIVERY_REQUEST = 'DELETE_DELIVERY_REQUEST';
export const DELETE_DELIVERY_SUCCESS = 'DELETE_DELIVERY_SUCCESS';
export const DELETE_DELIVERY_FAILURE = 'DELETE_DELIVERY_FAILURE';

export const UPDATE_DELIVERY_REQUEST = 'UPDATE_DELIVERY_REQUEST';
export const UPDATE_DELIVERY_SUCCESS = 'UPDATE_DELIVERY_SUCCESS';
export const UPDATE_DELIVERY_FAILURE = 'UPDATE_DELIVERY_FAILURE';

export const CREATE_DELIVERY_REQUEST = 'CREATE_DELIVERY_REQUEST';
export const CREATE_DELIVERY_SUCCESS = 'CREATE_DELIVERY_SUCCESS';
export const CREATE_DELIVERY_FAILURE = 'CREATE_DELIVERY_FAILURE';

// Fetch deliveries with a caching mechanism
export const fetchDeliveries = () => async (dispatch, getState) => {
    const { deliveryList = [] } = getState().delivery || {}; // Guard for undefined deliveries
    const timestamp = getState().delivery.timestamp;
    const FIVE_MINUTES = 5 * 60 * 1000;

    // If deliveries exist and are within the 5-minute limit, skip fetch
    if (deliveryList.length > 0 && timestamp) {
      const now = Date.now();
      if (now - timestamp < FIVE_MINUTES) {
        return; // Data is still valid, no need to fetch
      } else {
        // Clear deliveries if data is older than 5 minutes
        dispatch(clearDeliveries());
      }
    }

    dispatch({ type: FETCH_DELIVERIES_REQUEST });
    try {
      const fetchedDeliveries = await fetchDeliveriesFromAPI(); // Replace with your API call
      dispatch({
        type: FETCH_DELIVERIES_SUCCESS,
        payload: { deliveries: fetchedDeliveries, timestamp: Date.now() }, // Update with new deliveries and timestamp
      });

    } catch (error) {
      dispatch({ type: FETCH_DELIVERIES_FAILURE, payload: error.message });

    }
};

// Action to clear deliveries manually
export const clearDeliveries = () => ({
    type: CLEAR_DELIVERIES,
});

// Action to delete a delivery
export const deleteDelivery = (deliveryId) => async (dispatch) => {
    dispatch({ type: DELETE_DELIVERY_REQUEST });

    try {
      await deleteDeliveryAPI(deliveryId); // Replace with your API call
      dispatch({
        type: DELETE_DELIVERY_SUCCESS,
        payload: deliveryId, // Pass the deleted delivery ID to the reducer
      });
      toast.success('Delivery deleted successfully!');
    } catch (error) {
      dispatch({
        type: DELETE_DELIVERY_FAILURE,
        payload: error.message,
      });
      toast.error(`Failed to delete delivery: ${error.message}`);
    }
};

// Action to update a delivery
export const updateDelivery = (deliveryId, updatedData) => async (dispatch) => {
    dispatch({ type: UPDATE_DELIVERY_REQUEST });

    try {
      const updatedDelivery = await updateDeliveryInAPI(deliveryId, updatedData); // Replace with your API call
      dispatch({
        type: UPDATE_DELIVERY_SUCCESS,
        payload: updatedDelivery, // Return the updated delivery to update the state
      });
      toast.success('Delivery updated successfully!');
    } catch (error) {
      dispatch({ type: UPDATE_DELIVERY_FAILURE, payload: error.message });
      toast.error('Failed to update delivery.');
    }
};

// Action to create a delivery
export const createDelivery = (newDeliveryData) => async (dispatch) => {
    dispatch({ type: CREATE_DELIVERY_REQUEST });

    try {
      const createdDelivery = await createNewDeliveryAPI(newDeliveryData); // Replace with your API call
      dispatch({
        type: CREATE_DELIVERY_SUCCESS,
        payload: createdDelivery, // Return the newly created delivery to update the state
      });
      toast.success('Delivery created successfully!');
    } catch (error) {
      dispatch({ type: CREATE_DELIVERY_FAILURE, payload: error.message });
      toast.error('Failed to create delivery.');
    }
};
