// Action types
export const SET_COUPON = 'SET_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const UPDATE_CART_TOTAL = 'UPDATE_CART_TOTAL';
// Action creator to set coupon ID and discount value
export const setCoupon = (couponId, discountValue) => ({
  type: SET_COUPON,
  payload: { couponId, discountValue },
});

// Action creator to remove coupon
export const removeCoupon = () => ({
  type: REMOVE_COUPON,
});
export const updateCartTotal = (cartTotal) => ({
    type: UPDATE_CART_TOTAL,
    payload: cartTotal ,
  });