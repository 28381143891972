import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faChevronDown, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBulkAddresses,
  fetchLocationDetails,
  createAddress,
  setSelectedAddress,
} from '../../../../redux/actions/AddressAction';
import AddressOffcanvas from '../../../common/address-offcanvas/AddressOffcanvas';
import './LocationDropdown.scss';

const LocationDropdown = () => {
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [showOffcanvas, setShowOffcanvas] = useState(false); // Offcanvas visibility
  const [initialAddressData, setInitialAddressData] = useState({}); // Initial data for AddressOffcanvas
  const [currentLocation, setCurrentLocation] = useState({ city: '', pincode: '' }); // Current location
  const [error, setError] = useState(null); // Geolocation error
  const [triggerOffcanvas, setTriggerOffcanvas] = useState(false); // Trigger for opening the Offcanvas
  const modalRef = useRef(); // Modal reference

  const dispatch = useDispatch(); // Redux dispatch
  const token = useSelector((state) => state.auth.token); // User token
  const userId = useSelector((state) => state.auth.user); // User ID
  const addresses = useSelector((state) => state.address.addresses); // Saved addresses
  const selectedAddressId = useSelector((state) => state.address.selectedAddressId); // Selected address ID
  const locationDetails = useSelector((state) => state.address.locationDetails); // Location fetched from lat/lng

  // Fetch bulk addresses if user is logged in
  useEffect(() => {
    if (userId) {
      dispatch(fetchBulkAddresses(userId, token));
    }
  }, [userId, token, dispatch]);

  // Automatically set the last address as the selected address if available
  useEffect(() => {
    if (userId && addresses.length > 0) {
      const lastAddress = addresses[addresses.length - 1]; // Get the last address
      dispatch(setSelectedAddress(lastAddress.address_id)); // Set it as selected address ID
    }
  }, [addresses, userId, dispatch]);

  // Fetch current location if no addresses are available
  useEffect(() => {
    if ((!userId || addresses.length === 0) && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
          dispatch(fetchLocationDetails(latitude, longitude)); // Fetch location details
        },
        (err) => {
          setError(err.message); // Handle geolocation error
        }
      );
    }
  }, [userId, addresses, dispatch]);

  // Update current location when location details are fetched
  useEffect(() => {
    if (locationDetails) {
      setCurrentLocation({
        city: locationDetails.city || '',
        pincode: locationDetails.pincode || '',
      });
    }

    // Open the Address Offcanvas only when explicitly triggered
    if (locationDetails && triggerOffcanvas) {
      setInitialAddressData({
        address: locationDetails.address || '',
        city: locationDetails.city || '',
        state: locationDetails.state || '',
        pincode: locationDetails.pincode || '',
        address_type: 'Current Location',
      });
      setShowOffcanvas(true);
      setTriggerOffcanvas(false); // Reset trigger
    }
  }, [locationDetails, triggerOffcanvas]);

  // Handle dropdown toggle
  const handleDropdownClick = () => {
    setShowModal(!showModal);
    if (userId) {
      dispatch(fetchBulkAddresses(userId, token));
    }
  };

  // Close modal on outside click
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  // Handle "Use Current Location" click
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
          dispatch(fetchLocationDetails(latitude, longitude)); // Fetch location details
          setTriggerOffcanvas(true); // Set trigger to open Offcanvas
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  // Handle save and continue from Address Offcanvas
  const handleSaveAndContinue = (savedAddress) => {
    setShowOffcanvas(false);
    dispatch(fetchBulkAddresses(userId, token)); // Refresh saved addresses
    setInitialAddressData(savedAddress); // Update the dropdown input value
    dispatch(setSelectedAddress(savedAddress.address_id)); // Automatically select the saved address
  };

  return (
    <div className="locationDropdownContainer">
      {/* Dropdown */}
      <div className="locationDropdown" onClick={handleDropdownClick}>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="locationIcon" />
        <input
          type="text"
          value={
            userId && addresses.length > 0
              ? `${addresses.find((addr) => addr.address_id === selectedAddressId)?.city || ''}, ${
                  addresses.find((addr) => addr.address_id === selectedAddressId)?.pincode || ''
                }`
              : `${currentLocation.city}, ${currentLocation.pincode}`
          }
          readOnly
          className="locationInput"
           placeholder="Add location"
        />
        <FontAwesomeIcon icon={faChevronDown} className="locationDropdownIcon" />
      </div>

      {/* Modal */}
      {showModal && (
        <div className="locationModal" ref={modalRef}>
          <div className="modalContent">
            <h6 className="modalHeading">Choose your location</h6>
            <div className="currentLocation" onClick={handleUseCurrentLocation}>
              <FontAwesomeIcon icon={faLocationCrosshairs} className="currentLocationIcon" />
              <span>Use your current location</span>
            </div>
            {error && <p className="error">{error}</p>} {/* Geolocation error */}
            <hr className="line" />
            <h6 className="savedAddresses">Saved addresses</h6>
            <div className="savedAddressList">
              {addresses.length > 0 ? (
                addresses.map((address) => (
                  <div
                    className={`savedAddress ${
                      selectedAddressId === address.address_id ? 'selected' : ''
                    }`}
                    key={address.address_id}
                  >
                    <div className="addressItem">
                      <h6 className="address-office">{address.address_type || 'Unknown'}</h6>
                      <label className="selectLabel">
                        <input
                          type="radio"
                          className="address"
                          checked={selectedAddressId === address.address_id}
                          onChange={() => dispatch(setSelectedAddress(address.address_id))}
                        />
                        <span className="select-address">Select</span>
                      </label>
                    </div>
                    <span className="address-text">{`${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}</span>
                  </div>
                ))
              ) : (
                <p>No saved addresses available</p>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Address Offcanvas */}
      <AddressOffcanvas
        show={showOffcanvas}
        handleClose={() => setShowOffcanvas(false)}
        onSaveAndContinueClick={handleSaveAndContinue}
        initialValues={initialAddressData}
      />
    </div>
  );
};

export default LocationDropdown;
