import {
    CREATE_PROCESS_DELIVERY_SUCCESS,
    CREATE_PROCESS_DELIVERY_ERROR,
  } from '../actions/ProcessDeliveriesActions';
  
  const initialState = {
    isSuccess: false,
    isError: false,
  };
  
  const processDeliveriesReducer = (state = initialState, action) => {
    switch (action.type) {
      // Create Single Process Delivery Success
      case CREATE_PROCESS_DELIVERY_SUCCESS:
        return {
          ...state,
          isSuccess: true, // Set success to true
          isError: false, // Reset error state
        };
  
      // Handle Create Process Delivery Error
      case CREATE_PROCESS_DELIVERY_ERROR:
        return {
          ...state,
          isSuccess: false, // Reset success state
          isError: true, // Set error to true
        };
  
      default:
        return state;
    }
  };
  
  export default processDeliveriesReducer;
  