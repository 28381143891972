import React, { useState, useEffect } from 'react';
import { fetchCoupons } from '../../redux/actions/couponAction';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './AllocateCoupon.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateAllocatedCoupon, createAllocatedCoupon } from '../../redux/actions/couponAllocationAction';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';
import { toast } from 'react-hot-toast';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const AllocateCoupon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const typeOptions = ['category', 'product'];
  const statusOptions = ['active', 'inactive', 'expired'];

  const [formData, setFormData] = useState({
    coupon_id: '',
    entity_type: '',
    entity_id: '',
    status: ''
  });

  const [originalFormData, setOriginalFormData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [entityName, setEntityName] = useState('');
  const [couponName, setCouponName] = useState('');
  const [Saving, setSaving] = useState(true);
  const [addType, setAddType] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const token = useSelector((state) => state.adminLogin.token);
  const allocatedCouponList = useSelector((state) => state.allocateCoupon.allocatedCoupons);
  const couponlist = useSelector((state) =>
    (state.coupon.couponList || []).filter(
      (coupon) => coupon.status === 'active' && new Date(coupon.expiry_date) >= new Date()
    )
  );
  const categoryOptions = useSelector((state) => state.category.categoryList.categories) || [];
  const productlist = useSelector((state) => state.products.basicProducts || []);

  const allocated_Id = location.state || null;

  useEffect(() => {
    const fetchInitialcoupons = () => {
      dispatch(fetchCoupons(token));
    };

    fetchInitialcoupons();
    const intervalId = setInterval(fetchInitialcoupons, 180000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (allocated_Id) {
      const couponallocatedData = allocatedCouponList.find(
        (data) => data.coupon_allocation_id === allocated_Id
      );
      if (couponallocatedData) {
        setFormData(couponallocatedData);
        setOriginalFormData(couponallocatedData);
      }
    }
  }, [allocated_Id, allocatedCouponList]);

  const handleInputChange = (name, value) => {
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    updateNames(updatedFormData);
  };

  const updateNames = (newFormData) => {
    const entityName =
      newFormData.entity_type === 'category'
        ? categoryOptions.find((category) => category.category_id === newFormData.entity_id)?.name || ''
        : productlist.find((product) => product.product_id === newFormData.entity_id)?.product_name || '';

    const couponName =
      couponlist.find((coupon) => coupon.coupon_id === newFormData.coupon_id)?.code || '';
    //console.log(couponName,newFormData.coupon_id,'coupon----------');
    setEntityName(entityName);
    setCouponName(couponName);
  };

  useEffect(() => {
    setIsModified(JSON.stringify(formData) !== JSON.stringify(originalFormData));
  }, [formData, originalFormData]);

  const handleEntitySelect = (selectedId) => {
    if (formData.entity_type === 'category') {
      const available = categoryOptions.find((c) => c.category_id === selectedId);
      if (available) {
        handleInputChange('entity_id', selectedId);
      } else {
        setAddType('category');
        setIsSidebarVisible(true);
      }
    } else if (formData.entity_type === 'product') {
      const available = productlist.find((p) => p.product_id === selectedId);
      if (available) {
        handleInputChange('entity_id', selectedId);
      } else {
        setAddType('basic');
        setIsSidebarVisible(true);
      }
    }
  };

  const handleCouponEntity = (selectedId) => {
    const available = couponlist.find((c) => c.coupon_id === selectedId);
    if (available) {
      handleInputChange('coupon_id', selectedId);
    } else {
      setAddType('coupon');
      setIsSidebarVisible(true);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarVisible(false);
  };

  const handleSubmit = () => {
    setSaving(false);
    if (!formData.entity_type || !formData.entity_id || !formData.coupon_id || !formData.status) {
      toast.error('All fields are required.');
      return;
    }

    if (allocated_Id) {
      dispatch(updateAllocatedCoupon(allocated_Id, formData, token));
    } else {
      const isDuplicate = allocatedCouponList.some(
        (allocation) =>
          allocation.coupon_id === formData.coupon_id &&
          allocation.entity_type === formData.entity_type &&
          allocation.entity_id === formData.entity_id
      );
    
      if (isDuplicate) {
        toast.error('This coupon allocation already exists.');
        setSaving(true);
        return;
      }
      dispatch(createAllocatedCoupon(formData, token));
    }

    setSaving(true);
    navigate('/admin/coupon/allocate');
  };

  const getEntityOptions = () => {
    if (formData.entity_type === 'product') {
      return productlist.map((product) => ({
        label: product.product_name,
        id: product.product_id
      }));
    } else if (formData.entity_type === 'category') {
      // Get unique category IDs from productlist
      const productCategoryIds = new Set(productlist.map(product => product.category_id));
  
      // Filter categoryOptions to include only categories that exist in productlist
      return categoryOptions
        .filter(category => productCategoryIds.has(category.category_id))
        .map(category => ({
          label: category.name,
          id: category.category_id
        }));
    } else {
      return [];
    }
  };
  
  const couponOptions = couponlist.map((coupon) => ({
    label: coupon.code,
    id: coupon.coupon_id
  }));

  return (
    <div className="admin-allocation-section">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
           <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Coupon List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/coupon/allocate" className="breadcrumb-text">
          Allocate Coupon List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Allocate Coupon</span>
      </div>
      <div className="add-offer-allocation-container"></div>
      <form className="admin-allocation-form">
      <h5 className="text-heading1">Add Offer Allocation</h5>
        <div className="form-row">
          <div className="form-group">
            <label>Entity Type</label>
            <DropdownButton
              label={formData.entity_type || 'Select Type'}
              options={typeOptions}
              onSelect={(selectedOption) => handleInputChange('entity_type', selectedOption)}
            />
          </div>
       
          <div className="form-group">
            <label>Entity</label>
            <SearchableDropdown
                options={getEntityOptions()}
                placeholder={`Search for ${formData.entity_type || 'entities'}`}
                value={
                  getEntityOptions().find((option) => option.id === formData.entity_id)?.label || ''
                } // Dynamically resolve the value based on selected entity_id
                onSelect={handleEntitySelect}
              />

          </div>
          </div>
          <div className="form-row">
          <div className="form-group">
            <label>Coupons</label>
            <SearchableDropdown
              options={couponOptions}
              placeholder="Search for coupons"
              value={
                couponOptions.find((option) => option.id === formData.coupon_id)?.label || ''
              } // Dynamically resolve the value based on selected entity_id
              onSelect={handleCouponEntity}
            />
          </div>
        
          <div className="form-group">
            <label>Status</label>
            <DropdownButton
              label={formData.status || 'Status'}
              options={statusOptions}
              onSelect={(selectedOption) => handleInputChange('status', selectedOption)}
            />
          </div>
        </div>
        <div className="action-buttons">
          <ButtonComponent
            text={Saving ? 'Save' : 'Saving...'}
            variant="primary"
            type="button"
            className="submit-button"
            onClick={handleSubmit}
          />
        </div>
      </form>
      {isSidebarVisible && <Sidebar type={addType} urls={'yes'} onClose={handleCloseSidebar} />}
    </div>
  );
};

export default AllocateCoupon;
