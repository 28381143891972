import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'; 
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import "./brandtable.scss";
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkUpdateBrands, bulkCreateBrands } from "../../redux/actions/brandAction";
import toast from "react-hot-toast";

const BrandTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brands = useSelector(state => state.brand.brandList.brands || []);

  const token = useSelector(state => state.adminLogin.token);

  const initialBrands = location.state || [];
  const [brandsData, setBrandsData] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]); // Track errors for each name
  const [isSaving, setIsSaving] = useState(false); // Track save button state

  const statusOptions = ["active", "inactive"];

  useEffect(() => {
    const processBrands = () => {
      if (!initialBrands || initialBrands.length === 0) {
        setBrandsData([{
          name: '',
          description: '',
          image_url: '',
          status: '',
       
        }]);
      } else {
        if (typeof initialBrands[0] === 'object') {
          setBrandsData(initialBrands);
        } else if (typeof initialBrands[0] === 'string' || typeof initialBrands[0] === 'number') {
          const fetchedBrands = initialBrands.map(id => {
            const brand = brands.find(brand => brand.brand_id === id);
            return brand ? { ...brand, brand_id: id } : { brand_id: id, name: '', description: '', parent_brand_ID: '', image_url: '', status: '' };
          });
          setBrandsData(fetchedBrands);
        }
      }
    };

    processBrands();
  }, [initialBrands, brands]);

  const handleInputChange = (index, field, value) => {
    const updatedBrands = [...brandsData];
    updatedBrands[index][field] = value;
    setBrandsData(updatedBrands);

    // Reset error if name is modified
    if (field === 'name') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false; // Reset duplicate error for this row
      setDuplicateErrors(updatedErrors);
    }
  };

  const handleAddRow = () => {
    setBrandsData(prevBrands => [
      ...prevBrands,
      { brand_id: '', name: '', description: '', parent_brand_ID: '', image_url: '', status: ''}
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]); // Add a new error state
  };

  const handleDelete = (index) => {
    const newBrands = [...brandsData];
    newBrands.splice(index, 1);
    setBrandsData(newBrands);

    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1); // Remove the corresponding error state
    setDuplicateErrors(newErrors);
  };

  // Check for duplicate names in the existing brands
  const checkForDuplicateNames = () => {
    const existingNames = brands.map(brand => brand.name.trim().toLowerCase());
    const updatedErrors = brandsData.map(brand => {
      // Skip validation if brand_id exists (indicating it's an existing brand)
      return !brand.brand_id && existingNames.includes(brand.name.trim().toLowerCase()) && brand.name.trim() !== '';
    });
    setDuplicateErrors(updatedErrors);
    return updatedErrors.some(error => error); // Returns true if any duplicate is found
  };
  
  const handleSaveClick = async () => {

    const hasEmptyFields = brandsData.some(brand => 
      !brand.name.trim() || !brand.status
    );
  
    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Name and Status).");
      return; // Prevent saving if there are empty fields
    }
    
    if (checkForDuplicateNames()) {
      return; // Prevent saving if duplicate names are found
    }
  
    setIsSaving(true); // Set to 'Saving...' state
    let responseStatus = false;
  
    if (typeof initialBrands[0] === 'object') {
      responseStatus = await dispatch(bulkCreateBrands(brandsData, token));
    } else {
      responseStatus = await dispatch(bulkUpdateBrands(brandsData, token));
    }
  
    setIsSaving(false); // Reset to normal state after saving
  
    if (responseStatus) {
      navigate('/admin'); // Navigate if save was successful
    } else {
      //console.error("Failed to save brands");
    }
  };
  
  return (
    <div className="bulk-brand-main-page">
    <div className="breadcrumb-container">
         
    <Link to="/admin/dashboard" className="breadcrumb-text">
    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
    </Link>
    <span className="breadcrumb-separator">{'>'}</span>
    <Link to="/admin" className="breadcrumb-text">
    <span className="breadcrumb-text">Brand List</span>
    </Link>
    <span className="breadcrumb-separator">{'>'}</span>
    <span className="breadcrumb-text">Bulk brand</span>
</div>
<div className="bulk-brand-container"></div>
<div className="bulk-brand">
    <div className="brand-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Brand</h5>
      <div className="add-new-button">
        {typeof initialBrands[0] === 'object' && (
          <ButtonComponent 
            text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
            className="add-button" 
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"}  // Conditionally show "Saving..." 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} // Disable button while saving
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th className="small-column">Remove</th>
            </tr>
          </thead>
          <tbody>
            {brandsData.map((brand, index) => (
              <tr key={index}>
                <td>
                  <InputComponent
                    className="table-input"
                    value={brand.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                  />
                  {duplicateErrors[index] && (
                    <div className="error-message">Already exists</div>
                  )}
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={brand.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                  />
                </td>
            
                <td>
                  <DropdownButton
                    options={statusOptions}
                    label={brand.status || 'Select Status'}
                    onSelect={(value) => handleInputChange(index, 'status', value)}
                  />
                </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default BrandTable;
