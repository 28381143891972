// reducers/offerAllocationReducer.js
import {
    FETCH_ALLOCATED_OFFERS,
    CREATE_ALLOCATED_OFFER,
    UPDATE_ALLOCATED_OFFER,
    DELETE_ALLOCATED_OFFER,
    BULK_CREATE_ALLOCATED_OFFERS,
    BULK_UPDATE_ALLOCATED_OFFERS,
    BULK_DELETE_ALLOCATED_OFFERS,
    OFFER_ALLOCATION_ERROR
} from '../actions/discountAllocateAction';

const initialState = {
    allocatedOffers: [],
    error: null
};

const offerAllocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALLOCATED_OFFERS:
            return { ...state, allocatedOffers: action.payload, error: null };

        case CREATE_ALLOCATED_OFFER:
            return { ...state, allocatedOffers: [...state.allocatedOffers, action.payload], error: null };

        case UPDATE_ALLOCATED_OFFER:
            return {
                ...state,
                allocatedOffers: state.allocatedOffers.map(offer =>
                    offer.offer_allocation_id === action.payload.offer_allocation_id ? action.payload : offer
                ),
                error: null
            };

        case DELETE_ALLOCATED_OFFER:
            return {
                ...state,
                allocatedOffers: state.allocatedOffers.filter(offer => offer.offer_allocation_id !== action.payload),
                error: null
            };

        case BULK_CREATE_ALLOCATED_OFFERS:
            return { ...state, allocatedOffers: [...state.allocatedOffers, ...action.payload], error: null };

        case BULK_UPDATE_ALLOCATED_OFFERS:
            return {
                ...state,
                allocatedOffers: state.allocatedOffers.map(offer => {
                    const updatedOffer = action.payload.find(uOffer => uOffer.offer_allocation_id === offer.offer_allocation_id);
                    return updatedOffer ? updatedOffer : offer;
                }),
                error: null
            };

        case BULK_DELETE_ALLOCATED_OFFERS:
            return {
                ...state,
                allocatedOffers: state.allocatedOffers.filter(offer => !action.payload.includes(offer.offer_allocation_id)),
                error: null
            };

        case OFFER_ALLOCATION_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default offerAllocationReducer;
