// reducers/supplierReducer.js
import {
  FETCH_SUPPLIERS,
  CREATE_SUPPLIER,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
  BULK_CREATE_SUPPLIERS,
  BULK_UPDATE_SUPPLIERS,
  BULK_DELETE_SUPPLIERS,
  SUPPLIER_ERROR,
} from '../actions/supplierActions';

const initialState = {
  suppliers: [],
  error: null,
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
        error: null,
      };

    case CREATE_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
        error: null,
      };

    case UPDATE_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) =>
          supplier.supplier_id === action.payload.supplier_id
            ? action.payload
            : supplier
        ),
        error: null,
      };

    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier) => supplier.supplier_id !== action.payload
        ),
        error: null,
      };

    case BULK_CREATE_SUPPLIERS:
      return {
        ...state,
        suppliers: [...state.suppliers, ...action.payload],
        error: null,
      };

    case BULK_UPDATE_SUPPLIERS:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) =>
          action.payload.find(
            (updatedSupplier) => updatedSupplier.supplier_id === supplier.supplier_id
          ) || supplier
        ),
        error: null,
      };

    case BULK_DELETE_SUPPLIERS:
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier) => !action.payload.includes(supplier.supplier_id)
        ),
        error: null,
      };

    case SUPPLIER_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default supplierReducer;
