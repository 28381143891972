import {
    fetchAllConfigs,
    createConfig,
    updateConfig,
    deleteConfig,
    createBulkConfigs,
    fetchBulkConfigs,
    updateBulkConfigs,
    deleteBulkConfigs,
  } from '../../api/services/deliveryConfigService';
  
  // Action Types
  export const DELIVERY_CONFIGS_FETCH_SUCCESS = 'DELIVERY_CONFIGS_FETCH_SUCCESS';
  export const DELIVERY_CONFIGS_FETCH_ERROR = 'DELIVERY_CONFIGS_FETCH_ERROR';
  export const DELIVERY_CONFIG_CREATE_SUCCESS = 'DELIVERY_CONFIG_CREATE_SUCCESS';
  export const DELIVERY_CONFIG_CREATE_ERROR = 'DELIVERY_CONFIG_CREATE_ERROR';
  export const DELIVERY_CONFIG_UPDATE_SUCCESS = 'DELIVERY_CONFIG_UPDATE_SUCCESS';
  export const DELIVERY_CONFIG_UPDATE_ERROR = 'DELIVERY_CONFIG_UPDATE_ERROR';
  export const DELIVERY_CONFIG_DELETE_SUCCESS = 'DELIVERY_CONFIG_DELETE_SUCCESS';
  export const DELIVERY_CONFIG_DELETE_ERROR = 'DELIVERY_CONFIG_DELETE_ERROR';
  export const DELIVERY_CONFIGS_BULK_FETCH_SUCCESS = 'DELIVERY_CONFIGS_BULK_FETCH_SUCCESS';
  export const DELIVERY_CONFIGS_BULK_FETCH_ERROR = 'DELIVERY_CONFIGS_BULK_FETCH_ERROR';
  export const DELIVERY_CONFIGS_BULK_CREATE_SUCCESS = 'DELIVERY_CONFIGS_BULK_CREATE_SUCCESS';
  export const DELIVERY_CONFIGS_BULK_CREATE_ERROR = 'DELIVERY_CONFIGS_BULK_CREATE_ERROR';
  export const DELIVERY_CONFIGS_BULK_UPDATE_SUCCESS = 'DELIVERY_CONFIGS_BULK_UPDATE_SUCCESS';
  export const DELIVERY_CONFIGS_BULK_UPDATE_ERROR = 'DELIVERY_CONFIGS_BULK_UPDATE_ERROR';
  export const DELIVERY_CONFIGS_BULK_DELETE_SUCCESS = 'DELIVERY_CONFIGS_BULK_DELETE_SUCCESS';
  export const DELIVERY_CONFIGS_BULK_DELETE_ERROR = 'DELIVERY_CONFIGS_BULK_DELETE_ERROR';
  
  // Fetch all configs
  export const getAllConfigs = (token) => async (dispatch) => {
    try {
      const data = await fetchAllConfigs(token);
      dispatch({ type: DELIVERY_CONFIGS_FETCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: DELIVERY_CONFIGS_FETCH_ERROR, payload: error.message });
    }
  };
  
  // Create a config
  export const createNewConfig = (configData, token) => async (dispatch) => {
    try {
      // Call the API to create the config
      const configdata = await createConfig(configData, token);
      
      // Dispatch the success action with the returned data
      dispatch({ type: DELIVERY_CONFIG_CREATE_SUCCESS, payload: configdata });
      
      //console.log("Config created successfully:", configdata); // Debugging
    } catch (error) {
      // Dispatch the error action with the error message
      dispatch({ type: DELIVERY_CONFIG_CREATE_ERROR, payload: error.message });
      
     // Rethrow error for handling in the component
    }
  };
  
  // Update a config
  export const updateConfigById = (configId, configData, token) => async (dispatch) => {
    try {
      // Call the API to update the delivery config
      const updatedConfig = await updateConfig(configId,configData, token);
  
      // Dispatch the success action with the returned data
      dispatch({ type: DELIVERY_CONFIG_UPDATE_SUCCESS, payload: updatedConfig });
  
      //console.log("Delivery Config updated successfully:", updatedConfig); // Debugging
    } catch (error) {
      // Dispatch the error action with the error message
      dispatch({ type: DELIVERY_CONFIG_UPDATE_ERROR, payload: error.message });
  
      //console.error("Error updating delivery config:", error.response?.data || error.message); // Debugging
      throw error; // Rethrow the error for handling in the component
    }
  };
  
  // Delete a config
  export const deleteConfigById = (configId, token) => async (dispatch) => {
    try {
      await deleteConfig(configId, token);
      dispatch({ type: DELIVERY_CONFIG_DELETE_SUCCESS, payload: configId });
    } catch (error) {
      dispatch({ type: DELIVERY_CONFIG_DELETE_ERROR, payload: error.message });
    }
  };
  
  // Bulk fetch configs
  export const getBulkConfigs = (token) => async (dispatch) => {
    try {
      const data = await fetchBulkConfigs(token);
      dispatch({ type: DELIVERY_CONFIGS_BULK_FETCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: DELIVERY_CONFIGS_BULK_FETCH_ERROR, payload: error.message });
    }
  };
  
  // Bulk create configs
  export const createBulkConfigsAction = (ConfigData, token) => async (dispatch) => {
    try {
      const data = await createBulkConfigs(ConfigData, token);
      dispatch({ type: DELIVERY_CONFIGS_BULK_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: DELIVERY_CONFIGS_BULK_CREATE_ERROR, payload: error.message });
    }
  };
  
  // Bulk update configs
  export const updateBulkConfigsAction = (ConfigData, token) => async (dispatch) => {
    try {
      const data = await updateBulkConfigs(ConfigData, token);
      dispatch({ type: DELIVERY_CONFIGS_BULK_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: DELIVERY_CONFIGS_BULK_UPDATE_ERROR, payload: error.message });
    }
  };
  
  // Bulk delete configs
  export const deleteBulkConfigsAction = (configId, token) => async (dispatch) => {
    try {
      await deleteBulkConfigs(configId, token);
      dispatch({ type: DELIVERY_CONFIGS_BULK_DELETE_SUCCESS, payload: configId });
    } catch (error) {
      dispatch({ type: DELIVERY_CONFIGS_BULK_DELETE_ERROR, payload: error.message });
    }
  };