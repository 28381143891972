// services/offerAllocationService.js
import { offer_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch all allocated offers
export const fetchAllocatedOffersFromAPI = async (token) => {
    const response = await apiClient.get(offer_endpoints.GET_BULK_ALLOCATED_OFFER, {
        headers: {
            Authorization: token,
        }
    });
    //console.log(response);
    if (!response.data) {
        throw new Error('Failed to fetch allocated offers');
    }
    return response.data.allocations;
};

// Create a new allocated offer
export const createNewAllocatedOfferAPI = async (newAllocationData, token) => {
    //console.log(newAllocationData);
    const response = await apiClient.post(
        offer_endpoints.GET_ALLOCATED_OFFER, 
        {
            offer_id: newAllocationData.offer_id,
            entity_type: newAllocationData.entity_type,
            entity_id: newAllocationData.entity_id,
            status: newAllocationData.status
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );
  
    //console.log("New offer allocation added");
    return response.data; // Return the created offer allocation data
};

// Update an allocated offer
export const updateAllocatedOfferInAPI = async (allocationId, updatedAllocationData, token) => {
    //console.log(updatedAllocationData, 'Updating offer allocation');
    try {
        const response = await apiClient.put(
            offer_endpoints.GET_ALLOCATED_OFFER, 
            {
                offer_allocation_id: allocationId,
                offer_id: updatedAllocationData.offer_id,
                entity_type: updatedAllocationData.entity_type,
                entity_id: updatedAllocationData.entity_id,
                status: updatedAllocationData.status
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        );
        //console.log(response.data);
        return response.data;
    } catch (error) {
        //console.error('Error response:', error.response?.data);
        throw new Error('Failed to update the allocated offer');
    }
};

// Delete an allocated offer
export const deleteAllocatedOfferAPI = async (allocationId, token) => {
    const response = await apiClient.delete(
        offer_endpoints.GET_ALLOCATED_OFFER,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            params: {
                offer_allocation_id: allocationId
            }
        }
    );

    if (!response.data) {
        throw new Error(`Failed to delete offer allocation with ID: ${allocationId}`);
    }
    return response.data; // Optionally return the deleted offer allocation data
};

// Bulk Create Allocated Offers
export const bulkCreateAllocatedOffersAPI = async (newAllocationsData, token) => {
    const response = await apiClient.post(
        offer_endpoints.GET_BULK_ALLOCATED_OFFER,
        newAllocationsData,
        {
            headers: {
                Authorization: token,
            },
        }
    );
    //console.log(response,'response')
    if (!response.data) {
        throw new Error('Failed to create bulk allocated offers');
    }
    
    return response.data; // Return created allocated offers data
};

// Bulk Update Allocated Offers
export const bulkUpdateAllocatedOffersAPI = async (allocationsData, token) => {
    //console.log(allocationsData,'allocated data ------------');
    const response = await apiClient.put(
        offer_endpoints.GET_BULK_ALLOCATED_OFFER,
        allocationsData,
        {
            headers: {
                Authorization: token,
            },
        }
    );
    //console.log(response,'response')
    if (!response.data) {
        throw new Error('Failed to update bulk allocated offers');
    }

    return response.data; // Return updated allocated offers data
};

// Bulk Delete Allocated Offers
export const bulkDeleteAllocatedOffersAPI = async (allocationIds, token) => {
    const response = await apiClient.delete(
        offer_endpoints.GET_BULK_ALLOCATED_OFFER,
        {
            headers: {
                Authorization: token,
            },
            data: allocationIds // Send an array of offer allocation IDs
        }
    );

    if (!response.data) {
        throw new Error('Failed to delete bulk allocated offers');
    }

    return response.data; // Optionally return the deleted offers data
};
