import React from 'react';
import './billdetails-card.scss';

const BillDetailCard = ({ label, value, isDiscount, isStriked }) => {
  return (
    <div className="bill-item">
      <span
        className={`bill-item-label ${
          label === 'Grand Total' ? 'bill-item-label-bold' : ''
        }`}
      >
        {label}
      </span>
      <span
        className={`bill-item-value ${
          label === 'Grand Total' ? 'bill-amount' : ''
        } ${isDiscount ? 'bill-item-discount' : ''}`}
      >
        {isStriked ? <s className="bill-item-striked">{value}</s> : value}
      </span>
    </div>
  );
};

export default BillDetailCard;
