import { generateToken, adminLoginWithPassword, resetPassword } from '../../api/services/adminLoginService'; // Import the correct login service
import { toast } from 'react-hot-toast'; // Optional: use for showing success/error messages
import { persistor } from '../store';
// Action Types
export const GENERATE_TOKEN_REQUEST = 'GENERATE_TOKEN_REQUEST';
export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS';
export const GENERATE_TOKEN_FAILURE = 'GENERATE_TOKEN_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const LOGOUT = 'LOGOUT';

// Action to generate token
export const generateTokenAction = () => async (dispatch) => {
  dispatch({ type: GENERATE_TOKEN_REQUEST });
  try {
    const data = await generateToken();  // Call the token generation service
    dispatch({ type: GENERATE_TOKEN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GENERATE_TOKEN_FAILURE, payload: error.message });
  }
};

// Action to log in with email and password
export const loginAction = (email, password) => async (dispatch, getState) => {
  dispatch({ type: LOGIN_REQUEST });

  //console.log(email,password)
  try {
    // Call the login service to get token and user data
    const data = await adminLoginWithPassword(email, password); // Use adminLoginWithPassword
      dispatch({ type: LOGIN_SUCCESS, payload: data });   // Dispatch token and store in Redux state
   
      // Show success toast and console log
      toast.success('Login successful!');
     
    
  } catch (error) {
  
   
    const errorMessage = error.message || 'An error occurred during login.';
    dispatch({ type: LOGIN_FAILURE, payload: errorMessage });
   
  }
};

// Action to reset password
export const resetPasswordAction = (email) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });
  try {
    const data = await resetPassword(email);  // Call the reset password API
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.message });
    toast.success('Password reset email sent successfully');
    
  } catch (error) {
    dispatch({ type: RESET_PASSWORD_FAILURE, payload: error.message });
    // toast.error(error.message || 'An error occurred during password reset.');
  }
};

// Action to log out
export const logoutAction = () => (dispatch) => {
  persistor.purge(); // Clear persisted data
  dispatch({ type: 'LOGOUT' }); // Reset Redux state
};
