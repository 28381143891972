import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { createCoupon,  updateCoupon } from '../../redux/actions/couponAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './CouponPage.scss';

const CouponPage = ({ urls, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [minPurchaseValue, setMinPurchaseValue] = useState('');
  const [maxDiscountAmount, setMaxDiscountAmount] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [usagePerUser, setUsagePerUser] = useState(0);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [originalData, setOriginalData] = useState(null);

  const coupons = useSelector((state) => state.coupon.couponList);
  const token = useSelector((state) => state.adminLogin.token);
  const coupon_Id = location.state || null;

  const statusOptions = ['active', 'inactive'];

  useEffect(() => {
    if (coupon_Id) {
      const couponData = coupons.find((coupon) => coupon.coupon_id === coupon_Id);
      if (couponData) {
        setCode(couponData.code);
        setDiscountType(couponData.discount_type);
        setDiscountValue(couponData.discount_value);
        setMinPurchaseValue(couponData.min_purchase_value);
        setMaxDiscountAmount(couponData.max_discount_amount);
        setStartDate(couponData.start_date);
        setExpiryDate(couponData.expiry_date);
        setUsageLimit(couponData.usage_limit);
        setUsagePerUser(couponData.usage_per_user);
        setStatus(couponData.status);

        setOriginalData(couponData);
      }
    }
  }, [coupon_Id, coupons]);

  useEffect(() => {
    const isFormModified =
      code !== originalData?.code ||
      discountType !== originalData?.discount_type ||
      discountValue !== originalData?.discount_value ||
      minPurchaseValue !== originalData?.min_purchase_value ||
      maxDiscountAmount !== originalData?.max_discount_amount ||
      startDate !== originalData?.start_date ||
      expiryDate !== originalData?.expiry_date ||
      usageLimit !== originalData?.usage_limit ||
      usagePerUser !== originalData?.usage_per_user ||
      status !== originalData?.status;

    setIsModified(isFormModified);
  }, [
    code,
    discountType,
    discountValue,
    minPurchaseValue,
    maxDiscountAmount,
    startDate,
    expiryDate,
    usageLimit,
    usagePerUser,
    status,
    originalData,
  ]);

  const handleSubmit = async () => {
    const requiredFields = [
      { field: code, label: 'Code' },
      { field: discountType, label: 'Discount Type' },
      { field: discountValue, label: 'Discount Value' },
      { field: minPurchaseValue, label: 'Minimum Purchase Value' },
      { field: maxDiscountAmount, label: 'Maximum Discount Amount' },
      { field: startDate, label: 'Start Date' },
      { field: expiryDate, label: 'Expiry Date' },
      { field: usageLimit, label: 'Usage Limit' },
      { field: usagePerUser, label: 'Usage Per User' },
      { field: status, label: 'Status' },
    ];

    const missingFields = requiredFields.filter((field) => !field.field);
    if (missingFields.length > 0) {
      toast.error(
        `Please fill all mandatory fields: ${missingFields.map((field) => field.label).join(', ')}`
      );
      return;
    }

    const couponData = {
      code,
      discountType,
      discountValue,
      minPurchaseValue,
      maxDiscountAmount,
      startDate,
      expiryDate,
      usageLimit,
      usagePerUser,
      status,
    };

    setLoading(true);
    let actionResult;

    if (coupon_Id) {
      actionResult = await dispatch(updateCoupon(coupon_Id, couponData, token));
    } else {
      const isDuplicate = coupons.some(
        (existingCoupon) => existingCoupon.code.toLowerCase() === code.toLowerCase() && existingCoupon.coupon_id !== coupon_Id
      );
    
      if (isDuplicate) {
        toast.error('A coupon with this code already exists. Please enter a unique code.');
        setLoading(false);
        return;
      }
      actionResult = await dispatch(createCoupon(couponData, token));
    }

    setLoading(false);
    if (actionResult && actionResult.success) {
      toast.success(`Coupon ${coupon_Id ? 'updated' : 'created'} successfully`);
      if (urls) {
        onClose();
      } else {
        navigate('/admin');
      }
    } else {
      toast.error(`Coupon not ${coupon_Id ? 'updated' : 'created'}. Please try again.`);
    }
  };

  return (
    <div className="coupon-page-section">
      {!urls && (
        <div className="breadcrumb-container">
          <Link to="/admin/dashboard" className="breadcrumb-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <Link to="/admin" className="breadcrumb-text">
            Coupon List
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <span className="breadcrumb-text">Add Coupon Info</span>
        </div>
      )}
      <div className="coupon-container"></div>
      <div className="coupon-page-container">
        <div className="coupon-page">
          <h5 className="text-heading1">Add Coupon Items</h5>
          <form>
            <div className="form-row">
              <div className="form-group">
                <label>
                  Discount Type <span className="required-symbol">*</span>
                </label>
                <DropdownButton
                  label={discountType || 'Select Discount Type'}
                  options={['percentage', 'fixed_amount']}
                  onSelect={(selectedOption) => setDiscountType(selectedOption)}
                />
              </div>
              <div className="form-group">
                <label>
                  Code <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  placeholder="Enter Discount Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>
                  Discount Value <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="number"
                  placeholder="Enter Discount Value"
                  value={discountValue}
                  onChange={(e) => setDiscountValue(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  Minimum Purchase Value <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="number"
                  placeholder="Enter Minimum Purchase Value"
                  value={minPurchaseValue}
                  onChange={(e) => setMinPurchaseValue(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>
                  Maximum Discount Amount <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="number"
                  placeholder="Enter Maximum Discount Amount"
                  value={maxDiscountAmount}
                  onChange={(e) => setMaxDiscountAmount(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  Usage Limit <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="number"
                  placeholder="Enter Usage Limit"
                  value={usageLimit}
                  onChange={(e) => setUsageLimit(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>
                  Usage Per User <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="number"
                  placeholder="Enter Usage Per User"
                  value={usagePerUser}
                  onChange={(e) => setUsagePerUser(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  Start Date <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="date"
                  placeholder="Select Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>
                  Expiry Date <span className="required-symbol">*</span>
                </label>
                <InputComponent
                  type="date"
                  placeholder="Select Expiry Date"
                  value={expiryDate}
                  onChange={(e) => setExpiryDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  Status <span className="required-symbol">*</span>
                </label>
                <DropdownButton
                  label={status || 'Select Status'}
                  options={statusOptions}
                  onSelect={(selectedOption) => setStatus(selectedOption)}
                />
              </div>
            </div>
            <div className="form-row save-button-container">
              <ButtonComponent
                text={loading ? 'Saving...' : 'Save'}
                onClick={handleSubmit}
                className="submit-button"
                disabled={!isModified || loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CouponPage;
