// services/bannerService.js
import { banner_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';

// Fetch all banners
export const fetchAllBanners = async (token) => {
    try {
        const response = await apiClient.get(banner_endpoints.GET_BULK_BANNERS, {
            headers: { Authorization: token }
        });
        if (!response.data) {
            throw new Error('Failed to fetch banners');
        }
        return response.data.banners;
    } catch (error) {
        //console.error("Error fetching banners:", error);
        // toast.error('Failed to fetch banners.');
        throw error;
    }
};

// Fetch a single banner by ID
export const fetchBannerById = async (bannerId, token) => {
    try {
        const response = await apiClient.get(`${banner_endpoints.GET_BANNER}/${bannerId}`, {
            headers: { Authorization: token }
        });
        if (!response.data) {
            throw new Error('Failed to fetch banner');
        }
        return response.data.banner;
    } catch (error) {
        //console.error("Error fetching banner:", error);
        toast.error('Failed to fetch banner.');
        throw error;
    }
};

// Create a new banner
export const createBanner = async (bannerData, token) => {
    try {
        const response = await apiClient.post(
            banner_endpoints.GET_BANNER,
            bannerData,
            { headers: { Authorization: token } }
        );
        return response.data;
    } catch (error) {
        //console.error("Error creating banner:", error);
        toast.error('Failed to create banner.');
        throw error;
    }
};

// Create multiple banners in bulk
export const createBulkBanners = async (bannersData, token) => {
    try {
        const response = await apiClient.post(
            banner_endpoints.GET_BULK_BANNERS,
            bannersData,
            { headers: { Authorization: token } }
        );
        return response.data;
    } catch (error) {
        //console.error("Error creating banners in bulk:", error);
        toast.error('Failed to create banners in bulk.');
        throw error;
    }
};

// Update a single banner by ID
export const updateBanner = async (bannerData, token) => {
    try {
        const response = await apiClient.put(
            banner_endpoints.GET_BANNER,
            bannerData,
            { headers: { Authorization: token } }
        );
        return response.data;
    } catch (error) {
        //console.error("Error updating banner:", error);
        toast.error('Failed to update banner.');
        throw error;
    }
};

// Update multiple banners in bulk
export const updateBulkBanners = async (bannersData, token) => {
    try {
        const response = await apiClient.put(
            banner_endpoints.GET_BULK_BANNERS,
            bannersData,
            { headers: { Authorization: token } }
        );
        return response.data;
    } catch (error) {
        //console.error("Error updating banners in bulk:", error);
        toast.error('Failed to update banners in bulk.');
        throw error;
    }
};

// Delete a single banner by ID
export const deleteBanner = async (bannerId, token) => {
    try {
        const response = await apiClient.delete(
            banner_endpoints.GET_BANNER,
            {
                params: { banner_id: bannerId },
                headers: { Authorization: token }
            }
        );
        return response.data;
    } catch (error) {
        //console.error("Error deleting banner:", error);
        toast.error('Failed to delete banner.');
        throw error;
    }
};

// Delete multiple banners in bulk
export const deleteBulkBanners = async (bannerIds, token) => {
    //console.log("ids",bannerIds)
    try {
        const response = await apiClient.delete(
            banner_endpoints.GET_BULK_BANNERS,
            
            {
                data:bannerIds,
                headers: { Authorization: token },
                
            }
        );
        return response.data;
    } catch (error) {
        //console.error("Error deleting banners in bulk:", error);
        toast.error('Failed to delete banners in bulk.');
        throw error;
    }
};
