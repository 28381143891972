import { fetchBanners,deleteBulkBannersAction,deleteExistingBanner } from '../../redux/actions/bannerAction';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPencilAlt,  faTrash ,faHome} from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './bannerList.scss'; // Update or create stylesheet for banners
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const BannerDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigationOptions = {
    gridPath: '/admin/add-banner',
    sheetPath: '/admin/uploadpage',
    from: 'banner',
  };
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const token = useSelector(state => state.adminLogin.token);
  const banners = useSelector(state => state.banner.banners) || [];
  const [showSearchFilters,setShowSearchFilters]=useState([]);
  useEffect(() => {
    const fetchInitialBanners = () => {
      dispatch(fetchBanners(token));
    };

    fetchInitialBanners();

    const intervalId = setInterval(fetchInitialBanners, 180000); // Fetch every 3 minutes

    return () => clearInterval(intervalId);
  }, [dispatch, token]);

  useEffect(() => {
    setTableData(banners.map(banner => ({
      ...banner,
      checked: false,
    })));
  }, [banners]);

  const handleDelete = async (bannerId) => {
    if (window.confirm('Are you sure you want to delete this banner?')) {
      try {
        dispatch(deleteExistingBanner(bannerId, token));
      } catch (error) {
        // console.error('Failed to delete banner', error);
      }
    }
  };

  const [tableData, setTableData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);


  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert('Please select at least one banner to proceed.');
      return;
    }

    if (action === 'edit') {
      navigate('/admin/bulk-banner', { state: selectedIds });
      setSelectedIds([]);
    } else if (action === 'delete') {
      if (window.confirm('Are you sure you want to delete the selected banners?')) {
        dispatch(deleteBulkBannersAction(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };

  const filteredBanners = banners.filter((banner) =>
    (banner.image?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (banner.banner_count?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (banner.status?.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleCheckboxChange = (bannerId) => {
    if (selectedIds.includes(bannerId)) {
      setSelectedIds((prev) => prev.filter(id => id !== bannerId));
    } else {
      setSelectedIds((prev) => [...prev, bannerId]);
    }
  };

  const handleEditClick = (id) => {
    navigate('/admin/add-banner', { state: id });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = banners.map(item => item.banner_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedIds.length === tableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, tableData.length]);

  return (
    <div>
      <div className="admin-banner-page-layout">
      <div className="breadcrumb-container">
         
         <Link to="/admin/dashboard" className="breadcrumb-text">
         <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <span className="breadcrumb-text">Banner List</span>
       </div>
       <div className="banner-container"></div>
        <div className="banner-page-container">
          <div className="right-section">
          <h5 className='text-heading1'>Banner List</h5>
            <div className="toolbar">
              
              <div className="search-wrapper" onClick={() => setShowSearchFilters(!showSearchFilters)}>
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="item-controls">
                <ButtonComponent text="+ Add Banners" variant="dark" className="add-items-button" onClick={() =>navigate('/admin/add-banner') } />
                <div className="actions-toolbar">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" className='dropdown-button' id="actions-dropdown">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                     
                      <Dropdown.Item onClick={() => handleActionSelect('delete')}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="table-container">
              {filteredBanners.length === 0 ? (
                <div className="no-banners-message">No banners found.</div>
              ) : (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <CheckboxComponent checked={selectAll} onChange={handleSelectAll} />
                      </th>
                      <th>ID</th>
                      <th>Image Bucket ID</th>
                      <th>Position</th>
                      <th>Status</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBanners.map((item, index) => (
                      <tr key={`${index}-${item.banner_id}`}>
                        <td>
                          <CheckboxComponent
                            checked={selectedIds.includes(item.banner_id)}
                            onChange={() => handleCheckboxChange(item.banner_id)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>{item.image}</td>
                        <td>{item.banner_count}</td>
                        <td>{item.status}</td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleEditClick(item.banner_id)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleDelete(item.banner_id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            
          </div>
        </div>
      </div>
      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
    </div>
  );
};

export default BannerDisplayPage;
