import React, { useEffect, useState } from 'react';
import './SkeletonLoader.scss'; // Ensure you have the correct path to your SCSS file

const SkeletonLoader = ({ className, duration = 600000 }) => { // 600,000 ms = 10 minutes
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration); // Stop shimmering after the specified duration (default 10 minutes)

    return () => clearTimeout(timer);
  }, [duration]);

  if (!visible) return null; // If not visible, return nothing

  return (
    <div className={`skeleton ${className}`}>
      <div className="shimmer"></div>
    </div>
  );
};

export default SkeletonLoader;
