import {
  GENERATE_TOKEN_REQUEST,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT,
} from '../actions/adminLoginActions';

const initialState = {
  token: null,         // Token is initially null
  loading: false,      // Loading flag for login, token generation, and reset password
  error: null,         // Error message for login, token generation, or reset password failures
  resetMessage: null,  // Message after successful password reset
  isLoggedIn:false,   // Indicates if the user is logged in
  user: null,          // User data after successful login
};

// Reducer function for login, token generation, reset password, and user state management
const adminLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    // Token generation actions
    case GENERATE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,  // Set loading true when generating token
        error: null,
      };

    case GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,          // Stop loading when token is successfully generated
        token: action.payload,   // Store the generated token in state
        error: null,
      };

    case GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,         // Stop loading if token generation fails
        error: action.payload,  // Store the error message
      };

    // Login actions
    case LOGIN_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.access_token,  // Store the token on successful login
        user: action.payload.user_id,           // Store user data after login
        isLoggedIn: true,                    // Set logged-in status
        error: null,
      };

    case LOGIN_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,  // Set the error message from the action payload
        isLoggedIn: false,      // Login failed, so the user is not logged in
      };

    // Reset password success
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetMessage: action.payload,  // Store reset message after successful password reset
        error: null,
      };


    // Logout action
    case LOGOUT:
      return {
        ...state,
        token: null,       // Clear token on logout
        user: null,        // Clear user data on logout
        isLoggedIn: false, // Set login state to false
        error: null,       // Clear any error messages
        resetMessage: null, // Clear the reset password message
      };

    default:
      return state;
  }
};

export default adminLoginReducer;
