import React from 'react';
import './usercard.scss'; // Import the SCSS for UserCard

// Generic UserCard Component for structure only
const UserCard = ({ children }) => {
  return (
    <div className="user-card">
      {children}
    </div>
  );
};

export default UserCard;
