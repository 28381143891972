import React from 'react';
import './BottomNavigation.scss'; // Import the specific stylesheet for Bottom Navigation

const BottomNavigation = ({ handleNavigation }) => {
  return (
    <div className="bottom-navigation">
      <div
        className="nav-item"
        onClick={(e) => {
          e.preventDefault(); // Prevent default action
          handleNavigation('/'); // Use handleNavigation function from props
        }}
      >
        <i className="nav-icon fas fa-home"></i>
        <div className="nav-text">Home</div>
      </div>
      
      <div
        className="nav-item"
        onClick={(e) => {
          e.preventDefault();
          handleNavigation('/orders');
        }}
      >
        <i className="nav-icon fas fa-box"></i>
        <div className="nav-text">Orders</div>
      </div>
      <div
        className="nav-item"
        onClick={(e) => {
          e.preventDefault();
          handleNavigation('/myprofile');
        }}
      >
        <i className="nav-icon fas fa-user"></i>
        <div className="nav-text">Profile</div>
      </div>
    </div>
  );
};

export default BottomNavigation;