import {
    DELIVERY_CONFIGS_FETCH_SUCCESS,
    DELIVERY_CONFIGS_FETCH_ERROR,
    DELIVERY_CONFIG_CREATE_SUCCESS,
    DELIVERY_CONFIG_CREATE_ERROR,
    DELIVERY_CONFIG_UPDATE_SUCCESS,
    DELIVERY_CONFIG_UPDATE_ERROR,
    DELIVERY_CONFIG_DELETE_SUCCESS,
    DELIVERY_CONFIG_DELETE_ERROR,
    DELIVERY_CONFIGS_BULK_FETCH_SUCCESS,
    DELIVERY_CONFIGS_BULK_FETCH_ERROR,
    DELIVERY_CONFIGS_BULK_CREATE_SUCCESS,
    DELIVERY_CONFIGS_BULK_CREATE_ERROR,
    DELIVERY_CONFIGS_BULK_UPDATE_SUCCESS,
    DELIVERY_CONFIGS_BULK_UPDATE_ERROR,
    DELIVERY_CONFIGS_BULK_DELETE_SUCCESS,
    DELIVERY_CONFIGS_BULK_DELETE_ERROR,
  } from '../actions/deliveryConfigActions';
  
  const initialState = {
    allConfigs: [], // Stores all delivery configs
    error: null, // Stores any error messages
  };
  
  const deliveryConfigReducer = (state = initialState, action) => {
    switch (action.type) {
      // Fetch all delivery configs
      case DELIVERY_CONFIGS_FETCH_SUCCESS:
        return { ...state, allConfigs: action.payload, error: null };
      case DELIVERY_CONFIGS_FETCH_ERROR:
        return { ...state, error: action.payload };
  
      // Create a new delivery config
      case DELIVERY_CONFIG_CREATE_SUCCESS:
        return { ...state, allConfigs: [...state.allConfigs, action.payload], error: null };
      case DELIVERY_CONFIG_CREATE_ERROR:
        return { ...state, error: action.payload };
  
      // Update an existing delivery config
      case DELIVERY_CONFIG_UPDATE_SUCCESS:
        return {
          ...state,
          allConfigs: state.allConfigs.map((config) =>
            config.config_id === action.payload.config_id ? action.payload : config
          ),
          error: null,
        };
      case DELIVERY_CONFIG_UPDATE_ERROR:
        return { ...state, error: action.payload };
  
      // Delete a delivery config
      case DELIVERY_CONFIG_DELETE_SUCCESS:
        return {
          ...state,
          allConfigs: state.allConfigs.filter((config) => config.config_id !== action.payload),
          error: null,
        };
      case DELIVERY_CONFIG_DELETE_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk fetch delivery configs
      case DELIVERY_CONFIGS_BULK_FETCH_SUCCESS:
        return { ...state, allConfigs: action.payload, error: null };
      case DELIVERY_CONFIGS_BULK_FETCH_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk create delivery configs
      case DELIVERY_CONFIGS_BULK_CREATE_SUCCESS:
        return { ...state, allConfigs: [...state.allConfigs, ...action.payload], error: null };
      case DELIVERY_CONFIGS_BULK_CREATE_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk update delivery configs
      case DELIVERY_CONFIGS_BULK_UPDATE_SUCCESS:
        return {
          ...state,
          allConfigs: state.allConfigs.map((config) =>
            action.payload.find((updatedConfig) => updatedConfig.config_id === config.config_id) || config
          ),
          error: null,
        };
      case DELIVERY_CONFIGS_BULK_UPDATE_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk delete delivery configs
      case DELIVERY_CONFIGS_BULK_DELETE_SUCCESS:
        return {
          ...state,
          allConfigs: state.allConfigs.filter(
            (config) => !action.payload.includes(config.config_id)
          ),
          error: null,
        };
      case DELIVERY_CONFIGS_BULK_DELETE_ERROR:
        return { ...state, error: action.payload };
  
      // Default case
      default:
        return state;
    }
  };
  
  export default deliveryConfigReducer;