import { SET_SUBSCRIPTION_MANAGE_NAVBAR_STATE } from '../actions/subscriptionManagementActions'; // Adjust the import path as necessary

const initialState = {
    deliveryconfig: true, // Default to true if Delivery Config is the initial state
    subscription: false,
    expiry: false,
};

const subscriptionNavbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBSCRIPTION_MANAGE_NAVBAR_STATE:
            return {
                ...state,
                ...action.payload, // Merges the new state with the existing state
            };
        default:
            return state;
    }
};

export default subscriptionNavbarReducer;
