import { generateToken, requestOTP, mobileLogin,mobileRegister } from '../../api/services/authService';
import {getCart,createBulkCartItems,createCart, getBulkCartItems, updateBulkCartItems} from './Addtocart'
import { getCookieCart,clearCookieCart,saveCookieCart } from '../../utils/schema/cookieCart';
// import fetchCart
// Action Types
export const GENERATE_TOKEN_REQUEST = 'GENERATE_TOKEN_REQUEST';
export const GENERATE_TOKEN_SUCCESS = 'GENERATE_TOKEN_SUCCESS';
export const GENERATE_TOKEN_FAILURE = 'GENERATE_TOKEN_FAILURE';

export const REQUEST_OTP_REQUEST = 'REQUEST_OTP_REQUEST';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
export const REQUEST_OTP_FAILURE = 'REQUEST_OTP_FAILURE';

export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT'; // Add this for logout action

// Action to generate token
export const generateTokenAction = () => async (dispatch) => {
    dispatch({ type: GENERATE_TOKEN_REQUEST });
    try {
        const data = await generateToken();
        //console.log(data,'----response')
        dispatch({ type: GENERATE_TOKEN_SUCCESS, payload: data });

    } catch (error) {
        
        dispatch({ type: GENERATE_TOKEN_FAILURE, payload: error.message });
    }                                         
               
};
// Action to request OTP
export const requestOTPAction = (mobileNumber) => async (dispatch, getState) => {
    dispatch({ type: REQUEST_OTP_REQUEST });
    const { auth } = getState();
    try {
        const data = await requestOTP(auth.token, mobileNumber);
        dispatch({ type: REQUEST_OTP_SUCCESS, payload: data });
        
    } catch (error) {
        dispatch({ type: REQUEST_OTP_FAILURE, payload: error.message });
    }
};
const cart_funct =()=> async (dispatch,getState)=>{
    const auth = getState().auth;
        const cart_response = await dispatch(getCart(auth.user, auth.token)); // Fetch the cart
        //console.log('userrrrrrrrrrrr--------------------',auth.user, auth.token,cart_response);
        if (!cart_response){
            //console.log('carttttttttttttttttt--------------------',auth.user, auth.token);
            await dispatch(createCart(auth.user, auth.token)); // Create a new cart if none exists
            //console.log('carttttttttttttttttt--------------------',auth.user, auth.token);
            

            await dispatch(getCart(auth.user, auth.token));
        
        }
            
        
        try{
            await dispatch(getBulkCartItems(getState().cart.cart.cart_id, auth.token));
        }
        catch{
            //console.log('')
        }
        const localcartItems = getCookieCart();
        const cart = getState().cart
        //console.log(getCookieCart(),getState().cart.cart.cart_id,cart,'------------------multiple cart response')
        if (localcartItems.length >0){
            const cartId =  getState().cart.cart.cart_id;
            const localCartItems =getCookieCart().map((item) => ({
                ...item,
                cart_id: cartId,
            }));
            if (cart.cartItems){
                if (cartId) {
                    const existingCartItems = getState().cart.cartItems;
                    const mergedItems = localCartItems.map((localItem) => {
                        const existingItem = existingCartItems.find(
                            (existing) => existing.product_id === localItem.product_id
                        );
        
                        if (existingItem) {
                            return {
                                ...localItem,
                                quantity: localItem.quantity + existingItem.quantity, // Add quantities
                              
                                total_price: existingItem.price_per_unit * (localItem.quantity + existingItem.quantity),
                            };
                        }
                        return localItem; // Keep local item as is if no match
                    });
                    const uniqueDatabaseItems = existingCartItems.filter(
                        (existing) =>
                            !localCartItems.some(
                                (local) => local.product_id === existing.product_id
                            )
                    );
                    const finalItems = [...mergedItems, ...uniqueDatabaseItems];
                    await dispatch(updateBulkCartItems(finalItems, auth.token));
                }
            }
            else{
                await dispatch(createBulkCartItems(localCartItems, auth.token));
            }

        await dispatch(getBulkCartItems(getState().cart.cart.cart_id, auth.token));
        }
}
export const mobRegisterAction =  (number, otp)=>async (dispatch)=>{
    try {
        const data = await mobileRegister(number, otp);
        //console.log('register data--------', data);
        dispatch({ type: LOGIN_SUCCESS, payload: data });
        await dispatch(cart_funct()); 
        
        return data;
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error.message });
        return false;
    }
}
export const loginAction = (number) => async (dispatch, getState) => {
    
    dispatch({ type: LOGIN_REQUEST });

    try {
        const data = await mobileLogin(number);
        //console.log('Login successful, data:', data,data.verified);
        
        
        // Get or Create Cart
        if (data.verified){
            dispatch({ type: LOGIN_SUCCESS, payload: data });
            await dispatch(cart_funct()); 
            return data;
        }
        else{
            dispatch({ type: LOGIN_SUCCESS, payload: data });
            return false;
        }
    } catch (error) {
        //console.log('Login failed:', error, error.message);
        if (error === 'No User found with this mobile number') {
            return error
        } else {
            dispatch({ type: LOGIN_FAILURE, payload: error.message });
            return false;
        }
    }
};

export const logoutAction = () => (dispatch) => {
    
    dispatch({ type: LOGOUT });  // Dispatch the LOGOUT action
};
// export const registerAction(mobile,password){
    
// }
