// src/redux/reducers/deliveryReducer.js
import {
    FETCH_DELIVERIES_REQUEST,
    FETCH_DELIVERIES_SUCCESS,
    FETCH_DELIVERIES_FAILURE,
    CLEAR_DELIVERIES,
} from '../actions/deliveryActions'; // Adjust the import path as necessary

const initialState = {
    deliveryList: [],
    timestamp: null,
    loading: false,
    error: null,
};

const deliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DELIVERIES_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_DELIVERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveryList: action.payload.deliveries, // Set fetched deliveries in state
                timestamp: action.payload.timestamp,     // Set the current timestamp
            };
        case FETCH_DELIVERIES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_DELIVERIES:
            return { ...state, deliveryList: [], timestamp: null }; // Clear deliveries and reset timestamp
        default:
            return state;
    }
};

export default deliveryReducer;
