import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa'; 
import { faTrash, faPencilAlt ,faHome} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkPurchases, getBulkPurchaseItems,deletePurchase,deleteBulkPurchaseItems, deleteBulkPurchases } from '../../redux/actions/purchaseActions';
import './request_for_purchase_list.scss';

const PurchaseManagement = () => {
  const token = useSelector((state) => state.adminLogin.token) || [];
  const dispatch = useDispatch();
  useEffect(() => {
    try{
      dispatch(getBulkPurchases(token));
      dispatch(getBulkPurchaseItems(token));
     
  
    }
    catch{
  
    } 
    }, [dispatch]);       
  const purchases = useSelector((state) => state.purchase.purchases) || [];
  const purchasesItems = useSelector((state) => state.purchase.purchaseItems) || [];
  const supplier = useSelector((state) => state.supplier.suppliers) || [];
  
 //console.log('purchasesItems',purchasesItems)

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [filteredPurchases, setFilteredPurchases] = useState(purchases);

  const navigate = useNavigate();

  const paymentOptions = ['All', 'paid', 'unpaid', 'Partially Paid'];

  // Generate supplier options dynamically with label and value
  const supplierOptions = [{ label: 'All', value: null }, ...supplier.map(sup => ({ label: sup.name, value: sup.supplier_id }))];
  const supplierLabel = selectedSupplierId
  ? supplierOptions.find(option => option.value === selectedSupplierId)?.label
  : 'All';
  const handlePaymentSelect = (option) => setSelectedPayment(option);
 
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      setSelectedIds(filteredPurchases.map((item) => item.purchase_id));
    }
    setSelectAll(!selectAll);
  };
      useEffect(() => {
        if (selectedIds.length === filteredPurchases.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }, [selectedIds, filteredPurchases.length]);


  const handleEditClick = (id) => {
    navigate(`/admin/add-request-for-purchase`, { state: id });
  };

  const handleBulkDeleteClick = async () => {
    if (selectedIds.length === 0) {
      alert('Please select at least one purchase request to delete.');
      return;
    }
  
    if (!window.confirm('Are you sure you want to delete the selected purchase requests?')) {
      return;
    }
  
    try {
      for (const purchaseId of selectedIds) {
        // Delete associated purchase items
        const associatedPurchaseItemIds = purchasesItems
          .filter(item => item.purchase_id === purchaseId)
          .map(item => item.purchase_item_id);
  
        if (associatedPurchaseItemIds.length > 0) {
          await dispatch(deleteBulkPurchaseItems(associatedPurchaseItemIds, token));
        }
  
        // Delete the purchase request
       
      }
      await dispatch(deleteBulkPurchases(selectedIds,token));
  
      // Refresh data after deletion
      dispatch(getBulkPurchases(token));
      dispatch(getBulkPurchaseItems(token));
  
      alert('Selected purchase requests and their items were deleted successfully.');
      setSelectedIds([]); // Clear selection after deletion
    } catch (error) {
      //console.error('Error during bulk deletion:', error);
      alert('An error occurred while deleting the selected purchase requests. Please try again.');
    }
  };
  

  const handleDeleteClick = async (id) => {
    if (!window.confirm('Are you sure you want to delete this Purchase request?')) {
      return;
    }
  
    try {
      // Delete the purchase request
      const response = await dispatch(deletePurchase(id, token));
      if (response) { // Assuming response has a status field
        // Find associated purchase item IDs
        const associatedPurchaseItemIds = purchasesItems
          .filter(item => item.purchase_id === id)
          .map(item => item.purchase_item_id);
  
        if (associatedPurchaseItemIds.length > 0) {
          // Delete associated purchase items
          await dispatch(deleteBulkPurchaseItems(associatedPurchaseItemIds, token))          ;
        }

        dispatch(getBulkPurchases(token));
        dispatch(getBulkPurchaseItems(token));
  
        alert('Purchase request deleted successfully.');
      } else {
        //console.error('Failed to delete purchase request:', response);
        alert('Failed to delete the purchase request. Please try again.');
      }
    } catch (error) {
      //console.error('Error deleting purchase request:', error);
      alert('An error occurred while deleting the purchase request.');
    }
  };
  

  const get_supplier_name = (id) => {
    const supplier_data = supplier.find(s => s.supplier_id === id);
    return supplier_data ? supplier_data.name : '';
  };

  const handleSearch = () => {
    const filtered = purchases.filter((purchase) => {
      const matchesStartDate = !startDate || new Date(purchase.date) >= new Date(startDate);
      const matchesEndDate = !endDate || new Date(purchase.date) <= new Date(endDate);
      const matchesPayment = selectedPayment === 'All' || purchase.payment_status === selectedPayment;
      const matchesStatus = selectedStatus === 'All' || purchase.status === selectedStatus;
      const matchesSupplier = selectedSupplierId === null || purchase.supplier_id === selectedSupplierId;
      return matchesStartDate && matchesEndDate && matchesPayment && matchesStatus && matchesSupplier;
    });
    setFilteredPurchases(filtered);
  };

  useEffect(() => {
    setFilteredPurchases(purchases);
  }, [purchases]);

  return (
    <div className="purchase-management-page">
       <div className="breadcrumb-container">
       <div className="breadcrumb-container">
        
        <Link to="/admin/dashboard" className="breadcrumb-text">
         <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <Link to="/admin" className="breadcrumb-text">
           Product List
         </Link>
         <span className="breadcrumb-separator">{'>'}</span>
         <Link to="/admin/request_for_purchase_list" className="breadcrumb-text">
           Request purchase List
         </Link>
       </div>
      </div>
      <div className="request-purchase-list-container"></div>
      <div className="purchase-management-container">
        <div className="purchase-list-content">
  <div className="header-row">
    <h5 className="text-heading1">Request for Purchase List</h5>
    <div className="top-right-buttons">
      <ButtonComponent
        text={<><FaPlus className="add-icon" /> Add Request For Purchase</>}
        className={"add-purchase-button1"}
        onClick={() => navigate('/admin/add-request-for-purchase')}
      />
    </div>
    </div>
    </div>
        <hr />

        <div className="filter-section">
          <div className="filter-item">
            <label>Start Date</label>
            <Datepicker
            className={"date-input"}
              dateValue={startDate}
              onDateChange={setStartDate}
              placeholder="Select Start Date"
            />
          </div>
          <div className="filter-item">
            <label>End Date</label>
            <Datepicker
            className={"date-input"}
              dateValue={endDate}
              onDateChange={setEndDate}
              placeholder="Select End Date"
            />
          </div>
      
          <div className="filter-item">
            <label>Payment</label>
            <DropdownButton 
              options={paymentOptions}
              label={selectedPayment}
              onSelect={handlePaymentSelect}
            />
          </div>
  
          <div className="action-buttons">
  <ButtonComponent
    text="Search"
    className="search-button"
    onClick={handleSearch}
  />
  <ButtonComponent
    text="Show All"
    className="show-all-button"
    onClick={() => setFilteredPurchases(purchases)}
  />
  <ButtonComponent
    text={
      <>
        <FontAwesomeIcon icon={faTrash} className="bulk-delete-icon" /> Bulk Delete
      </>
    }
    className="bulk-delete-button"
    onClick={handleBulkDeleteClick}
    disabled={selectedIds.length === 0}
  />
</div>

        </div>

        <hr className="hr-line" />

        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th><CheckboxComponent checked={selectAll} onChange={handleSelectAllChange} /></th>
                <th>PO No.</th> 
                <th>Supplier/Third Party</th>
                <th>Date</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredPurchases.map((item) => (
                <tr key={item.id}>
                  <td>
                    <CheckboxComponent
                      checked={selectedIds.includes(item.purchase_id)}
                      onChange={() => handleCheckboxChange(item.purchase_id)}
                    />
                  </td>
                  <td>{item.po_number}</td>
                  <td>{get_supplier_name(item.supplier_id)}</td>
                  <td>{item.date}</td>
                  <td>
             
                    <button className="icon-button" onClick={() => handleEditClick(item.purchase_id)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  </td>
                  <td>
                    <button className="icon-button" onClick={() => handleDeleteClick(item.purchase_id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PurchaseManagement;