import React from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.scss';
import googleplay from '../../../assets/images/googleplay.svg';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const location = useLocation(); // Hook to get the current location
  const navigate = useNavigate();
  // Conditionally render the Footer only if not on the Refer page
  if (location.pathname === '/refer') {
      return null; // Do not render the Footer on the Refer page
  }
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Company Section */}
        {/* <div className="footer-column footer-company">
          <h4 className="footer-heading">Company</h4>
          <ul className="footer-ul">
            <li>About Us</li>
            <li>Health Article</li>
            <li>All Medicines</li>
            <li>Need Help</li>
            <li>FAQ</li>
          </ul>
        </div> */}

        {/* Social and Legal Sections */}
        <div className="footer-column footer-company">
          <h4 className="footer-heading">Social</h4>
          <div className="social-icons">
            <i className="fab fa-facebook"></i>
            <i className="fab fa-youtube"></i>
            <i className="fab fa-linkedin"></i>
          </div>
         
        </div>

        {/* Subscribe Section */}
        <div className="footer-column footer-social">
        <h4 className="footer-heading">Legal</h4>
          <ul>
            <li onClick={()=>navigate('/terms_conditions')}>Terms & Conditions</li>
            <li onClick={()=>navigate('/privacy')}>Privacy Policy</li>
            <li onClick={()=>navigate('/refund')}>Refund Policy</li>
            <li onClick={() => navigate('/shipping_delivery')}>Shipping & Delivery Policy</li>
          
        
          </ul>
        </div>

        {/* Download Truemeds Section */}
        <div className="footer-column footer-truemed">
          <h4 className="footer-heading">Download Freshpure</h4>
          <div className="footer-truemed-content">
          <p>Fresh from the farm, delivered to your doorstep!</p>
          <p>Get easy access to organic produce, fresh dairy, and natural farm products. With our app, you can enjoy farm-fresh goodness with just a tap. Download now and bring the farm to your home.</p>
            <img src={googleplay} alt="Pharma" className="img-fluid googleplay" />
          </div>
        </div>

        {/* Contact Us Section */}
        <div className="footer-column footer-contact">
      <h4 className="footer-heading">Contact Us</h4>
      <p>Our customer representative team is available 7 days a week from 9 am - 9 pm.</p>
      <p>
        <i className="fas fa-envelope"></i> freshpure.in@gmail.com
      </p>
      <p>
      <i className="fas fa-phone"></i> 094429 17151
      </p>
      <p>
        <i className="fas fa-map-marker-alt"></i> Athipalayam Rd, Saravanampatti, Coimbatore, <br/> Tamil Nadu-641035
      </p>
    </div>

    

      {/* Horizontal Line and Bottom Footer Text */}
      <hr className="footer-divider" />
      <div className="footer-bottom">      
        <span className='footer-copyright-text'>&copy; 2024 - Freshpure | All rights reserved</span>
        <div className="footer-payment-partners">
          <span className='footer-payment-partners-text'>Our Payment Partners</span>
          {/* Add payment partner icons here */}
          <i className="fab fa-cc-visa"></i>
          <i className="fab fa-cc-mastercard"></i>
          <i className="fab fa-cc-paypal"></i>
          <i className="fab fa-cc-amex"></i>
          {/* Add more payment partner icons as needed */}
        </div>
      </div>
    </div>
    </footer>
  );
};

export default Footer;