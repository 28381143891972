import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import { FaTrash, FaPlus } from "react-icons/fa";
import "./bulk-allocate-discount.scss";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import {
  bulkCreateAllocatedOffers,
  bulkUpdateAllocatedOffers,
} from "../../redux/actions/discountAllocateAction";
import toast from "react-hot-toast";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import SearchableDropdown from "../../components/common/SearchableDropdown/SearchableDropdown";
const BulkAllocateOffer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [offerData, setOfferData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const statusOptions = ["active", "inactive", "expired"];
  const typeOptions = ["category", "product"];

  const categories = useSelector((state) => state.category.categoryList.categories || []);
  const products = useSelector((state) => state.products.basicProducts || []);
 
  const [entityOptions, setEntityOptions] = useState([]);

  const allocatedOfferList = useSelector((state) => state.allocateOffer.allocatedOffers || []);
  const offers = useSelector((state) => state.discount.offerList || []);
  const token = useSelector((state) => state.adminLogin.token);
  const initialOffers = location.state || [];

  const offerOptions = offers.map((offer) => ({ label: offer.conditions, id: offer.offer_id }));
  const normalizeDate = (dateString) => {
    if (!dateString) return '';
    const dateParts = dateString.match(/(\d{2})[-\/](\d{2})[-\/](\d{4})/);
    if (dateParts) {
      return `${dateParts[3]}-${dateParts[2]}-${dateParts[1]}`; // Convert to YYYY-MM-DD
    }
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split('T')[0] : ''; // Fallback to default date parsing
  };
  useEffect(() => {
    const categoryMap = categories.reduce(
      (map, category) => ({ ...map, [category.category_id]: category.name }),
      {}
    );
    const productMap = products.reduce(
      (map, product) => ({ ...map, [product.product_id]: product.product_name }),
      {}
    );
    const processOffers = () => {
      if (initialOffers.length === 0) {
        if (offerData.length === 0) {
          setOfferData([{ offer_id: "", entity_type: "", entity_id: "", status: "" }]);
        }
      } else {
        if (typeof initialOffers[0] === "object") {
          const fetchedOffers = initialOffers.map((offer) => ({
            offer_id:
              offers.find((off) => off.conditions === offer.conditions)?.offer_id || "",
            entity_type: offer.entity_type,
            entity_id:
              (offer.entity_type === "category" &&
                categories.find((cat) => cat.name === offer.entity_name)?.category_id) ||
              (offer.entity_type === "product" &&
                products.find((prod) => prod.product_name === offer.entity_name)?.product_id) ||
              "",
            status: offer.status,
            entity_name: offer.entity_name,
          }));
          setOfferData(fetchedOffers);
        } else if (typeof initialOffers[0] === "string") {
          const fetchedOffers = initialOffers.map((id) => {
            const offer = allocatedOfferList.find((o) => o.offer_allocation_id === id);
            if (offer) {
              const entityName =
                (offer.entity_type === "category" && categoryMap[offer.entity_id]) ||
                (offer.entity_type === "product" && productMap[offer.entity_id]) ||
                "";
              return { ...offer, entity_name: entityName };
            } else {
              return {
                offer_allocation_id: id,
                offer_id: "",
                entity_type: "",
                entity_id: "",
                status: "",
                entity_name: "",
              };
            }
          });
          setOfferData(fetchedOffers);
        }
      }
    };

    processOffers();
  }, [initialOffers, offers, categories, products]);

  const handleSaveClick = async () => {
    const hasEmptyFields = offerData.some(
      (offer) => !offer.offer_id || !offer.entity_id || !offer.status
    );
    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Offer Code, Entity Name, and Status).");
      return;
    }

    setIsSaving(true);
    let responseStatus;
    if (Array.isArray(initialOffers) && initialOffers.length > 0) {
      if (typeof initialOffers[0] === "object") {
        responseStatus = await dispatch(bulkCreateAllocatedOffers(offerData, token));
      } else if (typeof initialOffers[0] === "string") {
        responseStatus = await dispatch(bulkUpdateAllocatedOffers(offerData, token));

      }
    }

    setIsSaving(false);

    if (responseStatus) {
      navigate("/admin/offer-allocation");
    } else {
      // //console.error("Failed to save offers");
    }
  };

  const handleEntityTypeChange = (index, selectedType) => {
    handleInputChange(index, "entity_type", selectedType);
    setEntityOptions(
      selectedType === "category"
        ? categories.map((category) => ({ label: category.name, id: category.category_id }))
        : products.map((product) => ({ label: product.product_name, id: product.product_id }))
    );
  };

  const getCode = (identifier) => {
    const offer = offers.find(
      (offer) => offer.offer_id === identifier || offer.conditions === identifier
    );
    return offer ? offer.conditions : "";
  };

  const getEntityName = (entityType, identifier) => {
    if (entityType === "category") {
      const category = categories.find(
        (category) => category.category_id === identifier || category.name === identifier
      );
      return category ? category.name : "";
    } else if (entityType === "product") {
      const product = products.find(
        (product) => product.product_id === identifier || product.product_name === identifier
      );
      return product ? product.product_name : "";
    }

    return "";
  };

  const handleInputChange = (index, field, value) => {
    const updatedOffers = [...offerData];
    updatedOffers[index][field] = value;
    setOfferData(updatedOffers);
  };

  const handleAddRow = () => {
    setOfferData((prevOffers) => [
      ...prevOffers,
      { offer_id: "", entity_type: "", entity_id: "", status: "" },
    ]);
  };

  const handleDelete = (index) => {
    const newOffers = [...offerData];
    newOffers.splice(index, 1);
    setOfferData(newOffers);
  };

  return (
    <div className="bulk-allocate-offer-main-page">
      <div className="breadcrumb-container">
        <Link to="/admin" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <Link to="/admin/offer-allocation" className="breadcrumb-text">
          <span className="breadcrumb-text">Offer Allocate list</span>
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <span className="breadcrumb-text">Bulk Allocate Offer</span>
      </div>
      <div className="bulk-allocate-offer-container"></div>
      <div className="bulk-allocate-offer">
        <div className="offer-table">
          <div className="header-row">
            <h5 className="text-heading1">Bulk Allocate Offer</h5>
            <div className="add-new-button">
              {typeof initialOffers[0] === "object" && (
                <ButtonComponent
                  text={<><FaPlus style={{ marginRight: "8px" }} />Add New</>}
                  onClick={handleAddRow}
                  className="add-button"
                />
              )}
              <ButtonComponent
                text={isSaving ? "Saving..." : "Save"}
                onClick={handleSaveClick}
                className="save-button"
                disabled={isSaving}
              />
            </div>
          </div>
        </div>
        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th>Offer Code</th>
                <th>Entity Type</th>
                <th>Entity Name</th>
                <th>Status</th>
                <th className="small-column">Delete</th>
              </tr>
            </thead>
            <tbody>
              {offerData.map((offer, index) => (
                <tr key={index}>
                  <td>
                    <SearchableDropdown
                      options={offerOptions}
                      placeholder="Search for offers"
                      value={getCode(offer.offer_id || offer.conditions)}
                      onSelect={(selectedId) => handleInputChange(index, "offer_id", selectedId)}
                    />
                  </td>
                  <td>
                    <DropdownButton
                      label={offer.entity_type || "Select Type"}
                      options={typeOptions}
                      onSelect={(selectedOption) =>
                        handleEntityTypeChange(index, selectedOption)
                      }
                    />
                  </td>
                  <td>
                  <SearchableDropdown
                    options={
                      offer.entity_type === "category"
                        ? categories
                            .filter(category => products.some(product => product.category_id === category.category_id)) // Filter categories linked to products
                            .map(category => ({
                              label: category.name,
                              id: category.category_id,
                            }))
                        : products.map(product => ({
                            label: product.product_name,
                            id: product.product_id,
                          }))
                    }
                    placeholder="Search for entities"
                    value={getEntityName(offer.entity_type, offer.entity_id)}
                    onSelect={(selectedId) => handleInputChange(index, "entity_id", selectedId)}
                  />

                  </td>
                  <td>
                    <DropdownButton
                      label={offer.status || "Status"}
                      options={statusOptions}
                      onSelect={(selectedOption) =>
                        handleInputChange(index, "status", selectedOption)
                      }
                    />
                  </td>
                  <td className="actions1">
                    <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BulkAllocateOffer;
