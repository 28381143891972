import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './SearchBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import LocationDropdown from '../LocationDropdown/LocationDropdown';

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const [showResults, setShowResults] = useState(false);
    const navigate = useNavigate();
    const basicProducts = useSelector((state) => state.products.basicProducts) || [];

    const filteredProducts = basicProducts.filter(
        (product) =>
            product.product_name &&
            product.product_name.toLowerCase().includes(query.toLowerCase())
    );

    const handleInputChange = (event) => {
        setQuery(event.target.value);
        setShowResults(true);
    };

    const handleResultClick = (product) => {
        //console.log(product,'---product search')
        if (product) {
            // Navigate to single product page if the product exists
            navigate(`/singleproducts/${encodeURIComponent(product.product_name)}`, {state: product.product_id });
        } else {
            // Navigate to products page if no product exists
            navigate('/products', { state: { searchQuery: query } });
        }
        setShowResults(false); // Close the dropdown
    };

    const handleSeeAllResults = () => {
        navigate('/products', { state: { searchQuery: query } });
    };

    const handleSearchButtonClick = () => {
        handleSeeAllResults();
    };

    return (
        <div className="searchBar">
            <div className="locationWrapper">
        <LocationDropdown />
    </div>
            <div className="searchInputWrapper">
                <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                <input
                    type="text"
                    placeholder="Search for products"
                    value={query}
                    onChange={handleInputChange}
                    className="searchInput"
                    onFocus={() => setShowResults(true)}
                    onBlur={() => setTimeout(() => setShowResults(false), 200)} // Add slight delay
                />

{showResults && query.trim() && (
                <div className="searchResults">
                    {filteredProducts.slice(0, 5).map((product, index) => (
                        <div
                            key={index}
                            className="searchResultItem"
                            onMouseDown={() => handleResultClick(product)} // Change onClick to onMouseDown
                        >
                            <span>{product.product_name}</span>
                        </div>
                    ))}
                    {filteredProducts.length > 0 && (
                        <div
                            className="searchSeeAll"
                            onMouseDown={handleSeeAllResults}
                        >
                            View all results
                        </div>
                    )}
                    {filteredProducts.length === 0 && (
                        <div className="searchNoResults">No results found</div>
                    )}
                </div>
            )}
            </div>
            <button className="searchButton" onClick={handleSearchButtonClick}>
                Search
             </button>
          
        </div>
    );
};

export default SearchBar;
