import React from 'react';
import './billSubscribed.scss';
import BillDetailCard from '../common/card/billdetails-card';
import { useSelector } from 'react-redux';

const BillDetailsCard = ({ subscriptionId }) => {
    // Redux selectors
    const subscriptions = useSelector((state) => state.subscription.subscriptionList || []);

    // Find the current subscription
    const currentSubscription = subscriptions.find(
        (subscription) => subscription.subscription_id === subscriptionId
    );

    // If no subscription is found, handle fallback
    if (!currentSubscription) {
        return (
            <div className="bill-details-card">
                <h6 className="bill-details-header">Subscription Details</h6>
                <p>No subscription details available.</p>
            </div>
        );
    }

    return (
        <div className="bill-details-card">
            <div className="bill-details">
                <h6 className="bill-details-header">Bill Details</h6>
                <hr />
                {/* Total Amount */}
                <BillDetailCard
                    label="Total Amount"
                    value={`₹${parseFloat(currentSubscription.total_amount || '0.00')}`}
                    isSmall
                />
                {/* Shipping Fee */}
                <BillDetailCard
                    label="Shipping Fee"
                    value={`₹${currentSubscription.shipping_fee || '0.00'}`}
                    isSmall
                />
                {/* Grand Total */}
                <BillDetailCard
                    label="Grand Total"
                    value={`₹${(
                        parseFloat(currentSubscription.total_amount || 0) +
                        parseFloat(currentSubscription.shipping_fee || 0)
                    ).toFixed(2)}`}
                    isSmall
                />
                {/* Additional Info */}
              
            </div>
        </div>
    );
};

export default BillDetailsCard;
