import React, { useState, useEffect } from 'react';
import { fetchOffers } from '../../redux/actions/discountAction';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './AddDiscountAllocation.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllocatedOffers, updateAllocatedOffer, createAllocatedOffer } from '../../redux/actions/discountAllocateAction';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';
import { fetchCategory } from '../../redux/actions/categoryAction';
import { toast } from 'react-hot-toast';
import {faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const AllocateOffer = () => {
  const dispatch = useDispatch();
  const typeOptions = ['category', 'product'];
  const statusOptions = ['active', 'inactive', 'expired'];

  const [formData, setFormData] = useState({
    offer_id: '',
    entity_type: '',
    entity_id: '',
    status: '',
  });

  const [originalFormData, setOriginalFormData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [entityName, setEntityName] = useState('');
  const [offerName, setOfferName] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [addType, setAddType] = useState('');

  const token = useSelector((state) => state.adminLogin.token);
  const allocatedOfferList = useSelector((state) => state.allocateOffer.allocatedOffers) || [];
  const offerList = useSelector((state) =>
    (state.discount.offerList || []).filter(
      (offer) => offer.status === 'active' && new Date(offer.expiry_date) >= new Date()
    )
  );
  const categoryOptions = useSelector((state) => state.category.categoryList.categories) || [];
  const productList = useSelector((state) => state.products.basicProducts || []);
  const location = useLocation();
  const navigate = useNavigate();
  const allocated_Id = location.state || null;

  const updateNames = (newFormData) => {
    const entityName = newFormData.entity_type === 'category'
      ? categoryOptions.find((category) => category.category_id === newFormData.entity_id)?.name || ''
      : productList.find((product) => product.product_id === newFormData.entity_id)?.product_name || '';

    const offerName = offerList.find((offer) => offer.offer_id === newFormData.offer_id)?.conditions || '';

    setEntityName(entityName);
    setOfferName(offerName);
  };

  useEffect(() => {
    if (formData.entity_id || formData.offer_id) {
      updateNames(formData);
    }
  }, [formData, categoryOptions, productList, offerList]);

  useEffect(() => {
    const fetchInitialOffers = () => {
      dispatch(fetchOffers(token));
    };

    fetchInitialOffers();
    const intervalId = setInterval(fetchInitialOffers, 180000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (allocated_Id) {
      const offerAllocatedData = allocatedOfferList.find((data) => data.offer_allocation_id === allocated_Id);
      if (offerAllocatedData) {
        setFormData(offerAllocatedData);
        setOriginalFormData(offerAllocatedData);
      }
    }
  }, [allocated_Id, allocatedOfferList]);

  const handleInputChange = (name, value) => {
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    updateNames(updatedFormData);
  };

  useEffect(() => {
    setIsModified(JSON.stringify(formData) !== JSON.stringify(originalFormData));
  }, [formData, originalFormData]);

  const handleEntitySelect = (selectedId) => {
    if (formData.entity_type === 'category') {
      const available = categoryOptions.find((c) => c.category_id === selectedId);
      if (available) {
        handleInputChange('entity_id', selectedId);
      } else {
        setAddType('category');
        setIsSidebarVisible(true);
      }
    } else if (formData.entity_type === 'product') {
      const available = productList.find((p) => p.product_id === selectedId);
      if (available) {
        handleInputChange('entity_id', selectedId);
      } else {
        setAddType('basic');
        setIsSidebarVisible(true);
      }
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarVisible(false);
    fetchCategory(token);
  };

  const handleOfferEntity = (selectedId) => {
    const available = offerList.find((o) => o.offer_id === selectedId);
    if (available) {
      handleInputChange('offer_id', selectedId);
    } else {
      setAddType('offer');
      setIsSidebarVisible(true);
    }
  };

  const handleSubmit = () => {
    if (!formData.entity_type || !formData.entity_id || !formData.offer_id || !formData.status) {
      toast.error('All fields are required.');
      return;
    }

    if (allocated_Id) {
      dispatch(updateAllocatedOffer(allocated_Id, formData, token));
    } else {
      dispatch(createAllocatedOffer(formData, token));
    }
    navigate('/admin/offer-allocation');
  };

const getEntityOptions = () => {
  if (formData.entity_type === 'product') {
    return productList.map((product) => ({
      label: product.product_name,
      id: product.product_id
    }));
  } else if (formData.entity_type === 'category') {
    // Get unique category IDs from productlist
    const productCategoryIds = new Set(productList.map(product => product.category_id));

    // Filter categoryOptions to include only categories that exist in productlist
    return categoryOptions
      .filter(category => productCategoryIds.has(category.category_id))
      .map(category => ({
        label: category.name,
        id: category.category_id
      }));
  } else {
    return [];
  }
};


  const offerOptions = offerList.map((offer) => ({
    label: offer.conditions,
    id: offer.offer_id,
  }));

  return (
    <div className="admin-discount-offer-section">
      <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
       </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Offer List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/offer-allocation" className="breadcrumb-text">
          Offer Allocation List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Offer Allocation</span>
      </div>
      <div className="add-offer-allocation-container"></div>
      <form className="admin-user-form">
      <h5 className="text-heading1">Add Offer Allocation</h5>
        <div className="form-row">
          <div className="form-group">
            <label>Entity Type</label>
            <DropdownButton
              label={formData.entity_type || 'Select Type'}
              options={typeOptions}
              onSelect={(selectedOption) => handleInputChange('entity_type', selectedOption)}
            />
          </div>
       
          <div className="form-group">
            <label>Entity</label>
            <SearchableDropdown
              options={getEntityOptions()}
              placeholder={`Search for ${formData.entity_type || 'entities'}`}
              value={entityName}
              onSelect={handleEntitySelect}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Offers</label>
            <SearchableDropdown
              options={offerOptions}
              placeholder="Search for offers"
              value={offerName}
              onSelect={handleOfferEntity}
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <DropdownButton
              label={formData.status || 'Status'}
              options={statusOptions}
              onSelect={(selectedOption) => handleInputChange('status', selectedOption)}
            />
          </div>
        </div>
        <div className="action-buttons">
          <ButtonComponent
            text="Save"
            variant="primary"
            type="button"
            className="submit-button"
            onClick={handleSubmit}
            disabled={!isModified}
          />
        </div>
      </form>
      {isSidebarVisible && <Sidebar type={addType} urls={'/admin/offer/allocation/add'} onClose={handleCloseSidebar} />}
    </div>
  );
};

export default AllocateOffer;
