import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import AddressOffcanvas from "../../components/common/address-offcanvas/AddressOffcanvas";
import CardItem from "../../components/common/card/card-items";
import OffcanvasComponent from "../../components/OffcanvasComponent/OffcanvasComponent";
import MedicineDetailsModal from "../../components/sections/MedicineDetailsModal/MedicineDetailsModal";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import Datepicker from "../../components/common/datepicker/datepicker";
import { useSelector, useDispatch } from "react-redux";
import {faEdit, faCaretDown, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import QuantityInput from "../../components/common/QuantityInput/QuantityInput";
import BillDetailsCard from "../../components/billsubscription/billorderdetails";
import {
  fetchBulkAddresses,
  setSelectedAddress,
} from "../../redux/actions/AddressAction";
import "./subscription-checkout.scss";
import { getSubscriptionCookie,saveSubscriptionCookie } from "../../utils/schema/cookieSubscription";

const SubscriptionSection = () => {
  const dispatch = useDispatch();
  ////console.log(getSubscriptionCookie(),'-----subscribeddddddddddddddddddd')
  
  const token = useSelector((state) => state.auth.authData?.token);
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
    const orderItems = useSelector((state) => state.orders.orderItems || []);
  const addresses = useSelector((state) => state.address.addresses) || [];
  const selectedAddressId = useSelector((state) => state.address.selectedAddressId) || '';
  const selectedAddress = addresses.find((address) => address.address_id === selectedAddressId);
  const products = useSelector((state) => state.products.products || []);
  const location = useLocation();
  const [deliveryDate, setDeliveryDate] = useState('');
  const order_id = location.state || null;
  const authData = useSelector((state) => state.auth.user) || "";
  const [modalShow, setModalShow] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState({});
  const basicProducts = useSelector((state) => state.products.basicProducts || []); // Fetch basic products
  const [loading, setLoading] = useState(true);
  const [showAddressOffcanvas, setShowAddressOffcanvas] = useState(false);
  const [showAddressDropdown, setShowAddressDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [discount, setDiscount] = useState(0);
  const productUrls=useSelector((state)=>state.image.urls.products);
  const currentOrderItems = orderItems.filter((item) => item.order_id === order_id);
  const [selectedProductItem,setSelectedProductItem]=useState([])
 
  const frequencies = {
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
  };
  const handleMonthlyDateAdd = (index, date) => {
    if (!date) return; // Ensure valid date is selected
  
    const selectedDay = new Date(date).getDate(); // Extract only the day
    const selectedMonth = new Date(date).getMonth(); // Extract the month
    const selectedYear = new Date(date).getFullYear(); // Extract the year
  
    // Validate the day exists in the selected month and year
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
  
    if (selectedDay <= daysInMonth) {
      setSubscriptions((prevSubscriptions) => {
        const updatedSubscriptions = [...prevSubscriptions];
        const currentDates = updatedSubscriptions[index].monthly_date || [];
  
        // Check if the day is already in the list
        if (!currentDates.includes(selectedDay)) {
          updatedSubscriptions[index].monthly_date = [...currentDates, selectedDay];
        }
  
        return updatedSubscriptions;
      });
    } else {
      //console.error("Invalid day selected for the given month and year.");
    }
  };
  
  
  const handleMonthlyDateRemove = (index, date) => {
    const day = new Date(date).getDate(); // Extract only the day
    setSubscriptions((prevSubscriptions) => {
      const updatedSubscriptions = [...prevSubscriptions];
      updatedSubscriptions[index].monthly_date = updatedSubscriptions[
        index
      ].monthly_date.filter((d) => d !== day); // Remove the selected day
      return updatedSubscriptions;
    });
  };
  
  
  const [selectedProduct, setSelectedProduct] = useState(null);
 // Frequency type (daily, weekly, etc.)
  const [timeOptions, setTimeOptions] = useState([]); // Available time options
  const [yearlyDate, setYearlyDate] = useState(null);
  const [billDetails, setBillDetails] = useState(null); //
  
 const singleproduct = useSelector((state)=>state.products.basicProducts)||[];
 const [subscriptions, setSubscriptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTimings, setSelectedTimings] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const[quantity,setQuantity]=useState(0);
 
  // Handle time selection

  
  const daysOfWeek = [
    {  label: "S", id: "Sunday" },
    { label: "M", id: "Monday" },
    { label: "T", id: "Tuesday" },
    { label: "W", id: "Wednesday" },
    {  label: "T", id: "Thursday" },
    { label: "F", id: "Friday" },
    {  label: "S", id: "Saturday" },
  ];

  // Handle day selection
 ////console.log(selectedDays,'--daysssss')
 const handleDaySelect = (index, dayId, selectAll = false) => {
  setSubscriptions((prev) => {
    const updated = [...prev];
    const selectedDays = updated[index].selectedDays || [];

    if (selectAll) {
      // Select all or deselect all days
      updated[index].selectedDays =
        selectedDays.length === 7 ? [] : daysOfWeek.map((d) => d.id);
    } else {
      // Toggle individual day
      if (selectedDays.includes(dayId)) {
        updated[index].selectedDays = selectedDays.filter((id) => id !== dayId);
      } else {
        updated[index].selectedDays = [...selectedDays, dayId];
      }
    }
    return updated;
  });
};

  useEffect(() => {
    if (selectedSubscription) {
      setSelectedTimings(selectedSubscription.daily_time || []);
    }
  }, [selectedSubscription]);
  
  
  const handleTimeSelect = (index, time) => {
    setSubscriptions((prevSubscriptions) => {
      const updatedSubscriptions = [...prevSubscriptions];
      const selectedTimes = updatedSubscriptions[index].daily_time || [];
      
      if (selectedTimes.includes(time)) {
        // Remove the time
        updatedSubscriptions[index].daily_time = selectedTimes.filter(t => t !== time);
      } else {
        // Add the time
        updatedSubscriptions[index].daily_time = [...selectedTimes, time];
      }
      return updatedSubscriptions;
    });
  };
  
  
  



  const handleDateChange = (index, field, date) => {
    if (!date) return; // Ensure valid date is selected
    
    setSubscriptions((prevSubscriptions) => {
      const updatedSubscriptions = [...prevSubscriptions];
      updatedSubscriptions[index][field] = date.toISOString();
      return updatedSubscriptions;
    });
  };
  


  const handleFieldChange = (index, field, value) => {
    setSubscriptions((prevSubscriptions) => {
      const updatedSubscriptions = [...prevSubscriptions];
      updatedSubscriptions[index] = {
        ...updatedSubscriptions[index],
        [field]: value,
      };
      return updatedSubscriptions; // React will automatically re-render
    });
  };
  
  const generateTimeOptions = () => {
    const times = [];
    const time = new Date();
    time.setHours(0, 0, 0, 0); // Start at midnight
  
    for (let i = 0; i < 48; i++) {
      const formattedTime = time.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use 24-hour format
      });
      times.push(formattedTime);
      time.setMinutes(time.getMinutes() + 30);
    }
    return times;
  }

  useEffect(() => {
    if (["daily", "weekly"].includes(selectedFrequency)) {
      const times = [];
      const time = new Date();
      time.setHours(0, 0, 0, 0);

      for (let i = 0; i < 48; i++) {
        const formattedTime = time.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        times.push(formattedTime);
        time.setMinutes(time.getMinutes() + 30);
      }
      setTimeOptions(times);
    } else {
      setTimeOptions([]);
    }
  }, [selectedFrequency]);



  useEffect(() => {
    const fetchedSubscriptions = getSubscriptionCookie(); // Fetch data from cookies
    ////console.log("Cookie data retrieved:", fetchedSubscriptions); // Log retrieved data
    setSubscriptions(fetchedSubscriptions || []); // Store data in state
    ////console.log(subscriptions,'-----subsssss')
  }, []);


  useEffect(() => {
    const loadedSubscriptions = getSubscriptionCookie() || [];

    const updatedSubscriptions = loadedSubscriptions.map((subscription) => ({
      ...subscription,
  
      weekly_day: subscription.selectedDays || [], // Map days for weekly
      start_date: subscription.startDate || null, // Map start date
      end_date: subscription.endDate || null, // Map end date
      daily_time: subscription.timings || [], // Map time slots
      monthly_date:subscription.selectedMonthDate ||[],
      yearly_date:subscription.selectedAnuallyDate || null,
      prouduct_id :subscription.productId,
      price:subscription.price
      
   
    }
  
  )
  
);
//console.log(loadedSubscriptions,'----subscriptionnnnn');
  if(loadedSubscriptions.length>0){
    setQuantity(loadedSubscriptions[0].quantity);

    const data = singleproduct.find((item)=>item.product_id===loadedSubscriptions[0].productId)
    setSelectedProductItem(data);

    ////console.log(selectedProductItem,data,loadedSubscriptions[0].productId,'------selected itemsssssssssssss')

  }
    setSubscriptions(updatedSubscriptions);
    if (updatedSubscriptions.length > 0) {
      setSelectedSubscription(updatedSubscriptions[0]);
      setBillDetails(updatedSubscriptions[0]);
      setQuantity(updatedSubscriptions[0].quantity || 0);
    }
  }, []);
  

 

  const handleSave = (index) => {
    const updatedSubscriptions = [...subscriptions];
    const currentSubscription = updatedSubscriptions[index];
  
    const productDetails = singleproduct.find(
      (product) => product.product_id === currentSubscription.productId
    );
  
    let price = 0;
    if (productDetails) {
      switch (currentSubscription.frequency) {
        case "daily":
          price = productDetails.daily_subscription_price || 0;
          break;
        case "weekly":
          price = productDetails.weekly_subscription_price || 0;
          break;
        case "monthly":
          price = productDetails.monthly_subscription_price || 0;
          break;
        case "yearly":
          price = productDetails.yearly_subscription_price || 0;
          break;
        default:
          price = 0;
      }
    }
  
    const formattedData = {
      productId: currentSubscription.productId || "Unknown Product",
      frequency: currentSubscription.frequency || "",
      quantity: currentSubscription.quantity || 1,
      startDate: currentSubscription.start_date || null,
      endDate: currentSubscription.end_date || null,
      price: price,
      timings: currentSubscription.daily_time || [],
      selectedDays: currentSubscription.selectedDays || [],
      selectedMonthDate: currentSubscription.monthly_date || null,
      selectedAnuallyDate: currentSubscription.yearly_date || null, // Ensure yearly dates are saved
    };
  
    updatedSubscriptions[index] = { ...currentSubscription };
    setSubscriptions(updatedSubscriptions);
    saveSubscriptionCookie(updatedSubscriptions);
  
    if (index === 0) {
      setBillDetails(formattedData);
      setQuantity(formattedData.quantity);
    }
    setIsEditMode(false);
  };
  
  
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleIncreaseQuantity = () => {
    if (billDetails) {
      const updatedQuantity = quantity + 1;
      setQuantity(updatedQuantity);
      setBillDetails({ ...billDetails, quantity: updatedQuantity });
    }
  };

  const handleDecreaseQuantity = () => {
    if (billDetails && quantity > 0) {
      const updatedQuantity = quantity - 1;
      setQuantity(updatedQuantity);
      setBillDetails({ ...billDetails, quantity: updatedQuantity });
    }
  };


  const handleRemove=()=>{
    setQuantity(0);
   }
  useEffect(() => {
    if (token) {
      dispatch(fetchBulkAddresses(token));
    }
  }, [dispatch, token]);

  if (loading) return <div>Loading...</div>;

  

  const handleApplyCoupon = (discountValue) => {
    setDiscount(discountValue);
  };

  const handleSelectAddress = (address) => {
    dispatch(setSelectedAddress(address.address_id));
    setShowAddressDropdown(false);
  };

  const handleAddNewAddress = () => {
    setShowAddressOffcanvas(true);
    setShowAddressDropdown(false);
  };
  const handleYearlyDateChange = (index, date) => {
    if (!date) return;
  
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  
    setSubscriptions((prevSubscriptions) => {
      const updatedSubscriptions = [...prevSubscriptions];
  
      if (!updatedSubscriptions[index].yearly_date) {
        updatedSubscriptions[index].yearly_date = [];
      }
  
      // Avoid duplicates
      if (!updatedSubscriptions[index].yearly_date.includes(formattedDate)) {
        updatedSubscriptions[index].yearly_date.push(formattedDate);
      }
  
      return updatedSubscriptions;
    });
  };
  
  const handleRemoveYearlyDate = (index, date) => {
    setSubscriptions((prevSubscriptions) => {
      const updatedSubscriptions = [...prevSubscriptions];
  
      updatedSubscriptions[index].yearly_date = updatedSubscriptions[index].yearly_date.filter(
        (d) => d !== date
      );
  
      return updatedSubscriptions;
    });
  };
  
  
  return (
    <div className="subscription-checkout-section">
      <div className="subscription-checkout-container">
        <div className="left-section">
          {/* Selected Address Section */}
          <div className="subscription-checkout-selected-address-card">
            <div className="subscription-checkout-address-header">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
              <span className="deliver-to">Deliver To</span>
              <strong className="address-name">{selectedAddress?.name || "User"}</strong>
              <button
                className="subscription-checkout-change-address-btn"
                onClick={() => setShowAddressOffcanvas(true)}
              >
                Change
              </button>
            </div>
            <div className="subscription-checkout-address-details">
              {selectedAddress ? (
                <>
                  <p className="address-line">{`${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.state}, ${selectedAddress.pincode}`}</p>
                  <p className="delivery-date">
                
                  </p>
                </>
              ) : (
                <p>No address selected</p>
              )}
            </div>
          </div>
          <AddressOffcanvas
            show={showAddressOffcanvas}
            handleClose={() => setShowAddressOffcanvas(false)}
            addresses={addresses}
            handleSelectAddress={handleSelectAddress}
          />
    {selectedProductItem.length>0 ? ( <CardItem
                key={selectedProductItem.product_id}
                title={selectedProductItem.product_name}
                price={`${selectedProductItem.price}`}
                mrp={`${selectedProductItem.mrp}`}
                discount={`${selectedProductItem.discount}`}
                quantity={quantity}
                onIncrease={() => handleIncreaseQuantity(selectedProductItem)}
                onDecrease={() => handleDecreaseQuantity(selectedProductItem)}
                imageUrl={productUrls[selectedProductItem.product_id]}
                hideClose={true}
                hidePause={true}
                min_order={1}
                showAddButton={true}
                popupState ={false}


              />): (
  <p>No products found</p> // Display this message if no product is selected
)}
           
{subscriptions.map((subscription, index) => (
  <div key={index} className="subscription-checkout">
    <div className="subscription-header">
      <h6 className="subscription-title">Details</h6>
      <FontAwesomeIcon
        icon={faEdit}
        className="edit-icon"
        onClick={() => setIsEditMode((prev) => !prev)}
        title="Edit"
      />
    </div>

    {/* Frequency Dropdown */}
    <div className="dropdown-section">
      <label>Frequency:</label>
      <select
        className="form-select"
        value={subscription.frequency}
        disabled={!isEditMode}
        onChange={(e) =>
          handleFieldChange(index, "frequency", e.target.value)
        }
      >
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
      </select>
    </div>



    {/* Weekly Selection */}
    {subscription.frequency === "weekly" && (
      <>
        <div className="weekly-selection">
          <label>Select Days</label>
          <div className="days-container">
  {daysOfWeek.map((day) => (
    <div
      key={day.id}
      className={`day ${
        subscription.selectedDays?.includes(day.id) ? "selected" : ""
      }`}
      onClick={() => {
        if (isEditMode) {
          handleDaySelect(index, day.id);
        }
      }}
    >
      {day.label}
    </div>
  ))}
</div>
          <label>Select slot</label>
          <div className="dropdown">
            <button
              className="form-control"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {
              subscription.daily_time?.length > 0 ? (
                <div className="selected-tags">
                  {subscription.daily_time.map((time, i) => (
                    <span key={i} className="tag">
                      {time}
                      {isEditMode && (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="remove-icon"
                          onClick={() =>
                            handleTimeSelect(index, time, true)
                          }
                        />
                      )}
                    </span>
                  ))}
                </div>
              ) : (
                "Select Time"
              )}
              <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon" />
            </button>
            <ul className="dropdown-menu">
  {generateTimeOptions().map((time, idx) => (
    <li key={idx} className="dropdown-item">
      <div
        className={`time-option ${
          subscription.daily_time?.includes(time) ? "selected" : ""
        }`}
        onClick={() => handleTimeSelect(index, time)}
      >
        {time}
        {subscription.daily_time?.includes(time) && (
          <FontAwesomeIcon icon={faCheck} className="check-icon" />
        )}
      </div>
    </li>
  ))}
</ul>
          </div>
        {/* Start and End Date */}
        <div className="datepickers">
          <div className="datepicker-container">
            <label>Start Date</label>
            <Datepicker
              className="date-input"
              selected={
                subscription.start_date ? new Date(subscription.start_date) : null
              }
              onChange={(date) => {
                if (date) {
                  handleFieldChange(index, "start_date", date.toISOString());
                }
              }}
              placeholderText="Select Start Date"
              disabled={!isEditMode}
            />
          </div>
          <div className="datepicker-container">
            <label>End Date</label>
            <Datepicker
              className="date-input"
              selected={
                subscription.end_date ? new Date(subscription.end_date) : null
              }
              onChange={(date) => {
                if (date) {
                  handleFieldChange(index, "end_date", date.toISOString());
                }
              }}
              placeholderText="Select End Date"
              disabled={!isEditMode}
            />
          </div>
        </div>
        </div>
      </>
    )}
      {/* {subscription.frequency === "weekly" && ( */}
  
    {/* Monthly Selyection */}
    {subscription.frequency === "monthly" && (
  <div className="monthly-selection">
    <label>Select Dates</label>

    {/* Selected Dates as Tags */}
    <div className="selected-dates">
    {subscription.monthly_date?.map((day, i) => (
  <span key={i} className="selected-date-tag">
    {/* Display the day in 2-digit format */}
    {String(day).padStart(2, "0")}
    <FontAwesomeIcon
      icon={faTimes}
      className="remove-icon"
      onClick={() => {
        if (isEditMode) {
          handleMonthlyDateRemove(index, day); // Use day directly since it's already a number
        }
      }}
    />
  </span>
))}

    </div>

    {/* Datepicker for Adding Dates */}
    <div className="datepicker-container">
      <Datepicker
        id="add-monthly-date"
        selected={null} // Reset input after selection
        onChange={(date) => {
          if (isEditMode) {
            handleMonthlyDateAdd(index, date);
          }
        }}
        placeholderText="Select Date"
        className="date-input"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={!isEditMode}
      />
    </div>

    {/* Start and End Date Fields */}
    <div className="datepickers">
  <div className="datepicker-container">
    <label>Start Month</label>
    <Datepicker
      className="date-input"
      selected={
        subscription.start_date && !isNaN(new Date(subscription.start_date))
          ? new Date(subscription.start_date)
          : null
      }
      onChange={(date) => handleDateChange(index, "start_date", date)}
      placeholderText="Select Start Month"
      showMonthYearPicker // Enables month and year picker
      dateFormat="MMM yyyy" // Displays the format as "Month Year"
      disabled={!isEditMode}
    />
  </div>
  <div className="datepicker-container">
    <label>End Month</label>
    <Datepicker
      className="date-input"
      selected={
        subscription.end_date && !isNaN(new Date(subscription.end_date))
          ? new Date(subscription.end_date)
          : null
      }
      onChange={(date) => handleDateChange(index, "end_date", date)}
      placeholderText="Select End Month"
      showMonthYearPicker // Enables month and year picker
      dateFormat="MMM yyyy" // Displays the format as "Month Year"
      disabled={!isEditMode}
    />
  </div>
</div>

  </div>
)}

      {/* Yearly Selection */}
      <div className="yearly-selection">
  <label>Select Dates</label>

  {/* Display Selected Dates */}
  <div className="selected-dates">
    {subscription.yearly_date?.map((date, i) => (
      <span key={i} className="selected-date-tag">
        {date} {/* Display MM-DD format */}
        {isEditMode && (
          <FontAwesomeIcon
            icon={faTimes}
            className="remove-icon"
            onClick={() => handleRemoveYearlyDate(index, date)}
          />
        )}
      </span>
    ))}
  </div>

  {/* Datepicker for Adding Dates */}
  <div className="datepicker-container">
    <Datepicker
      selected={null} // Reset after selection
      onChange={(date) => isEditMode && handleYearlyDateChange(index, date)}
      placeholderText="Select Date"
      dateFormat="MM-dd"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      disabled={!isEditMode}
    />
  </div>

  {/* Start and End Year Selection */}
  <div className="datepickers">
    <div className="datepicker-container">
      <label>Select Start Year</label>
      <Datepicker
        className="date-input"
        selected={subscription.start_date ? new Date(subscription.start_date) : null}
        onChange={(date) => handleDateChange(index, "start_date", date)}
        placeholderText="Select Start Year"
        showYearPicker
        dateFormat="yyyy"
        disabled={!isEditMode}
      />
    </div>
    <div className="datepicker-container">
      <label>Select End Year</label>
      <Datepicker
        className="date-input"
        selected={subscription.end_date ? new Date(subscription.end_date) : null}
        onChange={(date) => handleDateChange(index, "end_date", date)}
        placeholderText="Select End Year"
        showYearPicker
        dateFormat="yyyy"
        disabled={!isEditMode}
      />
    </div>
  </div>
</div>


{subscription.frequency  === "daily" && (
  <>
    {/* Day Selection */}
    <div className="day-selection">
      
     
    </div>

    {/* Time Slot Selection */}
    <div className="time-selection">
      <label>Select Time Slots</label>
      <div className={`dropdown ${!isEditMode ? "disabled-dropdown" : ""}`}>
  <button
    className="form-control"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    disabled={!isEditMode} // Prevent interaction for the button
  >
    {subscription.daily_time?.length > 0 ? (
      <div className="selected-tags">
        {subscription.daily_time.map((time, i) => (
          <span key={i} className="tag">
            {time}
            {isEditMode && ( // Remove icon only in edit mode
              <FontAwesomeIcon
                icon={faTimes}
                className="remove-icon"
                onClick={() => handleTimeSelect(index, time)}
              />
            )}
          </span>
        ))}
      </div>
    ) : (
      "Select Time"
    )}
    <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon" />
  </button>
  <ul className="dropdown-menu">
    {generateTimeOptions().map((time, idx) => (
      <li key={idx} className="dropdown-item">
        <div
          className={`time-option ${
            subscription.daily_time?.includes(time) ? "selected" : ""
          }`}
          onClick={() => isEditMode && handleTimeSelect(index, time)} // Prevent selection when not in edit mode
        >
          {time}
          {subscription.daily_time?.includes(time) && (
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
          )}
        </div>
      </li>
    ))}
  </ul>
</div>

    </div>

    {/* Start and End Date */}
    <div className="datepickers">
      <div className="datepicker-container">
        <label>Start Date</label>
        <Datepicker
          className="date-input"
          selected={
            subscription.start_date ? new Date(subscription.start_date) : null
          }
          onChange={(date) => {
            if (date) {
              handleFieldChange(index, "start_date", date.toISOString());
            }
          }}
          placeholderText="Select Start Date"
          disabled={!isEditMode}
        />
      </div>
      <div className="datepicker-container">
        <label>End Date</label>
        <Datepicker
          className="date-input"
          selected={
            subscription.end_date ? new Date(subscription.end_date) : null
          }
          onChange={(date) => {
            if (date) {
              handleFieldChange(index, "end_date", date.toISOString());
            }
          }}
          placeholderText="Select End Date"
          disabled={!isEditMode}
        />
      </div>
    </div>
  </>
)}

    {/* Save Button */}
    {isEditMode && (
      <div className="save-button-container">
        <button
          className="save-button"
          onClick={() => handleSave(index)}
        >
          Save
        </button>
      </div>
    )}
    <hr />
  </div>
))}

        </div>
       <div className="right-section2">
  {billDetails ? (
    <BillDetailsCard
      discount_val={discount}
      onApplyCouponClick={() => setShow(true)}
      subscriptionData={billDetails}
    />
  ) : (
    <p></p>
  )}
</div>

      </div>
      <OffcanvasComponent
        show={show}
        handleClose={() => setShow(false)}
        onApplyCoupon={handleApplyCoupon}
      />
      <MedicineDetailsModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        medicineData={selectedMedicine}
      />
    
    </div>
  );
};

export default SubscriptionSection;
