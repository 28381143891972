import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTimes } from '@fortawesome/free-solid-svg-icons';
import './profilepage.scss';
import { useSelector } from 'react-redux';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { useNavigate } from 'react-router-dom';
const ProfileForm = () => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const statusOptions = ['Male', 'Female', 'Others'];
  
  // Form data state
  const [formData, setFormData] = useState({
    brand_id: '',
    name: '',
    description: '',
    image_url: '',
    status: '',
  });
  const navigate=useNavigate();
  const userdata = useSelector((state)=>state.usermanage.users);
  // Redux selectors
  const user_id = useSelector((state) => state.adminLogin.user);
  //console.log(user_id, '---user_id');
  const user =userdata.find((user) => user.user_id === user_id);


  // Populate user data into the form
  useEffect(() => {
    
    if (user) {
      //console.log("admin user",user)
      setFormData({
        ...formData,
        name: user.username || '',
        email: user.email || '',
        mobile_number: user.mobile_number || '',
        gender: user.gender || '',
        dob: user.dob || '',
      });
    }
  }, [user]);

  // File input change handler
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setFileUrl(URL.createObjectURL(uploadedFile));
    setFormData((prev) => ({ ...prev, image_url: uploadedFile.name }));
  };

  // Remove file handler
  const handleFileRemove = () => {
    setFile(null);
    setFileUrl('');
    setFormData((prev) => ({ ...prev, image_url: '' }));
  };

  // Form submit handler
  const handleSubmit = (e) => {
    navigate('/admin/business_information')
    
  };

  return (
    <div className="profile-section">
      {/* Breadcrumb Section */}
      <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
     <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
     </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Profile Information</span>
      </div>
      <div className="profile-container"></div>
      <div className="profile-person">
        <h5 className="text-heading1">Profile Information</h5>
        <form onSubmit={handleSubmit} className="form">
          <div className="form-row">
            <div className="form-group">
              <label>Name:</label>
              <InputComponent
                value={formData.name}
                readOnly
                className="readOnlyInput"
                placeholder="Name"
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <InputComponent
                value={formData.email || ''}
                readOnly
                className="readOnlyInput"
                placeholder="Email"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Mobile Number:</label>
              <InputComponent
                type="tel"
                value={formData.mobile_number}
                onChange={(e) =>
                  setFormData({ ...formData, mobile_number: e.target.value })
                }
                placeholder="Enter mobile number"
                className="input"
              />
            </div>

            <div className="form-group">
              <label>Gender:</label>
              <DropdownButton
                options={statusOptions}
                label={formData.gender || 'Select Gender'}
                onSelect={(value) => setFormData({ ...formData, gender: value })}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Date of Birth:</label>
              <InputComponent
                type="date"
                value={formData.dob}
                onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                className="input"
              />
            </div>
            <div className="form-group">
              <label>Profile Picture:</label>
              {fileUrl ? (
                <div className="image-container">
                  <img
                    src={fileUrl}
                    alt="Uploaded"
                    className="uploaded-image"
                  />
                  <FontAwesomeIcon
                    className="close-icon"
                    icon={faTimes}
                    onClick={handleFileRemove}
                  />
                </div>
              ) : (
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleFileChange}
                />
              )}
            </div>
          </div>

          <div className="action-buttons">
            <ButtonComponent text=" Next" type="submit" className="submit-button">
             
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;