import { subscription_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch subscriptions
export const fetchSubscriptions = async (token, userId) => {
  const response = await apiClient.get(subscription_endpoints.GET_SUBSCRIPTIONS, {
    params: { user_id: userId },
    headers: {
      Authorization: token,
    },
  });

  if (!response.data) {
    throw new Error('Failed to fetch subscriptions');
  }
  return response.data;
};

// Add a new subscription
export const addSubscription = async (subscriptionData, token) => {
  const response = await apiClient.post(
    subscription_endpoints.ADD_SUBSCRIPTION,
    subscriptionData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to add subscription');
  }
  return response.data;
};

// Update a subscription
export const updateSubscription = async (subscriptionId, subscriptionData, token) => {
  const response = await apiClient.put(
    `${subscription_endpoints.UPDATE_SUBSCRIPTION}`,
    subscriptionData,
    {
      params:{
        subcription_id:subscriptionId
      },
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to update subscription');
  }
  return response.data;
};

// Delete a subscription
export const deleteSubscription = async (subscriptionId, token) => {
  try {
    //console.log('Deleting subscription with ID:', subscriptionId); // Log the subscription ID
    const response = await apiClient.delete('/auth/subscription', {
      params: { subscription_id: subscriptionId }, // Pass the subscription ID as params
      headers: { Authorization: token }, // Include the token in the headers
    });
    //console.log(response.data, 'Response from deleteSubscription'); // Log the successful response data
    return response.data; // Return the response data
  } catch (error) {
    //console.error('Error deleting subscription:', error.response?.data || error.message); // Log the error details
    throw error; // Re-throw the error for further handling
  }
 
};


// Bulk create subscriptions
export const bulkAddSubscriptions = async (subscriptionsData, token) => {
    const response = await apiClient.post(
      subscription_endpoints.BULK_ADD_SUBSCRIPTIONS,
      subscriptionsData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  
    if (!response.data) {
      throw new Error('Failed to add bulk subscriptions');
    }
    return response.data;
  };
  
  // Bulk get subscriptions
  export const bulkFetchSubscriptions = async (token) => {
    const response = await apiClient.get(
      '/auth/bulk-subscription',
      {
        
        headers: {
          Authorization: token,
        },
      }
    );
  
    if (!response.data) {
      throw new Error('Failed to fetch bulk subscriptions');
    }
    return response.data;
  };

  // Bulk update subscriptions
export const bulkUpdateSubscriptions = async (subscriptionsData, token) => {
  const response = await apiClient.put(
    subscription_endpoints.BULK_UPDATE_SUBSCRIPTIONS,
    subscriptionsData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to update bulk subscriptions');
  }
  return response.data;
};

export const bulkDeleteSubscriptions = async (subscriptionIds, token) => {
  try {
    const response = await apiClient.delete(
      subscription_endpoints.BULK_DELETE_SUBSCRIPTIONS,
      {
        data: subscriptionIds,
        headers: { Authorization: token },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

 
  