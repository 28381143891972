// src/api/endpoints.js

// Existing endpoints for auth
const auth_endpoints = {
  REQUEST_OTP: '/auth/request-otp',
  VERIFY_OTP: '/auth/register-with-mobile',
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  GENERATE_TOKEN:'/auth/authorize'
};

// Endpoints for product CRUD operations
const product_endpoints = {
  GET_PRODUCTS: '/auth/product-basic-info',    // For product CRUD
  CREATE_PRODUCT: '/auth/product-basic-info',
  UPDATE_PRODUCT: '/auth/product-basic-info',
  DELETE_PRODUCT: '/auth/product-basic-info',
};

const category_endpoints = {
  GET_BULK_CATEGORIES:'/auth/bulk-categories',

  GET_CATEGORY:'/auth/category',
}

const brand_endpoints = {
  GET_BULK_BRANDS:'/auth/bulk-brands',
  GET_BRANDS:'/auth/brand',
   GET_BULK_ALLOCATED_OFFER :'/auth/bulk-coupon-allocations',
  GET_ALLOCATED_OFFER:'/auth/coupon-allocation'
  
}

const coupon_endpoints = {
  GET_BULK_COUPONS:'/auth/bulk-coupons',
  GET_COUPON:'/auth/coupon',
  GET_BULK_ALLOCATED_COUPON :'/auth/bulk-coupon-allocations',
  GET_ALLOCATED_COUPON:'/auth/coupon-allocation'  
}

const offer_endpoints = {
  GET_OFFER:'/auth/offer', 
  GET_BULK_OFFERS:'/auth/bulk-offers', 
  GET_BULK_ALLOCATED_OFFER :'/auth/bulk-offer-allocations',
  GET_ALLOCATED_OFFER:'/auth/offer-allocation'  
}
const basic_product_endpoints ={
  GET_BASIC_PRODUCT:'/auth/product-basic-info',
  GET_BASIC_BULK_PRODUCT:'/auth/bulk-product-basic-info',
}

const custom_product_endpoints ={
  GET_CUSTOM_PRODUCT:'/auth/product-custom-info',
  GET_CUSTOM_BULK_PRODUCT:'/auth/bulk-product-custom-info',
}

const user_mananagement_endpoint={

  GET_BULK_PERSONAL_INFO:'/auth/bulk-personal-info',
  GET_PERSONAL_INFO:'/auth/personal-info',
}

const purchase_request_endpoint={
  GET_BULK_PURCHASE_REQUEST:'/auth/bulk-purchase',
  GET_PURCHASE_REQUEST:'/auth/purchase',
}

const supplier_endpoints={
  GET_BULK_SUPPLIER:'/auth/bulk-suppliers',
  GET_SUPPLIER:'/auth/supplier',
}

const register_endpoints = {
  REGISTER: '/auth/register',  // User registration endpoint
};
const business_endpoints={
  GET_BUSINESS_INFO:'/auth/business-info'
}

const login_endpoints = {
  ADMIN_LOGIN: '/auth/login',  // Ensure this endpoint is correct
};


const forgot_password={
  FORGOT_PASSWORD:'/auth/forgot-password',
}


const cart_endpoints ={
  GET_CART_ITEMS:'auth/cart-items',
  GET_BULK_CART_ITEMS:'/auth/bulk-carts-items',
  GET_CART:'/auth/cart',
  GET_BULK_CART:'/auth/bulk-carts'
}

const document_endpoints={
  UPLOAD_DOCUMENT:'/auth/upload_document',
  GENERATE_PRESIGNED_URL:'/auth/generate_presigned_url'
}


const banner_endpoints={
 GET_BANNER:'/auth/banner',
  GET_BULK_BANNERS:'/auth/bulk-banners'
}
const address_endpoints={
  GET_ADDRESS:'/auth/address',
  GET_BULK_ADDRESS:'/auth/bulk-address'
}
const location_endpoints={
  GET_LOCATION_CONFIG:'/auth/location-config',
  GET_BULK_LOCATIONS_CONFIG:'/auth/bulk-location-config'
}
const export_endpoints={
  GET_EXPORT_ENDPOINTS:'/auth/export-orders',
}

const inventory_threshold_endpoints = {
  CREATE_INVENTORY_THRESHOLD: '/auth/inventory-threshold',
  GET_INVENTORY_THRESHOLD: '/auth/inventory-threshold',
  UPDATE_INVENTORY_THRESHOLD: '/auth/inventory-threshold',
  DELETE_INVENTORY_THRESHOLD: '/auth/inventory-threshold',
  CREATE_BULK_INVENTORY_THRESHOLD: '/auth/bulk-inventory-threshold',
  GET_BULK_INVENTORY_THRESHOLD: '/auth/bulk-inventory-threshold',
  UPDATE_BULK_INVENTORY_THRESHOLD: '/auth/bulk-inventory-threshold',
  DELETE_BULK_INVENTORY_THRESHOLD: '/auth/bulk-inventory-threshold',
};

const deliveryConfigEndpoints = {
  CREATE_CONFIG: '/auth/delivery-config', // POST
  GET_ALL_CONFIGS: '/auth/delivery-config', // GET (fetch all configs)
  UPDATE_CONFIG: '/auth/delivery-config', // PUT
  DELETE_CONFIG: '/auth/delivery-config', // DELETE

  CREATE_BULK_CONFIGS: '/auth/bulk-delivery-config', // POST
  GET_BULK_CONFIGS: '/auth/bulk-delivery-config', // GET (fetch all bulk configs)
  UPDATE_BULK_CONFIGS: '/auth/bulk-delivery-config', // PUT
  DELETE_BULK_CONFIGS: '/auth/bulk-delivery-config', // DELETE
};




const subscription_endpoints = {
  GET_SUBSCRIPTIONS: '/auth/subscription',
  ADD_SUBSCRIPTION: '/auth/subscription',
  UPDATE_SUBSCRIPTION: '/auth/subscription',
  DELETE_SUBSCRIPTION: '/auth/subscription',
  BULK_ADD_SUBSCRIPTIONS: '/auth/bulk-subscription', // Endpoint for bulk creation of subscriptions
  BULK_GET_SUBSCRIPTIONS: '/auth/bulk-subscription', 
  BULK_UPDATE_SUBSCRIPTIONS: '/auth/bulk-subscription',
  BULK_DELETE_SUBSCRIPTIONS: '/auth/bulk-subscription', 
};

// Endpoints for expiry alert
const expiry_alert_endpoints = {
  CREATE_EXPIRY_ALERT: '/auth/expiry-alert',
  GET_EXPIRY_ALERT: '/auth/expiry-alert',
  UPDATE_EXPIRY_ALERT: '/auth/expiry-alert',
  DELETE_EXPIRY_ALERT: '/auth/expiry-alert',
  CREATE_BULK_EXPIRY_ALERT: '/auth/bulk-expiry-alert',
  GET_BULK_EXPIRY_ALERT: '/auth/bulk-expiry-alert',
  UPDATE_BULK_EXPIRY_ALERT: '/auth/bulk-expiry-alert',
  DELETE_BULK_EXPIRY_ALERT: '/auth/bulk-expiry-alert',
};

const notification_endpoints = {
  CREATE_NOTIFICATION: '/auth/notifications',
  GET_NOTIFICATIONS: '/auth/notifications',
  UPDATE_NOTIFICATION: '/auth/notifications',
  DELETE_NOTIFICATION: '/auth/notifications',
  CREATE_BULK_NOTIFICATIONS: '/auth/bulk-notifications',
  GET_BULK_NOTIFICATIONS: '/auth/bulk-notifications',
  UPDATE_BULK_NOTIFICATIONS: '/auth/bulk-notifications',
  DELETE_BULK_NOTIFICATIONS: '/auth/bulk-notifications',
};

const process_deliveries={
  CREATE_PROCESS_DELIVERIES: '/auth/process-deliveries',
}

export { auth_endpoints, 
  product_endpoints,
  category_endpoints, 
  brand_endpoints,
  coupon_endpoints, 
  offer_endpoints, 
  basic_product_endpoints,
  custom_product_endpoints,
  user_mananagement_endpoint,
  purchase_request_endpoint,
  supplier_endpoints,
  register_endpoints,
  login_endpoints,
  forgot_password,
  cart_endpoints,
  document_endpoints,
  banner_endpoints,
  location_endpoints,
  address_endpoints,
  business_endpoints,
  export_endpoints,
  subscription_endpoints,
  inventory_threshold_endpoints,
  expiry_alert_endpoints,
  deliveryConfigEndpoints,
  notification_endpoints,
  process_deliveries
 };



