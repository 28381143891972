import React, { useState,useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import './createadminPage.scss'; // Assuming your SCSS file is already set up for this component
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {fetchAdmin,createAdmin, updateAdmin} from '../../redux/actions/adminActions';
const AddAdmin = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobileNumber: '',
  });
  const adminlist = useSelector((state) => state.admin.adminList);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const admin_Id = location.state || null;

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  useEffect(() => {
    if (admin_Id) {
      const adminData = adminlist.find(data => data.id === admin_Id);
      if (adminData) {
        setFormData(adminData);
      }
    }
  }, [admin_Id, adminlist]);



  // Handle file change for the file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileType = file?.name.split('.').pop().toLowerCase(); // Get file extension

    if (['csv', 'xlsx', 'xls'].includes(fileType)) {
      setSelectedFile(file);
      setErrorMessage(''); // Clear error
    } else {
      setSelectedFile(null);
      setErrorMessage('Only .csv and Excel files (.xlsx, .xls) are supported.');
    }
  };


  const handleSubmit = () => {
    if (admin_Id) {
      dispatch(updateAdmin(admin_Id, formData));
    } else {
      dispatch(createAdmin(formData));
    }
    navigate('/admin/user-management');
  };

  

  return (
    <div className="admin-user-section">
      <form className="admin-user-form" >
        {/* Username Field */}
        <div className="form-row">
          <div className="form-group">
            <label>Username</label>
            <InputComponent
              type="text"
              name="username"
              placeholder="Enter Username"
              value={formData.username}
              onChange={handleInputChange}
            />
          </div>

          {/* Email Field */}
          <div className="form-group">
            <label>E-mail</label>
            <InputComponent
              type="email"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Password Fields */}
        <div className="form-row">
          <div className="form-group">
            <label>Password</label>
            <InputComponent
              type="password"
              name="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Confirm Password</label>
            <InputComponent
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Mobile Number Field */}
        <div className="form-row">
          <div className="form-group">
            <label>Mobile Number</label>
            <InputComponent
              type="tel"
              name="mobileNumber"
              placeholder="Enter Mobile Number"
              value={formData.mobileNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Upload File</label>
            <InputComponent
              type="file"
              name="fileUpload"
              className="form-control"
              onChange={handleFileChange}
            />
            {selectedFile && <span>{selectedFile.name}</span>}
          </div>
        </div>

        {/* Error Message */}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        {/* Save Button at the bottom-right corner */}
        <div className="action-buttons ">
          <ButtonComponent
            text="Save"
            variant="primary"
            type="submit"
            className="submit-button"

            onClick={()=>handleSubmit()}
          />
        </div>
      </form>
    </div>
  );
};

export default AddAdmin;
