import React, { useState, useEffect } from "react";
import "./subscription-page.scss";
import NoProduct from "../../assets/images/Noproduct.webp"; // Ensure this path is correct
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SubscribedProductOffcanvas from "../../components/common/SubscribedProductOffcanvas/SubscribedProductOffcanvas";
import { bulkGetSubscriptions } from "../../redux/actions/subscriptionAction";
import { startOfDecade } from "date-fns";

const SubscriptionPage = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Access state using useSelector
  const auth = useSelector((state) => state.auth);
  const subscriptionList = useSelector((state) => state.subscription.subscriptionList);
  const basicProducts = useSelector((state) => state.products.basicProducts);
  const productUrls = useSelector((state)=>state.image.urls.products);


  // Fetch subscriptions on component mount
  useEffect(() => {
    if (auth?.token) {
      dispatch(bulkGetSubscriptions(auth.token));
    }
  }, [auth.token, dispatch]);

  // Filter subscriptions based on selected status and user ID
  const filteredSubscriptions = subscriptionList
    .filter((sub) => sub.user_id === auth.user) // Match user ID
    .filter((sub) => selectedStatus === "All" || sub.status === selectedStatus);

  // Helper to fetch product name using product ID
  const getProductName = (productId) => {
    const product = basicProducts.find((p) => p.product_id === productId);
    return product ? product.product_name : "Unknown Product";
  };

  // Handle subscription card click
  const handleSubscriptionClick = (subscription) => {
    //console.log(subscription)
    navigate("/subscription-details", { state:subscription.subscription_id });
    //console.log(subscription)
    navigate("/subscription-details", { state:subscription.subscription_id });
  };

  // Handle status changeP

  // Handle status changeP
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };


  // Get status color for cards
  const getStatusColor = (status) => {
    const colors = {
      Active: "var(--green)", // Replace with your actual CSS variable
      Expired: "var(--expired)", // Replace with your actual CSS variable
      Paused: "var(--paused)", // Replace with your actual CSS variable
      Cancelled: "var(--black)", // Replace with your actual CSS variable
    };
    return colors[status];
  };

  return (
    <div className="Subscription subscription-page">
      <div className="breadcrumb-container">
        <Link to="/" className="breadcrumb-text">
          Home
        </Link>
        <span className="breadcrumb-separator"> / </span>
        <span className="breadcrumb-text">Subscriptions</span>
      </div>

      <div className="Subscription main-content">
        <div className="Subscription left-section">
          <h4 className="Subscription filter-text">Filters</h4>
          <div className="Subscription filters-section">
            <div className="Subscription filter-container">
              <div className="Subscription filter-title">
                <h6>Status</h6>
              </div>
              <hr />
              <div className="Subscription filter-options">
                {["All", "active", "expired", "paused", "cancelled","cancel_request"].map((status) => (
                  <div key={status} className="Subscription filter-option">
                    <label>
                      <input
                        type="radio"
                        name="status"
                        checked={selectedStatus === status}
                        onChange={() => handleStatusChange(status)}
                      />
                      <span>{status}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="Subscription right-section">
          <div className="Subscription header-row">
            <h4>Your Subscriptions</h4>
            <button className="Subscription filter-button" onClick={() => setShowOffcanvas(true)}>
              <i className="fas fa-filter"></i> Filter
            </button>
            {/* SubscribedProductOffcanvas */}
            <SubscribedProductOffcanvas
              show={showOffcanvas}
              handleClose={() => setShowOffcanvas(false)}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
            />
          </div>
          {filteredSubscriptions.map((subscription) => (
            <div
              key={subscription.subscription_id}
              className="Subscription subscription-card"
              onClick={() => handleSubscriptionClick(subscription)}
            >
              <div className="Subscription card-header">
                <div className="Subscription product-info">
                  <img
                    src={productUrls[subscription.product_id]?productUrls[subscription.product_id]:NoProduct}
                    alt={getProductName(subscription.product_id)}
                    className="Subscription product-image"
                  />
                  <h4 className="Subscription product-name">
                    {getProductName(subscription.product_id)}
                  </h4>
                </div>
                <span
                  className="Subscription status"
                  style={{
                    backgroundColor: getStatusColor(subscription.status),
                  }}
                >
                  {subscription.status.toUpperCase()}
                </span>
              </div>
              <div className="Subscription card-body">
                <p>
                  <span className="Subscription label">Next Delivery:</span>{" "}
                  <span className="Subscription value">{subscription.next_delivery_date}</span>{" "}
                  {" | "}
                  <span className="Subscription label">Quantity:</span>{" "}
                  <span className="Subscription value">{subscription.quantity}</span>{" "}
                  {" | "}
                  <span className="Subscription label">Frequency:</span>{" "}
                  <span className="Subscription value">{subscription.frequency}</span>
                </p>
                <div className="Subscription time-dates-row">
                  <div className="Subscription time-slots">
                    <span className="Subscription label">Time Slots:</span>{" "}
                    <span className="Subscription value">
                      {subscription.daily_time?.join(", ") || "N/A"} {" | "}
                    </span>
                    <span className="Subscription label">Expires On:</span>{" "}
                    <span className="Subscription value">
                      {subscription.end_date || "N/A"}
                    </span>{" "}
                    {" | "}
                  </div>
                </div>
                <p>
                  <span className="Subscription label">Start Date:</span>{" "}
                  <span className="Subscription value">{subscription.start_date || "N/A"}</span>{" "}
                  {" | "}
                  <span className="Subscription label">End Date:</span>{" "}
                  <span className="Subscription value">{subscription.end_date || "N/A"}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
