import React, { useState, useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelectDropdown from "../../components/common/multi-select-dropdown/multi-select-dropdown";
import InputComponent from "../../components/common/InputComponent/InputComponent";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import { addNotification, modifyNotification } from "../../redux/actions/NotificationCreationAction";
import "./add-notification-creation.scss";

const NotificationCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    user_group: "",
    target_area: [],
    category: "",
    content: "",
    status: "",
  });

  const [entityNames, setEntityNames] = useState([]);
  const [isModified, setIsModified] = useState(false);

  const notificationId = location.state || null;

  const token = useSelector((state) => state.auth.token);
  const productList = useSelector((state) => state.products.basicProducts || []);
  const locations = useSelector((state) => state.location.locations || []);
  const notificationList = useSelector((state) => state.notifications.notifications || []);

  const categoryOptions = ["warning", "information", "action"];
  const statusOptions = ["delivered", "failed"];
  const userGroupOptions = ["Target area", "Product"];

  const productOptions = useMemo(() =>
    productList.map((product) => ({
      label: product.product_name,
      id: product.product_id,
    })),
    [productList]
  );
  
  const locationOptions = useMemo(() =>
    locations.map((location) => ({
      label: location.location_name,
      id: location.location_id,
    })),
    [locations]
  );
  

  // Initialize form data only once when notificationId changes
// Initialize form data only once when notificationId changes
useEffect(() => {
  if (!notificationId || notificationList.length === 0) return;

  const notificationData = notificationList.find(
    (notification) => notification.notification_id === notificationId
  );

  if (notificationData) {
    const selectedEntities =
      notificationData.user_group.toLowerCase() === "product"
        ? productOptions.filter((product) => notificationData.product_id.includes(product.id))
        : locationOptions.filter((location) => notificationData.target_area.includes(location.id));

    setFormData({
      user_group: notificationData.user_group === "location" ? "Target Area" : notificationData.user_group,
      target_area: selectedEntities.map((entity) => entity.id),
      category: notificationData.category || "",
      content: notificationData.content || "",
      status: notificationData.status || "",
    });

    setEntityNames(selectedEntities.map((entity) => entity.label));
  }
}, [notificationId, notificationList, productOptions, locationOptions]);

  

const handleInputChange = (field, value) => {
  setFormData((prevData) => {
    const updatedData = { ...prevData, [field]: value };

    if (field === "user_group") {
      updatedData.user_group = value === "location" ? "Target Area" : value;
      updatedData.target_area = []; // Reset target_area when user_group changes
    }

    return updatedData;
  });

  setEntityNames([]); // Ensure the UI updates correctly
  setIsModified(true);
};


  const getEntityOptions = () => {
    // Ensure `formData.user_group` is handled correctly
    return formData.user_group.toLowerCase() === "product"
      ? productOptions
      : locationOptions;
  };
  

  const handleEntitySelect = (selectedIds) => {
    let selectedEntities = [];
  
    if (formData.user_group.toLowerCase() === "product") {
      selectedEntities = productOptions.filter((product) => selectedIds.includes(product.id));
    } else {
      selectedEntities = locationOptions.filter((location) => selectedIds.includes(location.id));
    }
  
    setFormData((prevData) => ({
      ...prevData,
      target_area: selectedIds, // Ensure correct update
    }));
  
    setEntityNames(selectedEntities.map((entity) => entity.label));
    setIsModified(true);
  };
  
  
  
  
  
const validateForm = () => {
  if (!formData.user_group || !formData.category || !formData.content || !formData.status) {
      toast.error("All fields are required.");
      return false;
  }

  if (
      (formData.user_group.toLowerCase() === "product" && formData.target_area.length === 0) ||
      (formData.user_group.toLowerCase() === "target area" && formData.target_area.length === 0)
  ) {
      toast.error("Target Area or Product ID is required.");
      return false;
  }

  return true;
};


const handleSubmit = async (e) => {
  e.preventDefault();

  if (!validateForm()) return;

  // Correctly map user_group values before sending
  const userGroupMapped = formData.user_group === "Target Area" ? "location" : "product";

  const notificationData = {
    notification_id: notificationId || null, 
    user_group: userGroupMapped,
    category: formData.category,
    content: formData.content,
    status: formData.status,
    ...(userGroupMapped === "product"
      ? { product_id: formData.target_area.length > 0 ? [...formData.target_area] : [] }
      : { target_area: formData.target_area.length > 0 ? [...formData.target_area] : [] }),
  };

  console.log(notificationData, "--- Final Notification Data ---");

  try {
    if (notificationId) {
      await dispatch(modifyNotification(notificationId, notificationData, token));
      toast.success("Notification updated successfully.");
    } else {
      await dispatch(addNotification(notificationData, token));
      toast.success("Notification created successfully.");
    }

    setIsModified(false);
    navigate("/admin/subscription-management");
  } catch (error) {
    console.error("Failed to save notification:", error);
    toast.error("Failed to save notification.");
  }
};



  
  

  return (
    <div className="admin-user-section">
      <div className="breadcrumb-container">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        <span className="breadcrumb-separator">{">"}</span>
        <Link to="/admin/subscription-management" className="breadcrumb-text">
          Notification List
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <span className="breadcrumb-text">Add Notification</span>
      </div>
      <div className="notification-creation-container"></div>
      <div className="notification-page">
        <h5 className="text-heading1">Notifications</h5>
        <div className="admin-user-form">
          <div className="form-row">
            <div className="form-group">
              <label>User Group</label>
              <DropdownButton
  label={formData.user_group === "location" ? "Target Area" : formData.user_group || "Select Group"}
  options={userGroupOptions}
  onSelect={(selectedOption) => handleInputChange("user_group", selectedOption)}
/>

            </div>
            <div className="form-group">
              <label>Group</label>
              <MultiSelectDropdown
                    options={getEntityOptions()}
                    placeholder="Search for product"
                    value={getEntityOptions().filter((option) =>
                      Array.isArray(formData.target_area) && formData.target_area.includes(option.id)
                    )}
                    onSelect={(selectedIds) => handleEntitySelect(selectedIds)}
                    disabled={formData.user_group === ""}
                  />

            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Category</label>
              <DropdownButton
                label={formData.category || "Select Category"}
                options={categoryOptions}
                onSelect={(selectedOption) => handleInputChange("category", selectedOption)}
              />
            </div>
            <div className="form-group">
              <label>Content</label>
              <InputComponent
                placeholder="Enter content"
                value={formData.content || ""}
                onChange={(e) => handleInputChange("content", e.target.value)}
              />
            </div>
          </div>
          <div className="form-row1">
            <div className="form-group">
              <label>Status</label>
              <DropdownButton
                label={formData.status || "Select Status"}
                options={statusOptions}
                onSelect={(selectedOption) => handleInputChange("status", selectedOption)}
              />
            </div>
          </div>
          <div className="action-buttons">
          <ButtonComponent
              text={notificationId ? "Update Notification" : "Create Notification"}
              onClick={handleSubmit}
              disabled={!isModified}
              className={"submit-button"}
          />

          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCreation;
