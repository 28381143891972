import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'; 
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import "./categorytable.scss";
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkUpdateCategories, bulkCreateCategories } from "../../redux/actions/categoryAction";
import toast from "react-hot-toast";
const CategoryTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(state => state.category.categoryList.categories || []);

  const token = useSelector(state => state.adminLogin.token);

  const initialMaterials = location.state || [];
  const [materials, setMaterials] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]); // Track errors for each name
  const [isSaving, setIsSaving] = useState(false); // Track save button state

  const statusOptions = ["active", "inactive"];

  useEffect(() => {
    const processMaterials = () => {
      if (!initialMaterials || initialMaterials.length === 0) {
        setMaterials([{
          name: '',
          description: '',
          parent_category_ID: '',
          image_url: '',
          status: '',
          sort_order: '',
        }]);
      } else {
        if (typeof initialMaterials[0] === 'object') {
          setMaterials(initialMaterials);
        } else if (typeof initialMaterials[0] === 'string' || typeof initialMaterials[0] === 'number') {
          const fetchedMaterials = initialMaterials.map(id => {
            const category = categories.find(category => category.category_id === id);
            return category ? { ...category, category_id: id } : { category_id: id, name: '', description: '', parent_category_ID: '', image_url: '', status: '' };
          });
          setMaterials(fetchedMaterials);
        }
      }
    };

    processMaterials();
  }, [initialMaterials, categories]);

  const handleInputChange = (index, field, value) => {
    const updatedMaterials = [...materials];
    updatedMaterials[index][field] = value;
    setMaterials(updatedMaterials);

    // Reset error if name is modified
    if (field === 'name') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false; // Reset duplicate error for this row
      setDuplicateErrors(updatedErrors);
    }
  };

  const handleAddRow = () => {
    setMaterials(prevMaterials => [
      ...prevMaterials,
      { category_id: '', name: '', description: '', parent_category_ID: '', image_url: '', status: '', sort_order: '' }
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]); // Add a new error state
  };

  const handleDelete = (index) => {
    const newMaterials = [...materials];
    newMaterials.splice(index, 1);
    setMaterials(newMaterials);

    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1); // Remove the corresponding error state
    setDuplicateErrors(newErrors);
  };

  // Check for duplicate names in the existing categories
  const checkForDuplicateNames = () => {
    const existingNames = categories.map(category => category.name.trim().toLowerCase());
    const updatedErrors = materials.map(material => {
      // Skip validation if category_id exists (indicating it's an existing category)
      return !material.category_id && existingNames.includes(material.name.trim().toLowerCase()) && material.name.trim() !== '';
    });
    setDuplicateErrors(updatedErrors);
    return updatedErrors.some(error => error); // Returns true if any duplicate is found
  };
  
  // In handleSaveClick, the rest remains the same
  const handleSaveClick = async () => {

    const hasEmptyFields = materials.some(material => 
      !material.name.trim() || !material.status
    );
  
    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Name and Status).");
      return; // Prevent saving if there are empty fields
    }
    
    if (checkForDuplicateNames()) {
      return; // Prevent saving if duplicate names are found
    }
  
    setIsSaving(true); // Set to 'Saving...' state
    let responseStatus = false;
  
    if (typeof initialMaterials[0] === 'object') {
      responseStatus = await dispatch(bulkCreateCategories(materials, token));
    } else {
      responseStatus = await dispatch(bulkUpdateCategories(materials, token));
    }
  
    setIsSaving(false); // Reset to normal state after saving
  
    if (responseStatus) {
      navigate('/admin'); // Navigate if save was successful
    } else {
      // Handle save error, e.g., show an error message
      //console.error("Failed to save categories");
    }
  };
  
  return (
    <div className="bulk-category-main-page">
    

    <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Category List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Bulk Category</span>
      </div>
       <div className="bulk-category-container"></div>
       <div className="bulk-category">
       <div className="category-table">
       <div className="header-row">
       <h5 className="text-heading1">Bulk Category</h5>
      <div className="add-new-button">
        {/* Conditionally hide the Add New button if initialMaterials is string or number */}
        {typeof initialMaterials[0] === 'object' && (
          <ButtonComponent 
            text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
            className="add-button" 
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"}  // Conditionally show "Saving..." 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} // Disable button while saving
        />
    </div>
      </div>
   </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Short Code</th>
              <th>Status</th>
              <th className="small-column">Delete</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index}>
                <td>
                  <InputComponent
                    className="table-input"
                    value={material.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                  />
                  {/* Display error message if the name is a duplicate */}
                  {duplicateErrors[index] && (
                    <div className="error-message">Already exists</div>
                  )}
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={material.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={material.sort_order}
                    onChange={(e) => handleInputChange(index, 'sort_order', e.target.value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    options={statusOptions}
                    label={material.status || 'Select Status'}
                    onSelect={(value) => handleInputChange(index, 'status', value)}
                  />
                </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};

export default CategoryTable;
