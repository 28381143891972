import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./subscription-offcanvas.scss";
import QuantityInput from "../QuantityInput/QuantityInput";
import ButtonComponent from "../buttons/ButtonComponent";
import Datepicker from "../datepicker/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch ,useSelector} from 'react-redux';
import {  Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Offcanvas } from "react-bootstrap";
import Noproduct from '../../../assets/images/Noproduct.webp'; 
import { useNavigate } from "react-router-dom";
import ImageProductCarousel from "../ImageProductCarousel/ImageProductCarousel";
import { saveSubscriptionCookie,getSubscriptionCookie } from "../../../utils/schema/cookieSubscription";
const SubscriptionOffcanvas = ({ show, handleClose, productDetails,}) => {
  const productId=productDetails.product_id
 // Ensure productId is declared first
 
  const [selectedFrequency, setSelectedFrequency] = useState(""); // Frequency type (daily, weekly, etc.)
  const [timeOptions, setTimeOptions] = useState([]); // Available time options
  const [selectedTimings, setSelectedTimings] = useState([]); // Selected timings
  const [startDate, setStartDate] = useState(null); // Start date for daily/weekly
  const [endDate, setEndDate] = useState(null); // End date for weekly
  const [selectedDate, setSelectedDate] = useState(null); 
  const [selectedMonthDate, setSelectedMonthDate] = useState(null); // Yearly month & date
  const [errorMessage, setErrorMessage] = useState(""); // Error message for time selection
  const [selectedDays, setSelectedDays] = useState([]); // Selected days for weekly selection
  const[selectedAnuallyDate,setSelectedAnuallyDate]=useState(null);
  const dispatch = useDispatch();
  const navigation=useNavigate();
  const [showSubscriptionPrice,setshowSubscriptionPrice] = useState(0);
  // Redux state selectors
  const authData = useSelector((state) => state.auth.user) || ""; // Get auth data
  const token = useSelector((state) => state.auth.token); // Retrieve token from Redux state
  const cartItems = useSelector((state) => state.cart.cartItems) || []; // Get cart items
  const product_details = useSelector((state) => state.products.basicProducts) || []; // Get product details
  const [subscriptionQuantity, setSubscriptionQuantity] = useState(1);
  // Derived data
  
  const cartItem = cartItems.find((item) => item.product_id === productId); // Find specific cart item
  const [selectedAnuallyDates, setSelectedAnuallyDates] = useState([]); // For yearly selection
const [selectedMonthlyDates, setSelectedMonthlyDates] = useState([]); // For monthly selection
  const single_product_details = product_details.find((item) => item.product_id === productId); // Find specific product details
  ////console.log(single_product_details,'=====singleeeeeeeeeeee')
  const showQuantityInput = Boolean(cartItem);
  const productUrls = useSelector((state) => state.image.urls.products) || [];
  const imageUrl = productUrls[productId];
  const [startYear, setStartYear] = useState(null); // Start Year
const [endYear, setEndYear] = useState(null); // End Year
  
const handleYearlyDateChange = (date) => {
  if (date) {
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`; // MM-DD format

    setSelectedAnuallyDates((prev) =>
      prev.includes(formattedDate) ? prev : [...prev, formattedDate]
    );
  }
};

  const handleRemoveYearlyDate = (date) => {
    setSelectedAnuallyDates((prev) =>
      prev.filter((d) => d !== date) // Remove the selected date
    );
  };
  const handleStartYearChange = (date) => {
    //console.log(date,'----dateeeee')
    try{
      if (date instanceof Date && !isNaN(date)) {
        setStartDate(date.toISOString()); // ✅ Store as full ISO string
      } else {
        //console.error("Invalid start date:", date);
      }
    }
    catch{
      //console.error("Invalid start date:", date);
    }
   
  };
  
  // const handleEndYearChange = (date) => {
  //   if (date instanceof Date && !isNaN(date)) {
  //     setEndDate(date.toISOString()); // ✅ Store as full ISO string
  //   } else {
  //     //console.error("Invalid end date:", date);
  //   }
  // };
  
  const handleEndYearChange = (date) => {
    if (date) {
      setEndDate(date.toISOString()); // Store full date in ISO format
    }
  };
  
  
  const frequencies = {
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
  };

  const daysOfWeek = [
    { id: "Sunday", label: "S" }, // Sunday
    { id: "Monday", label: "M" }, // Monday
    { id: "Tuesday", label: "T" }, // Tuesday
    
    { id: "Wednesday", label: "W" }, // Wednesday
    { id: "Thursday", label: "T" }, // Wednesday
    { id: "Friday", label: "F" }, // Thursday
    { id: "Saturday", label: "S" }, // Friday
  
  ]; // Days for weekly selection

  // Dynamically generate time options (daily/weekly)
  useEffect(() => {
    if (["daily", "weekly"].includes(selectedFrequency)) {
      const times = [];
      const time = new Date();
      time.setHours(0, 0, 0, 0);

      for (let i = 0; i < 48; i++) {
        const formattedTime = time.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false, // Use 24-hour format
        });
        times.push(formattedTime);
        time.setMinutes(time.getMinutes() + 30);
      }
      setTimeOptions(times);
    } else {
      setTimeOptions([]);
    }
  }, [selectedFrequency]);

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value);
    setSelectedTimings([]);
    setSelectedDays([]);
    setStartDate(null);
    setEndDate(null);
    setSelectedMonthDate(null);
    setErrorMessage("");
    if (e.target.value ==="weekly"){
      setshowSubscriptionPrice(single_product_details.weekly_subscription_price)
    }
    else if(e.target.value ==="monthly"){
      setshowSubscriptionPrice(single_product_details.monthly_subscription_price)
    }
    else if(e.target.value==="yearly"){
      setshowSubscriptionPrice(single_product_details.yearly_subscription_price)
    }
    else if(e.target.value === "daily"){
      setshowSubscriptionPrice(single_product_details.daily_subscription_price)
    }
  };
 const handleRemove=()=>{
  setSubscriptionQuantity(0);
 }
  const handleAddToCart = () => {
    setSubscriptionQuantity(1)
  };
  ////console.log("Product ID:", productId);
  const handleIncreaseQuantity = () => {
    const quantity = subscriptionQuantity+1
   setSubscriptionQuantity(quantity);
  
  };
const onremove =()=>{
  setSubscriptionQuantity(0);
}
  const handleDecreaseQuantity = () => {
   if (subscriptionQuantity >0){
    const quantity = subscriptionQuantity-1
    setSubscriptionQuantity(quantity)
   }
  
   
  };
  const handleAnuallyDateChange = (date) => {
    setSelectedAnuallyDates((prev) =>
      prev.includes(date)
        ? prev.filter((d) => d !== date) // Remove if already selected
        : [...prev, date] // Add if not already selected
    );
  };
  
  const handleMonthlyDateChange = (date) => {
    const selectedDay = new Date(date).getDate(); // Extract the day
   ////console.log("month date",date)
    // Avoid adding duplicate days
    if (!selectedMonthlyDates.includes(selectedDay)) {
      setSelectedMonthlyDates((prev) => [...prev, selectedDay]); // Add the day only if it's not already present
    }
    ////console.log("month date",selectedMonthlyDates)
  };
  

  const handleTimeSelect = (time) => {
    if (selectedTimings.length >= 2 && !selectedTimings.includes(time)) {
      setErrorMessage("You can only select up to two time slots.");
    } else {
      setSelectedTimings((prev) =>
        prev.includes(time) ? prev.filter((t) => t !== time) : [...prev, time]
      );
      setErrorMessage(""); // Clear the error message
    }
  };

  const handleSaveSubscription = () => {
    ////console.log(startDate,endDate,'------datessssss')
    if (!selectedFrequency) {
      toast.error("Please select a frequency.");
      return;
    }
  
    if (!subscriptionQuantity || subscriptionQuantity <= 0) {
      toast.error("Please enter a valid quantity.");
      return;
    }
  
    // Fetch the subscription price based on the selected frequency
    let subscriptionPrice = null;
  
    if (selectedFrequency === "daily") {
      subscriptionPrice = single_product_details.daily_subscription_price;
      if (!selectedTimings || selectedTimings.length === 0) {
        toast.error("Please select a timing for daily subscription.");
        return;
      }
    } else if (selectedFrequency === "weekly") {
      subscriptionPrice = single_product_details.weekly_subscription_price;
      if (!selectedDays || selectedDays.length === 0) {
        toast.error("Please select at least one day for weekly subscription.");
        return;
      }
      if (!selectedTimings || selectedTimings.length === 0) {
        toast.error("Please select a timing for weekly subscription.");
        return;
      }
    } else if (selectedFrequency === "monthly") {
      subscriptionPrice = single_product_details.monthly_subscription_price;
      if (!selectedMonthlyDates) {
        toast.error("Please select a date for monthly subscription.");
        return;
      }
    } else if (selectedFrequency === "yearly") {
      subscriptionPrice = single_product_details.yearly_subscription_price;
      if (!selectedAnuallyDates) {
        toast.error("Please select a date for annual subscription.");
        return;
      }
    }
  
    if (!subscriptionPrice) {
      toast.error("Unable to fetch subscription price for the selected frequency.");
      return;
    }
  
    const subscriptionData = {
      productId,
      frequency: selectedFrequency,
      quantity: subscriptionQuantity,
      timings: selectedTimings,
      startDate:
      selectedFrequency === "yearly"
        ? startDate
          ? new Date(startDate).toISOString()
          : null
        : startDate?.toISOString() || null,
    endDate:
      selectedFrequency === "yearly"
        ? endDate
          ? new Date(endDate).toISOString()
          : null
        : endDate?.toISOString() || null,
      selectedDays: selectedFrequency === "weekly" ? selectedDays : [],
      selectedMonthDate: selectedFrequency === "monthly" ?selectedMonthlyDates : null,
      selectedAnuallyDate: selectedFrequency === "yearly" ? selectedAnuallyDates : null,
      price: subscriptionPrice, // Add the subscription price to the data
    };
  
    ////console.log(subscriptionData, 'on submitttt');
  
    saveSubscriptionCookie([subscriptionData]);
    toast.success(
      `Subscription saved successfully for ${selectedFrequency} with quantity ${subscriptionQuantity} at a price of ${subscriptionPrice}.`
    );
  
    navigation('/subscription-checkout');
  };
  
  
  // Handle day selection (only one "T" or "S" can be selected at a time)
  const handleDaySelect = (day) => {
    setSelectedDays((prev) => {
      const isSelected = prev.includes(day.id);
  
      if (day.label === "T") {
        // Handle the special case for 'T'
        const otherTSelected = prev.some(
          (id) => id !== day.id && daysOfWeek[id]?.label === "T"
        );
  
        if (otherTSelected) {
          // If another 'T' is selected, remove it
          return prev
            .filter((id) => daysOfWeek[id]?.label !== "T")
            .concat(isSelected ? [] : [day.id]);
        }
      }
  
      // Toggle the selected day
      return isSelected ? prev.filter((id) => id !== day.id) : [...prev, day.id];
    });
  };

  // Remove a selected time
  const handleRemoveTime = (time) => {
    setSelectedTimings((prev) => prev.filter((t) => t !== time));
    setErrorMessage(""); // Clear the error messagehadn
  };

  return (
    
   
       <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Subscription</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <div className="subscription-offcanvas">
        <div className="offcanvas-body no-scroll">
          {productDetails ? (
            <>
              {/* Product Details */}
              <div className="product-info-container">
              <div className="product-image">
              <img
              src={imageUrl?productUrls[productId]:Noproduct }
              alt={productDetails.title}
              className="img-fluid"
            />
                  </div>

                <div className="product-details">
               
                        <h4 className="product-name">
                          {single_product_details?.product_name || "Product Name"}
                        </h4>
                        <p className="mrp">
                          MRP: <span>₹{single_product_details?.mrp || "0.00"}</span>
                        </p>
                        <p className="price">Price :
                          ₹{single_product_details?.price}{" "}
                          <span className="price-per-unit">
                            {single_product_details?.price_per_unit}
                          </span>
                        </p>

                        <p className="price">Subscription Price :
                          ₹ {showSubscriptionPrice}
                          <span className="price-per-unit">
                          
                          </span>
                        </p>
                      </div>
                      <div className="product-actions">
                          {subscriptionQuantity ? (
                            <QuantityInput
                            quantity={subscriptionQuantity}
                            onIncrease={handleIncreaseQuantity}
                            onDecrease={handleDecreaseQuantity}
                            min_order={1}
                            onRemove={handleRemove}

                    
                            />
                          ) : (
                            <button
                              className="add-to-cart-button"
                              onClick={handleAddToCart}
                            >
                              Add 
                            </button>
                          )}
                        </div>
                      </div>



              <h6 className="subscription-title">Choose your Subscription</h6>
              
              {/* Frequency Dropdown */}
              <div className="dropdown-section">
                
                <select
                  id="recurrenceDropdown"
                  className="form-select"
                  value={selectedFrequency}
                  onChange={handleFrequencyChange}
                  aria-label="Recurrence Dropdown"
                >
                  <option value="">Select Frequency</option>
                  {Object.entries(frequencies).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              {/* Weekly Selection */}
              {selectedFrequency === "weekly" && (
                <>
                  <div className="weekly-selection">
                  <label>Select Days</label>
                  <div className="days-container">
                          {daysOfWeek.map((day) => (
                            <div
                              key={day.id}
                              className={`day ${selectedDays.includes(day.id) ? "selected" : ""}`}
                              onClick={() => handleDaySelect(day)}
                            >
                              {day.label}
                            </div>
                          ))}
                        </div>

                        <label >Select slot</label>
                    <div className="dropdown">
                      <button
                        className="form-control"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedTimings.length > 0 ? (
                          <div className="selected-tags">
                            {selectedTimings.map((time, index) => (
                              <span key={index} className="tag">
                                {time}
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="remove-icon"
                                  onClick={() => handleRemoveTime(time)}
                                />
                              </span>
                            ))}
                          </div>
                        ) : (
                          "Select Time"
                        )}
                        <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon" />
                      </button>
                      <ul className="dropdown-menu">
                        {timeOptions.map((time, index) => (
                          <li key={index} className="dropdown-item">
                            <div
                              className={`time-option ${
                                selectedTimings.includes(time) ? "selected" : ""
                              }`}
                              onClick={() => handleTimeSelect(time)}
                            >
                              {time}
                              {selectedTimings.includes(time) && (
                                <FontAwesomeIcon icon={faCheck} className="check-icon" />
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                      {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                 <div className="datepickers">
                  <div className="datepicker-container">
                    <label>Start Date</label>
                    <Datepicker
                      className="date-input"
                      dateValue={startDate}
                      onDateChange={setStartDate}
                      placeholder="Select Start Date"
                    />
                  </div>
                  <div className="datepicker-container">
                    <label>End Date</label>
                    <Datepicker
                      className="date-input"
                      dateValue={endDate}
                      onDateChange={setEndDate}
                      placeholder="Select End Date"
                    />
                  </div>
                </div>

                 
                  </div>
                </>
              )}

              {/* Monthly Select  ion */}
              {selectedFrequency === "monthly" && (
  <div className="monthly-selection">
    <label>Select Date</label>
    {/* Display selected dates as tags */}
    <div className="selected-dates">
    {selectedMonthlyDates.map((day, index) => (
  <span key={index} className="selected-date-tag">
    {String(day).padStart(2, "0")} {/* Display day as 2-digit format */}
    <FontAwesomeIcon
      icon={faTimes}
      className="remove-icon"
      onClick={() =>
        setSelectedMonthlyDates((prev) => prev.filter((d) => d !== day))
      }
    />
  </span>
))}

    </div>

    {/* Datepicker input */}
    <div className="datepicker-containers">
      <Datepicker
        id="start-date"
        type="text"
        className="date-input1"
        placeholder="Select Date"
        dateValue={null} // Always null to reset input after selection
        onDateChange={handleMonthlyDateChange}
      />
    </div>

    {/* Start and End Date Fields */}
    <div className="datepickers">
      <div className="datepicker-container">
        <label>Start Date</label>
        <Datepicker
          className="date-input"
          dateValue={startDate}
          onDateChange={setStartDate}
          placeholder="Select Start Month"
          showMonthYearPicker
          dateFormat="MMM yyyy" // Displays the format as "Month Year"
        />
      </div>
      <div className="datepicker-container">
        <label>End Date</label>
        <Datepicker
          className="date-input"
          dateValue={endDate}
          onDateChange={setEndDate}
          placeholder="Select End Month"
          showMonthYearPicker
          dateFormat="MMM yyyy" // Displays the format as "Month Year"
        />
      </div>
    </div>
  </div>
)}



              {/* Yearly Selection */}
              {selectedFrequency === "yearly" && (
  <div className="yearly-selection">
    {/* Yearly Date Selection */}
    <label>Select Dates</label>
    <div className="selected-dates">
      {selectedAnuallyDates.map((date, index) => (
        <span key={index} className="selected-date-tag">
          {date} {/* Display the day-month */}
          <FontAwesomeIcon
            icon={faTimes}
            className="remove-icon"
            onClick={() => handleRemoveYearlyDate(date)} // Remove date from the list
          />
        </span>
      ))}
    </div>
    <div className="datepicker-containers">
      <Datepicker
        className="date-input"
        selected={null} // Always null to reset the input after selection
        onChange={handleYearlyDateChange} // Call handleYearlyDateChange
        placeholderText="Select Day & Month"
        dateFormat="dd-MM"
        showYearDropdown={false}
        showMonthDropdown
        dropdownMode="select"
      />
    </div>

    {/* Display Selected Dates as Tags */}
   

    {/* Start and End Year Selection */}
    <div className="datepickers">
      <div className="datepicker-container">
        <label>Select Start Year</label>
        <Datepicker
  className="date-input"
  selected={startDate ? new Date(startDate) : null} // ✅ Convert ISO string back to Date
  onChange={handleStartYearChange}
  placeholderText="Select Start Year"
  showYearPicker
  dateFormat="yyyy"
/>

      </div>
      <div className="datepicker-container">
        <label>Select End Year</label>
        <Datepicker
  className="date-input"
  selected={endDate ? new Date(endDate) : null} // ✅ Convert ISO string back to Date
  onChange={handleEndYearChange}
  placeholderText="Select End Year"
  showYearPicker
  dateFormat="yyyy"
/>

      </div>
    </div>
  </div>
)}




              {/* Time Selection (Daily/Weekly) */}
              {selectedFrequency === "daily"&& (
                <>
                  <div className="time-selection">
                    <label >Select slot</label>
                    <div className="dropdown">
                      <button
                        className="form-control"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedTimings.length > 0 ? (
                          <div className="selected-tags">
                            {selectedTimings.map((time, index) => (
                              <span key={index} className="tag">
                                {time}
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="remove-icon"
                                  onClick={() => handleRemoveTime(time)}
                                />
                              </span>
                            ))}
                          </div>
                        ) : (
                          "Select Time"
                        )}
                        <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon" />
                      </button>
                      <ul className="dropdown-menu">
                        {timeOptions.map((time, index) => (
                          <li key={index} className="dropdown-item">
                            <div
                              className={`time-option ${
                                selectedTimings.includes(time) ? "selected" : ""
                              }`}
                              onClick={() => handleTimeSelect(time)}
                            >
                              {time}
                              {selectedTimings.includes(time) && (
                                <FontAwesomeIcon icon={faCheck} className="check-icon" />
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                      {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                <div className="datepickers">
                  <div className="datepicker-container">
                    <label>Start Date</label>
                    <Datepicker
                      className="date-input"
                      dateValue={startDate}
                      onDateChange={setStartDate}
                      placeholder="Select Start Date"
                    />
                  </div>
                  <div className="datepicker-container">
                    <label>End Date</label>
                    <Datepicker
                      className="date-input"
                      dateValue={endDate}
                      onDateChange={setEndDate}
                      placeholder="Select End Date"
                    />
                  </div>
                </div>

                  </div>
                </>
              )}

              {/* Save Button */}
              {(selectedFrequency === "daily" ||
                  selectedFrequency === "weekly" ||
                  selectedFrequency === "monthly" ||
                  selectedFrequency === "yearly") && (
                  <div className="save-button-container">
                    <ButtonComponent
                      type="button"
                      text="Subscribe"
                      className="save-button"
                      onClick={() =>handleSaveSubscription()}
                    >
                      Save
                    </ButtonComponent>
                  </div>
                )}

            </>
          ) : (
            <p>No product details available.</p>
          )}
        </div>
    </div>
   
    </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SubscriptionOffcanvas;