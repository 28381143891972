import { exportOrdersAPI } from "../../api/services/exportapiService";
import { toast } from "react-hot-toast";

// Action Types
export const EXPORT_ORDERS_REQUEST = "EXPORT_ORDERS_REQUEST";
export const EXPORT_ORDERS_SUCCESS = "EXPORT_ORDERS_SUCCESS";
export const EXPORT_ORDERS_FAILURE = "EXPORT_ORDERS_FAILURE";

// Action Creators
const exportOrdersRequest = () => ({
  type: EXPORT_ORDERS_REQUEST,
});

const exportOrdersSuccess = () => ({
  type: EXPORT_ORDERS_SUCCESS,
});

const exportOrdersFailure = (error) => ({
  type: EXPORT_ORDERS_FAILURE,
  payload: error,
});

/**
 * Export Orders Action
 * @param {string} token - User's authentication token
 * @param {string} startDate - Optional start date for filtering orders (in YYYY-MM-DD format)
 * @param {string} endDate - Optional end date for filtering orders (in YYYY-MM-DD format)
 */
export const exportOrdersAction = (token, startDate, endDate) => {
  return async (dispatch) => {
    dispatch(exportOrdersRequest());

    try {
      // Call the API Service to export orders
      await exportOrdersAPI(token, startDate, endDate);

      dispatch(exportOrdersSuccess());
      toast.success("Orders exported successfully!");
    } catch (error) {
      dispatch(exportOrdersFailure(error.message));
      toast.error(
        error.response?.data?.error || "Failed to export orders. Please try again."
      );
    }
  };
};
