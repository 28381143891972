import React from 'react';
import { useSelector } from 'react-redux';
import SecondaryNavbar from '../../components/layout/SecondaryNavbar/SecondaryNavbar';
import AdminProductsPage from '../AdminProductsPage/AdminProductsPage';
import CouponDisplayPage from '../CouponPage/coupondisplayPage';
import DiscountDisplayPage from '../OfferPage/offerdisplayPage';
import CategoryDisplayPage from '../AdminCategory/categorylistpage';
import BrandDisplayPage from '../AdminBrandManagement/brandlistPage';
const AdminMainPage = () => {
    // Get the navbar state from Redux
    const navbarState = useSelector((state) => state.navbar);

    return (
        <div>
            <SecondaryNavbar />
            
            {navbarState.category && <CategoryDisplayPage/>}
            {navbarState.brand && <BrandDisplayPage/>}
            {navbarState.product && <AdminProductsPage />}
            {navbarState.coupon && <CouponDisplayPage/>}
            {navbarState.offer && <DiscountDisplayPage/>}

        </div>
    );
};

export default AdminMainPage;
