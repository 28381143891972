// services/cartService.js

import apiClient from '../apiClient';

// Cart Service

export const createCart = async (userId, token) => {
    try {
        //console.log("Sending token:", token);
        const response = await apiClient.post(
            '/auth/cart',
            { user_id: userId },
            {
                headers: {
                    Authorization: token, // Use Bearer prefix
                },
            }
        );
        //console.log("Response data:", response.data);
        return response.data;
    } catch (error) {
        //console.error("Authorization error:", error.response?.data || error.message);
        throw error; // Re-throw the error for proper handling
    }
};


export const getCart = async (cartId, token) => {
    try{
        const response = await apiClient.get('/auth/cart', {
            params:{user_id:cartId},
            headers: { Authorization: token }
        });
        return response.data;
    }
    catch(error){
        throw new Error('cannot fetch cart')
    }
    
};

export const updateCart = async (data, token) => {
    const response = await apiClient.put('/auth/cart', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteCart = async (cartId, token) => {
    const response = await apiClient.delete('/auth/cart', {
        params: { cart_id: cartId },
        headers: { Authorization: token }
    });
    return response.data;
};

// Cart Item Service

export const createCartItem = async (data, token) => {
    //console.log(data,token,'--------------===================')
    const response = await apiClient.post('/auth/cart-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getCartItem = async (cartId, token) => {
    const response = await apiClient.get(`/auth/cart-items?cart_id=${cartId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateCartItem = async (data, token) => {
    //console.log('data',data);
    const response = await apiClient.put('/auth/cart-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteCartItem = async (cartItemId, token) => {
    const response = await apiClient.delete('/auth/cart-items', {
        params: { cart_item_id: cartItemId },
        headers: { Authorization: token }
    });
    return response.data;
};

// Bulk Operations for Carts

export const createBulkCarts = async (data, token) => {
    const response = await apiClient.post('/auth/bulk-carts', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getBulkCarts = async (token) => {
    const response = await apiClient.get('/auth/bulk-carts', {
        headers: { Authorization: token }
    });
    return response.data.carts;
};

export const updateBulkCarts = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-carts', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteBulkCarts = async (cartIds, token) => {
    const response = await apiClient.delete('/auth/bulk-carts', {
        headers: { Authorization: token },
        data: { cart_ids: cartIds }
    });
    return response.data;
};

// Bulk Operations for Cart Items

export const createBulkCartItems = async (data, token) => {
    const response = await apiClient.post('/auth/bulk-cart-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getBulkCartItems = async (id,token) => {
    const response = await apiClient.get('/auth/bulk-cart-items', {
        params:{cart_id:id},
        headers: { Authorization: token }
    });

    //console.log(response,'carts items in response -------------------')
    return response.data.cart_items;
};

export const updateBulkCartItems = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-cart-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteBulkCartItems = async (cartItemIds, token) => {
    //console.log('bulk cart items delete response',cartItemIds)
    const response = await apiClient.delete('/auth/bulk-cart-items', 
        {
        headers: { Authorization: token },
        data:cartItemIds,
       
    });
    //console.log('bulk cart items delete response',response)
    return response.data;
};
