import React from 'react';
import './Pagination.scss';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
  

    // Add the "Previous" button
    pageNumbers.push(
      <li
        key="previous"
        className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <a className="page-link" href=" " aria-label="Previous">
          &lsaquo;
        </a>
      </li>
    );

    // Always show the first page
    pageNumbers.push(
      <li
        key={1}
        className={`page-item ${currentPage === 1 ? 'active' : ''}`}
        onClick={() => onPageChange(1)}
      >
        <a className="page-link" href=" ">
          1
        </a>
      </li>
    );

    // Show ellipsis if needed
    if (currentPage > 3) {
      pageNumbers.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">«</span>
        </li>
      );
    }

    // Show the pages around the current page
    for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
      if (i !== 1 && i !== totalPages) {
        pageNumbers.push(
          <li
            key={i}
            className={`page-item ${currentPage === i ? 'active' : ''}`}
            onClick={() => onPageChange(i)}
          >
            <a className="page-link" href=" ">
              {i}
            </a>
          </li>
        );
      }
    }

    // Show ellipsis if needed
    if (currentPage < totalPages - 2) {
      pageNumbers.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Always show the last page
    pageNumbers.push(
      <li
        key={totalPages}
        className={`page-item ${currentPage === totalPages ? 'active' : ''}`}
        onClick={() => onPageChange(totalPages)}
      >
        <a className="page-link" href=" ">
          {totalPages}
        </a>
      </li>
    );

    // Add the "Next" button
    pageNumbers.push(
      <li
        key="next"
        className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <a className="page-link" href=" " aria-label="Next">
          &rsaquo;
        </a>
      </li>
    );

    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation example" className="d-flex justify-content-center">
      <ul className="pagination">
        {renderPageNumbers()}
      </ul>
    </nav>
  );
};

export default Pagination;
