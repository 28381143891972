import React from "react";
import "./privacy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <div className="header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Privacy Policy</h2>
          <p className="effective-date">Effective Date: Feburary 28, 2025</p>
          <p>Fresh & Pure Dairy & Agro Products values your privacy and ensures that your personal information is handled securely.</p>
          <h3>Data We Collect</h3>
          <p>We collect personal information, including your name, login credentials, delivery address, and phone number, for order processing and account management.</p>
          <p>We do not collect or store payment details; all transactions are processed securely via third-party payment gateways.</p>
          <h3>How We Use Your Data</h3>
          <ul>
            <li>To process and deliver orders.</li>
            <li>To communicate with you regarding orders, services, or updates.</li>
            <li>To improve our platform and user experience.</li>
          </ul>
          <h3>Data Sharing</h3>
          <p>We do not sell or share your personal information with third parties.</p>
          <p>Your information may be shared with delivery partners solely for order fulfillment.</p>
          <h3>No Advertisements</h3>
          <p>Our app and website do not display third-party advertisements.</p>
          <h3>Account Deletion</h3>
          <p>To delete your account, email us at freshpure.in@gmail.com with your request. Account data will be permanently removed within 7 working days unless retention is required for legal or compliance purposes.</p>
          <h3>Cookies</h3>
          <p>Our platform uses cookies for functional and analytical purposes to enhance user experience. You can disable cookies through your browser settings.</p>
          <p>For privacy concerns, contact us at:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> freshpure.in@gmail.com</li>
            <li><strong>Phone:</strong> 094429 17151</li>
          </ul>
        </div>
        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>Contact our sales staff with any questions, and they will provide you with extensive clarification.</p>
          <p className="contact-number">094429 17151</p>
          <p className="contact-email">freshpure.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;