import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickCarouselComponent.scss";
import { useSelector, useDispatch } from "react-redux";
import { fetchBanners } from "../../redux/actions/bannerAction";
import { fetchPresignedUrl } from "../../redux/actions/fetchImage";

const SlickCarouselComponent = () => {
  const dispatch = useDispatch();

  // Redux state
  const banners = useSelector((state) => state.banner.banners) || []; // Banners from Redux
  const urls = useSelector((state) => state.image.urls.banners) || {}; // Pre-signed URLs for banners
  const loadingBanners = useSelector((state) => state.banner.loading); // Loading state for banners
  const loadingUrls = useSelector((state) => state.image.loading); // Loading state for URLs
  const token = useSelector((state) => state.auth.token); // Authentication token

  const [isInitialLoad, setIsInitialLoad] = useState(true); // Initial load state

  useEffect(() => {
    const fetchAndLoad = async () => {
      // Fetch banners first
      await dispatch(fetchBanners(token));

      // Filter and sort banners
      const filteredAndSorted = banners
        .filter((banner) => banner.status === "active")
        .sort((a, b) => a.banner_count - b.banner_count);


      // Set initial load to false once all data is ready
      setIsInitialLoad(false);
    };

    fetchAndLoad();
  }, [dispatch, token]); // Dependency array

  // Filter and sort banners for rendering
  const filteredAndSortedBanners = banners
    .filter((banner) => banner.status === "active")
    .sort((a, b) => a.banner_count - b.banner_count);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  useEffect(() => {
    if (banners.length > 0 && !loadingBanners) {
      banners.forEach((banner) => {
        if (banner?.banner_id && banner?.url) {
          dispatch(fetchPresignedUrl("banners", banner.banner_id, banner.url, token));
        }
      });
    }
  }, [banners, loadingBanners, token]);
  
  return (
    <div className="slick-carousel-container">
      {loadingBanners || (isInitialLoad && loadingUrls) ? (
        <div className="loader-container">
          <div className="skeleton-banner shimmer"></div>
        </div>
      ) : (
        <Slider {...settings}>
          {filteredAndSortedBanners.map((banner, index) => (
            <div key={index} className="slick-slide">
              <img
                src={urls[banner.banner_id] || banner.url}
                alt={banner.title || `Banner ${index + 1}`}
              />
              {banner.title && <p className="banner-title">{banner.title}</p>}
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
  
};

export default SlickCarouselComponent;
