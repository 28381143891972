import apiClient from "../apiClient";
export const createRazorpayOrder = async (token, orderData) => {
    try {
        const response = await apiClient .post('/auth/create-razorpay-order', orderData, {
            headers: {
                Authorization: token, // Pass the access token
            },
        });
        return response.data;
    } catch (error) {
        //console.error('Error creating Razorpay order:', error);
        throw error.response ? error.response.data : error;
    }
};

// Function to trigger /verify-payment
export const verifyRazorpayPayment = async (token, paymentData) => {
    try {
        const response = await apiClient.post('/auth/verify-payment', paymentData, {
            headers: {
                Authorization: token, // Pass the access token
            },
        });
        return response.data;
    } catch (error) {
        //console.error('Error verifying Razorpay payment:', error);
        throw error.response ? error.response.data : error;
    }
};