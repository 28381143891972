// src/redux/reducers/adminReducer.js
import {
    FETCH_ADMINS_REQUEST,
    FETCH_ADMINS_SUCCESS,
    FETCH_ADMINS_FAILURE,
    CLEAR_ADMINS,
  } from '../actions/adminActions'; // Adjust the import path as necessary
  
  const initialState = {
    adminList: [],
    timestamp: null,
    loading: false,
    error: null,
  };
  
  const adminReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ADMINS_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_ADMINS_SUCCESS:
        return {
          ...state,
          loading: false,
          adminList: action.payload.admins,
          timestamp: action.payload.timestamp,
        };
      case FETCH_ADMINS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case CLEAR_ADMINS:
        return { ...state, adminList: [], timestamp: null };
      default:
        return state;
    }
  };
  
  export default adminReducer;
  