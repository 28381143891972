// src/redux/reducers/adminRegisterReducer.js

import {
  GENERATE_TOKEN_REQUEST,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
} from '../actions/adminRegisterActions';

const initialState = {
  loading: false,         // Loading flag for registration and token generation
  success: false,         // Success flag for registration
  error: null,            // Error message for registration or token generation
  accessToken: null,      // Token after successful registration
  expiresAt: null,        // Token expiration time
  message: null,          // Success or failure message
  isLoggedIn: false,      // Logged-in state
  user: null,             // User data after successful registration
};

// Reducer function for registration, token generation, and user state management
const adminRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    // Token generation actions
    case GENERATE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,  // Set loading true when generating token
        error: null,    // Clear any previous errors
      };

    case GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,          // Stop loading when token is successfully generated
        accessToken: action.payload.accessToken,   // Store the generated token in state
        error: null,             // Clear any errors
      };

    case GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,         // Stop loading if token generation fails
        error: action.payload,  // Store the error message
      };

    // Registration actions
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,    // Set loading true while registering
        success: false,   // Reset success flag
        error: null,      // Clear any previous errors
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,            // Stop loading on successful registration
        success: true,             // Set success flag
        accessToken: action.payload.access_token, // Store the access token in state
        expiresAt: action.payload.expires_at,     // Store the token expiration time
        message: action.payload.message,          // Store any success message
        user: action.payload.user,                // Store the registered user data
        error: null,               // Clear any errors
        isLoggedIn: true,          // Set logged-in status
      };

    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,       // Stop loading if registration fails
        success: false,       // Reset success flag
        error: action.payload, // Store the error message
        isLoggedIn: false,     // Reset logged-in status
      };

    // Logout action
    case LOGOUT:
      return {
        ...state,
        accessToken: null,     // Clear token on logout
        expiresAt: null,       // Clear token expiration on logout
        user: null,            // Clear user data on logout
        isLoggedIn: false,     // Reset logged-in state
        error: null,           // Clear error
        message: null,         // Clear any messages
        success: false,        // Reset success flag
      };

    default:
      return state;
  }
};

export default adminRegisterReducer;
