import { basic_product_endpoints, custom_product_endpoints } from "../endPoints";
import apiClient from '../apiClient';

// **Basic Products Operations**
// Fetch Single Basic Product
export const fetchSingleBasicProduct = async (productId, token) => {
  const response = await apiClient.get(`${basic_product_endpoints.GET_BASIC_PRODUCT}/${productId}`, {
    headers: {
      Authorization: token,
    },
  });

  if (!response.data) {
    throw new Error('Failed to fetch basic product');
  }

  //console.log("Single Basic Product Response:", response.data);
  return response.data;
};

// Fetch Bulk Basic Products
export const fetchBulkBasicProducts = async (token) => {
  const response = await apiClient.get(basic_product_endpoints.GET_BASIC_BULK_PRODUCT, {
    headers: {
      Authorization: token,
    },
  });

  if (!response.data) {
    throw new Error('Failed to fetch bulk basic products');
  }

  //console.log("Bulk Basic Products Response:", response.data.products);
  return response.data.products;
};

// Update Single Basic Product
export const updateSingleBasicProduct = async (productData, token) => {
  const response = await apiClient.put(
    basic_product_endpoints.GET_BASIC_PRODUCT,
    
      productData
    
    ,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to update basic product');
  }

  //console.log('Single Basic Product Updated Successfully', response.data);
  return response.data;
};

// Update Bulk Basic Products
export const updateBulkBasicProducts = async (productsData, token) => {
  //console.log(productsData, token,'---------------123456789')
  const response = await apiClient.put(
    basic_product_endpoints.GET_BASIC_BULK_PRODUCT,
    productsData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to update bulk basic products');
  }

  //console.log('Bulk Basic Products Updated Successfully', response.data);
  return response.data;
};

// Delete Single Basic Product
export const deleteSingleBasicProduct = async (productId, token) => {
  try {
    const response = await apiClient.delete(
      basic_product_endpoints.GET_BASIC_PRODUCT, // The endpoint for category deletion
       {
         headers: {
         
           Authorization: token,
         },
         params:{product_id: productId}
       }
     );

    //console.log('Single Basic Product Deleted Successfully');
    return response.data;
  } catch (error) {
    //console.error('Error deleting product:', error.response?.data || error.message);
    throw error;
  }
};


// Delete Bulk Basic Products
export const deleteBulkBasicProducts = async (productIds, token) => {
 
  const response = await apiClient.delete(
    basic_product_endpoints.GET_BASIC_BULK_PRODUCT, 
    {
    headers: {
    
      Authorization: token,
    },
    data: productIds,
    
  });

  if (!response.data) {
    throw new Error('Failed to delete bulk basic products');
  }

  //console.log('Bulk Basic Products Deleted Successfully');
  return response.data;
};

// Create Single Basic Product
export const createSingleBasicProduct = async (productData, token) => {
  //console.log('--basic product',productData);
  const response = await apiClient.post(
    basic_product_endpoints.GET_BASIC_PRODUCT,
    productData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  //console.log('Single Basic Product Created Successfully', response.data);
  return response.data;
};

// Create Bulk Basic Products
export const createBulkBasicProducts = async (productsData, token) => {
  const response = await apiClient.post(
    basic_product_endpoints.GET_BASIC_BULK_PRODUCT,
    productsData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to create bulk basic products');
  }

  //console.log('Bulk Basic Products Created Successfully', response.data);
  return response.data;
};


// **Custom Products Operations**
// Fetch Single Custom Product
export const fetchSingleCustomProduct = async (productId, token) => {
  const response = await apiClient.get(`${custom_product_endpoints.GET_CUSTOM_PRODUCT}/${productId}`, {
    headers: {
      Authorization: token,
    },
  });

  if (!response.data) {
    throw new Error('Failed to fetch custom product');
  }

  //console.log("Single Custom Product Response:", response.data);
  return response.data;
};

// Fetch Bulk Custom Products
export const fetchBulkCustomProducts = async (token) => {
  const response = await apiClient.get(custom_product_endpoints.GET_CUSTOM_BULK_PRODUCT, {
    headers: {
      Authorization: token,
    },
  });

  if (!response.data) {
    throw new Error('Failed to fetch bulk custom products');
  }

  //console.log("Bulk Custom Products Response:", response.data);
  return response.data.products;
};

// Update Single Custom Product
export const updateSingleCustomProduct = async (productData, token) => {
  const response = await apiClient.put(
    custom_product_endpoints.GET_CUSTOM_PRODUCT,
    productData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to update custom product');
  }

  //console.log('Single Custom Product Updated Successfully', response.data);
  return response.data;
};

// Update Bulk Custom Products
export const updateBulkCustomProducts = async (productsData, token) => {
  //console.log(productsData)
  const response = await apiClient.put(
    custom_product_endpoints.GET_CUSTOM_BULK_PRODUCT,
    productsData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to update bulk custom products');
  }

  //console.log('Bulk Custom Products Updated Successfully', response.data);
  return response.data;
};

// Delete Single Custom Product
export const deleteSingleCustomProduct = async (productId, token) => {
  const response = await apiClient.delete(
    custom_product_endpoints.GET_CUSTOM_PRODUCT, {

    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    params:{
      product_id:productId
    }
  });

  if (!response.data) {
    throw new Error(`Failed to delete custom product with ID: ${productId}`);
  }

  //console.log('Single Custom Product Deleted Successfully');
  return response.data;
};

// Delete Bulk Custom Products
export const deleteBulkCustomProducts = async (productIds, token) => {
  const response = await apiClient.delete(custom_product_endpoints.GET_CUSTOM_BULK_PRODUCT, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    data: productIds,
    
  });

  if (!response.data) {
    throw new Error('Failed to delete bulk custom products');
  }

  //console.log('Bulk Custom Products Deleted Successfully');
  return response.data;
};

// Create Single Custom Product
export const createSingleCustomProduct = async (productData, token) => {
  const response = await apiClient.post(
    custom_product_endpoints.GET_CUSTOM_PRODUCT,
    productData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to create custom product');
  }

  //console.log('Single Custom Product Created Successfully', response.data);
  return response.data;
};

// Create Bulk Custom Products
export const createBulkCustomProducts = async (productsData, token) => {
  const response = await apiClient.post(
    custom_product_endpoints.GET_CUSTOM_BULK_PRODUCT,
    productsData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (!response.data) {
    throw new Error('Failed to create bulk custom products');
  }

  //console.log('Bulk Custom Products Created Successfully', response.data);
  return response.data;
};
