import React, { useState } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './ReferPage.scss';

const Refer = () => {
  const [viewMore, setViewMore] = useState(true); // Set to true initially to always show "View More"

  const toggleViewMore = () => {
    setViewMore(!viewMore); // Toggle between true and false
  };

  return (
    <div className="refer-container">
      {/* Main Referral Banner */}
      <div className="refer-banner">
        <div className="refer-banner-text">
          <h5 className="refer-para">Tell your friend about Truemeds &</h5>
          <h4 className="refer-earn-text">Earn ₹200</h4>
        </div>
        <div className="refer-banner-image"></div>

        <div className="refer-code-container">
          <div className="refer-code-input">
            <label>Your Referral Code</label>
            <div className="input-copy-container">
              <input type="text" value="EDA7KBD" readOnly />
              <button className="copy-button">
                <i className="fas fa-copy"></i>
              </button>
            </div>
          </div>
          <ButtonComponent text="Refer a Friend" className="refer-button" />
        </div>
      </div>

      {/* How Referral Works Section */}
      <div className="referral-info-container">
        <div className="referral-info-header">
          <h4 className="referal-heading">How referral works</h4>
          <button className="view-toggle" onClick={toggleViewMore}>
            {viewMore ? 'View Less' : 'View More'}
          </button>
        </div>

        {viewMore && (
          <div className="referral-steps">
            <div className="step">
              <i className="step-icon fas fa-user-friends"></i>
              <p>Invite your friends to join Truemeds using your referral code</p>
            </div>
            <div className="step">
              <i className="step-icon fas fa-rupee-sign"></i>
              <p>They receive ₹200 in their TM rewards on successful registration</p>
            </div>
            <div className="step">
              <i className="step-icon fas fa-box"></i>
              <p>You earn ₹200 in your TM rewards after their order is delivered</p>
            </div>
          </div>
        )}
      </div>

      {/* Claim Referral Code Section */}
      <div className="claim-reward-container">
        <h4>Have a referral code?</h4>
        <div className="claim-reward-input">
          <InputComponent type="text" placeholder="Enter Referral Code" />
          <button className="claim-button">Claim Reward</button>
        </div>
      </div>
    </div>
  );
};

export default Refer;
