// redux/reducers/ordersReducer.js

import {
    CREATE_ORDER,
    GET_ORDER,
    UPDATE_ORDER,
    DELETE_ORDER,
    GET_BULK_ORDERS,
    DELETE_BULK_ORDERS,
    CREATE_ORDER_ITEM,
    UPDATE_BULK_ORDERS,
    GET_ORDER_ITEM,
    UPDATE_ORDER_ITEM,
    DELETE_ORDER_ITEM,
    GET_BULK_ORDER_ITEMS,
    CREATE_BULK_ORDER_ITEMS,
    UPDATE_BULK_ORDER_ITEMS,
    DELETE_BULK_ORDER_ITEMS,
    CLEAR_ORDER_STATE,

} from '../actions/orderActions';

const initialState = {
    orders: [],
    orderItems: []
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ORDER:
            return {
                ...state,
                orders: [...state.orders, action.payload]
            };
        
        case GET_ORDER:
            return {
                ...state,
                orders: state.orders.map(order => 
                    order.order_id === action.payload.order_id ? action.payload : order
                )
            };
        
        case UPDATE_ORDER:
            return {
                ...state,
                orders: state.orders.map(order =>
                    order.order_id === action.payload.order_id ? action.payload : order
                )
            };
        
        case DELETE_ORDER:
            return {
                ...state,
                orders: state.orders.filter(order => order.order_id !== action.payload)
            };
        
        case GET_BULK_ORDERS:
            return {
                ...state,
                orders: action.payload
            };
        
        case DELETE_BULK_ORDERS:
            return {
                ...state,
                orders: state.orders.filter(order => !action.payload.deletedOrderIds.includes(order.order_id))
            };

        case CREATE_ORDER_ITEM:
            return {
                ...state,
                orderItems: [...state.orderItems, action.payload]
            };
        
        case GET_ORDER_ITEM:
            return {
                ...state,
                orderItems: state.orderItems.map(item => 
                    item.order_item_id === action.payload.order_item_id ? action.payload : item
                )
            };
        
        case UPDATE_ORDER_ITEM:
            return {
                ...state,
                orderItems: state.orderItems.map(item =>
                    item.order_item_id === action.payload.order_item_id ? action.payload : item
                )
            };
        
        case DELETE_ORDER_ITEM:
            return {
                ...state,
                orderItems: state.orderItems.filter(item => item.order_item_id !== action.payload)
            };
        
        case GET_BULK_ORDER_ITEMS:
            return {
                ...state,
                orderItems: action.payload
            };
        
        case CREATE_BULK_ORDER_ITEMS:
            return {
                ...state,
                orderItems: [...state.orderItems, ...action.payload]
            };
        
        case UPDATE_BULK_ORDER_ITEMS:
            return {
                ...state,
                orderItems: state.orderItems.map(item =>
                    action.payload.find(updatedItem => updatedItem.order_item_id === item.order_item_id) || item
                )
            };
        
        case DELETE_BULK_ORDER_ITEMS:
            return {
                ...state,
                orderItems: state.orderItems.filter(item => !action.payload.deletedItems.includes(item.order_item_id))
            };
        case UPDATE_BULK_ORDERS:
                return {
                    ...state,
                    orders: state.orders.map(order =>
                        action.payload.find(updatedOrder => updatedOrder.order_id === order.order_id) || order
                    )
                };

        case CLEAR_ORDER_STATE: // Reset the state to initial values
                return {
                  ...initialState,
                };
          
            
        default:
            return state;
    }
};

export default ordersReducer;
