import React, { useState } from 'react';
import InputComponent from "../../components/common/InputComponent/InputComponent";
import CheckboxComponent from "../../components/common/CheckboxComponent/CheckboxComponent";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import './AddItemPage.scss';

const AddItemPage = () => {
  // Options for dropdowns
  const categories = [
    { label: 'Category 1', value: 1 },
    { label: 'Category 2', value: 2 },
  ];

  const dietaryOptions = [
    { label: 'Veg', value: 'Veg' },
    { label: 'Non-Veg', value: 'Non-Veg' },
  ];

  const gstTypeOptions = [
    { label: 'Services', value: 'Services' },
    { label: 'Goods', value: 'Goods' },
  ];

  // State to handle multiple rows
  const [rows, setRows] = useState([{
    selectedCategory: 'Select Category',
    selectedDietary: 'Veg',
    selectedGstType: 'Services',
    orderTypes: {
      delivery: false,
      pickUp: false,
      dineIn: false,
      online: false,
      expose: false
    }
  }]);

  const handleAddRow = () => {
    setRows([...rows, {
      selectedCategory: 'Select Category',
      selectedDietary: 'Veg',
      selectedGstType: 'Services',
      orderTypes: {
        delivery: false,
        pickUp: false,
        dineIn: false,
        online: false,
        expose: false
      }
    }]);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleOrderTypeChange = (index, type) => {
    const updatedRows = [...rows];
    updatedRows[index].orderTypes[type] = !updatedRows[index].orderTypes[type];
    setRows(updatedRows);
  };

  return (
    <div className='add-item-page-section'>
      <div className="add-item-page">
        <div className="add-item header-section">
          <div className="button-group">
            <ButtonComponent text="Save & Exit" variant="custom-exit" className="exit-button" />
            <ButtonComponent text="Save & Add Menu Items" variant="custom-save" className="save-button" />
            <ButtonComponent text="Back" variant="custom-back" className="back-button"  onClick={handleAddRow} />
          </div>
        </div>

        <table className="form-table">
          <thead>
            <tr>
              <th className="table-header"><span>Name *</span></th>
              <th className="table-header"><span>Short Code *</span></th>
              <th className="table-header"><span>Online Display Name</span></th>
              <th className="table-header"><span>Category *</span></th>
              <th className="table-header"><span>Price *</span></th>
              <th className="table-header"><span>Description</span></th>
              <th className="table-header"><span>Dietary</span></th>
              <th className="table-header"><span>GST Type</span></th>
              <th className="table-header"><span>Order Type</span></th>
              <th className="table-header"><span>Variation</span></th>
              <th className="table-header"><span>Addon</span></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td><InputComponent className="add-item no-margin-bottom" /></td>
                <td><InputComponent className="add-item no-margin-bottom" /></td>
                <td><InputComponent className="add-item no-margin-bottom" /></td>
                <td>
                  <DropdownButton 
                    label={row.selectedCategory} 
                    options={categories.map(cat => cat.label)} 
                    onSelect={(value) => handleRowChange(index, 'selectedCategory', value)}
                  />
                </td>
                <td><InputComponent type="number" className="no-margin-bottom" /></td>
                <td><InputComponent className="add-item no-margin-bottom" /></td>
                <td>
                  <DropdownButton 
                    label={row.selectedDietary} 
                    options={dietaryOptions.map(diet => diet.label)} 
                    onSelect={(value) => handleRowChange(index, 'selectedDietary', value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    label={row.selectedGstType}
                    options={gstTypeOptions.map(gst => gst.label)}
                    onSelect={(value) => handleRowChange(index, 'selectedGstType', value)}
                  />
                </td>
                <td>
                  <div className="checkbox-group">
                    <CheckboxComponent 
                      checked={row.orderTypes.delivery} 
                      onChange={() => handleOrderTypeChange(index, 'delivery')}
                    /> 
                    <span className='add-item item-text-group'>Delivery</span>
                  </div>
                  <div className="checkbox-group">
                    <CheckboxComponent 
                      checked={row.orderTypes.pickUp} 
                      onChange={() => handleOrderTypeChange(index, 'pickUp')}
                    />
                    <span className='add-item item-text-group'>Pick Up</span>
                  </div>
                  <div className="checkbox-group">
                    <CheckboxComponent 
                      checked={row.orderTypes.dineIn} 
                      onChange={() => handleOrderTypeChange(index, 'dineIn')}
                    />
                    <span className='add-item item-text-group'>Dine In</span>
                  </div>
                  <div className="checkbox-group">
                    <CheckboxComponent 
                      checked={row.orderTypes.online} 
                      onChange={() => handleOrderTypeChange(index, 'online')}
                    />
                    <span className='add-item item-text-group'>Online</span>
                  </div>
                  <div className="checkbox-group">
                    <CheckboxComponent 
                      checked={row.orderTypes.expose} 
                      onChange={() => handleOrderTypeChange(index, 'expose')}
                    /> 
                    <span className='add-item item-text-group'>Expose</span>
                  </div>
                </td>
                <td><CheckboxComponent /></td>
                <td><CheckboxComponent /></td>
              </tr>
            ))}
          </tbody>
        </table>

        <hr className="add-item section-divider" />

        <div className="add-item self-item-recipe">
          <div className="add-item checkbox-group1">
            <CheckboxComponent />
            <label>Create Self Item Recipe</label>
          </div>
          <span className='add-item item-text'>
            Applicable only when menu item is Purchased but does not have any recipe. 
            After setting this option you can not revert it back.
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddItemPage;
