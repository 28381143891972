// services/stockService.js

import apiClient from '../apiClient';

// Stock CRUD operations
export const createStock = async (data, token) => {
    const response = await apiClient.post('/auth/stock', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getStock = async (stockId, token) => {
    const response = await apiClient.get(`/auth/stock?stock_id=${stockId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateStock = async (data, token) => {
    const response = await apiClient.put('/auth/stock', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteStock = async (stockId, token) => {
    const response = await apiClient.delete(`/auth/stock?stock_id=${stockId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

// Bulk Stock Operations
export const createBulkStock = async (data, token) => {
    const response = await apiClient.post('/auth/bulk-stock', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getBulkStock = async (token) => {
    const response = await apiClient.get('/auth/bulk-stock', {
        headers: { Authorization: token }
    });
    return response.data.stocks;
};

export const updateBulkStock = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-stock', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteBulkStock = async (stockIds, token) => {
    const response = await apiClient.delete('/auth/bulk-stock', {
        headers: { Authorization: token },
        data: stockIds
    });
    return response.data;
};

// Stock Item CRUD operations
export const createStockItem = async (data, token) => {
    const response = await apiClient.post('/auth/stock-item', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getStockItem = async (stockItemId, token) => {
    const response = await apiClient.get(`/auth/stock-item?stock_item_id=${stockItemId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateStockItem = async (data, token) => {
    const response = await apiClient.put('/auth/stock-item', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteStockItem = async (stockItemId, token) => {
    const response = await apiClient.delete('/auth/stock-item', {
        params:{stock_item_id:stockItemId},
        headers: { Authorization: token }
    });
    return response.data;
};

// Bulk Stock Item Operations
export const createBulkStockItems = async (data, token) => {
    const response = await apiClient.post('/auth/bulk-stock-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getBulkStockItems = async (token) => {
    const response = await apiClient.get('/auth/bulk-stock-items', {
        headers: { Authorization: token }
    });
    //console.log(response.data,'--------stock response');
    return response.data.stocks;
};

export const updateBulkStockItems = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-stock-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteBulkStockItems = async (stockItemIds, token) => {
    const response = await apiClient.delete('/auth/bulk-stock-items', {
        headers: { Authorization: token },
        data: stockItemIds
    });
    return response.data;
};
