import {
    EXPORT_ORDERS_REQUEST,
    EXPORT_ORDERS_SUCCESS,
    EXPORT_ORDERS_FAILURE,
  } from "../actions/exportapiActions";
  
  const initialState = {
    isExporting: false,
    error: null,
  };
  
  const exportReducer = (state = initialState, action) => {
    switch (action.type) {
      case EXPORT_ORDERS_REQUEST:
        return {
          ...state,
          isExporting: true,
          error: null,
        };
  
      case EXPORT_ORDERS_SUCCESS:
        return {
          ...state,
          isExporting: false,
        };
  
      case EXPORT_ORDERS_FAILURE:
        return {
          ...state,
          isExporting: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default exportReducer;
  