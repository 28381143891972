import React, { useState} from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import './BannerPage.scss'; // Custom styles for BannerPage

const BannerPage = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [bannerData, setBannerData] = useState([
    { id: 1, uploadFile: '', imageUrl: '' },
  ]);



  // Handle file upload restriction to .jpg and .jpeg
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file && !['image/jpeg', 'image/jpg'].includes(file.type)) {
      alert('Only JPG and JPEG formats are supported.');
      return;
    }

    const updatedBannerData = [...bannerData];
    updatedBannerData[index].uploadFile = file;
    setBannerData(updatedBannerData);
  };

  const handleImageUrlChange = (index, value) => {
    const updatedBannerData = [...bannerData];
    updatedBannerData[index].imageUrl = value;
    setBannerData(updatedBannerData);
  };

  const handleEditClick = (index) => {
    //console.log('Edit clicked for', index);
    // Handle edit functionality here
  };

  const handleDeleteClick = (index) => {
    const updatedBannerData = bannerData.filter((item, i) => i !== index);
    setBannerData(updatedBannerData);
  };

  const handleSave = () => {
    //console.log('Saving banners:', bannerData);
    // Handle save functionality here
  };

  const handleAddNewBanner = () => {
    const newId = bannerData.length ? bannerData[bannerData.length - 1].id + 1 : 1;
    setBannerData([...bannerData, { id: newId, uploadFile: '', imageUrl: '' }]);
  };

  return (
    <div className="banner-page-section">
      <div className="banner-page">
        <div className="banner-container">
          {/* Upload and Image URL in one row */}
          <div className="form-row">
            <div className="form-group">
              <label>Upload</label>
              <InputComponent
                field={{ name: 'fileUpload' }}
                form={{ touched: {}, errors: {} }}
                type="file"
                accept=".jpg,.jpeg"
                className="form-control"
                onChange={(e) => handleFileChange(e, -1)} // General upload
              />
            </div>
            <div className="form-group">
              <label>Image URL</label>
              <InputComponent
                placeholder="Enter Image URL"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </div>
          </div>

         
        </div>


       {/* Add New Button */}
            <div className="action-buttons">
                <ButtonComponent
                    onClick={handleAddNewBanner}
                    text={
                    <>
                        <FontAwesomeIcon icon={faPlus} className="add-icon" />
                        Add New
                    </>
                    }
                    className="add-new-button1"
                />
            </div>

        {/* Table */}
        <div className="table-container">
          <table className="banner-table">
            <thead>
              <tr>
                <th>SI No</th>
                <th>Upload</th>
                <th>Image URL</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {bannerData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <InputComponent
                      field={{ name: 'fileUpload' }}
                      form={{ touched: {}, errors: {} }}
                      type="file"
                      accept=".jpg,.jpeg"
                      className="form-control"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                  </td>
                  <td>
                    <InputComponent
                     
                      value={item.imageUrl}
                      onChange={(e) => handleImageUrlChange(index, e.target.value)}
                    />
                  </td>
                  <td>
                    <button className="icon-button" onClick={() => handleEditClick(index)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                  </td>
                  <td>
                    <button className="icon-button" onClick={() => handleDeleteClick(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
         
        {/* Save Button */}
        <div className="save-button-container">
          <ButtonComponent text="Save" onClick={handleSave} className="save-button" />
        </div>
      </div>
    </div>
  );
};

export default BannerPage;
