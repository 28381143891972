import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {

  deleteConfigById,

  getBulkConfigs,
  deleteBulkConfigsAction,
} from "../../redux/actions/deliveryConfigActions";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import CheckboxComponent from "../../components/common/CheckboxComponent/CheckboxComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash, faHome, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import "./delivery-config-displaypage.scss";
const DeliveryConfigDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const deliveryConfigs = useSelector((state) => state.deliveryConfig.allConfigs || []);
  const products = useSelector((state) => state.products.basicProducts || []);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const fetchConfigs = () => {
      if (token) {
        dispatch(getBulkConfigs(token));
      }
    };
    fetchConfigs();
    const intervalId = setInterval(fetchConfigs, 180000); // Fetch every 3 minutes
    return () => clearInterval(intervalId);
  }, [dispatch, token]);


  const filteredConfigs = Array.isArray(deliveryConfigs)
  ? deliveryConfigs.filter((config) => {
      if (!searchTerm) return true;

      const productIdMatch = config.product_id
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const deliveryDaysMatch = Array.isArray(config.delivery_days)
        ? config.delivery_days.some((day) =>
            day.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : config.delivery_days
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      return productIdMatch || deliveryDaysMatch;
    })
  : [];



  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };
  
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = filteredConfigs.map((config) => config.config_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };
  useEffect(() => {
    if (selectedIds.length === filteredConfigs.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, filteredConfigs.length]);

  const handleNavigation = () => {
    navigate("/admin/delivery-config");
  };
  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one configuration to proceed.");
      return;
    }
    if (action === "edit") {
      navigate("/admin/delivery-config", { state: selectedIds });
      setSelectedIds([]);
    } else if (action === "delete") {
      if (window.confirm("Are you sure you want to delete the selected configurations?")) {
        //console.log("Deleting congig------:", selectedIds);
        dispatch(deleteBulkConfigsAction(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this delivery configuration?")) {
      try {
        await dispatch(deleteConfigById(id, token));
        toast.success("Delivery configuration deleted successfully.");
      } catch (error) {
       
      }
    }
  };
  const getProductName = (productId) => {
    const product = products.find((prod) => prod.product_id === productId);
    return product ? product.product_name : "Unknown Product";
  };
  const handleEditClick = (id) => {
    navigate("/admin/delivery-config", { state: id });
  };
  return (
    <div className="delivery-config-page">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <span className="breadcrumb-text">Delivery Configurations</span>
      </div>
      <div className="delivery-config-list-container"></div>
      <div className="delivery-config-list-class">
        <div className="right-section">
          <h5 className="text-heading1">Delivery Configurations</h5>
          <div className="toolbar">
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search by product ID , Delivery days"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="item-controls">
              <ButtonComponent
                text="+ Add Items"
                className="add-items-button"
              
                onClick={handleNavigation} // Call navigation function
              />
              <div className="actions-toolbar">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    className="dropdown-toggle"
                    id="actions-dropdown"
                  >
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                   
                    <Dropdown.Item onClick={() => handleActionSelect("delete")}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
         
          <div className="table-container">
            {filteredConfigs.length === 0 ? (
              <div className="no-data-message">No delivery configurations found.</div>
            ) : (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>
                      <CheckboxComponent
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>Config ID</th>
                    <th>Product Name</th>
                    <th>Delivery Days</th>
                  
                    <th>Actions</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredConfigs.map((config,index) => (
                    <tr key={config.config_id}>
                      <td>
                        <CheckboxComponent
                          checked={selectedIds.includes(config.config_id)}
                          onChange={() => handleCheckboxChange(config.config_id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{getProductName(config.product_id)}</td>
                          <td>
                            {config.delivery_days
                              ? Array.isArray(config.delivery_days)
                                ? config.delivery_days.join(", ")
                                : config.delivery_days.split(",").map(day => day.trim()).join(", ")
                              : "No Days Configured"}
                          </td>
                    
                      <td className="actions-column">
                        <button
                          className="icon-button edit-button"
                          onClick={() => handleEditClick(config.config_id)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                          
                         
                         
                        </button>
                        </td>
                        <td className="actions-column">
                        <button
                          className="icon-button delete-button"
                          onClick={() => handleDelete(config.config_id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                       
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryConfigDisplayPage;