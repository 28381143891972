import { SET_COUPON, REMOVE_COUPON,UPDATE_CART_TOTAL } from '../actions/setCouponAction';

const initialState = {
  appliedCouponId: null,
  discountValue: null,
  cartTotal: 0,
 
};

const setCouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUPON:
      return {
        ...state,
        appliedCouponId: action.payload.couponId,
        discountValue: action.payload.discountValue,
     
      };

    case REMOVE_COUPON:
      return {
        ...state,
        appliedCouponId: null,
        discountValue: null,
      };
    case UPDATE_CART_TOTAL:{
        return {
            ...state,
            cartTotal:action.payload,
          };
    }
    default:
      return state;
  }
};

export default setCouponReducer;
