// services/purchaseService.js

import apiClient from '../apiClient';

export const createPurchase = async (data, token) => {
    //console.log(data,'---data12223333333')
    const response = await apiClient.post(
        '/auth/purchase',    
        data, 
        {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getPurchase = async (purchaseId, token) => {
    
    const response = await apiClient.get(`/auth/purchase?purchase_id=${purchaseId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updatePurchase = async (data, token) => {
    //console.log("hereee",data)

    const response = await apiClient.put('/auth/purchase',
         data, {
        headers: { Authorization: token }
    });
    //console.log(response,'---response')
    return response.data;
};

export const deletePurchase = async (purchaseId, token) => {
    //console.log(purchaseId,'purchase------------------------------------')
    const response = await apiClient.delete('/auth/purchase', {
       
        headers: { Authorization: token }
    });
    return response.data;
};

export const createPurchaseItem = async (data, token) => {
    const response = await apiClient.post('/auth/purchase-item', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getPurchaseItem = async (purchaseItemId, token) => {
    const response = await apiClient.get(`/auth/purchase-item?purchase_item_id=${purchaseItemId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updatePurchaseItem = async (data, token) => {
    //console.log("hereee",data)
    const response = await apiClient.put('/auth/purchase-item', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deletePurchaseItem = async (purchaseItemId, token) => {
    const response = await apiClient.delete('/auth/purchase-item', {
        params:{purchase_item_id:purchaseItemId},
        headers: { Authorization: token }
    });
    return response.data;
};

// Bulk Operations
export const getBulkPurchases = async (token) => {
    const response = await apiClient.get('/auth/bulk-purchase', {
        headers: { Authorization: token }
    });
    //console.log(response,'response')
    return response.data.purchases;
};

export const getBulkPurchaseItems = async (token) => {
    const response = await apiClient.get('/auth/bulk-purchase-items', {
        headers: { Authorization: token }
    });
    return response.data.purchases;
};

export const createBulkPurchaseItems = async (data, token) => {
    const response = await apiClient.post('/auth/bulk-purchase-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateBulkPurchaseItems = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-purchase-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteBulkPurchaseItems = async (data, token) => {
    const response = await apiClient.delete('/auth/bulk-purchase-items', {
        headers: { Authorization: token },
        data: data
    });
    return response.data;
};

// Bulk Delete for Purchases
export const deleteBulkPurchases = async (purchaseIds, token) => {
    const response = await apiClient.delete('/auth/bulk-purchase', {
        headers: { Authorization: token },
        data: purchaseIds,
    });
    return response.data;
};
