// authReducer.js

import {
    GENERATE_TOKEN_REQUEST,
    GENERATE_TOKEN_SUCCESS,
    GENERATE_TOKEN_FAILURE,
    REQUEST_OTP_REQUEST,
    REQUEST_OTP_SUCCESS,
    REQUEST_OTP_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SET_LOGGED_IN,
    LOGOUT, // Import the LOGOUT action type
} from '../actions/authActions';

const initialState = {
    token: null,
    loading: false,
    error: null,
    isLoggedIn: false,
    user: null, // User data after login
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_TOKEN_REQUEST:
        case REQUEST_OTP_REQUEST:
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GENERATE_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.payload,
            };
        case REQUEST_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case LOGIN_SUCCESS:
            //console.log(action.payload.user_id,"userrrr")
            return {
                ...state,
                loading: false,
                user: action.payload.user_id, // User data
                token: action.payload.access_token,
                isLoggedIn: true,
            };
        case GENERATE_TOKEN_FAILURE:
        case REQUEST_OTP_FAILURE:
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isLoggedIn: false,
            };
        case SET_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: true,
            };
        case LOGOUT:
            return {
                ...state,
                token: null,
                user: null,  // Clear user data
                isLoggedIn: false,  // Set login state to false
            };
        default:
            return state;
    }
};

export default authReducer;
