import { fetchUsers, deleteUser, deleteBulkUsers } from '../../redux/actions/adminuserActions';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faHome, faPencilAlt,  faTrash } from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import Dropdown from 'react-bootstrap/Dropdown';
import './userlistPage.scss';
import { useDispatch, useSelector } from 'react-redux';


const UserDisplayPage = () => {
  const dispatch = useDispatch(); 
  const users = useSelector(state => state.usermanage.users) || [];
  const token = useSelector(state => state.adminLogin.token);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  // Fetch users initially and every 3 minutes
  useEffect(() => {
    const fetchInitialUser = () => {
      dispatch(fetchUsers(token));
    };

    fetchInitialUser(); 

    const intervalId = setInterval(fetchInitialUser, 180000);

    return () => clearInterval(intervalId); 
  }, [dispatch, token]);

  // Filter users based on role and search term
  const filteredUsers = users
    .filter(user => user.role === 'user')
    .filter(user => {
      const normalizedSearchTerm = searchTerm ? searchTerm.toLowerCase() : ''; // Ensure searchTerm is a string
      return (
        user.mobile_number.includes(normalizedSearchTerm) ||
        (user.username && user.username.toLowerCase().includes(normalizedSearchTerm)) || // Check if username exists
        (user.email && user.email.toLowerCase().includes(normalizedSearchTerm)) // Check if email exists
      );
    });

  const handleDelete = (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      dispatch(deleteUser(userId, token)); 
    }
  };
 
  const navigationOptions = {
    gridPath: '/admin/add-user', // Modify as needed
    sheetPath: '/admin/uploadpage', // Modify as needed
    from: 'user'
  };
  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
        alert("Please select at least one user to proceed.");
        return;
    }

    if (action === "edit") {
        ////console.log("Editing categories:", selectedIds);
        navigate('/admin/bulk-user', { state: selectedIds } )
        setSelectedIds([]);
    } else if (action === "delete") {
        if (window.confirm(`Are you sure you want to delete the selected users?`)) {
            ////console.log("Deleting categories:", selectedIds);
            dispatch(deleteBulkUsers(selectedIds, token));
            setSelectedIds([]);
        }
    }
};
const [tableData, setTableData] = useState([]);


const handleSelectAll = () => {
  if (selectAll) {
      setSelectedIds([]);
  } else {
      const allIds = filteredUsers.map(item => item.user_id);
      setSelectedIds(allIds);
  }
  setSelectAll(!selectAll);
};
useEffect(() => {
  if (selectedIds.length === tableData.length) {
      setSelectAll(true);
  } else {
      setSelectAll(false);
  }
}, [selectedIds, tableData.length]);


  const handleCheckboxChange = (categoryId) => {
    if (selectedIds.includes(categoryId)) {
        setSelectedIds(prev => prev.filter(id => id !== categoryId));
    } else {
        setSelectedIds(prev => [...prev, categoryId]);
    }
};
useEffect(() => {
  const newTableData = filteredUsers.map(user => ({
    ...user,
    checked: false,
  }));

  // Only update state if the new table data is different
  if (JSON.stringify(newTableData) !== JSON.stringify(tableData)) {
    setTableData(newTableData);
  }
}, [filteredUsers, tableData]);


  const handleEditClick = (id) => {
    navigate('/admin/add-user', { state: id });
  };


  return (
    <div>
      <div className="admin-user-page-layout">
      <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/user-management" className="breadcrumb-text">
          User List
        </Link>
      </div>
      <div className="user-list-container"></div>
        <div className="user-list-class">
          <div className="right-section">
          <h5 className='text-heading1'>Users List</h5>
            <div className="toolbar">
          
              
              <div className="search-wrapper">
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search by ID, Username,Email or Phone Number"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
         

            <div className="item-controls">
              <ButtonComponent text="+ Add User" variant="dark" className="add-items-button" onClick={() => setShowModal(true)} />
              <div className="actions-toolbar">
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-toggle8" id="actions-dropdown">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            </div>
            {filteredUsers.length === 0 ? (
              <div className="no-users-message">
                <p>No users found</p>
              </div>
            ) : (
              <div className="table-container">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <CheckboxComponent checked={selectAll} onChange={handleSelectAll} />
                      </th>
                      <th>ID</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Date of Birth</th>
                      <th>Gender</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((item, index) => (
                      <tr key={`${index}-${item.user_id}`}>
                        <td>
                          <CheckboxComponent
                            checked={selectedIds.includes(item.user_id)}
                            onChange={() => handleCheckboxChange(item.user_id)}
                          />
                        </td>
                        <td>{index+1}</td>
                        <td>{item.username}</td>
                        <td>{item.email}</td>
                        <td>{item.mobile_number}</td>
                        <td>{item.dob}</td>
                        <td>{item.gender}</td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleEditClick(item.user_id)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleDelete(item.user_id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} />

            {/* <div className="showing-records-pagination">
              <div className="showing-records">
                <p>
                  Showing {currentPage * 50 - 49} to {Math.min(currentPage * 50, totalRecords)} of {totalRecords} records
                </p>
              </div>
              <div className="pagination-section">
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <AdminCategoryOffcanvas show={showOffcanvas} onClose={() => setShowOffcanvas(false)} />
    </div>
  );
};

export default UserDisplayPage;
