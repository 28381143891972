import { fetchCategoryFromAPI, updateCategoryInAPI, deleteCategoryAPI, createNewCategoryAPI,
  updateCategoriesBulkAPI,deleteCategoriesBulkAPI,createCategoriesBulkAPI
 } from '../../api/services/categoryService'; // Assuming you have this API function
import { toast } from 'react-hot-toast';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';


export const BULK_DELETE_CATEGORIES_REQUEST = 'BULK_DELETE_CATEGORIES_REQUEST';
export const BULK_DELETE_CATEGORIES_SUCCESS = 'BULK_DELETE_CATEGORIES_SUCCESS';
export const BULK_DELETE_CATEGORIES_FAILURE = 'BULK_DELETE_CATEGORIES_FAILURE';

export const BULK_UPDATE_CATEGORIES_REQUEST = 'BULK_UPDATE_CATEGORIES_REQUEST';
export const BULK_UPDATE_CATEGORIES_SUCCESS = 'BULK_UPDATE_CATEGORIES_SUCCESS';
export const BULK_UPDATE_CATEGORIES_FAILURE = 'BULK_UPDATE_CATEGORIES_FAILURE';

export const BULK_CREATE_CATEGORIES_REQUEST = 'BULK_CREATE_CATEGORIES_REQUEST';
export const BULK_CREATE_CATEGORIES_SUCCESS = 'BULK_CREATE_CATEGORIES_SUCCESS';
export const BULK_CREATE_CATEGORIES_FAILURE = 'BULK_CREATE_CATEGORIES_FAILURE';



// Fetch categories with a caching mechanism similar to coupons
export const fetchCategory = (token) => async (dispatch, getState) => {
  const { categories = [] } = getState().category.categoryList || []; // Guard for undefined categories
 
  const timestamp = getState().category.timestamp;
  const FIVE_MINUTES = 5 * 60 * 1000;

  // If categories exist and are within the 5-minute limit, skip fetch
  if (categories.length > 0 && timestamp) {
    const now = Date.now();
    if (now - timestamp < FIVE_MINUTES) {
      return; // Data is still valid, no need to fetch
    } else {
      // Clear categories if data is older than 5 minutes
      dispatch({ type: CLEAR_CATEGORIES });
    }
  }


  
  dispatch({ type: FETCH_CATEGORIES_REQUEST });
  try {
    const fetchedCategories = await fetchCategoryFromAPI(token); // Replace with your API call
    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: { categories: fetchedCategories, timestamp: Date.now() }, // Update with new categories and timestamp
    });
 
  } catch (error) {
    dispatch({ type: FETCH_CATEGORIES_FAILURE, payload: error.message });

  }
};

// Action to clear categories manually
export const clearCategories = () => ({
  type: CLEAR_CATEGORIES,
});

// Action to delete a category
export const deleteCategory = (categoryId,token) => async (dispatch) => {
  dispatch({ type: DELETE_CATEGORY_REQUEST });

  // try {
    await deleteCategoryAPI(categoryId,token); // Replace with your API call
    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: categoryId, // Pass the deleted category ID to the reducer
    });
    toast.success('Category deleted successfully!');

 
};

// Action to update a category
export const updateCategory = (categoryId, updatedData,token) => async (dispatch) => {
  dispatch({ type: UPDATE_CATEGORY_REQUEST });

  try {
    const updatedCategory = await updateCategoryInAPI(updatedData,token); // Replace with your API call
   
    return { success: true };
  } catch (error) {
    dispatch({ type: UPDATE_CATEGORY_FAILURE, payload: error.message });
    return { success: false };
  }
};

// Action to create a category
export const createCategory = (newCategoryData,token) => async (dispatch) => {
  dispatch({ type: CREATE_CATEGORY_REQUEST });

  try {
    const createdCategory = await createNewCategoryAPI(newCategoryData,token); // Replace with your API call
    //console.log("create category", createdCategory)
    
    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: newCategoryData, // Pass the created category data as payload
    });
    return { success: true };
  } catch (error) {
    dispatch({ type: CREATE_CATEGORY_FAILURE, payload: error.message });
   
    return { success: false };
  }
}


export const bulkDeleteCategories = (categoryIds, token) => async (dispatch) => {
  dispatch({ type: BULK_DELETE_CATEGORIES_REQUEST });

  try {
    // Call API to bulk delete
    await deleteCategoriesBulkAPI(categoryIds, token);
    
    dispatch({
      type: BULK_DELETE_CATEGORIES_SUCCESS,
      payload: categoryIds, // Pass the deleted category IDs as payload
    });
    
    toast.success('Categories deleted successfully!');
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_DELETE_CATEGORIES_FAILURE,
      payload: error.message,
    });
    toast.error(`Failed to delete categories: ${error.message}`);
    return { success: false};
  }
};

export const bulkUpdateCategories = (categoriesData, token) => async (dispatch) => {
  dispatch({ type: BULK_UPDATE_CATEGORIES_REQUEST });

  try {
    // Call API to bulk update categories
    const updatedCategories = await updateCategoriesBulkAPI(categoriesData, token);
    
    dispatch({
      type: BULK_UPDATE_CATEGORIES_SUCCESS,
      payload: categoriesData, // Pass the updated categories data as payload
    });

    toast.success('Categories updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_UPDATE_CATEGORIES_FAILURE,
      payload: error.message,
    });
    toast.error(`Failed to update categories: ${error.message}`);
    return { success: false };
  }
};

export const bulkCreateCategories = (newCategoriesData, token) => async (dispatch) => {
  dispatch({ type: BULK_CREATE_CATEGORIES_REQUEST });

  try {
    // Call the API to bulk create categories
    const createdCategories = await createCategoriesBulkAPI(newCategoriesData, token);

    dispatch({
      type: BULK_CREATE_CATEGORIES_SUCCESS,
      payload: newCategoriesData, // Pass the created categories data as payload
    });

    toast.success('Categories created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_CREATE_CATEGORIES_FAILURE,
      payload: error.message,
    });

    toast.error(`Failed to create categories: ${error.message}`);
    return { success: false };
  }
};
