import Cookies from 'js-cookie';

// Get cart data from cookies
export const getCookieCart = () => {
    const cart = Cookies.get('cart');
    return cart ? JSON.parse(cart) : [];
};

// Save cart data to cookies
export const saveCookieCart = (cartData) => {
    Cookies.set('cart', JSON.stringify(cartData), { expires: 7 }); // Expires in 7 days
};

// Clear cart data from cookies
export const clearCookieCart = () => {
    Cookies.remove('cart');
};
