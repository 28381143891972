// sections/EmailModal/EmailModal.jsx
import React from 'react';
import InputComponent from '../../../components/common/InputComponent/InputComponent'; // Import the InputComponent
import './EmailModal.scss';

const EmailModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  // Placeholder function for handling form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
  };

  return (
    <div className="email-modal-overlay">
      <div className="email-modal-content">
        <p className="email-header">Add your Email ID</p>
        {/* Replace input with InputComponent */}
        <InputComponent
          field={{ name: 'email' }}
          form={{ touched: {}, errors: {} }} // Example of empty touched/errors objects; replace with actual state or form library integration
          placeholder="Enter your Email ID"
          id="email"
        />
        <div className="modal-buttons">
          <button className="cancel-btn" onClick={onClose}>Cancel</button>
          <button className="save-btn" onClick={handleSubmit}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
