// actions/userActions.js
import { toast } from 'react-hot-toast';
import {
  fetchUserFromAPI,
  createNewUserAPI,
  deleteUserAPI,
  updateUserInAPI,
  createNewBulkUserAPI,
  deletBulkUserAPI,
  updateBulkUserInAPI,
  fetchSingleUserFromAPI
} from '../../api/services/userService';

// Action types
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const BULK_CREATE_USERS_REQUEST = 'BULK_CREATE_USERS_REQUEST';
export const BULK_CREATE_USERS_SUCCESS = 'BULK_CREATE_USERS_SUCCESS';
export const BULK_CREATE_USERS_FAILURE = 'BULK_CREATE_USERS_FAILURE';
export const BULK_DELETE_USERS_REQUEST = 'BULK_DELETE_USERS_REQUEST';
export const BULK_DELETE_USERS_SUCCESS = 'BULK_DELETE_USERS_SUCCESS';
export const BULK_DELETE_USERS_FAILURE = 'BULK_DELETE_USERS_FAILURE';
export const BULK_UPDATE_USERS_REQUEST = 'BULK_UPDATE_USERS_REQUEST';
export const BULK_UPDATE_USERS_SUCCESS = 'BULK_UPDATE_USERS_SUCCESS';
export const BULK_UPDATE_USERS_FAILURE = 'BULK_UPDATE_USERS_FAILURE';
export const FETCH_SINGLE_USER_REQUEST = 'FETCH_SINGLE_USER_REQUEST';
export const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';
export const FETCH_SINGLE_USER_FAILURE = 'FETCH_SINGLE_USER_FAILURE';

// Action Creators

export const fetchUsers = (token) => async (dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  try {
    const users = await fetchUserFromAPI(token);
    dispatch({ type: FETCH_USERS_SUCCESS, payload: users });

  } catch (error) {
    dispatch({ type: FETCH_USERS_FAILURE, payload: error.message });

  }
};
export const fetchSingleUser = (userId, token) => async (dispatch) => {
  dispatch({ type: FETCH_SINGLE_USER_REQUEST });
  try {
    const user = await fetchSingleUserFromAPI(userId, token);
    //console.log(user,'in response userrrrrrrrrrrrrrrrrrrrrrrrr');
    dispatch({ type: FETCH_SINGLE_USER_SUCCESS, payload: [user] });
    return user;
  } catch (error) {
    dispatch({ type: FETCH_SINGLE_USER_FAILURE, payload: error.message });
    return null;
  }
};

export const createUser = (newUser, token) => async (dispatch) => {
  dispatch({ type: CREATE_USER_REQUEST });
  try {
    const response = await createNewUserAPI(newUser, token);
    // dispatch({ type: CREATE_USER_SUCCESS, payload: newUser });
    toast.success('User created successfully');
    return true;
  } catch (error) {
    dispatch({ type: CREATE_USER_FAILURE, payload: error.message });
    toast.error('Failed to create user');
    return false;
  }
};

export const updateUser = (userId, updatedData, token) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_REQUEST });
  try {
    await updateUserInAPI(userId, updatedData, token);
    dispatch({ type: UPDATE_USER_SUCCESS, payload: { userId, updatedData } });

    return true;
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAILURE, payload: error.message });
    toast.error('Failed to update user');
    return false;
  }
};

export const deleteUser = (userId, token) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });
  try {
    await deleteUserAPI(userId, token);
    dispatch({ type: DELETE_USER_SUCCESS, payload: userId });
    // toast.success('User deleted successfully');
    return true;
  } catch (error) {
    dispatch({ type: DELETE_USER_FAILURE, payload: error.message });
    // toast.error('Failed to delete user');
    return false;
  }
};

// Bulk actions
export const createBulkUsers = (newUsers, token) => async (dispatch) => {
  dispatch({ type: BULK_CREATE_USERS_REQUEST });
  //console.log(newUsers,'---------new userssssss');
  try {
    await createNewBulkUserAPI(newUsers, token);
    
    toast.success('Bulk users created successfully');
    return true;
  } catch (error) {
    dispatch({ type: BULK_CREATE_USERS_FAILURE, payload: error.message });
    toast.error('Failed to create bulk users');
    return false;
  }
};

export const deleteBulkUsers = (userIds, token) => async (dispatch) => {
  dispatch({ type: BULK_DELETE_USERS_REQUEST });
  try {
    await deletBulkUserAPI(userIds, token);
    dispatch({ type: BULK_DELETE_USERS_SUCCESS, payload: userIds });
    // toast.success('Bulk users deleted successfully');
    return true;
  } catch (error) {
    dispatch({ type: BULK_DELETE_USERS_FAILURE, payload: error.message });
    // toast.error('Failed to delete bulk users');
    return false;
  }
};

export const updateBulkUsers = (updatedData, token) => async (dispatch) => {
  dispatch({ type: BULK_UPDATE_USERS_REQUEST });
  
  try {
    await updateBulkUserInAPI(updatedData, token);
    dispatch({ type: BULK_UPDATE_USERS_SUCCESS, payload:updatedData  });
    toast.success('Bulk users updated successfully');
    return true;
  } catch (error) {
    dispatch({ type: BULK_UPDATE_USERS_FAILURE, payload: error.message });
    toast.error('Failed to update bulk users');
    return false;
  }
};
