import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage as the default storage
import { combineReducers } from 'redux';
import productReducer from './reducers/productReducer';
import navbarReducer from './reducers/secondarynavbarReducer';
import couponReducer from './reducers/couponReducer';
import discountReducer from './reducers/discountReducer';
import categoryReducer from './reducers/categoryReducer';
import brandReducer from './reducers/brandReducer';
import usernavReducer from './reducers/usermanagenavbarReducer';
import adminReducer from './reducers/adminReducer';
import adminuserReducer from './reducers/adminuserRedux';
import deliveryReducer from './reducers/deliveryReducer';
import stockpurchaseReducer from './reducers/stockpurchaseReducer';
import authReducer from './reducers/authReducer';
import couponAllocationReducer from './reducers/couponAllocationReducer';
import purchaseReducer from './reducers/purchaseReqReducer';
import supplierReducer from './reducers/supplierReducer';
import adminRegisterReducer from './reducers/adminRegisterReducer';
import adminLoginReducer from './reducers/adminLoginReducer';
import cartReducer from './reducers/Addtocart';
import stockReducer from './reducers/stockpurchaseReducer';
import bannerReducer from './reducers/bannerReducer';
import offerAllocationReducer from './reducers/AllocateOfferReducer';
import locationReducer from './reducers/locationReducer';
import ordersReducer from './reducers/orderReducer';
import addressReducer from './reducers/AddressReducer';
import setcouponReducer from './reducers/setCouponReducer';
import fileUploadReducer from './reducers/fileReducer';
import { imageReducer } from './reducers/fetchimage';
import exportReducer from './reducers/exportapiReducer';
import inventoryThresholdReducer from './reducers/inventoryThresholdReducer';
import deliveryConfigReducer from './reducers/deliveryConfigReducer';
import subscriptionReducer from './reducers/subscriptionReducer'; 
import  notificationReducer  from './reducers/NotificationCreationReducer';
import expiryAlertReducer from './reducers/expiryAlertReducer';
import businessReducer from './reducers/businessReducer';
import subscriptionNavbarReducer from './reducers/subscriptionNavbarReducer';  
import processDeliveriesReducer from './reducers/ProcessDeliveriesReducer';

// Redux persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'products', 'purchases', 'category', 'brand', 'image', 'allocateCoupon','coupon',' discount', 'purchase', 'supplier','allocateOffer', 'adminLogin', 'cart', 'stock', 'usermanage', 'location', 'orders', 'address', 'fileUpload', 'setcoupon','businessInfo'], // Only persist the 'products' state
};

// Root reducer with logout handling
const appReducer = combineReducers({
  products: productReducer,
  purchases: purchaseReducer,
  navbar: navbarReducer,
  coupon: couponReducer,
  discount: discountReducer,
  category: categoryReducer,
  brand: brandReducer,
  usermanagenav: usernavReducer,
  admin: adminReducer,
  usermanage: adminuserReducer,
  delivery: deliveryReducer,
  stockpurchases: stockpurchaseReducer,
  auth: authReducer,
  allocateCoupon: couponAllocationReducer,
  allocateOffer: offerAllocationReducer,
  purchase: purchaseReducer,
  supplier: supplierReducer,
  adminRegister: adminRegisterReducer,
  adminLogin: adminLoginReducer,
  cart: cartReducer,
  stock: stockReducer,
  banner: bannerReducer,
  location: locationReducer,
  orders: ordersReducer,
  address: addressReducer,
  setcoupon: setcouponReducer,
  fileUpload: fileUploadReducer,
  image: imageReducer,
  exportapi: exportReducer,
  inventoryThreshold:inventoryThresholdReducer,
  deliveryConfig: deliveryConfigReducer,
  expiryAlert: expiryAlertReducer, 
  subscription: subscriptionReducer,
  businessInfo:businessReducer,
  subscriptionnavbarState: subscriptionNavbarReducer,
  notifications: notificationReducer,
  processdelivery:processDeliveriesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    storage.removeItem('persist:root'); // Clear persisted storage
    state = undefined; // Reset state to initial state
  }
  return appReducer(state, action);
};

// Apply the persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with middleware
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializability check for redux-persist
    }),
});

export const persistor = persistStore(store);
export default store;
