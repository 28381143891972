import React from "react";
import "./refund.scss";

const RefundPolicy = () => {
  return (
    <div className="policy-container">
      <div className="header">
        <h1>Refund Policy</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Refund Policy</h2>
          <p className="effective-date">Effective Date: February 28, 2025</p>
          <p>At Fresh & Pure Dairy & Agro Products, we aim to ensure customer satisfaction. Please review the following refund guidelines:</p>
          
          <h3>Refund for Spoiled or Damaged Products</h3>
          <ul>
            <li><strong>Perishable Products (Milk, etc.):</strong> Refund requests must be raised on the same day of delivery before 11:00 AM. A photo of the spoiled product in its original container or during use is required for verification.</li>
            <li><strong>Non-Perishable Products:</strong> Refunds will be processed if the product is damaged or defective. A photo of the damaged product and packaging must be submitted.</li>
          </ul>
          
          <h3>Refund Processing</h3>
          <p>Refunds will be credited back to the original payment method or provided as store credit based on customer preference.</p>
          <p>Refunds may take 3-5 business days to process after approval.</p>
         
         
          <h3>Conditions</h3>
          <p>Refunds are subject to verification and approval by our team. We reserve the right to deny refunds for unsupported claims or insufficient evidence.</p>
          
          <h3>Contact Us</h3>
          <p>For refund assistance, contact us at:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> freshpure.in@gmail.com</li>
            <li><strong>Phone:</strong> 094429 17151</li>
          </ul>
        </div>
        
        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>Contact our sales staff with any questions, and they will provide you with extensive clarification.</p>
          <p className="contact-number">094429 17151</p>
          <p className="contact-email">freshpure.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
