import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel, Row, Col } from 'react-bootstrap';
import ShopCard from '../../components/common/ShopCard/ShopCard';
import './ShoppingCard.scss';
import './skeleton-loader.scss'; // New skeleton loader styles
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategory } from '../../redux/actions/categoryAction';
import { fetchBrand } from '../../redux/actions/brandAction';
import { fetchPresignedUrl } from '../../redux/actions/fetchImage';

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const ShopCarousel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [cardsPerRow, setCardsPerRow] = useState(2);

  const token = useSelector((state) => state.auth.token);
  const categories = useSelector((state) => state.category.categoryList.categories) || [];
  const brands = useSelector((state) => state.brand.brandList.brands) || [];
  const categoryUrls = useSelector((state) => state.image.urls.categories) || {};
  const brandUrls = useSelector((state) => state.image.urls.brands) || {};
  useEffect(() => {
    // Load categories and brands
    const loadCategoriesAndBrands = async () => {
      setLoadingCategories(true);
      setLoadingBrands(true);

      await Promise.all([dispatch(fetchCategory(token)), dispatch(fetchBrand(token))]);

      setLoadingCategories(false);
      setLoadingBrands(false);
    };

    loadCategoriesAndBrands();
  }, [dispatch, token]);

  useEffect(() => {
    if (!loadingCategories && categories.length > 0) {
      categories.forEach((category) => {
        if (category?.category_id && category?.image_url) {
          dispatch(fetchPresignedUrl("categories", category.category_id, category.image_url, token));
        }
      });
    }
  
    if (!loadingBrands && brands.length > 0) {
      brands.forEach((brand) => {
        if (brand?.brand_id && brand?.image_url) {
          dispatch(fetchPresignedUrl("brands", brand.brand_id, brand.image_url, token));
        }
      });
    }
  }, [categories, brands, loadingCategories, loadingBrands, token]);
  


  useEffect(() => {
    const updateCardsPerRow = () => {
      const width = window.innerWidth;
      if (width <= 575.98) setCardsPerRow(2);
      else if (width >= 576 && width < 768) setCardsPerRow(3);
      else if (width >= 768 && width < 992) setCardsPerRow(4);
      else setCardsPerRow(6);
    };
    window.addEventListener('resize', updateCardsPerRow);
    updateCardsPerRow();
    return () => window.removeEventListener('resize', updateCardsPerRow);
  }, []);

  const validCategories = categories.filter(
    (category) => category && category.category_id && category.status === "active"
  );

  const validBrands = brands.filter(
    (brand) => brand && brand.brand_id && brand.status === "active"
  );

  const categoryChunks = chunkArray(
    validCategories.map((category) => ({
      ...category,
      url: categoryUrls[category.category_id] || "",
    })),
    cardsPerRow
  );

  const brandChunks = chunkArray(
    validBrands.map((brand) => ({
      ...brand,
      url: brandUrls[brand.brand_id] || "",
    })),
    cardsPerRow
  );

  const handleCardClick = (category) => {
    navigate(`/categories/${category.name}`, { state: category.category_id });
  };

  const handleBrandClick = (brand) => {
    navigate(`/brandproducts/${brand.name}`, { state: brand.brand_id });
  };

  // return (
  //   <div className="shop-section">
  //     <div className="sc carousel-section">
  //       <h5 className="sc section-title">Shop by category</h5>
  //       {loadingCategories ? (
  //         <Row className="skeleton-loader-row">
  //           {Array.from({ length: cardsPerRow }).map((_, index) => (
  //             <Col key={index} className="skeleton-loader-col">
  //               <div className="skeleton-card"></div>
  //             </Col>
  //           ))}
  //         </Row>
  //       ) : validCategories.length === 0 ? (
  //         <p>No Categories Available</p>
  //       ) : (
  //         <Carousel controls={true} indicators={false} interval={null}>
  //           {categoryChunks.map((chunk, index) => (
  //             <Carousel.Item key={index}>
  //               <Row className="sc carousel-row">
  //                 {chunk.map((category) => (
  //                   <Col key={category.category_id} className="sc carousel-col" xs={6} sm={3} md={3} lg={2} xl={2} xxl={2}>
  //                     <ShopCard
  //                       title={category.name || ''}
  //                       image={category.url || ''}
                     
  //                       onClick={() => handleCardClick(category)}
  //                     />
  //                   </Col>
  //                 ))}
  //               </Row>
  //             </Carousel.Item>
  //           ))}
  //         </Carousel>
  //       )}
  //     </div>

  //     <div className="sb carousel-section">
  //       <h5 className="sb section-title">Shop by brands</h5>
  //       {loadingBrands ? (
  //         <Row className="skeleton-loader-row">
  //           {Array.from({ length: cardsPerRow }).map((_, index) => (
  //             <Col key={index} className="skeleton-loader-col">
  //               <div className="skeleton-card"></div>
  //             </Col>
  //           ))}
  //         </Row>
  //       ) : validBrands.length === 0 ? (
  //         <p>No Brands Available</p>
  //       ) : (
  //         <Carousel controls={true} indicators={false} interval={null}>
  //           {brandChunks.map((chunk, index) => (
  //             <Carousel.Item key={index}>
  //               <Row className="sb carousel-row">
  //                 {chunk.map((brand) => (
  //                   <Col key={brand.brand_id || index} className="sb carousel-col" xs={6} sm={3} md={3} lg={2} xl={2} xxl={2}>
  //                     <ShopCard
  //                       title={brand.name || ''}
  //                       image={brand.url || ''}
                     
  //                       onClick={() => handleBrandClick(brand)}
  //                     />
  //                   </Col>
  //                 ))}
  //               </Row>
  //             </Carousel.Item>
  //           ))}
  //         </Carousel>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default ShopCarousel;
