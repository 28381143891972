import React from "react";
import "./termsConditions.scss";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <div className="header">
        <h1>Terms & Conditions</h1>
      </div>
      <div className="template-box">
        <div className="content-box">
          <h2>Terms of Use</h2>
          <p className="effective-date">Last updated on Feburary 28, 2025</p>
          <p>Welcome to Fresh & Pure Dairy & Agro Products! By accessing our website or mobile app, you agree to these Terms and Conditions. If you do not agree with these terms, please stop using our services.</p>
          <h3>Scope</h3>
          <p>
            These terms apply to all users of the Fresh & Pure Dairy & Agro Products website and mobile app.
          </p>
          <h3>Usage of Services</h3>
          <p>
            The platform provides e-commerce services for dairy and agro products. All orders placed are subject to product availability and confirmation.
          </p>
          <p>
            Unauthorized use of our services may result in a claim for damages or legal action.
          </p>
          <h3>Intellectual Property</h3>
          <p>
            Our website contains material owned by or licensed to us, including but not limited to design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice.
          </p>
          <h3>Account Responsibility</h3>
          <p>
            Users are responsible for maintaining the confidentiality of their login credentials.
          </p>
          <p>
            Any misuse or unauthorized access of an account should be reported immediately.
          </p>
          <h3>Liability & Warranty Disclaimer</h3>
          <p>
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found on this website for any particular purpose.
          </p>
          <p>
            You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
          </p>
          <h3>External Links</h3>
          <p>
            From time to time, our website may include links to other websites. These links are provided for your convenience to provide further information.
          </p>
          <p>
            You may not create a link to our website from another website or document without Fresh & Pure Dairy & Agro Products' prior written consent.
          </p>
          <h3>Governing Law</h3>
          <p>
            Any dispute arising out of the use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India.
          </p>
          <h3>Modifications</h3>
          <p>
            We may update these Terms and Conditions without prior notice. Continued use of our services indicates acceptance of any updates.
          </p>
          <h3>Contact Information</h3>
          <p>For assistance, contact us at:</p>
          <ul className="contact-list">
            <li><strong>Email:</strong> freshpure.in@gmail.com</li>
            <li><strong>Phone:</strong> 094429 17151</li>
          </ul>
        </div>
        <div className="contact-box">
          <h2>Need Help?</h2>
          <p>Contact our sales staff with any questions, and they will provide you with extensive clarification.</p>
          <p className="contact-number">094429 17151</p>
          <p className="contact-email">freshpure.in@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;