import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './AdminCategory.scss';

const AdminCategory = ({ onCategorySelect }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const categories = useSelector((state) => state.category.categoryList.categories) || [];

  const handleCategoryClick = (category) => {
    setActiveCategory(category ? category.category_id : null);
    onCategorySelect(category ? category.category_id : '');
  };

  return (
    <div className="category-container">
      <h6 className="category-title">Categories</h6>
      <hr />
      <ul className="category-list">
        <li
          className={`category-item ${activeCategory === null ? 'active' : ''}`}
          onClick={() => handleCategoryClick(null)}
        >
          All
        </li>
        {categories.map((category) => (
          <li
            key={category.category_id}
            className={`category-item ${activeCategory === category.category_id ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminCategory;
