// actions/supplierActions.js
import {
  fetchSuppliersFromAPI,
  createNewSupplierAPI,
  updateSupplierInAPI,
  deleteSupplierAPI,
  createBulkSuppliersAPI,
  updateBulkSuppliersAPI,
  deleteBulkSuppliersAPI,
} from '../../api/services/supplierService';

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const BULK_CREATE_SUPPLIERS = 'BULK_CREATE_SUPPLIERS';
export const BULK_UPDATE_SUPPLIERS = 'BULK_UPDATE_SUPPLIERS';
export const BULK_DELETE_SUPPLIERS = 'BULK_DELETE_SUPPLIERS';
export const SUPPLIER_ERROR = 'SUPPLIER_ERROR';

export const fetchSuppliers = (supplierId, token) => async (dispatch) => {
  try {
    const suppliers = await fetchSuppliersFromAPI(supplierId, token);
    //console.log('suppliersss',suppliers)
    dispatch({ type: FETCH_SUPPLIERS, payload: suppliers });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};

export const createSupplier = (supplierData, token) => async (dispatch) => {
  try {
    const newSupplier = await createNewSupplierAPI(supplierData, token);
    dispatch({ type: CREATE_SUPPLIER, payload: supplierData });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};

export const updateSupplier = (supplierData, token) => async (dispatch) => {
  try {
    const updatedSupplier = await updateSupplierInAPI(supplierData, token);
    dispatch({ type: UPDATE_SUPPLIER, payload: supplierData });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};

export const deleteSupplier = (supplierId, token) => async (dispatch) => {
  try {
    await deleteSupplierAPI(supplierId, token);
    dispatch({ type: DELETE_SUPPLIER, payload: supplierId });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};

export const bulkCreateSuppliers = (suppliersData, token) => async (dispatch) => {
  try {
    const createdSuppliers = await createBulkSuppliersAPI(suppliersData, token);
    dispatch({ type: BULK_CREATE_SUPPLIERS, payload: suppliersData });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};

export const bulkUpdateSuppliers = (suppliersData, token) => async (dispatch) => {
  try {
    const updatedSuppliers = await updateBulkSuppliersAPI(suppliersData, token);
    dispatch({ type: BULK_UPDATE_SUPPLIERS, payload: suppliersData });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};

export const bulkDeleteSuppliers = (supplierIds, token) => async (dispatch) => {
  try {
    await deleteBulkSuppliersAPI(supplierIds, token);
    dispatch({ type: BULK_DELETE_SUPPLIERS, payload: supplierIds });
    return true;
  } catch (error) {
    dispatch({ type: SUPPLIER_ERROR, payload: error.message });
    return false;
  }
};
