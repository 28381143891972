import apiClient from '../apiClient';

export const createProcessDeliveries = async (token) => {
    try{
        //console.log('Creating process deliveries', token);
        const response = await apiClient.post(
            '/auth/process-deliveries',
            {}, // Request body (if required, otherwise keep it as an empty object)
            {
                headers: { Authorization: token }, // Headers go in the third argument
            }
        );
       
    }
    catch(error){
        //console.error('Error creating process deliveries:', error);
        throw error;
    }
   
};