import { fetchBrand, deleteBrand, bulkDeleteBrands } from '../../redux/actions/brandAction';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faSearch, faPencilAlt, faBars, faTrash ,faHome} from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './brandlistPage.scss'; // Make sure you create a stylesheet for brands
import { useDispatch, useSelector } from 'react-redux';

const BrandDisplayPage = () => {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();

  const navigationOptions = {
    gridPath: '/admin/add-brand',
    sheetPath: '/admin/uploadpage',
    from: 'brand',
  };
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const token = useSelector(state => state.adminLogin.token);
  const brands = useSelector(state => state.brand.brandList.brands) || [];

  useEffect(() => {
    const fetchInitialBrands = () => {
      dispatch(fetchBrand(token));
    };

    fetchInitialBrands(); 

    const intervalId = setInterval(fetchInitialBrands, 180000); // Fetch every 3 minutes

    return () => clearInterval(intervalId);
  }, [dispatch, token]);

  useEffect(() => {
    setTableData(brands.map(brand => ({
      ...brand,
      checked: false, 
    })));
  }, [brands]);

  const handleDelete = async (brandId) => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      try {
        dispatch(deleteBrand(brandId, token)); 
      } catch (error) {
        //console.error('Failed to delete brand', error);
      }
    }
  };

  const [tableData, setTableData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 4;
  const totalRecords = 199;

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert('Please select at least one brand to proceed.');
      return;
    }

    if (action === 'edit') {
      navigate('/admin/bulk-brand', { state: selectedIds });
      setSelectedIds([]);
    } else if (action === 'delete') {
      if (window.confirm('Are you sure you want to delete the selected brands?')) {
        dispatch(bulkDeleteBrands(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (brand.description && brand.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleCheckboxChange = (brandId) => {
    if (selectedIds.includes(brandId)) {
      setSelectedIds((prev) => prev.filter(id => id !== brandId));
    } else {
      setSelectedIds((prev) => [...prev, brandId]);
    }
  };

  const handleEditClick = (id) => {
    navigate('/admin/add-brand', { state: id });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = brands.map(item => item.brand_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedIds.length === tableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, tableData.length]);

  return (
    <div>
      <div className="admin-brand-page-layout">
      <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <span  className="breadcrumb-text">
          Brand List
        </span>
        {/* <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Info</span> */}
      </div>
      <div className="brandlist-container"></div>
        <div className="admin-brand-page-container">
          <div className="right-section">
          <h5 className='text-heading1'>Brand List</h5>
            <div className="toolbar">
          
              <div className="search-wrapper" onClick={() => setShowSearchFilters(!showSearchFilters)}>
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="item-controls">
                <ButtonComponent text="+ Add Brands" variant="dark" className="add-items-button" onClick={() => setShowModal(true)} />
                <div className="actions-toolbar">
                  <Dropdown>
                  <Dropdown.Toggle variant="primary" id="actions-dropdown" className='dropdown-toggle9'>
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleActionSelect('edit')}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleActionSelect('delete')}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="table-container">
              {filteredBrands.length === 0 ? (
                <div className="no-brands-message">No brands found.</div>
              ) : (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <CheckboxComponent checked={selectAll} onChange={handleSelectAll} />
                      </th>
                      <th>ID</th>
                      <th>Brand Name</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                     
                      <th>Actions</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBrands.map((item, index) => (
                      <tr key={`${index}-${item.brand_id}`}>
                        <td>
                          <CheckboxComponent
                            checked={selectedIds.includes(item.brand_id)}
                            onChange={() => handleCheckboxChange(item.brand_id)}
                          />
                        </td>
                        <td>
                          {index+1} 
                        </td>
                        <td>
                          {item.name}
                        </td>
                        <td>
                          {item.description}
                        </td>
                        <td>
                         {item.status}
                        </td>
                        <td>
                         {item.created_at}
                        </td>
                        <td>
                         {item.updated_at}
                        </td>
                       
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleEditClick(item.brand_id)}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </button>
                        </td>
                        <td className="actions-column">
                          <button className="icon-button" onClick={() => handleDelete(item.brand_id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} />
          
          </div>
        </div>
      </div>
      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
    </div>
  );
};

export default BrandDisplayPage;
