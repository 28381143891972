// SuccessAnimationModal.jsx
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/success.json'; // Replace with the correct path

const SuccessAnimationModal = ({ show, handleClose }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Lottie options={defaultOptions} height={200} width={200} />
            </Modal.Body>
        </Modal>
    );
};

export default SuccessAnimationModal;
