import {  FETCH_SUBSCRIPTIONS_SUCCESS,
    FETCH_SUBSCRIPTIONS_ERROR,
    ADD_SUBSCRIPTION_SUCCESS,
    ADD_SUBSCRIPTION_ERROR,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_ERROR,
    DELETE_SUBSCRIPTION_SUCCESS,
    DELETE_SUBSCRIPTION_ERROR,
    BULK_ADD_SUBSCRIPTIONS_SUCCESS,
    BULK_ADD_SUBSCRIPTIONS_ERROR,
    BULK_GET_SUBSCRIPTIONS_SUCCESS,
    BULK_GET_SUBSCRIPTIONS_ERROR,
    BULK_UPDATE_SUBSCRIPTIONS_SUCCESS,
    BULK_UPDATE_SUBSCRIPTIONS_ERROR,
    BULK_DELETE_SUBSCRIPTIONS_SUCCESS,
    BULK_DELETE_SUBSCRIPTIONS_ERROR, } from "../actions/subscriptionAction";
  const initialState = {
    subscriptionList: [],
    timestamp: null,
    loading: false,
    error: null,
  };
  
  const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          subscriptionList: action.payload.subscriptions,
          timestamp: action.payload.timestamp,
          error: null, // Reset error
        };
      
  
      case FETCH_SUBSCRIPTIONS_ERROR:
        return { ...state, loading: false, error: action.payload };
  
      case ADD_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          loading: false,
          subscriptionList: [...state.subscriptionList, action.payload],
        };
  
      case ADD_SUBSCRIPTION_ERROR:
        return { ...state, loading: false, error: action.payload };
  
        case UPDATE_SUBSCRIPTION_SUCCESS:
          return {
            ...state,
            loading: false,
            subscriptionList: state.subscriptionList.map(subscription =>
              subscription.subscription_id === action.payload.subscription_id ? action.payload : subscription
            ),
            error: null,
          };
  
      case UPDATE_SUBSCRIPTION_ERROR:
        return { ...state, loading: false, error: action.payload };
  
        case DELETE_SUBSCRIPTION_SUCCESS:
          return {
            ...state,
            loading: false,
            subscriptionList: state.subscriptionList.filter(subscription => subscription.subscription_id !== action.payload),
            error: null,
          };
    
      case DELETE_SUBSCRIPTION_ERROR:
        return { ...state, loading: false, error: action.payload };
  
        case BULK_ADD_SUBSCRIPTIONS_SUCCESS:
          return {
            ...state,
            loading: false,
            subscriptionList: [...state.subscriptionList, ...action.payload],
            error: null,
          };
  
      case BULK_ADD_SUBSCRIPTIONS_ERROR:
        return { ...state, loading: false, error: action.payload };
  
      case BULK_GET_SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          subscriptionList: action.payload.subscriptions,
          timestamp: action.payload.timestamp,
        };
  
      case BULK_GET_SUBSCRIPTIONS_ERROR:
        return { ...state, loading: false, error: action.payload };
  
        case BULK_UPDATE_SUBSCRIPTIONS_SUCCESS:
          return {
            ...state,
            loading: false,
            subscriptionList: state.subscriptionList.map(subscription =>
              action.payload.find(updated => updated.subscription_id === subscription.subscription_id) || subscription
            ),
            error: null,
          };
      case BULK_UPDATE_SUBSCRIPTIONS_ERROR:
        return { ...state, loading: false, error: action.payload };
  
        case BULK_DELETE_SUBSCRIPTIONS_SUCCESS:
          return {
            ...state,
            loading: false,
            subscriptionList: state.subscriptionList.filter(subscription => !action.payload.includes(subscription.subscription_id)),
            error: null,
          };
  
      case BULK_DELETE_SUBSCRIPTIONS_ERROR:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default subscriptionReducer;
  