import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
  getBulkExpiryAlerts,
  deleteExpiryAlertByIdAction,
  deleteBulkExpiryAlertsAction
} from '../../redux/actions/expiryAlertActions';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTrash,
  faHome,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import './expiry-alert-displaypage.scss';

const ExpiryAlertDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch token from the state
  const token = useSelector((state) => state.auth.token);
  const expiryAlerts = useSelector((state) => state.expiryAlert.allExpiryAlerts || []);
 
  // State variables
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Fetch expiry alerts on component mount
  useEffect(() => {
    const fetchInitialExpiryAlerts = () => {
      if (token) {
        dispatch(getBulkExpiryAlerts(token)); // Fetch expiry alerts
      }
    };

    fetchInitialExpiryAlerts();

    // Fetch every 3 minutes (180,000 ms)
    const intervalId = setInterval(fetchInitialExpiryAlerts, 180000);
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [dispatch, token]);

  // Filter alerts based on the search term
  const filteredAlerts = Array.isArray(expiryAlerts)
    ? expiryAlerts.filter((alert) =>
        alert.alert_status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        alert.subscription_name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  // Handle checkbox changes
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };
  

  // Handle "Select All" functionality
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = expiryAlerts.map((alert) => alert.alert_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };
  useEffect(() => {
    if (selectedIds.length === expiryAlerts.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, expiryAlerts.length]);

  // Handle bulk actions (Edit/Delete)
  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert('Please select at least one alert to proceed.');
      return;
    }

    if (action === 'edit') {
     
      setSelectedIds([]);
    } else if (action === 'delete') {
      if (window.confirm(`Are you sure you want to delete the selected alerts?`)) {
        dispatch(deleteBulkExpiryAlertsAction(selectedIds, token));
        setSelectedIds([]);
        toast.success('Selected alerts deleted successfully.');
      }
    }
  };

  // Handle individual alert deletion
  const handleDelete = async (alert_id) => {
    if (window.confirm('Are you sure you want to delete this expiry alert?')) {
      try {
        await dispatch( deleteExpiryAlertByIdAction(alert_id, token));
        toast.success('Expiry alert deleted successfully.');
      } catch (error) {
        toast.error('Error deleting expiry alert.');
      }
    }
  };

  // Handle editing an alert
  const handleEditClick = (alert_id) => {
    navigate('/admin/expiry-alert', { state: alert_id });
  };

  // Render component
  return (
    <div className="expiry-alert-page">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <div className="breadcrumb-text">
          Expiry Alert List
        </div>
      </div>

      <div className="expiry-alert-container"></div>
      <div className="expiry-alert-list">
        <div className="right-section">
          <h5 className="text-heading1">Expiry Alert List</h5>
          <div className="toolbar">
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search by alert status"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="item-controls">
              <ButtonComponent
                text="+ Add Alert"
                className="add-items-button"
                onClick={() => navigate('/admin/expiry-alert')}
              />
              <div className="actions-toolbar">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    className="dropdown-toggle"
                    id="actions-dropdown"
                  >
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                   
                    <Dropdown.Item onClick={() => handleActionSelect('delete')}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="table-container">
            {filteredAlerts.length === 0 ? (
              <div className="no-data-message">No expiry alerts found.</div>
            ) : (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>
                      <CheckboxComponent checked={selectAll} onChange={handleSelectAll} />
                     
                    </th>
                    <th>ID</th>
                    <th>Subscription Name</th>
                    <th>Alert Count</th>
                    <th>Alert Interval</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAlerts.map((alert, index) => (
                    <tr key={alert.alert_id}>
                      <td>
                        <CheckboxComponent
                          checked={selectedIds.includes(alert.alert_id)}
                          onChange={() => handleCheckboxChange(alert.alert_id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{alert.subscription_id}</td>
                      <td>{alert.alert_count}</td>
                      <td>{alert.alert_interval}</td>
                      <td>{alert.alert_status}</td>
                      <td className="actions-column">
                        <button
                          className="icon-button"
                          onClick={() => handleEditClick(alert.alert_id)}
                        >
                         <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        </td> 
                        <td className="actions-column">
                        <button
                          className="icon-button"
                          onClick={() => handleDelete(alert.alert_id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiryAlertDisplayPage;
