import React from 'react';
import './billorderdetails.scss';
import BillDetailCard from '../common/card/billdetails-card';
import { useSelector } from 'react-redux';

const BillDetailsCard = ({ orderId }) => {
    // Redux selectors
    const orders = useSelector((state) => state.orders.orders || []);

    // Find the current order
    const currentOrder = orders.find((order) => order.order_id === orderId);

  
    return (
        <div className="bill-details-card">
            <div className="bill-details">
                <h6 className="bill-details-header">Bill Details</h6>
                <hr />
                {/* Total Amount */}
                <BillDetailCard
                    label="Total Amount"
                    value={`₹${parseFloat(currentOrder.total_amount)+parseFloat(currentOrder.discount_amount) || '0.00'}`}
                    isSmall
                />
        {/* Discount */}
                <BillDetailCard
                    label="Discount"
                    value={`-₹${currentOrder.discount_amount || '0.00'}`}
                    isDiscount
                    isSmall
                />
                {/* Shipping Fee */}
                <BillDetailCard
                    label="Shipping Fee"
                    value={`₹${currentOrder.shipping_fee || '0.00'}`}
                    isSmall
                />
                {/* Grand Total */}
                <BillDetailCard
                    label="Grand Total"
                    value={`₹${(
                        parseFloat(currentOrder.total_amount || 0) +
                        parseFloat(currentOrder.shipping_fee || 0)                        
                    )}`}
                    isSmall
                />
                {/* <p className="text-muted">Inclusive of all taxes*</p> */}
            </div>
        </div>
    );
};

export default BillDetailsCard;
