// reducers/userReducer.js
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_SINGLE_USER_REQUEST,
  FETCH_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  BULK_CREATE_USERS_REQUEST,
  BULK_CREATE_USERS_SUCCESS,
  BULK_CREATE_USERS_FAILURE,
  BULK_DELETE_USERS_REQUEST,
  BULK_DELETE_USERS_SUCCESS,
  BULK_DELETE_USERS_FAILURE,
  BULK_UPDATE_USERS_REQUEST,
  BULK_UPDATE_USERS_SUCCESS,
  BULK_UPDATE_USERS_FAILURE,
} from '../actions/adminuserActions';

const initialState = {
  users: [],
  loading: false,
  error: null,
  singleUser:null,
};

const adminuserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case FETCH_SINGLE_USER_REQUEST: // Handle single user fetch request
      return {
        ...state,
        loading: true,
        users: null,
        error: null,
      };
    case FETCH_SINGLE_USER_SUCCESS: // Handle successful single user fetch
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case FETCH_SINGLE_USER_FAILURE: // Handle failure to fetch single user
      return {
        ...state,
        loading: false,
        users: null,
        error: action.payload,
      };

      
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
   
      case CREATE_USER_SUCCESS:
        //console.log('New user added:', action.payload,state.users); // Debugging log
        return {
          ...state,
          loading: false,
          users: [...state.users, action.payload],
        };
  
    case CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.map(user => 
          user.user_id === action.payload.user_id ? { ...user, ...action.payload.updatedData } : user
        ),
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => user.user_id !== action.payload),
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case BULK_CREATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BULK_CREATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, ...action.payload], // Add all new users to the list
      };
    case BULK_CREATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case BULK_DELETE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BULK_DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => !action.payload.includes(user.user_id)), // Remove all deleted users
      };
    case BULK_DELETE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case BULK_UPDATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      case BULK_UPDATE_USERS_SUCCESS:
        return {
            ...state,
            loading: false,
            users: state.users.map(user =>
                action.payload.find(updatedUser => updatedUser.user_id === user.user_id) || user
            ), // Update multiple users
            error: null,
        };
    

    case BULK_UPDATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default adminuserReducer;
