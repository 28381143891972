import Cookies from 'js-cookie';

// Get subscription data from cookies
export const getSubscriptionCookie = () => {
    const subscription = Cookies.get('subscription');
    return subscription ? JSON.parse(subscription) : [];
};

// Save subscription data to cookies
export const saveSubscriptionCookie = (subscriptionData) => {
    Cookies.set('subscription', JSON.stringify(subscriptionData), { expires: 30 }); // Expires in 30 days
};

// Clear subscription data from cookies
export const clearSubscriptionCookie = () => {
    Cookies.remove('subscription');
};
