import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import ButtonComponent from '../../../components/common/buttons/ButtonComponent';
import Textbox from '../../../components/common/Textbox/Textbox';
import InputComponent from '../../../components/common/InputComponent/InputComponent';
import './ReturnDetailsFormModal.scss';

const ReturnDetailsFormModal = ({ show, onHide, orderDetails }) => {
  const [formData, setFormData] = useState({
    order_id: '',
    product_id: '',
    comments: '',
  });

  useEffect(() => {
    if (orderDetails) {
      setFormData({
        order_id: orderDetails.order_id || '',
        product_id: orderDetails.product_id || '',
        comments: '',
      });
    }
  }, [orderDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formData);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <h6>Return Orders</h6>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formOrderId">
            <Form.Label>Order ID</Form.Label>
            <InputComponent
              label="Order ID"
              name="order_id"
              value={formData.order_id}
              readOnly={true}
            />
          </Form.Group>
          
          <Form.Group controlId="formProductId">
            <Form.Label>Product ID</Form.Label>
            <InputComponent
              label="Product ID"
              name="product_id"
              value={formData.product_id}
              readOnly={true}
            />
          </Form.Group>

          <Form.Group controlId="formComments">
            <Form.Label>Return Reason</Form.Label>
            <Textbox
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
          </Form.Group>

          <ButtonComponent
            text="Submit Return"
            type="submit"
            variant="primary"
            className="submit-return-btn"
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ReturnDetailsFormModal;
