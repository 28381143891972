import { brand_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';

export const fetchBrandFromAPI = async (token) => {
  const response = await apiClient.get(brand_endpoints.GET_BULK_BRANDS,
    {
      headers: {
        Authorization: token,

      }
    },    
  ); 
  //console.log("brand response",response)// Assuming categories are a part of the products API

    if (!response.data) {
      throw new Error('Failed to fetch brands');
    }
    return await response.data.brands;
  };
  
  // Create a new brand
  
  export const createNewBrandAPI = async (brandData,token) => {
  

    //console.log("brand data adding ",brandData)
 
     const response = await apiClient.post(
       brand_endpoints.GET_BRANDS, 
       {
           
         name:brandData.name,
         description:brandData.description,
         image_url:brandData.image_url,
         status:brandData.status
 
       },
       {
         headers: {
          
           Authorization:  token,
         },
       }
     );
 
     if (response.status !== 201) {
       throw new Error(`Failed to create brand withnd ID: ${brandData}`);
     }
 
     //console.log('brand created successfully', response.data);
     return response.data; // Optionally return the deleted category data
   
 };
  
  // Delete a brand by ID
  export const deleteBrandAPI = async (brandId,token) => {
    const response = await apiClient.delete(
      brand_endpoints.GET_BRANDS, // The endpoint for category deletion
      {
        
        headers: {
          'Content-Type': 'application/json', // Set the correct Content-Type
          Authorization: token,
              },
        params: {
          brand_id: brandId // Send categoryId in the request body
        }
      }
    );
    if (!response.data) {
      throw new Error(`Failed to delete brand with ID: ${brandId}`);
    }
  
    return await response.data; // Optionally return the deleted brand data
  };
  
  // Update a brand by ID
  export const updateBrandInAPI = async (brandId, updatedData,token) => {
    const response = await apiClient.put(
      brand_endpoints.GET_BRANDS, 
      {
        brand_id:updatedData.brand_id,
        name:updatedData.name,
        description:updatedData.description,
        image_url:updatedData.image_url,
        status:updatedData.status
      },
      {
        headers: {
         
          Authorization: token,          
        },
      }
    );
    if (!response.data) {
      throw new Error('Failed to update the brand');
    }
    return await response.data; // Return the updated brand
  };
  // Fetch bulk brands
export const fetchBulkBrandsFromAPI = async (token) => {
  const response = await apiClient.get(brand_endpoints.GET_BULK_BRANDS, {
    headers: {
      Authorization: token,
    },
  });

  //console.log("Bulk brand response", response.data.brands);
  if (!response.data) {
    throw new Error('Failed to fetch brands');
  }
  return response.data.brands;
};


// Create bulk brands
export const createBulkBrandsAPI = async (brandsData, token) => {
  //console.log("Bulk Brand Data Adding:", brandsData);

  const response = await apiClient.post(
    brand_endpoints.GET_BULK_BRANDS,
    brandsData, // Send array of brand data
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (response.status !== 201) {
    throw new Error('Failed to create brands in bulk');
  }

  //console.log('Bulk brands created successfully', response.data);
  return response.data;
};

// Update bulk brands
export const updateBulkBrandsAPI = async (brandsData, token) => {
  //console.log("Bulk Brand Data Update:", brandsData);

  const response = await apiClient.put(
    brand_endpoints.GET_BULK_BRANDS,
    brandsData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Failed to update brands in bulk');
  }

  //console.log('Bulk brands updated successfully', response.data);
  return response.data;
};

// Delete bulk brands
export const deleteBulkBrandsAPI = async (brandIds, token) => {
  //console.log("Bulk Delete Brands:", brandIds);

  const response = await apiClient.delete(
    brand_endpoints.GET_BULK_BRANDS,
    {
      data: brandIds, // Send array of brand IDs in the request body
      headers: {
        Authorization: token,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Failed to delete brands in bulk');
  }

  //console.log('Bulk brands deleted successfully', response.data);
  return response.data;
};