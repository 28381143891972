import {
  CREATE_STOCK,
  GET_STOCK,
  UPDATE_STOCK,
  DELETE_STOCK,
  GET_BULK_STOCK,
  CREATE_BULK_STOCK,
  UPDATE_BULK_STOCK,
  DELETE_BULK_STOCK,
  CREATE_STOCK_ITEM,
  GET_STOCK_ITEM,
  UPDATE_STOCK_ITEM,
  DELETE_STOCK_ITEM,
  GET_BULK_STOCK_ITEMS,
  CREATE_BULK_STOCK_ITEMS,
  UPDATE_BULK_STOCK_ITEMS,
  DELETE_BULK_STOCK_ITEMS,
} from '../actions/stockpurchaseActions';

const initialState = {
  stocks: [],
  stockItems: [],
  stockDetails: null,
  stockItemDetails: null,
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
      // Single Stock Actions
      case CREATE_STOCK:
          return { ...state, stocks: [...state.stocks, action.payload] };

      case GET_STOCK:
          return { ...state, stockDetails: action.payload };

      case UPDATE_STOCK:
          return {
              ...state,
              stocks: state.stocks.map(stock =>
                  stock.stock_id === action.payload.stock_id ? action.payload : stock
              ),
          };

      case DELETE_STOCK:
          return {
              ...state,
              stocks: state.stocks.filter(stock => stock.stock_id !== action.payload.stockId),
          };

      // Bulk Stock Actions
      case GET_BULK_STOCK:
          return { ...state, stocks: action.payload };

      case CREATE_BULK_STOCK:
          return { ...state, stocks: [...state.stocks, ...action.payload] };

      case UPDATE_BULK_STOCK:
          return {
              ...state,
              stocks: state.stocks.map(stock => {
                  const updatedStock = action.payload.find(updated => updated.stock_id === stock.stock_id);
                  return updatedStock ? updatedStock : stock;
              }),
          };

      case DELETE_BULK_STOCK:
          return {
              ...state,
              stocks: state.stocks.filter(stock => !action.payload.deletedStockIds.includes(stock.stock_id)),
          };

      // Single Stock Item Actions
      case CREATE_STOCK_ITEM:
          return { ...state, stockItems: [...state.stockItems, action.payload] };

      case GET_STOCK_ITEM:
          return { ...state, stockItemDetails: action.payload };

      case UPDATE_STOCK_ITEM:
          return {
              ...state,
              stockItems: state.stockItems.map(item =>
                  item.stock_item_id === action.payload.stock_item_id ? action.payload : item
              ),
          };

      case DELETE_STOCK_ITEM:
          return {
              ...state,
              stockItems: state.stockItems.filter(item => item.stock_item_id !== action.payload),
          };

      // Bulk Stock Item Actions
      case GET_BULK_STOCK_ITEMS:
        //console.log(action.payload)
          return { ...state, stockItems: action.payload };

        case CREATE_BULK_STOCK_ITEMS:
            //console.log(action.payload,"paoad",state.stockItems);
            return { ...state, stockItems: [...state.stockItems, ...action.payload] };
        
      case UPDATE_BULK_STOCK_ITEMS:
          return {
              ...state,
              stockItems: state.stockItems.map(item => {
                  const updatedItem = action.payload.find(updated => updated.stock_item_id === item.stock_item_id);
                  return updatedItem ? updatedItem : item;
              }),
          };

      case DELETE_BULK_STOCK_ITEMS:
          return {
              ...state,
              stockItems: state.stockItems.filter(item => !action.payload.deletedStockItemIds.includes(item.stock_item_id)),
          };

      default:
          return state;
  }
};

export default stockReducer;
