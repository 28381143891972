// reducers/addressReducer.js

import {
    FETCH_ADDRESS_SUCCESS,
    CREATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_SUCCESS,
    BULK_CREATE_ADDRESSES_SUCCESS,
    BULK_UPDATE_ADDRESSES_SUCCESS,
    BULK_DELETE_ADDRESSES_SUCCESS,
    FETCH_BULK_ADDRESSES_SUCCESS,
    ADDRESS_ERROR,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_ERROR,
    SET_SELECTED_ADDRESS,
    CLEAR_ADDRESS_STATE,
  } from '../actions/AddressAction';
  
  const initialState = {
    addresses: [],
    address: null,
    error: null,
    selectedAddressId:null,
  };
  
  const addressReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ADDRESS_SUCCESS:
        return { ...state, address: action.payload, error: null };
  
      case CREATE_ADDRESS_SUCCESS:
        return { ...state, addresses: [...state.addresses, action.payload], error: null };
  
      case UPDATE_ADDRESS_SUCCESS:
        return {
          ...state,
          addresses: state.addresses.map((addr) =>
            addr.address_id === action.payload.address_id ? action.payload : addr
          ),
          error: null
        };
  
      case DELETE_ADDRESS_SUCCESS:
        return {
          ...state,
          addresses: state.addresses.filter((addr) => addr.address_id !== action.payload),
          error: null
        };
  
      case BULK_CREATE_ADDRESSES_SUCCESS:
        return { ...state, addresses: [...state.addresses, ...action.payload], error: null };
  
        case FETCH_BULK_ADDRESSES_SUCCESS: // New case for bulk fetch
      return { ...state, addresses: action.payload, error: null };

      case BULK_UPDATE_ADDRESSES_SUCCESS:
        return {
          ...state,
          addresses: state.addresses.map((addr) =>
            action.payload.find((updated) => updated.address_id === addr.address_id) || addr
          ),
          error: null
        };
  
      case BULK_DELETE_ADDRESSES_SUCCESS:
        return {
          ...state,
          addresses: state.addresses.filter((addr) => !action.payload.includes(addr.address_id)),
          error: null
        };
  
      case ADDRESS_ERROR:
        return { ...state, error: action.payload };

        case SET_SELECTED_ADDRESS:
          return {
            ...state,
            selectedAddressId: action.payload, // Update selected address ID
          };


          case FETCH_LOCATION_SUCCESS:
            return {
              ...state,
              locationDetails: action.payload, // Update location details
              locationError: null, // Clear any previous errors
            };
      
          case FETCH_LOCATION_ERROR:
            return {
              ...state,
              locationDetails: null, // Clear location details on error
              locationError: action.payload, // Update the error message
            };
            case CLEAR_ADDRESS_STATE: // Clear all address-related state
      return {
        ...initialState,
      };
  
      default:
        return state;
    }
  };
  
  export default addressReducer;
  