// services/offerService.js
import { offer_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch bulk offers
export const fetchOffersFromAPI = async (token) => {
    const response = await apiClient.get(offer_endpoints.GET_BULK_OFFERS, {
        headers: {
            Authorization: token,
        },
    });

    //console.log("Offers response:", response.data.offers);
    if (!response.data) {
        throw new Error('Failed to fetch offers');
    }
    return response.data.offers; // Return the list of offers
};

// Create a new offer
export const createNewOfferAPI = async (newOffer, token) => {
    //console.log("Adding new offer:", newOffer.offer_id);

    const response = await apiClient.post(
        offer_endpoints.GET_OFFER,
        {
           
            offer_type: newOffer.offer_type,
            discount_value: newOffer.discount_value,
            conditions: newOffer.conditions,
            start_date: newOffer.start_date,
            expiry_date: newOffer.expiry_date,
            status: newOffer.status,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (!response.data) {
        throw new Error('Failed to create new offer');
    }

    //console.log('New offer created successfully', response.data);
    return response.data; // Return the created offer data
};

// Delete an offer
export const deleteOfferAPI = async (offerId, token) => {
    //console.log('Deleting offer with ID:', offerId);

    const response = await apiClient.delete(
        offer_endpoints.GET_OFFER,
        {
            params: { offer_id: offerId },
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error(`Failed to delete offer with ID: ${offerId}`);
    }

    //console.log('Offer deleted successfully', response.data);
    return response.data; // Optionally return the deleted offer data
};

// Update an offer by ID
export const updateOfferInAPI = async (offerId, updatedData, token) => {
    //console.log("Updating offer data:", updatedData);

    const response = await apiClient.put(
        offer_endpoints.GET_OFFER,
        {
            offer_id: offerId,
            offer_type: updatedData.offer_type,
            discount_value: updatedData.discount_value,
            conditions: updatedData.conditions,
            start_date: updatedData.start_date,
            expiry_date: updatedData.expiry_date,
            status: updatedData.status,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error(`Failed to update offer with ID: ${offerId}`);
    }

    //console.log('Offer updated successfully', response.data);
    return response.data; // Return the updated offer data
};

// Bulk create offers
export const createOffersBulkAPI = async (offersData, token) => {
    //console.log("Bulk Offer Data Adding:", offersData);

    const response = await apiClient.post(
        offer_endpoints.GET_BULK_OFFERS,
        offersData,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (!response.data) {
        throw new Error('Failed to create offers in bulk');
    }

    //console.log('Bulk offers created successfully', response.data);
    return response.data; // Return created offers data
};

// Bulk update offers
export const updateOffersBulkAPI = async (offersData, token) => {
    //console.log("Bulk Offer Data Update:", offersData);

    const response = await apiClient.put(
        offer_endpoints.GET_BULK_OFFERS,
        offersData,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to update offers in bulk');
    }

    //console.log('Bulk offers updated successfully', response.data);
    return response.data; // Return updated offers data
};

// Bulk delete offers
export const deleteOffersBulkAPI = async (offerIds, token) => {
    //console.log("Bulk Delete Offers:", offerIds);

    const response = await apiClient.delete(
        offer_endpoints.GET_BULK_OFFERS,
        {
            data: offerIds,
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to delete offers in bulk');
    }

    //console.log('Bulk offers deleted successfully', response.data);
    return response.data; // Return deleted offers data
};
