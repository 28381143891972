import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../common/buttons/ButtonComponent';
import './AddItems-adminModal.scss';

const AddItemsAdminModal = ({ show, handleClose, navigationOptions }) => {
  const navigate = useNavigate();

  // Function to navigate to AddItemPage
  const handleGridClick = () => {
    if (navigationOptions.gridPath) {
      navigate(navigationOptions.gridPath);
    }
  };

  // Function to navigate to AdminImportPage
  const handleSheetClick = () => {
    navigate('/admin/uploadpage', { state: navigationOptions.from });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title><h5>Add New Items</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-items-modal-body">
        <div className="text-center">
          <h6>Add New Item(s) Using</h6>
          <div className="modal-buttons my-3">
            <ButtonComponent
              text="Grid"
              variant="dark"
              className="grid-button"
              onClick={handleGridClick} // Navigate to AddItemPage on Grid click
            />
            <span className="mx-2">OR</span>
            <ButtonComponent
              text="Sheet"
              variant="dark"
              className="sheet-button"
              onClick={handleSheetClick} // Navigate to AdminImportPage on Sheet click
            />
          </div>
          <span className="modal-footer-text">
            The Item(s) Will Be Added To The Basemenu And Will Be In Inactive Status In All Areas Until You Mark Them Activated.
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddItemsAdminModal;
