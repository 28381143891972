// reducers/cartReducer.js

import {
    CREATE_CART,
    GET_CART,
    UPDATE_CART,
    DELETE_CART,
    CREATE_CART_ITEM,
    GET_CART_ITEM,
    UPDATE_CART_ITEM,
    DELETE_CART_ITEM,
    ADD_TO_CART,
    ADD_BULK_TO_CART_ITEMS,
    UPDATE_BULK_CART_ITEMS,
    DELETE_BULK_CART_ITEMS,
    INCREASE_CART_ITEM,
    DECREASE_CART_ITEM,
    GET_BULK_CART_ITEMS,
    CLEAR_CART_STATE,
} from '../actions/Addtocart';

const initialState = {
    cart: null,            // General cart data
    cartItems: [],         // Array to store cart items, each with product_id and quantity
    loading: false,
    error: null
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {

        // Cart Metadata Actions
        case CREATE_CART:
        case GET_CART:
            //console.log(action.payload,'------------payload')
            return { ...state, cart: action.payload };

        case UPDATE_CART:
            return { ...state, cart: { ...state.cart, ...action.payload } };

        case DELETE_CART:
            return { ...state, cart: null };

        // Individual Cart Item Actions
        case CREATE_CART_ITEM:
            return {
                ...state,
                cartItems: [...state.cartItems, { product_id: action.payload.product_id, quantity: 1 }]
            };

        case GET_CART_ITEM:
            return { ...state, cartItems: action.payload };

        case UPDATE_CART_ITEM:
            return {
                ...state,         
                cartItems: state.cartItems.map(item =>
                    item.product_id === action.payload.product_id ? { ...item, ...action.payload } : item
                )
            };

        case DELETE_CART_ITEM:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.product_id !== action.payload)
            };

        // ADD_TO_CART (Increments quantity if item already exists)
        case ADD_TO_CART: {
            //console.log(action.payload,'in reducer')
             const existingItem = state.cartItems.find(item => item.product_id === action.payload.product_id);
            const price = action.payload.price;
            
            if (existingItem) {
                return {
                    ...state,
                    cartItems: state.cartItems.map(item =>
                        item.product_id === action.payload.product_id
                            ? { ...item, quantity: item.quantity + 1,price_per_unit:price,total_price:price*(item.quantity + 1) }
                            : item
                    )
                };
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, { product_id: action.payload.product_id, quantity: action.payload.minimum_order_quantity,price_per_unit:price,total_price:price}]
                };
            }
        };
        case INCREASE_CART_ITEM: {
            const productId = action.payload.product_id;
            const price = action.payload.price || action.payload.price_per_unit;
            
            //console.log(state.cartItems, action.payload,'in reducer')
            return {
                ...state,
                cartItems: state.cartItems.map(item =>
                    item.product_id === productId
                        ? { ...item, quantity: item.quantity + 1,price_per_unit:price,total_price:price*(item.quantity + 1)}
                        : item
                )
            };
        }

        case DECREASE_CART_ITEM: {
            //console.log(action.payload,'decrease-------------')
            const productId = action.payload.product_id;
            const price = action.payload.price || action.payload.price_per_unit;
         
            return {
                ...state,
                cartItems: state.cartItems
                    .map(item =>
                        item.product_id === productId
                            ? { ...item, quantity: item.quantity - 1,price_per_unit:price,total_price:price*(item.quantity - 1) }
                            : item
                    )
                    .filter(item => item.quantity > 0) // Removes item if quantity reaches 0
            };
        }
        // Bulk Operations on Cart Items
     

        case UPDATE_BULK_CART_ITEMS:
            return {
                ...state,
                cartItems: state.cartItems.map(item => {
                    const updatedItem = action.payload.find(updated => updated.product_id === item.product_id);
                    return updatedItem ? { ...item, ...updatedItem } : item;
                })
            };
            case GET_BULK_CART_ITEMS:
                return {
                    ...state,
                    cartItems: action.payload // This updates the cartItems with the bulk data provided in the action
                };
        case DELETE_BULK_CART_ITEMS:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => !action.payload.includes(item.cart_item_id))
            };

            case CLEAR_CART_STATE: // Clear the cart and cart items
            return {
              ...initialState,
            };
        default:
            return state;
    }
};

export default cartReducer;
