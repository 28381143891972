import React, { useState, useRef  } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useNavigate, useLocation } from 'react-router-dom';

import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './AdminImportPage.scss';

const fieldMappings = {
  brand: ['name', 'description', 'status'],
  category: ['name', 'description', 'sort_order','status'],
  coupon: [
    'code', 'discount_type', 'discount_value', 'min_purchase_value',
    'max_discount_amount', 'start_date', 'expiry_date', 'usage_limit',"usage_per_user",'status',
  ],
  Allocatecoupon: ['code', 'entity_type', 'entity_name', 'status'],
  user: ['username', 'email', 'mobile_number','gender', 'dob'],
  delivery:  ['username', 'email', 'mobile_number', 'internal_id','password'],
  products: [
    'product_name', 'category_name', 'brand_name','short_code', 'price', 'description','discount','mrp','unit','pcs_per_unit','stock_level',
  'minimum_order_quantity','max_order_quantity','product_weight','dimensions','storage_conditions','packaging_type','delivery_timeframe','shipping_price',

  'daily_subscription_price','weekly_subscription_price','monthly_subscription_price','yearly_subscription_price',
 
 
],
  
  supplier: [
    'name', 'company', 'email', 'phone', 'address',
   'gst_no', 'fssai_lic_no', 'type', 'tcs_percent'
  ],

  discount:[
    "offer_type",
    "discount_value",
    "conditions",
    "start_date",
    "expiry_date",
    "status"
  ],
  AllocateOffer:[
  
   "conditions",
    "entity_type",
    "entity_name",   
    "status"   
  ],
  location:[
    "location_name","base_charge","serviceable","additional_charge_per_km"
  ]
};

const AdminImportPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const page_type = location.state || null;
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
      if (['csv', 'xlsx', 'xls'].includes(fileType)) {
        setSelectedFile(file);
        setErrorMessage('');
      } else {
        setErrorMessage('Only CSV and Excel files are supported.');
      }
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setErrorMessage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input value
    }
  };

  const handleDownloadTemplate = () => {
    const fields = fieldMappings[page_type];
    const headers = Array.isArray(fields)
      ? fields
      : [...fields.basic, ...fields.custom];
    const csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${page_type}_template.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const processCSVFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        navigateToNextPage(result.data);
      },
      error: (error) => {
        setErrorMessage('Error processing CSV file.');
        // //console.error('CSV Parsing Error:', error);
      },
    });
  };

  const processExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headers = jsonData[0];
      const rows = jsonData.slice(1).map((row) => {
        const rowData = {};
        headers.forEach((header, index) => {
          rowData[header] = row[index];
        });
        return rowData;
      });
      navigateToNextPage(rows);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setErrorMessage('Please upload a valid file.');
      return;
    }
    const fileType = selectedFile.name.split('.').pop().toLowerCase();
    if (fileType === 'csv') processCSVFile(selectedFile);
    else if (['xlsx', 'xls'].includes(fileType)) processExcelFile(selectedFile);
  };

  const navigateToNextPage = (parsedData) => {
    const routes = {
      brand: '/admin/bulk-brand',
      category: '/admin/bulk-category',
      coupon: '/admin/bulk-coupon',
      Allocatecoupon: '/admin/bulk-allocate-coupon',
      user: '/admin/bulk-user',
      delivery: '/admin/bulk-delivery',
      products: '/admin/bulk-products',
      supplier: '/admin/bulk-supplier',
      discount:'/admin/bulk-offer',
      AllocateOffer:'/admin/bulk-allocate-offer',
      location:'/admin/bulk-location'
    };
    if (routes[page_type]) navigate(routes[page_type], { state: parsedData });
  };

  return (
    <div className="admin-import-page">
      <form className="import-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="fileUpload" className="form-label">
            Upload File *
          </label>
          <input
            type="file"
            id="fileUpload"
            accept=".csv, .xlsx, .xls"
            onChange={handleFileChange}
            className="form-control"
            ref={fileInputRef} 
          />
          {selectedFile && (
            <div className="file-info">
              <span>{selectedFile.name}</span>
              <button type="button" className='remove-btn' onClick={handleRemoveFile}>
                Remove
              </button>
            </div>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
        <div className="form-footer">
          <button
            type="button"
            onClick={handleDownloadTemplate}
            className="template-download-btn"
          >
            Download Template
          </button>
          <ButtonComponent text="Submit"  className="submit-btn" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default AdminImportPage;