import { createProcessDeliveries } from '../../api/services/ProcessDeliveriesService';

// Action Types
export const CREATE_PROCESS_DELIVERY_SUCCESS = 'CREATE_PROCESS_DELIVERY_SUCCESS';
export const CREATE_PROCESS_DELIVERY_ERROR = 'CREATE_PROCESS_DELIVERY_ERROR';

// Add Process Deliveries
export const addProcessDelivery = (token) => async (dispatch) => {
  try {
    const newProcessDelivery = await createProcessDeliveries(token); // Pass only the token
    dispatch({ type: CREATE_PROCESS_DELIVERY_SUCCESS, payload: newProcessDelivery });
  } catch (error) {
    dispatch({ type: CREATE_PROCESS_DELIVERY_ERROR, payload: error.message });
  }
};
