// src/api/services/adminService.js

// Fetch admins from API
export const fetchAdminFromAPI = async () => {
    const response = await fetch('https://fakestoreapi.com/users');
    if (!response.ok) {
      throw new Error('Failed to fetch admins');
    }
    return await response.json();
  };
  
  // Create a new admin
  export const createNewAdminAPI = async (newAdmin) => {
    const response = await fetch('https://fakestoreapi.com/users', {
      method: 'POST', // Change the method to POST to create a new user
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newAdmin), // Send the new user data in the request body
    });
  
    if (!response.ok) {
      throw new Error('Failed to create new admin');
    }
  
    return await response.json(); // Return the created admin data from the API
  };
  
  // Delete an admin
  export const deleteAdminAPI = async (adminId) => {
    //console.log('delete', adminId);
    const response = await fetch(`https://fakestoreapi.com/users/${adminId}`, {
      method: 'DELETE',
    });
  
    // Check for a successful response
    if (!response.ok) {
      throw new Error(`Failed to delete admin with ID: ${adminId}`);
    }
  
    return await response.json(); // Optionally return the deleted admin data
  };
  
  // Update an admin by ID
  export const updateAdminInAPI = async (adminId, updatedData) => {
    const response = await fetch(`https://fakestoreapi.com/users/${adminId}`, {
      method: 'PUT', // Or PATCH based on how your API handles updates
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });
    if (!response.ok) {
      throw new Error('Failed to update the admin');
    }
    return await response.json(); // Return the updated admin
  };
  