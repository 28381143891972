import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSave } from '@fortawesome/free-solid-svg-icons';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './AddLocation.scss'; // Custom styles for the page

const tamilNaduDistricts = [
  'Ariyalur', 'Chengalpattu', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri', 'Dindigul', 'Erode',
  'Kallakurichi', 'Kancheepuram', 'Karur', 'Krishnagiri', 'Madurai', 'Nagapattinam', 'Namakkal',
  'Nilgiris', 'Perambalur', 'Pudukkottai', 'Ramanathapuram', 'Ranipet', 'Salem', 'Sivaganga',
  'Tenkasi', 'Thanjavur', 'Theni', 'Thiruvallur', 'Thiruvarur', 'Thoothukudi', 'Tiruchirappalli',
  'Tirunelveli', 'Tirupathur', 'Tiruppur', 'Tiruvannamalai', 'Vellore', 'Viluppuram', 'Virudhunagar'
];

const AddLocation = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [sortedDistricts, setSortedDistricts] = useState([...tamilNaduDistricts].sort());
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredDistricts = tamilNaduDistricts.filter(district =>
      district.toLowerCase().includes(term.toLowerCase())
    ).sort();
    setSortedDistricts(filteredDistricts);
  };

  const handleCheckboxChange = (district) => {
    if (selectedDistricts.includes(district)) {
      setSelectedDistricts(selectedDistricts.filter(item => item !== district));
    } else {
      setSelectedDistricts([...selectedDistricts, district]);
    }
  };

  const handleSave = () => {
    navigate('/delivery/delivery-location-configuration', { state: { selectedLocations: selectedDistricts } });
  };

  return (
    <div className="add-location-page">
      <div className="header-section">
        <h4>Add New Location</h4>
        <ButtonComponent text={<><FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />Save</>}
          onClick={handleSave}
          className="save-button" />
      </div>

      <div className="search-container">
        <div className="search-bar">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search for district"
            className="search-input"
          />
        </div>
      </div>

      <div className="district-list">
        {sortedDistricts.length > 0 ? (
          sortedDistricts.map((district, index) => (
            <div className="district-item" key={index}>
              <CheckboxComponent
                checked={selectedDistricts.includes(district)}
                onChange={() => handleCheckboxChange(district)}
              />
              <span>{district}</span>
            </div>
          ))
        ) : (
          <p className="no-results">No matching districts found.</p>
        )}
      </div>
    </div>
  );
};

export default AddLocation;
