import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import './AddLocation.scss'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { createLocation, fetchLocations, updateLocation } from '../../redux/actions/locationAction'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const LocationPage = () => {
  const dispatch = useDispatch(); 
  const [locationId, setLocationId] = useState('');
  const [locationName, setLocationName] = useState('');
  const [serviceable, setServiceable] = useState(''); // Boolean, yes or no dropdown
  const [baseCharge, setBaseCharge] = useState('');
  const [additionalChargePerKm, setAdditionalChargePerKm] = useState('');
  const locations = useSelector((state) => state.location.locations);
  const token = useSelector(state => state.adminLogin.token);

  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const serviceableOptions = ['Yes', 'No'];
  const location_Id = pageLocation.state || null;

  useEffect(() => {
    if (location_Id) {
      const locationData = locations.find(location => location.location_id === location_Id);
      if (locationData) {
        setLocationName(locationData.location_name);
        setServiceable(locationData.serviceable ? 'Yes' : 'No');
        setBaseCharge(locationData.base_charge);
        setAdditionalChargePerKm(locationData.additional_charge_per_km);

        setOriginalData(locationData);
      }
    }
  }, [location_Id, locations]);

  useEffect(() => {
    const isFormModified = 
        locationName !== originalData?.location_name ||
        (serviceable === 'Yes' ? true : false) !== originalData?.serviceable ||
        baseCharge !== originalData?.base_charge ||
        additionalChargePerKm !== originalData?.additional_charge_per_km;

    setIsModified(isFormModified);
  }, [locationName, serviceable, baseCharge, additionalChargePerKm, originalData]);

  const handleSubmit = async () => {
    const locationData = {
      locationId,
      locationName,
      serviceable: serviceable === 'Yes',
      baseCharge,
      additionalChargePerKm
    };
    //console.log(locationData, 'location');
    const trimmedName = locationName.trim().toLowerCase();
  
    if (!location_Id) {
      const nameExists = locations.some(location =>
        location.location_name && // Ensure location_name exists before trimming
        location.location_name.trim().toLowerCase() === trimmedName
      );
  
      if (nameExists) {
        toast.error("Location name already exists");
        return;
      }
    }
  
    if (locationName && baseCharge) {
      let actionResult;
  
      if (location_Id) {
        setLoading(true);
        actionResult = await dispatch(updateLocation(location_Id, locationData, token));
      } else {
        setLoading(true);
        actionResult = await dispatch(createLocation(locationData, token));
      }
  
      if (actionResult) {
        setLoading(false);
        toast.success("Location updated/created successfully");
        navigate('/admin/location');
      } else {
        setLoading(false);
        toast.error("Location not updated/created. Please try again.");
      }
    } else {
      toast.error("Please fill in the mandatory fields");
    }
  };
  
  
  return (
    <div className="location-main-page">
    

    <div className="breadcrumb-container">
     
        <Link to="/admin/dashboard" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/location" className="breadcrumb-text">
        <span className="breadcrumb-text">Location Service</span>
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Location</span>
   </div>
   <div className="location-container"></div>
    <div className="location-page-section">
    <h5 className="text-heading1">Add Location</h5>
      
        <form onSubmit={handleSubmit}>
          {/* Row 1 */}
          <div className="form-row">
            <div className="form-group">
              <label>Location Name<span className="required-symbol">*</span></label>
              <InputComponent
                placeholder="Enter Location Name"
                value={locationName}
                onChange={(e) => setLocationName(e.target.value)}
                icon="fa fa-map-marker"
              />
            </div>

            <div className="form-group">
              <label>Serviceable<span className="required-symbol">*</span></label>
              <DropdownButton
                options={serviceableOptions}
                label={serviceable || 'Select Status'}
                onSelect={(selectedOption) => setServiceable(selectedOption)}
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className="form-row">
            <div className="form-group">
              <label>Base Charge<span className="required-symbol">*</span></label>
              <InputComponent
                type="number"
                placeholder="Enter Base Charge"
                value={baseCharge}
                onChange={(e) => setBaseCharge(e.target.value)}
                icon="fa fa-dollar-sign"
              />
            </div>

            <div className="form-group">
              <label>Additional Charge per KM</label>
              <InputComponent
                type="number"
                placeholder="Enter Additional Charge per KM"
                value={additionalChargePerKm}
                onChange={(e) => setAdditionalChargePerKm(e.target.value)}
                icon="fa fa-dollar-sign"
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="action-buttons">
            <ButtonComponent
              text={loading ? "Saving..." : "Save"}
              onClick={handleSubmit}
              className="save-button"
              disabled={!isModified}
            />
          </div>
        </form>
      </div>
    </div>

   
  );
};

export default LocationPage;
