import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    getPurchase,
    getBulkPurchaseItems,
    createPurchase,
    updatePurchase,
    createBulkPurchaseItems,
    updateBulkPurchaseItems,
    deletePurchaseItem
} from '../../redux/actions/purchaseActions';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import RadioButtonComponent from '../../components/common/RadioButtonComponent/RadioButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import { FaPencilAlt, FaEdit, FaTrash } from 'react-icons/fa';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './add-request-for-purchase.scss';
import { toast } from 'react-hot-toast'; // Import toast
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const AddRequestForPurchase = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const purchaseId = location.state || null; // Retrieve purchase_id from the location state
    const [supplierName, setSupplierName] = useState('');
    
    // Redux State with Default Values
    const purchases = useSelector((state) => state.purchase.purchases || []);
    const purchaseItems = useSelector((state) => state.purchase.purchaseItems || []);
    const token = useSelector((state) => state.adminLogin.token);
    const supplier = useSelector((state) => state.supplier.suppliers);
    const productlist = useSelector((state) => state.products.basicProducts || []);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [saving,setSaving] = useState(false)
    const [addType,setAddType] = useState('')
    const [paymentType, setPaymentType] = useState('unpaid');
    const [selectedDate, setSelectedDate] = useState('');
    const [materials, setMaterials] = useState([]);
    const [form, setForm] = useState({
        po_number: '',
        supplier_id: '',
        date: '',
        payment_type: '',
    });
    const updateNames = (id) => {
        const entityName = productlist.find(product => product.product_id === id)?.product_name || '';
        return entityName;
    };
    const parseDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Extract YYYY-MM-DD
    };
    useEffect(() => {
        if (purchaseId) {
            const purchaseData = purchases.find(p => p.purchase_id === purchaseId);
            if (purchaseData) {
                setForm({
                    po_number: purchaseData.po_number,
                    supplier_id: purchaseData.supplier_id,
                    date: purchaseData.date,
                    payment_type: purchaseData.payment_status,
                });
                setPaymentType(purchaseData.payment_status);
                setSelectedDate(parseDateForInput(purchaseData.date));
                //console.log(selectedDate,purchaseData,'----selected dateeeeeeeeeeeeeeee')
                const foundSupplier = supplier.find(s => s.supplier_id === purchaseData.supplier_id);
                setSupplierName(foundSupplier ? foundSupplier.name : '');
            }

            const items = purchaseItems.filter(item => item.purchase_id === purchaseId);
            setMaterials(items.map(item => ({ ...item }))); // Ensures a fresh copy of each item
        }
    }, [purchaseId, purchases, purchaseItems, supplier]);

    const handleInputChange = (index, field, value) => {
        setMaterials(prevMaterials =>
            prevMaterials.map((material, i) =>
                i === index ? { ...material, [field]: value } : material
            )
        );
        //console.log(materials);
    };

    const handleAddNew = () => {
        const newRow = { name: '', quantity: 0, unit: 0, price: 0, amount: 0, description: '' };
        setMaterials(prevMaterials => [...prevMaterials, newRow]);
    };

    const handleDelete = async (index, itemId) => {
        setMaterials(prevMaterials => prevMaterials.filter((_, i) => i !== index));
        
        if (itemId) {
            await dispatch(deletePurchaseItem(itemId, token));
        }
    };

    const handleSave = async () => {
        setSaving(true);
        //console.log(materials,form,'---------------')
        if (!form.po_number || !form.supplier_id || !selectedDate || materials.length === 0) {
            toast.error('Please fill all required fields and add at least one material.');
            return;
        }

        if (materials.some(material => !material.quantity || !material.unit)) {
            toast.error('All fields in each material are required.');
            return;
        }

        const purchaseData = {
            po_number: form.po_number,
            supplier_id: form.supplier_id,
            date: selectedDate,
            payment_status: paymentType,
        };

        let savedPurchaseId;
        if (purchaseId) {
            //console.log(purchaseData,'purchaseeee')
            await dispatch(updatePurchase({ ...purchaseData, purchase_id: purchaseId }, token));
            savedPurchaseId = purchaseId;
            //console.log(purchaseData,purchaseId,'purchaseeee999999')
            const itemsToUpdate = materials.filter(item => item.purchase_item_id);
            const itemsToCreate = materials.filter(item => !item.purchase_item_id);
            //console.log(itemsToCreate,itemsToUpdate)
            if (itemsToUpdate.length > 0) {
                const updatedItems = itemsToUpdate.map(item => ({ ...item, purchase_id: savedPurchaseId }));
                await dispatch(updateBulkPurchaseItems(updatedItems, token));
            }

            if (itemsToCreate.length > 0) {
                const newItems = itemsToCreate.map(item => ({ ...item, purchase_id: savedPurchaseId }));
                await dispatch(createBulkPurchaseItems(newItems, token));
            }
        } else {
            const createResponse = await dispatch(createPurchase(purchaseData, token));
            savedPurchaseId = createResponse.purchase_id;

            const itemsWithPurchaseId = materials.map(item => ({ ...item, purchase_id: savedPurchaseId }));
            await dispatch(createBulkPurchaseItems(itemsWithPurchaseId, token));
        }
        setSaving(false);
        toast.success('Purchase request saved successfully.');
        navigate('/admin/request_for_purchase_list'); // Redirect to purchase list
    };
    const handleCloseSidebar = () => {
        setIsSidebarVisible(false);
        setAddType('');
      };
    const handleSupplier = (selectedId) => {
        

        const foundSupplier = supplier.find(s => s.supplier_id === selectedId);
        //console.log(foundSupplier,'--founded supplier');
        if(foundSupplier){
            setSupplierName(foundSupplier ? foundSupplier.name : '');
            const updatedFormData = { ...form, supplier_id: selectedId };
            setForm(updatedFormData);
        }
        else{
            setAddType('supplier');
        setIsSidebarVisible(true)
        }
        
        
    };

    const handleEntitySelect = (index, selectedId) => {
        const available = productlist.find(p => p.product_id === selectedId);
        if (available) {
            handleInputChange(index, 'product_id', selectedId);
        } else {
            setAddType('basic');
            setIsSidebarVisible(true);
            
        }
    };

    if (!purchases || !purchaseItems) return null;
    
    return (
        <div className="add-request-for-purchase">
            <div className="breadcrumb-container">

                    <Link to="/admin/dashboard" className="breadcrumb-text">
                    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <Link to="/admin" className="breadcrumb-text">
                    <span className="breadcrumb-text">Product list</span>
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <Link to="/admin/request_for_purchase_list" className="breadcrumb-text">
                    <span className="breadcrumb-text">Request Purchase list</span>
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <span className="breadcrumb-text">Add Request Purchase List</span>
                    </div>
                    <div className="request-purchase-list-container"></div>
            <div className="add-request-page">
                <div className="add-request-header-container">
                    <h6 className="add-request-header">Request for Purchase Details</h6>
                    <hr />
                    <div className="add-request-form">
                        <div className="form-row">
                            <div className="form-group po-number-group">
                                <label>PO No.</label>
                                <input
                                    type="text"
                                    className="form-control po-input"
                                    value={form.po_number}
                                    onChange={(e) => setForm({ ...form, po_number: e.target.value })}
                                />
                             
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>Supplier</label>
                                <SearchableDropdown
                                    options={supplier.map(sup => ({ label: sup.name, id: sup.supplier_id }))}
                                    placeholder="Search for suppliers"
                                    value={supplierName}
                                    onSelect={handleSupplier}
                                />
                            </div>
                            <div className="form-group">
                                <label>Date</label>
                              <InputComponent
                                type="date" // Ensures both date and time can be selected
                                placeholder="Select Start Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                icon="fa fa-calendar"
                            />

                              
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group payment-type">
                                <label>Payment Type</label>
                                <RadioButtonComponent
                                    name="paymentType"
                                    value="Unpaid"
                                    checked={paymentType === 'unpaid'}
                                    onChange={() => setPaymentType('unpaid')}
                                />
                                <label>Unpaid</label>
                                <RadioButtonComponent
                                    name="paymentType"
                                    value="Paid"
                                    checked={paymentType === 'paid'}
                                    onChange={() => setPaymentType('paid')}
                                />
                                <label>Paid</label>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="raw-material-details">
                    <div className="header">
                        <h6 className="add-request-header1">Raw Material Details</h6>
                        <ButtonComponent text="Add New" className="add-new-button" onClick={handleAddNew} />
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th><CheckboxComponent /></th>
                                <th>Name *</th>
                                <th>Quantity *</th>
                                <th>Unit *</th>
                                <th>Price</th>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materials.map((material, index) => (
                                <tr key={index}>
                                    <td><CheckboxComponent /></td>
                                    <td>
                                        <SearchableDropdown
                                            options={productlist.map(sup => ({ label: sup.product_name, id: sup.product_id }))}
                                            placeholder="Search for product"
                                            value={updateNames(material.product_id)}
                                            onSelect={(selectedOption) => handleEntitySelect(index, selectedOption)}
                                        />
                                    </td>
                                    <td><InputComponent value={material.quantity} type="number" onChange={(e) => handleInputChange(index, 'quantity', e.target.value)} /></td>
                                    <td><InputComponent value={material.unit} type="number" onChange={(e) => handleInputChange(index, 'unit', e.target.value)} /></td>
                                    <td><InputComponent value={material.price} type="number" onChange={(e) => handleInputChange(index, 'price', e.target.value)} /></td>
                                    <td><InputComponent value={material.amount} type="number" onChange={(e) => handleInputChange(index, 'amount', e.target.value)} /></td>
                                    <td><InputComponent value={material.description} onChange={(e) => handleInputChange(index, 'description', e.target.value)} /></td>
                                    <td>
                                
                                        <FaTrash className="action-icon" onClick={() => handleDelete(index, material.purchase_item_id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div class="button-container">
                    <ButtonComponent text={saving?"Save ...":"Save"} disabled={saving}className={"save-button"} onClick={handleSave} />
                    {isSidebarVisible && <Sidebar type={addType} urls={'yes'} onClose={handleCloseSidebar} />}
                </div>
                </div>
            </div>
        </div>
    );
};

export default AddRequestForPurchase;