import React from 'react';
import { Form } from 'react-bootstrap';
import './InputComponent.scss';

const InputComponent = ({ field, onChange, value, placeholder,readOnly, type = 'text', form = { touched: {}, errors: {} }, ...props }) => {
  const errorText = form.errors?.[field?.name] && form.touched?.[field?.name] ? form.errors[field.name] : '';

  return (
    <Form.Group controlId={props.id}>
      <Form.Control
        type={type} // Set type dynamically, default to 'text'
        value={value || ''}  // Ensure value is controlled by parent
        readOnly={readOnly}
        placeholder={placeholder}
        className={`form-control input-placeholder span ${errorText ? 'is-invalid' : ''}`}
        onChange={(e) => {
          // Call parent onChange handler
          if (onChange) {
            onChange(e);
          }

          // Optionally handle Formik's onChange if field is provided
          if (field?.onChange) {
            field.onChange(e);
          }
        }}
      />
      {errorText && <div className="text-danger">{errorText}</div>}
    </Form.Group>
  );
};

export default InputComponent;



// import React from 'react';
// import { Form } from 'react-bootstrap';
// import './InputComponent.scss';

// const InputComponent = ({ value, onChange, placeholder, type = 'text', ...props }) => {
//   return (
//     <Form.Group controlId={props.id}>
//       <Form.Control
//         type={type} // Default to text if not provided
//         value={value} // Explicitly set the value from props
//         onChange={onChange} // Explicitly set the onChange handler from props
//         placeholder={placeholder}
//         className="form-control input-placeholder"
//       />
//     </Form.Group>
//   );
// };

// export default InputComponent;
