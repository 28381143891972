import React, { useState } from 'react';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import {  FaTrash, FaPlus } from 'react-icons/fa';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';

import './ClosingStock.scss';

const AddRequestForPurchase = () => {
    const [startDate, setStartDate] = useState(new Date());
  const [materials, setMaterials] = useState([{ name: '', quantity: '', unit: '', price: '', amount: '', description: '' }]);

  const handleAddNew = () => {
    setMaterials([...materials, { name: '', quantity: '', unit: '', price: '', amount: '', description: '' }]);
  };

  const handleDelete = (index) => {
    const updatedMaterials = [...materials];
    updatedMaterials.splice(index, 1);
    setMaterials(updatedMaterials);
  };

  return (
    <div className='add-request-for-purchase1'>
      <div className="add-request-page1">
      <div className="add-request-header-class1">
  <h6 className="add-request-header1">Closing Stock Details</h6>
  <hr />
  <div className="invoice-details-container1">
  <div className="form-group">
    <div className="form row">
      <label className="invoice-header1">Invoice Date <span style={{ color: 'red' }}>*</span></label>
        <Datepicker
        className="date-picker1"
        dateValue={startDate}
        onDateChange={setStartDate}
        placeholderText={new Date().toLocaleDateString()} // Set current date as placeholder
        dateFormat="dd MMM yyyy" 
        />
  </div>
  </div>
  </div>
</div>

        {/* Raw Material Details */}
        <div className="raw-material-details1">
        <div className="header1">
  <h6 className="add-request-header2">Raw Material Details</h6>
  <div className="action-buttons">
    <ButtonComponent
      text={<> <FaPlus className="add-icon1" /> Add New</>}
      className="add-new-buttons"
      onClick={handleAddNew}
    />
    
  </div>
</div>

<table className="table table-bordered1">
  <thead>
    <tr>
      <th><CheckboxComponent /></th>
      <th>Raw Material</th>
      <th>Current Stock</th>
      <th>Unit</th>
      <th>Comments</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {materials.map((material, index) => (
      <tr key={index}>
        <td><CheckboxComponent /></td>
        <td>
          <DropdownButton className="dropdown-button1" label="Select Raw Material" options={[{ label: 'Option 1' }, { label: 'Option 2' }]} />
        </td>
        <td><InputComponent className="table-input1" placeholder="Current Stock" /></td>
        <td>
          <DropdownButton className="dropdown-button1" label="Select Unit" options={[{ label: 'Unit 1' }, { label: 'Unit 2' }]} />
        </td>
        <td><InputComponent className="table-input1" placeholder="Comments" /></td>
        <td className="actions1">
          <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
        </td>
      </tr>
    ))}
  </tbody>
</table>

          <div className="footer-buttons1">
            <ButtonComponent text="Cancel" variant="danger" className="btn-cancel1" />
            <ButtonComponent text="Save changes" variant="secondary" className="btn-save1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRequestForPurchase;
