import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrand, fetchBrand, updateBrand } from '../../redux/actions/brandAction';
import { uploadDocumentAPI, generatePresignedUrlAPI } from '../../api/services/filestorageService';
import './brands.scss';

const Brands = ({ urls }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.adminLogin.token);
  const brandlist = useSelector((state) => state.brand.brandList.brands);
  const location = useLocation();
  const brand_Id = urls?null:location.state || null;

  const [formData, setFormData] = useState({
    brand_id: '',
    name: '',
    description: '',
    image_url: '',
    status: '',
  });
  const [isModified, setIsModified] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const statusOptions = ['active', 'inactive'];

  // Generate a presigned URL for the existing image
  const generatePresignedUrl = async (imageUrl) => {
    try {
      const presignedUrlData = await generatePresignedUrlAPI(imageUrl, token);
      if (presignedUrlData.presigned_url) {
        setFileUrl(presignedUrlData.presigned_url); // Set presigned URL for display
      }
    } catch (error) {
      //console.error('Error generating presigned URL:', error);
      // toast.error('An error occurred while fetching the image.');
    }
  };

  useEffect(() => {
    if (brand_Id) {
      const brandData = brandlist.find((data) => data.brand_id === brand_Id);
      if (brandData) {
        setFormData(brandData);
        setOriginalData(brandData);
        if (brandData.image_url) {
          generatePresignedUrl(brandData.image_url); // Generate presigned URL for the existing image
        }
      }
    }
  }, [brand_Id, brandlist]);

  useEffect(() => {
    setIsModified(JSON.stringify(formData) !== JSON.stringify(originalData));
  }, [formData, originalData]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
      setIsModified(true);
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const uploadedUrl = await uploadDocumentAPI(file, 'brand/', token);
        if (uploadedUrl && uploadedUrl.file_url) {
          setFileUrl(uploadedUrl.file_url);
          setFormData((prevData) => ({
            ...prevData,
            image_url: uploadedUrl.file_url,
          }));
          return uploadedUrl.file_url;
        } else {
          toast.error('File upload failed. Please try again.');
        }
      } catch (error) {
        //console.error('Error uploading file:', error);
        // toast.error('An error occurred during file upload.');
      }
    }
    return formData.image_url;
  };

  const handleSubmit = async () => {
    setLoading(true);
    let updatedFormData = { ...formData };
    if (file) {
      updatedFormData.image_url = await handleFileUpload();
    }
    //console.log(brand_Id,'--brand_id')
    if (updatedFormData.name && updatedFormData.status) {
      const actionResult = brand_Id
        ? await dispatch(updateBrand(brand_Id, updatedFormData, token))
        : await dispatch(createBrand(updatedFormData, token));

      if (actionResult?.success) {
        toast.success('Brand updated/created successfully');
        
         if(!urls){
          navigate('/admin');  
        }
        else{
          await dispatch(fetchBrand(token))
        }
        
      } else {
        toast.error('Brand not updated/created. Please try again.');
      }
      setLoading(false);
    } else {
      toast.error('Need to fill the mandatory fields');
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl('');
    setFormData((prevData) => ({ ...prevData, image_url: '' }));
  };

  return (
    <div className="add-brand-section">
      {/* Conditionally render the breadcrumb container */}
      {!urls && (
        <div className="breadcrumb-container">
          <Link to="/admin/dashboard" className="breadcrumb-text">
             <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <Link to="/admin" className="breadcrumb-text">
            Brand List
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <span className="breadcrumb-text">Add Brand</span>
        </div>
      )}
      <div className="add-brand-container"></div>
      <div className="add-brand-class">
        <h5 className="text-heading1">Add Brand Items</h5>
        <div className="form-row">
          <div className="form-group">
            <label>
              Name <span className="required-symbol">*</span>
            </label>
            <InputComponent
              placeholder="Enter Name"
              name="name"
              value={formData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <InputComponent
              placeholder="Enter Description"
              name="description"
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
         
          <div className="form-group">
            <label>
              Status <span className="required-symbol">*</span>
            </label>
            <DropdownButton
              options={statusOptions}
              label={formData.status || 'Select Status'}
              onSelect={(value) => handleInputChange('status', value)}
            />
          </div>
          <div className="form-group">
            <label>Logo Image</label>
            {fileUrl ? (
              <div className="image-container">
                <img src={fileUrl} alt="Uploaded" className="uploaded-image" />
                <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={handleFileRemove} />
              </div>
            ) : (
              <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange} />
            )}
          </div>
        </div>
        <div className="action-buttons">
          <ButtonComponent
            text={loading ? 'Saving...' : 'Save'}
            onClick={handleSubmit}
            className="save-button"
            disabled={!isModified}
          />
        </div>
      </div>
    </div>
  );
};

export default Brands;
