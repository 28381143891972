import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  CLEAR_CATEGORIES,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  BULK_CREATE_CATEGORIES_REQUEST,
  BULK_CREATE_CATEGORIES_SUCCESS,
  BULK_CREATE_CATEGORIES_FAILURE,
  BULK_UPDATE_CATEGORIES_REQUEST,
  BULK_UPDATE_CATEGORIES_SUCCESS,
  BULK_UPDATE_CATEGORIES_FAILURE,
  BULK_DELETE_CATEGORIES_REQUEST,
  BULK_DELETE_CATEGORIES_SUCCESS,
  BULK_DELETE_CATEGORIES_FAILURE
} from '../actions/categoryAction'; // Adjust the path as needed

const initialState = {
  categoryList: {
    categories: [],
    loading: false,
    error: null,
    timestamp: null,
  },
};


const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: true,
          error: null,
        },
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          categories: action.payload.categories,
          timestamp: action.payload.timestamp, // Store the timestamp
          error: null,
        },
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          error: action.payload,
        },
      };
    case CLEAR_CATEGORIES:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          categories: [],
          timestamp: null, // Clear timestamp as well
        },
      };
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: true,
          error: null,
        },
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          categories: state.categoryList.categories.filter(category => category.category_id !== action.payload),
          error: null,
        },
      };
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: true,
          error: null,
        },
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          categories: state.categoryList.categories.map(category =>
            category.category_id === action.payload.category_id ? action.payload : category
          ),
          error: null,
        },
      };
    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: true,
          error: null,
        },
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          categories: [...state.categoryList.categories, action.payload], // Add newly created category
          error: null,
        },
      };
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          loading: false,
          error: action.payload,
        },
      };
    
     case BULK_CREATE_CATEGORIES_SUCCESS:
  return {
    ...state,
    categoryList: {
      ...state.categoryList,
      loading: false,
      categories: [...state.categoryList.categories, ...action.payload], // Merge bulk categories
      error: null,
    },
  };
      case BULK_CREATE_CATEGORIES_SUCCESS:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: false,
            categories: [...state.categoryList.categories, ...action.payload], // Add multiple created categories
            error: null,
          },
        };
      case BULK_CREATE_CATEGORIES_FAILURE:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: false,
            error: action.payload,
          },
        };
  
      // Bulk Update Categories
      case BULK_UPDATE_CATEGORIES_REQUEST:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: true,
            error: null,
          },
        };
      case BULK_UPDATE_CATEGORIES_SUCCESS:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: false,
            categories: state.categoryList.categories.map(category =>
              action.payload.find(updatedCategory => updatedCategory.category_id === category.category_id) || category
            ), // Update multiple categories
            error: null,
          },
        };
      case BULK_UPDATE_CATEGORIES_FAILURE:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: false,
            error: action.payload,
          },
        };
  
      // Bulk Delete Categories
      case BULK_DELETE_CATEGORIES_REQUEST:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: true,
            error: null,
          },
        };
      case BULK_DELETE_CATEGORIES_SUCCESS:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: false,
            categories: state.categoryList.categories.filter(
              category => !action.payload.includes(category.category_id)
            ), // Remove multiple categories
            error: null,
          },
        };
      case BULK_DELETE_CATEGORIES_FAILURE:
        return {
          ...state,
          categoryList: {
            ...state.categoryList,
            loading: false,
            error: action.payload,
          },
        };
   
    
      default:
      return state;
  }
};

export default categoryReducer;
