import { SET_FILE, CLEAR_FILE } from "../actions/fileAction";

const initialState = {
  uploadedFile: null, // Store only one file at a time
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILE:
      // Replace the existing file with the new one
      return {
        ...state,
        uploadedFile: action.payload,
      };

    case CLEAR_FILE:
      // Clear the uploaded file
      return {
        ...state,
        uploadedFile: null,
      };

    default:
      return state;
  }
};

export default fileReducer;
