import { fetchOffers,deleteOffer,bulkDeleteOffers,bulkUpdateOffers} from '../../redux/actions/discountAction';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';

import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome,faPencilAlt,  faTrash } from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './offerdisplayPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const DiscountDisplayPage = () => {
  const dispatch = useDispatch();
  const discounts = useSelector(state => state.discount.offerList) || [];
  const navigate = useNavigate();
  const token = useSelector(state=>state.adminLogin.token)

  const navigationOptions = {
    gridPath: '/admin/offer',
    sheetPath: '/admin/uploadpage',
    from: 'discount',
  };

  useEffect(() => {
    const fetchInitialDiscounts = () => {
      dispatch( fetchOffers(token));
    };
    fetchInitialDiscounts();
    const intervalId = setInterval(fetchInitialDiscounts, 180000); // Refresh every 3 minutes
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvasOpen = () => setShowOffcanvas(true);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);

  const handleDelete = async (discountId) => {
    if (window.confirm('Are you sure you want to delete this discount?')) {
      try {
        dispatch(deleteOffer(discountId,token));
       
      } catch (error) {
        // //console.error(error);
      }
    }
  };

  // Bulk Delete Functionality
  const handleBulkDelete = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one discount to delete.");
      return;
    }
    if (window.confirm(`Are you sure you want to delete the selected discounts?`)) {
      dispatch(bulkDeleteOffers(selectedIds,token));
      setSelectedIds([]);
    
    }
  };

  // Bulk Edit Functionality
  const handleBulkEdit = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one discount to edit.");
      return;
    }
    navigate('/admin/bulk-offer', { state: selectedIds });
    setSelectedIds([]);
  };

  // Individual Edit Functionality
  const handleEditClick = (discountId) => {
    navigate('/admin/offer', { state: discountId  });
  };

  const filteredDiscounts = discounts.filter(discount =>
    (discount.offer_type && discount.offer_type.toLowerCase().includes((searchTerm || "").toLowerCase())) ||
    (discount.description && discount.description.toLowerCase().includes((searchTerm || "").toLowerCase()))
  );
  

  const handleCheckboxChange = (discountId) => {
    if (selectedIds.includes(discountId)) {
      setSelectedIds(prev => prev.filter(id => id !== discountId));
    } else {
      setSelectedIds(prev => [...prev, discountId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = discounts.map(item => item.offer_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectAll(selectedIds.length === discounts.length);
  }, [selectedIds, discounts.length]);
  const handleRefresh = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format
  
    // Filter and map discounts to update status
    const discountsToUpdate = discounts
      .filter(
        discount =>
          new Date(discount.expiry_date) <= new Date(currentDate) && // Check if expiry_date is in the past or today
          discount.status !== "expired" // Ensure the status is not already "expired"
      )
      .map(discount => ({
        offer_id: discount.offer_id, // Include offer_id
        status: "expired", // Update the status to "expired"
      }));
  
    if (discountsToUpdate.length > 0) {
      dispatch(bulkUpdateOffers(discountsToUpdate, token)); // Dispatch the bulk update action
      alert(`${discountsToUpdate.length} discounts updated to expired.`);
    } else {
      alert("No discounts to update.");
    }
  };
  
  return (
    <div>
      <div className="offer-page-layout">
      <div className="breadcrumb-container">
        
      <Link to="/admin/dashboard" className="breadcrumb-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
            <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">
         Discount List
        </span>
       
       
      </div>
      <div className="discount-container"></div>
        <div className="discount-display-container">
          <div className="right-sections">
          <h5 className='text-heading1'>Discount List</h5>
            <div className="toolbar">
         
              <div className="search-wrapper">
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="item-controls">
                <ButtonComponent text="+ Discount"  className="add-items-button" onClick={handleModalShow} />
              
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" className='dropdown-toggle2' id="actions-dropdown">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={handleBulkEdit}>Edit </Dropdown.Item>
                      <Dropdown.Item onClick={handleBulkDelete}>Delete </Dropdown.Item>
                     
                    </Dropdown.Menu>
                  </Dropdown>
               
                <ButtonComponent text="Allocate" className="add-items-button" onClick={()=>navigate('/admin/offer-allocation')} />

                <ButtonComponent
                  text="Refresh"
                  variant="dark"
                  className="add-items-button"
                  onClick={handleRefresh}
                />

              </div>
         
            </div>

            <div className="table-container5">
              {filteredDiscounts.length === 0 ? (
                <div className="no-discounts-message">No discounts found.</div>
              ) : (
                <table className="custom-table5">
                  <thead>
                    <tr>
                      <th>
                        <CheckboxComponent
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>ID</th>
                      <th>Discount Type</th>
                      <th>Discount Value</th>
                      <th>Conditions</th>
                      <th>Start Date</th>
                      <th>Expiry Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredDiscounts.map((item, index) => (
                        <tr key={`${index}-${item.offer_id}`}>
                          <td>
                            <CheckboxComponent
                              checked={selectedIds.includes(item.offer_id)}
                              onChange={() => handleCheckboxChange(item.offer_id)}
                            />
                          </td>
                          <td>{index+1}</td>
                          <td>{item.offer_type}</td>
                          <td>{item.discount_value}</td>
                          <td>{item.conditions}</td>
                          <td>{item.start_date}</td>
                          <td>{item.expiry_date}</td>
                          <td>{item.status}</td>
                          <td className="actions-column">
                            <button
                              className="icon-button"
                              onClick={() => handleEditClick(item.offer_id)}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                          </td>
                          <td className="actions-column">
                            <button
                              className="icon-button"
                              onClick={() => handleDelete(item.offer_id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )}
            </div>

            <AddItemsAdminModal show={showModal} handleClose={handleModalClose} navigationOptions={navigationOptions} />
            {/* <div className="showing-records-pagination">
              <div className="showing-records">
                <p>Showing {1} to {filteredDiscounts.length} of {filteredDiscounts.length} records</p>
              </div>
              <div className="pagination-section">
                <Pagination
                  currentPage={1}
                  totalPages={1} // Adjust based on your pagination logic
                  onPageChange={() => {}}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
    </div>
  );
};

export default DiscountDisplayPage;
