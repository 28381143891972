import React, { useState, useEffect,useRef } from "react";
import "./PersonalDetailOffcanvas.scss";
import InputComponent from "../../../components/common/InputComponent/InputComponent";
import RadioButtonComponent from "../../../components/common/RadioButtonComponent/RadioButtonComponent";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import { updateUser,fetchSingleUser } from "../../../redux/actions/adminuserActions";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

const PersonalDetailOffcanvas = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.user);
  const userdetail = useSelector((state) => state.usermanage.users || []);
  const reduxUser = userdetail.length > 0 ? userdetail[0] : {}; // Safely fetch user data

  const [formData, setFormData] = useState({
    user_id: "",
    username: "",
    age: "",
    email: "",
    gender: "male",
    dob: "", // New dob field
  });

  const isInitialized = useRef(false); // Track initialization

  const parseDateForInput = (dateString) => {
    if (!dateString) return ""; // Return an empty string if the date is invalid or empty
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split("T")[0] : ""; // Validate the date before formatting
  };

  useEffect(() => {
    if (!isInitialized.current && reduxUser && userId) {
      const updatedFormData = {
        user_id: userId,
        username: reduxUser.username || "",
        email: reduxUser.email || "",
        gender: reduxUser.gender || "male",
        dob: parseDateForInput(reduxUser.dob) || "",
      };
      setFormData(updatedFormData);
      isInitialized.current = true; // Mark as initialized
    }
  }, [reduxUser, userId]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      const response = await dispatch(updateUser(userId, formData, token));
      if (response) {
        dispatch(fetchSingleUser(userId, token));
        toast.success("Profile updated successfully");
      } else {
        throw new Error("Profile not updated");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while updating the profile");
    }
    onClose();
  };

  return (
    <div
      className={`offcanvas offcanvas-end personal-detail-offcanvas ${show ? "show" : ""}`}
      tabIndex="-1"
      id="personalDetailOffcanvas"
      aria-labelledby="personalDetailOffcanvasLabel"
      style={{ visibility: show ? "visible" : "hidden" }}
      data-bs-backdrop="true"
      data-bs-scroll="false"
    >
      <div className="offcanvas-header">
        <h5 id="personalDetailOffcanvasLabel" className="offcanvas-title">
          <button className="back-button" onClick={onClose}>
            <i className="fas fa-arrow-left"></i> Edit Profile
          </button>
        </h5>
        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
      </div>
      <div className="offcanvas-body">
        <div className="form-group">
          <label>Full Name</label>
          <InputComponent
            id="fullName"
            value={formData.username}
            type="text"
            onChange={(e) => handleInputChange("username", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <InputComponent
            id="email"
            type="email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Date of Birth</label>
          <InputComponent
            type="date"
            value={formData.dob}
            onChange={(e) => handleInputChange("dob", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Gender</label>
          <div className="radio-group">
            <div className="radio-option">
              <RadioButtonComponent
                value="male"
                checked={formData.gender === "male"}
                onChange={() => handleInputChange("gender", "male")}
              />
              <label className="radio-label">Male</label>
            </div>
            <div className="radio-option">
              <RadioButtonComponent
                value="female"
                checked={formData.gender === "female"}
                onChange={() => handleInputChange("gender", "female")}
              />
              <label className="radio-label">Female</label>
            </div>
            <div className="radio-option">
              <RadioButtonComponent
                value="other"
                checked={formData.gender === "other"}
                onChange={() => handleInputChange("gender", "other")}
              />
              <label className="radio-label">Other</label>
            </div>
          </div>
        </div>
        <ButtonComponent
          text="Save & Continue"
          className="save-button"
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default PersonalDetailOffcanvas;