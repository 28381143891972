import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBox, faTruckLoading, faTruck, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './DetailedStatusModal.scss';

const DetailedStatusModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered className="detailed-status-modal">
      <Modal.Header closeButton>
        <h5>Detailed Status</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="status-timeline">
          {/* Order Placed */}
          <div className="status-step active">
            <div className="icon-container">
              <FontAwesomeIcon icon={faCheckCircle} className="order-placed-icon" />
            </div>
            <div className="status-content">
              <p className="status-title">Order placed</p>
              <div className="status-time">10 Sep 04:03 PM</div>
              <div className="status-detail">
                <div className="status-detail-header">Order has been assigned to the warehouse</div>
                <div className="status-detail-description">Processing your order...</div>
              </div>
            </div>
          </div>

          {/* Order Packed */}
          <div className="status-step">
            <div className="icon-container">
              <FontAwesomeIcon icon={faBox} className="order-packed-icon" />
            </div>
            <div className="status-content">
              <div className="status-title">Order packed</div>
            </div>
          </div>

          {/* Ready for Dispatch */}
          <div className="status-step">
            <div className="icon-container">
              <FontAwesomeIcon icon={faTruckLoading} className="ready-for-dispatch-icon" />
            </div>
            <div className="status-content">
              <div className="status-title">Ready for dispatch</div>
            </div>
          </div>

          {/* Dispatched */}
          <div className="status-step">
            <div className="icon-container">
              <FontAwesomeIcon icon={faTruck} className="dispatched-icon" />
            </div>
            <div className="status-content">
              <div className="status-title">Dispatched</div>
              <div className="status-expected">Expected by 11 Sep</div>
            </div>
          </div>

          {/* Delivered */}
          <div className="status-step">
            <div className="icon-container">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="delivered-icon" />
            </div>
            <div className="status-content">
              <div className="status-title">Delivered</div>
              <div className="status-expected">Expected by 13 Sep</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DetailedStatusModal;
