import * as cartService from '../../api/services/Addtocart';
import { getCookieCart, saveCookieCart, clearCookieCart } from '../../utils/schema/cookieCart';

export const CREATE_CART = 'CREATE_CART';
export const GET_CART = 'GET_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';

export const CREATE_CART_ITEM = 'CREATE_CART_ITEM';
export const GET_CART_ITEM = 'GET_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';

export const ADD_TO_CART = 'ADD_TO_CART';
export const INCREASE_CART_ITEM = 'INCREASE_CART_ITEM';
export const DECREASE_CART_ITEM = 'DECREASE_CART_ITEM';

export const CREATE_BULK_CARTS = 'CREATE_BULK_CARTS';
export const GET_BULK_CARTS = 'GET_BULK_CARTS';
export const UPDATE_BULK_CARTS = 'UPDATE_BULK_CARTS';
export const DELETE_BULK_CARTS = 'DELETE_BULK_CARTS';

export const CREATE_BULK_CART_ITEMS = 'CREATE_BULK_CART_ITEMS';
export const GET_BULK_CART_ITEMS = 'GET_BULK_CART_ITEMS';
export const UPDATE_BULK_CART_ITEMS = 'UPDATE_BULK_CART_ITEMS';
export const DELETE_BULK_CART_ITEMS = 'DELETE_BULK_CART_ITEMS';
export const CLEAR_CART_STATE = 'CLEAR_CART_STATE';

// Single Item Actions

export const createCart = (data, token) => async (dispatch) => {
    if (token) {
        //console.log('----data user id', data);
        const response = await cartService.createCart(data, token);
    }
};

export const getCart = (userId, token) => async (dispatch) => {
    try {
        if (token) {
            const response = await cartService.getCart(userId, token);
            //console.log(response, '----cart service');
            dispatch({ type: GET_CART, payload: response });
        } else {
            const cookieCart = getCookieCart();
            dispatch({ type: GET_CART, payload: cookieCart });
        }
        return true;
    } catch {
        return false;
    }
};

export const updateCart = (data, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.updateCart(data, token);
        dispatch({ type: UPDATE_CART, payload: response });
    }
};

export const deleteCart = (cartId, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.deleteCart(cartId, token);
        dispatch({ type: DELETE_CART, payload: response });
    } else {
        clearCookieCart();
        dispatch({ type: DELETE_CART, payload: [] });
    }
};

// Cart Item Actions

export const addToCart = (product, token) => async (dispatch, getState) => {
    const productId = product.product_id;
    const price_per_unit = product.price;
    const total_price = price_per_unit;

    if (token) {
        const cart = getState().cart;
        const cart_id = cart.cart.cart_id;
        const data = { product_id: productId, quantity: product.minimum_order_quantity, total_price, price_per_unit, cart_id };
        await cartService.createCartItem(data, token);
        dispatch({ type: ADD_TO_CART, payload: data });
        await dispatch(getBulkCartItems(cart.cart.cart_id, token));
    } else {
        let cartItems = getCookieCart();
        const existingItem = cartItems.find(item => item.product_id === productId);

        if (existingItem) {
            cartItems = cartItems.map(item =>
                item.product_id === productId
                    ? {
                          ...item,
                          quantity: item.quantity + 1,
                          price_per_unit,
                          total_price: price_per_unit * (item.quantity + 1),
                      }
                    : item
            );
        } else {
            cartItems = [
                ...cartItems,
                {
                    product_id: productId,
                    quantity: product.minimum_order_quantity,
                    price_per_unit,
                    total_price,
                },
            ];
        }
        saveCookieCart(cartItems);
        dispatch({ type: ADD_TO_CART, payload: product });
    }
};

export const increaseCartItem = (cartItem, token) => async (dispatch, getState) => {
    if (token) {
        const response = await cartService.updateCartItem(cartItem, token);
        dispatch({ type: INCREASE_CART_ITEM, payload: cartItem });
    } else {
        const cartItemId = cartItem.product_id;
        const price_per_unit = cartItem.price;
        let cartItems = getCookieCart().map(item =>
            item.product_id === cartItemId
                ? {
                      ...item,
                      quantity: item.quantity + 1,
                      price_per_unit,
                      total_price: price_per_unit * (item.quantity + 1),
                  }
                : item
        );
        saveCookieCart(cartItems);
        dispatch({ type: INCREASE_CART_ITEM, payload: cartItem });
    }
};

export const decreaseCartItem = (cartItem, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.updateCartItem(cartItem, token);
        dispatch({ type: DECREASE_CART_ITEM, payload: cartItem });
    } else {
        const cartItemId = cartItem.product_id;
        const price_per_unit = cartItem.price;
        let cartItems = getCookieCart()
            .map(item =>
                item.product_id === cartItemId
                    ? {
                          ...item,
                          quantity: item.quantity - 1,
                          price_per_unit,
                          total_price: price_per_unit * (item.quantity - 1),
                      }
                    : item
            )
            .filter(item => item.quantity > 0);
        saveCookieCart(cartItems);
        dispatch({ type: DECREASE_CART_ITEM, payload: cartItem });
    }
};

export const createBulkCartItems = (data, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.createBulkCartItems(data, token);
        dispatch({ type: CREATE_BULK_CART_ITEMS, payload: response });
    } else {
        let cartItems = getCookieCart();
        cartItems = [...cartItems, ...data.map(item => ({ product_id: item.product_id, quantity: item.quantity }))];
        saveCookieCart(cartItems);
        dispatch({ type: CREATE_BULK_CART_ITEMS, payload: cartItems });
    }
};

export const getBulkCartItems = (cart_id, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.getBulkCartItems(cart_id, token);
        dispatch({ type: GET_BULK_CART_ITEMS, payload: response });
    } else {
        const cookieCart = getCookieCart();
        dispatch({ type: GET_BULK_CART_ITEMS, payload: cookieCart });
    }
};

export const updateBulkCartItems = (data, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.updateBulkCartItems(data, token);
        dispatch({ type: UPDATE_BULK_CART_ITEMS, payload: response });
    } else {
        let cartItems = getCookieCart().map(item =>
            data.find(i => i.product_id === item.product_id) || item
        );
        saveCookieCart(cartItems);
        dispatch({ type: UPDATE_BULK_CART_ITEMS, payload: cartItems });
    }
};

export const deleteBulkCartItems = (cartItemIds, token) => async (dispatch) => {
    if (token) {
        const response = await cartService.deleteBulkCartItems(cartItemIds, token);
        dispatch({ type: DELETE_BULK_CART_ITEMS, payload: cartItemIds });
    } else {
        const cartItems = getCookieCart().filter(item => !cartItemIds.includes(item.product_id));
        saveCookieCart(cartItems);
        dispatch({ type: DELETE_BULK_CART_ITEMS, payload: cartItems });
    }
};

export const deleteCartItem = (cartItemId, token) => async (dispatch,getState) => {
    //console.log(cartItemId,token,'---remove cart item')
    if (token) {
        try {
            // Make API call to delete the cart item
           
            // Fetch the current cart items from the state
            const currentCartItems = getState().cart.cartItems;

            // Remove the specific cart item from the state by filtering
            const updatedCartItems = currentCartItems.find(
                (item) => item.cart_item_id === cartItemId
            );
            //console.log(updatedCartItems ,'-----currentCartItems')
            await cartService.deleteCartItem(cartItemId, token);

            // Dispatch the updated cart items to the Redux store
            dispatch({
                type: DELETE_CART_ITEM,
                payload: updatedCartItems.product_id,
            });

            //console.log("Cart item deleted successfully");
        } catch (error) {
        
        }
    } else {
        //console.log(cartItemId,token,'---remove cart item1')
        const cartItems = getCookieCart().filter(item => item.product_id !== cartItemId);
        saveCookieCart(cartItems);
        dispatch({ type: DELETE_CART_ITEM, payload: cartItemId });
    }
};
export const clearCartState = () => ({
    type: CLEAR_CART_STATE,
  });
  