// redux/actions/purchaseActions.js

import * as purchaseService from '../../api/services/purchaseService';

// Action Types
export const CREATE_PURCHASE = 'CREATE_PURCHASE';
export const GET_PURCHASE = 'GET_PURCHASE';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const DELETE_PURCHASE = 'DELETE_PURCHASE';
export const GET_BULK_PURCHASES = 'GET_BULK_PURCHASES';
export const DELETE_BULK_PURCHASES = 'DELETE_BULK_PURCHASES'; // New bulk delete action type

export const CREATE_PURCHASE_ITEM = 'CREATE_PURCHASE_ITEM';
export const GET_PURCHASE_ITEM = 'GET_PURCHASE_ITEM';
export const UPDATE_PURCHASE_ITEM = 'UPDATE_PURCHASE_ITEM';
export const DELETE_PURCHASE_ITEM = 'DELETE_PURCHASE_ITEM';
export const GET_BULK_PURCHASE_ITEMS = 'GET_BULK_PURCHASE_ITEMS';

export const CREATE_BULK_PURCHASE_ITEMS = 'CREATE_BULK_PURCHASE_ITEMS';
export const UPDATE_BULK_PURCHASE_ITEMS = 'UPDATE_BULK_PURCHASE_ITEMS';
export const DELETE_BULK_PURCHASE_ITEMS = 'DELETE_BULK_PURCHASE_ITEMS';

// Single Purchase Actions
export const createPurchase = (data, token) => async (dispatch) => {
    const response = await purchaseService.createPurchase(data, token);
    dispatch({ type: CREATE_PURCHASE, payload: response });
    return response;
};

export const getPurchase = (purchaseId, token) => async (dispatch) => {
    const response = await purchaseService.getPurchase(purchaseId, token);
    dispatch({ type: GET_PURCHASE, payload: response });
};

export const updatePurchase = (data, token) => async (dispatch) => {
    //console.log(data,'------------data')
    const response = await purchaseService.updatePurchase(data, token);
    dispatch({ type: UPDATE_PURCHASE, payload: data});
};

export const deletePurchase = (purchaseId, token) => async (dispatch) => {
    try {
        const response = await purchaseService.deletePurchase(purchaseId, token);
        dispatch({ type: DELETE_PURCHASE, payload: { purchaseId } });
        return true; // Indicate success
    } catch (error) {
        //console.error('Error deleting purchase:', error);
        throw error; // Propagate the error
    }
};


// Bulk Purchase Actions
export const getBulkPurchases = (token) => async (dispatch) => {
    const response = await purchaseService.getBulkPurchases(token);
    dispatch({ type: GET_BULK_PURCHASES, payload: response });
};

export const deleteBulkPurchases = (purchaseIds, token) => async (dispatch) => {
    const response = await purchaseService.deleteBulkPurchases(purchaseIds, token);
    dispatch({ type: DELETE_BULK_PURCHASES, payload: { deletedPurchaseIds: purchaseIds } });
};

// Single Purchase Item Actions
export const createPurchaseItem = (data, token) => async (dispatch) => {
    const response = await purchaseService.createPurchaseItem(data, token);
    dispatch({ type: CREATE_PURCHASE_ITEM, payload: response });
};

export const getPurchaseItem = (purchaseItemId, token) => async (dispatch) => {
    const response = await purchaseService.getPurchaseItem(purchaseItemId, token);
    dispatch({ type: GET_PURCHASE_ITEM, payload: response });
};

export const updatePurchaseItem = (data, token) => async (dispatch) => {
    const response = await purchaseService.updatePurchaseItem(data, token);
    dispatch({ type: UPDATE_PURCHASE_ITEM, payload:data });
};

export const deletePurchaseItem = (purchaseItemId, token) => async (dispatch) => {
    const response = await purchaseService.deletePurchaseItem(purchaseItemId, token);
    dispatch({ type: DELETE_PURCHASE_ITEM, payload: { purchaseItemId } });
};

// Bulk Purchase Item Actions
export const getBulkPurchaseItems = (token) => async (dispatch) => {
    const response = await purchaseService.getBulkPurchaseItems(token);
    dispatch({ type: GET_BULK_PURCHASE_ITEMS, payload: response });
};

export const createBulkPurchaseItems = (data, token) => async (dispatch) => {
    //console.log(data,'------data')
    const response = await purchaseService.createBulkPurchaseItems(data, token);
    // dispatch({ type: CREATE_BULK_PURCHASE_ITEMS, payload: data });
};

export const updateBulkPurchaseItems = (data, token) => async (dispatch) => {
    //console.log(data,'------data')
    const response = await purchaseService.updateBulkPurchaseItems(data, token);
    dispatch({ type: UPDATE_BULK_PURCHASE_ITEMS, payload: data });
};

export const deleteBulkPurchaseItems = (data, token) => async (dispatch) => {
    const response = await purchaseService.deleteBulkPurchaseItems(data, token);
    dispatch({ type: DELETE_BULK_PURCHASE_ITEMS, payload: { deletedItems: data } });
};
