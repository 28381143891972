import React, { useState, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../common/buttons/ButtonComponent';
import { createAddress, updateAddress, fetchAddress, fetchBulkAddresses } from '../../../redux/actions/AddressAction';
import './AddressOffcanvas.scss';

const AddressOffcanvas = ({ show, handleClose, onSaveAndContinueClick, initialValues = {} }) => {
  const [placement, setPlacement] = useState('end');
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.user);

  // Handle placement of the Offcanvas based on screen size
  useEffect(() => {
    const updateDeviceSize = () => {
      const width = window.innerWidth;
      setPlacement(width >= 992 ? 'end' : 'bottom');
    };
    updateDeviceSize();
    window.addEventListener('resize', updateDeviceSize);
    return () => window.removeEventListener('resize', updateDeviceSize);
  }, []);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Full Address is required'),
    pincode: Yup.string()
      .required('Pincode is required')
      .matches(/^\d{6}$/, 'Pincode must be exactly 6 digits'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    address_type: Yup.string().required('Address type is required'),
  });

  // Form submission handler
  const handleSubmit = async (values, { setSubmitting }) => {
    ////console.log(initialValues,'---valuessss')
    try {
      const dataToSend = {
        ...values,
        user_id: userId,
        address_id:initialValues.address_id
      };
      ////console.log('Submitting Data:', dataToSend,values);

      if (!dataToSend.address_id) {
        await dispatch(createAddress(dataToSend, token));
        await dispatch(fetchBulkAddresses(userId, token));

      } else {
        await dispatch(updateAddress(dataToSend, token));
        await dispatch(fetchBulkAddresses(userId, token));

      }

      setSubmitting(false);
      handleClose();

    
    } catch (error) {
      setSubmitting(false);
      // //console.error('Error creating address:', error);
    }
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement={placement}>
      <Offcanvas.Header closeButton>
        <p className="address-title">Address Details</p>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column">
        <div className="address-form flex-grow-1">
          <Formik
            initialValues={{
              address: initialValues.address || '',
              pincode: initialValues.pincode || '',
              city: initialValues.city || '',
              state: initialValues.state || '',
              address_type: initialValues.address_type || '',
              
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting, setFieldValue, values, isValid }) => (
              <Form>
                <div className="address-group">
                  <span className="address-label">Full Address</span>
                  <Field
                    name="address"
                    as="textarea"
                    className="form-control"
                    placeholder="Enter full address"
                  />
                  <ErrorMessage name="address" component="div" className="error-text" />
                </div>
                <div className="address-group">
                  <span className="address-label">Enter Pincode</span>
                  <Field
                    name="pincode"
                    type="text"
                    className="form-control"
                    placeholder="Enter pincode"
                  />
                  <ErrorMessage name="pincode" component="div" className="error-text" />
                </div>
                <div className="address-city-state">
                  <div className="address-group">
                    <span className="address-label">City</span>
                    <Field
                      name="city"
                      type="text"
                      className="form-control"
                      placeholder="Enter city"
                    />
                    <ErrorMessage name="city" component="div" className="error-text" />
                  </div>
                  <div className="address-group">
                    <span className="address-label">State</span>
                    <Field
                      name="state"
                      type="text"
                      className="form-control"
                      placeholder="Enter state"
                    />
                    <ErrorMessage name="state" component="div" className="error-text" />
                  </div>
                </div>
                <div className="address-group">
                  <span className="address-label">Save as</span>
                  <div className="save-as-buttons">
                    <ButtonComponent
                      text="Home"
                      className={`save-as-button ${values.address_type === 'Home' ? 'active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue('address_type', 'Home');
                      }}
                    />
                    <ButtonComponent
                      text="Work"
                      className={`save-as-button ${values.address_type === 'Work' ? 'active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue('address_type', 'Work');
                      }}
                    />
                    <ButtonComponent
                      text="Other"
                      className={`save-as-button ${values.address_type === 'Other' ? 'active' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue('address_type', 'Other');
                      }}
                    />
                  </div>
                  <ErrorMessage name="address_type" component="div" className="error-text" />
                </div>
                <div className="address-save-continue">
                  <ButtonComponent
                    text={isSubmitting ? 'Saving...' : 'Save & Continue'}
                    type="submit"
                    className="address-save-continue-btn"
                    disabled={isSubmitting || !isValid}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddressOffcanvas;
