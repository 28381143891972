import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  removeNotification,
  bulkGetNotifications,
  bulkRemoveNotifications,
} from "../../redux/actions/NotificationCreationAction";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import CheckboxComponent from "../../components/common/CheckboxComponent/CheckboxComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash, faHome, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import "./notification-creation-displaypage.scss";

const NotificationCreationDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const notifications = useSelector((state) => state.notifications.notifications|| []);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
 const productList = useSelector((state) => state.products.basicProducts || []);
   const locations = useSelector((state) => state.location.locations || []);
 
   useEffect(() => {
    if (!token) {
      //console.warn("No token available. Skipping notification fetch.");
      return;
    }
  
    const fetchNotifications = () => {
      //console.log("Fetching notifications...");
      dispatch(bulkGetNotifications(token));
    };
  
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 180000); // Fetch every 3 minutes
    return () => clearInterval(intervalId);
  }, [dispatch, token]);
  
  const filteredNotifications = Array.isArray(notifications)
  ? notifications.filter((notification) =>
    notification.user_group?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  notification.status?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];


  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds((prev) => [...prev, id]);
    }
  };
  
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = filteredNotifications.map((notification) => notification.notification_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };
    useEffect(() => {
      if (selectedIds.length === filteredNotifications.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }, [selectedIds, filteredNotifications.length]);

  const handleNavigation = () => {
    navigate("/admin/add-notification-creation");
  };

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one notification to proceed.");
      return;
    }
    if (action === "delete") {
      if (window.confirm("Are you sure you want to delete the selected notifications?")) {
        dispatch(bulkRemoveNotifications(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };
  const getProductName = (productIds) => {
    if (!Array.isArray(productIds)) {
      return "No Products"; // Default message if not an array
    }
  
    return productIds
      .map((productId) => {
        const product = productList.find(
          (prod) => String(prod.product_id) === String(productId)
        );
        return product ? product.product_name : "Unknown Product";
      })
      .join(", "); // Join product names
  };
  
  const getLocationName = (locationIds) => {
    if (!Array.isArray(locationIds)) {
      return "No Locations"; // Default message if not an array
    }
  
    return locationIds
      .map((locationId) => {
        const location = locations.find((loc) => loc.location_id === locationId);
        return location ? location.location_name : "Unknown Location";
      })
      .join(", "); // Join location names
  };
  
  

  useEffect(() => {
    //console.log("Product List:", productList); // Debugging log
  }, [productList]);
  


  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this notification?")) {
      try {
        await dispatch(removeNotification(id, token));
        toast.success("Notification deleted successfully.");
      } catch (error) {
        toast.error("Failed to delete the notification.");
      }
    }
  };

  const handleEditClick = (id) => {
    if (id) {
      navigate("/admin/add-notification-creation", { state: id });
    } else {
      //console.error("Invalid notification ID");
    }
  };
  

  return (
    <div className="notification-creation-page">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <span className="breadcrumb-text">Notifications</span>
      </div>
      <div className="notification-list-container"></div>
      <div className="notification-list-class">
        <div className="right-section">
          <h5 className="text-heading1">Notifications</h5>
          <div className="toolbar">
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search by user group"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="item-controls">
              <ButtonComponent
                text="+ Add Notification"
                className="add-items-button"
                onClick={handleNavigation} // Call navigation function
              />
              <div className="actions-toolbar">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    className="dropdown-toggle"
                    id="actions-dropdown"
                  >
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleActionSelect("delete")}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="table-container">
            {filteredNotifications.length === 0 ? (
              <div className="no-data-message">No notifications found.</div>
            ) : (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>
                      <CheckboxComponent
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>Notification ID</th>
                    <th>User Group</th>
                    <th>Product Name</th>
                    <th>Target Area</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>Content</th>
                    <th>Actions</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
  {filteredNotifications.map((notification, index) => (
    <tr key={notification.notification_id}>
      <td>
        <CheckboxComponent
          checked={selectedIds.includes(notification.notification_id)}
          onChange={() => handleCheckboxChange(notification.notification_id)}
        />
      </td>
      <td>{index + 1}</td>
      <td>{notification.user_group}</td>
      <td>
        {notification.user_group === "product" && notification.product_id
          ? getProductName(notification.product_id)
          :null}
      </td>
      <td>
        {notification.user_group === "location" && notification.target_area
          ? getLocationName(notification.target_area)
          : null}
      </td>
      <td>{notification.category}</td>
      <td>{notification.status}</td>
      <td>{notification.content}</td>
      <td className="actions-column">
        <button
          className="icon-button edit-button"
          onClick={() => handleEditClick(notification.notification_id)}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      </td>
      <td className="actions-column">
        <button
          className="icon-button delete-button"
          onClick={() => handleDelete(notification.notification_id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  ))}
</tbody>



              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCreationDisplayPage;
