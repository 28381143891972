import React from 'react';
import { Modal, Carousel } from 'react-bootstrap'; // Import Carousel from react-bootstrap
import gel from '../../../assets/images/gel.avif'; // Import the gel.avif image
import './MedicineDetailsModal.scss'; // Import your custom SCSS file for styling

const MedicineDetailsModal = ({ show, handleClose, medicineData }) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Medicine details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="medicine-details-container">
          <div className="medicine-details-left">
            {/* Carousel for displaying images */}
            <Carousel indicators={true}>
              <Carousel.Item>
              <img src={gel} className="medicine-image" alt="Medicine Gel" />
              </Carousel.Item>
            </Carousel>

            <div className="medicine-image-pagination">
              <span className="pagination-dot active"></span>
              <span className="pagination-dot"></span>
              <span className="pagination-dot"></span>
            </div>
          </div>
          <div className="medicine-details-right">
            <div className="fixed-content">
              <h3 className="medicine-title">{medicineData.title}</h3>
              <p className="medicine-company">{medicineData.company}</p>
              <p className="medicine-size">{medicineData.size}</p>
              <p className="medicine-mrp">MRP: <del>₹{medicineData.mrp}</del></p>
              <p className="medicine-price">₹{medicineData.price}</p>
              <p className="medicine-discount applied-discount">✔ {medicineData.discount}% off applied</p>
              <p className="medicine-info">*MRP inclusive of all taxes</p>
            </div>

            <div className="scrollable-content">
              <div className="medicine-features">
                <div className="feature">
                  <i className="fa fa-check-circle"></i> 100% genuine medicines
                </div>
                <div className="feature">
                  <i className="fa fa-lock"></i> Safe & secure payments
                </div>
                <div className="feature">
                  <i className="fa fa-undo"></i> 15 days Easy returns
                </div>
              </div>

              {/* Composition and Product Highlights Section */}
              <div className="composition-section">
                <h5 className="composition-title">
                  <i className="fa fa-flask"></i> Composition
                </h5>
                <p>All Other Combinations (0 Mg)</p>
              </div>

              <div className="product-highlights">
                <h5 className='product-highlights-title'>Product Highlights</h5>
                <ul className='product-highlights-list'>
                  <li>It helps manage acne and pimples.</li>
                  <li>It helps regulate excess sebum production.</li>
                  <li>It helps unclog clogged pores.</li>
                  <li>Helps hydrate the skin.</li>
                  <li>Provides anti-inflammatory benefits.</li>
                  <li>Supports skin health.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MedicineDetailsModal;
