import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CategoriesCard from '../../components/common/CategoriesCard/CategoriesCard';
import BrandsOffcanvas from '../../components/BrandsOffcanvas/BrandsOffcanvas';
import Pagination from '../../components/common/Pagination/Pagination';
import './BrandProducts.scss';
import { useSelector,useDispatch } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';
import ProductCard from '../../components/common/ProductCard/ProductCard';
import { addToCart, decreaseCartItem, increaseCartItem,deleteCartItem } from '../../redux/actions/Addtocart';
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
const BrandProducts= () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(null); // Track the selected brand
  const [selectedCategories, setSelectedCategories] = useState([]); // Track selected categories
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 23;
  
  const [brandName,setBrandName]=useState('')
  const authData = useSelector((state) => state.auth.user) || '';
  const categories = useSelector(state => state.category.categoryList.categories) || [];
  const brands = useSelector(state => state.brand.brandList.brands) || [];
  const products = useSelector(state => state.products.basicProducts) || [];
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn) || '';
  const token = useSelector((state) => state.auth.token) || '';
  // Function to filter products based on the selected category and brand
  const locationBrandId = location.state || null;
  const [selectedCategory, setSelectedCategory] = useState(null);


  const filterProducts = (selectedCategories, selectedBrand) => {
    let filtered = products;

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(product => selectedCategories.includes(product.category_id));
    }

    if (selectedBrand) {
      filtered = filtered.filter(product => product.brand_id === selectedBrand);
    }

    setFilteredProducts(filtered);
  };

  // Handle category selection
  const handleCategoryChange = (categoryId) => {
    let updatedCategories = [...selectedCategories];

    if (updatedCategories.includes(categoryId)) {
      updatedCategories = updatedCategories.filter((id) => id !== categoryId);
    } else {
      updatedCategories.push(categoryId);
    }

    setSelectedCategories(updatedCategories);
    filterProducts(updatedCategories, selectedBrands);
  };

  // Handle brand selection
  const handleBrandChange = (brandId) => {
    setSelectedBrands(brandId);
    filterProducts(selectedCategories, brandId);

    const brand = brands.find((brand) => brand.brand_id === brandId);
    setBrandName(brand ? brand.name : '');
  };

  // Clear category filter
  const clearCategoryFilter = () => {
    setSelectedCategories([]);
    filterProducts([], selectedBrands);
  };

  // Clear brand filter
  const clearBrandFilter = () => {
    setSelectedBrands(null);
    filterProducts(selectedCategories, null);
  };

  // Set initial products on component load
  useEffect(() => {
    filterProducts(selectedCategories, selectedBrands);
  }, [products]);

  const handleAddToCart = (product) => {
    if(authData){
      dispatch(addToCart(product, token));
    }
    else{
      dispatch(addToCart(product, ''));
  }
  };
  const handleIncreaseQuantity = (productId) => {

    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)) {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity + 1 };
      dispatch(increaseCartItem(updatedCartItem, token));
    }
    else{
      if(cartItem.quantity >= Number(productId.max_order_quantity)){
        toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
      }
      else{
        toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
      }
    }
    
    }
    else{
      const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
      if (cartItem && cartItem.quantity < Number(productId.max_order_quantity) && cartItem.quantity <= Number(productId.stock_level)){
        dispatch(increaseCartItem(productId,''));
      }
      else{
        if(cartItem.quantity >= Number(productId.max_order_quantity)){
          toast.error(`A user can order only up to the specified maximum limit of ${productId.max_order_quantity} items for this product.`);
        }
        else{
          toast.error(`Cannot add more! Only ${productId.stock_level} units available.`);
        }
      }
      
    }
    
  };

  const handleDecreaseQuantity = (productId) => {
    if(authData){
     
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity))  {
      const updatedCartItem = { ...cartItem, quantity: cartItem.quantity - 1 };
      dispatch(decreaseCartItem(updatedCartItem, token));
    }

    else{
      removefromCart(productId.product_id)
    }
  }
  else{
    const cartItem = cartItems.find((item) => item.product_id === productId.product_id);
    if (cartItem && cartItem.quantity > Number(productId.minimum_order_quantity)){
      dispatch(decreaseCartItem(productId, ''));
    }
    else{
      removefromCart(productId.product_id)
    }
   
  }
  };

  const removefromCart = (productId) => {
    if(authData){
      const cartItem = cartItems.find((item) => item.product_id === productId);
      if (cartItem) {
        dispatch(deleteCartItem(cartItem.cart_item_id, token));
      }
    }
    else{
      
      dispatch(deleteCartItem(productId, token))
    }
   
  };


  // Get the quantity of a product in the cart
  const getProductQuantity = (productId) => {
    const item = cartItems.find(item => item.product_id === productId);
 
    return item ? item.quantity : 0;
  };

 
  useEffect(() => {
    if (locationBrandId) {
      setSelectedBrands(locationBrandId); // Set the initial brand from location
      filterProducts(selectedCategories, locationBrandId); // Filter products by brand
    } else {
      filterProducts(selectedCategories, selectedBrands);
    }
    const brand = brands.find((brand) => brand.brand_id === locationBrandId);
    setBrandName(brand ? brand.name : '');
  }, [products, locationBrandId]);

  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handlePageChange = (page) => setCurrentPage(page);

  return (
    <div className="categories-page">
      <div className="breadcrumb-container">
      <Link to="/" className="breadcrumb-text">
      <span className="breadcrumb-text">Home</span>
        </Link>
        
        <span className="breadcrumb-separator">{' / '}</span>
         
       
        <span className="breadcrumb-text">Brands</span>
      </div>
      <div className="main-content">
        <div className="left-section">
          <h4>Filters</h4>
          <div className="filters-section">
          <div className="filter-container">
              <h6 className="filter-title">
                Brand 
                {selectedBrands && <span className="clear-link" onClick={clearBrandFilter}>Clear</span>}
              </h6>
              <hr />
              <div className="filter-options">
                {brands.map((brand) => (
                  <div className="filter-option" key={brand.brand_id}>
                    <label>
                      <input
                        type="radio"
                        name="brand"
                        checked={selectedBrands === brand.brand_id}
                        onChange={() => handleBrandChange(brand.brand_id)}
                      />
                      <span>{brand.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* Filter by Categories */}
            <div className="filter-container">
              <h6 className="filter-title">
                Categories 
                {selectedCategories.length > 0 && <span className="clear-link" onClick={clearCategoryFilter}>Clear</span>}
              </h6>
              <hr />
              <div className="filter-options">
                {categories.map((category) => (
                  <div className="filter-option" key={category.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedCategories.includes(category.category_id)}
                        onChange={() => handleCategoryChange(category.category_id)}
                      />
                      <span>{category.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Filter by Brand */}
           

          </div>
        </div>

        <div className="right-section">
          <div className="header">
            <h4 className="skin-care-title">{brandName}</h4>
            <div className="filter-button" onClick={handleShowOffcanvas}>
              <FontAwesomeIcon icon={faFilter} />
              <span>Filter</span>
            </div>
          </div>
          <hr />
          <div className="products-grid">
            {filteredProducts.map((product) => {
              
              return (
                <ProductCard
                      key={product.product_id}
                      productName={product.product_name}
                      mrp={product.mrp}
                      price={product.price}
                      quantity={getProductQuantity(product.product_id)} // Pass the current quantity
                      onAddToCart={() => handleAddToCart(product)}
                      onIncrease={() => handleIncreaseQuantity(product)}
                      onDecrease={() => handleDecreaseQuantity(product)}
                      onRemove={()=>removefromCart(product.product_id)}
                    description={product.description}
                    min_order={product.minimum_order_quantity}
                    stock_level={product.stock_level}
                    />
              );
            })}
          </div>
          {/* <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} /> */}
        </div>
      </div>
      <BrandsOffcanvas
        show={showOffcanvas}
        handleClose={() => setShowOffcanvas(false)}
        brands={brands}
        categories={categories}
        selectedBrands={selectedBrands}
        selectedCategories={selectedCategories}
        onBrandChange={handleBrandChange}
        onCategoryChange={handleCategoryChange}
        onClearBrand={clearBrandFilter}
        onClearCategory={clearCategoryFilter}
      />


    </div>
  );
};

export default BrandProducts;








