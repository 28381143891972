// reducers/bannerReducer.js
import {
    FETCH_BANNERS_REQUEST,
    FETCH_BANNERS_SUCCESS,
    FETCH_BANNERS_FAILURE,
    CREATE_BANNER_REQUEST,
    CREATE_BANNER_SUCCESS,
    CREATE_BANNER_FAILURE,
    UPDATE_BANNER_REQUEST,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_FAILURE,
    DELETE_BANNER_REQUEST,
    DELETE_BANNER_SUCCESS,
    DELETE_BANNER_FAILURE
} from '../actions/bannerAction';

const initialState = {
    banners: [],
    loading: false,
    error: null,
};

// Banner Reducer
const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BANNERS_REQUEST:
        case CREATE_BANNER_REQUEST:
        case UPDATE_BANNER_REQUEST:
        case DELETE_BANNER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        
        case FETCH_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: action.payload, // Expecting an array of banners
            };

        case CREATE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: [...state.banners, action.payload], // Adds the new banner
            };

        case UPDATE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: state.banners.map(banner =>
                    banner.banner_id === action.payload.banner_id ? action.payload : banner
                ), // Replaces the updated banner
            };

        case DELETE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: Array.isArray(action.payload)
                    ? state.banners.filter(banner => !action.payload.includes(banner.banner_id)) // Bulk delete
                    : state.banners.filter(banner => banner.banner_id !== action.payload), // Single delete
            };

        case FETCH_BANNERS_FAILURE:
        case CREATE_BANNER_FAILURE:
        case UPDATE_BANNER_FAILURE:
        case DELETE_BANNER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default bannerReducer;
