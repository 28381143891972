import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import InputComponent from "../../components/common/InputComponent/InputComponent";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import { FaUpload } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { updateBulkOrders } from "../../redux/actions/orderActions";
import "./orderExport.scss";

const OrderExportTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.adminLogin.token);

  const [orderData, setOrderData] = useState([]);
  const [columns, setColumns] = useState([]); // Columns for the table
  const [isSaving, setIsSaving] = useState(false);

  const defaultFields = [
    "Order ID", // Mandatory field for updates
    "Courier Name",
    "Consignment Number",
    "Order Status",
  ];

  const editableFields = ["Courier Name", "Consignment Number", "Order Status"];

  // Dropdown options for Order Status and Courier Name
  const orderStatusOptions = ["Pending", "Processing", "Shipped", "Delivered"];
  const courierNameOptions = ["FedEx", "DHL", "UPS", "Blue Dart"];

  const handleInputChange = (index, field, value) => {
    setOrderData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = value;
      return updatedData;
    });
  };

  const handleDeleteRow = (index) => {
    setOrderData((prevData) => prevData.filter((_, i) => i !== index));
    toast.success("Order deleted successfully!");
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      const validOrders = orderData
        .filter((order) => order["Order ID"]) // Only include rows with valid Order ID
        .map((order) => {
          // Only send required fields to the API
          const updatedOrder = { "order_id": order["Order ID"] };
          editableFields.forEach((field) => {
            updatedOrder[field.toLowerCase().replace(" ", "_")] = order[field];
          });
          return updatedOrder;
        });
       //console.log(validOrders,'--valid')
      if (validOrders.length === 0) {
        toast.error("No valid orders to update.");
        setIsSaving(false);
        return;
      }
      await dispatch(updateBulkOrders(validOrders, token));
      toast.success("Orders updated successfully!");
      navigate("/admin/order-management");
    } catch (error) {
      toast.error("Failed to save orders.");
      //console.error("Error saving orders:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();
    try {
      let uploadedData = [];
      let sheetColumns = [];

      if (fileExtension === "xlsx" || fileExtension === "xls") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

          sheetColumns = Object.keys(sheetData[0] || {}); // Columns from the sheet
          const allColumns = Array.from(new Set([...sheetColumns, ...defaultFields])); // Merge with default fields
          setColumns(allColumns);

          uploadedData = sheetData.map((order) => {
            const filledOrder = {};
            allColumns.forEach((field) => {
              filledOrder[field] = order[field] || ""; // Add missing fields with blank values
            });
            return filledOrder;
          });
          setOrderData(uploadedData);
        };
        reader.readAsArrayBuffer(file);
      } else if (fileExtension === "csv") {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            sheetColumns = Object.keys(result.data[0] || {}); // Columns from the sheet
            const allColumns = Array.from(new Set([...sheetColumns, ...defaultFields])); // Merge with default fields
            setColumns(allColumns);

            uploadedData = result.data.map((order) => {
              const filledOrder = {};
              allColumns.forEach((field) => {
                filledOrder[field] = order[field] || ""; // Add missing fields with blank values
              });
              return filledOrder;
            });
            setOrderData(uploadedData);
          },
          error: (error) => {
            throw error;
          },
        });
      } else {
        toast.error("Unsupported file format. Please upload a CSV or XLSX file.");
      }
    } catch (error) {
      toast.error("Failed to process file.");
      //console.error("Error processing file:", error);
    }
  };

  return (
    <div className="order-export-main-page">
      <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{">"}</span>
        <Link to="/admin/order-management" className="breadcrumb-text">
          Order List
        </Link>
      </div>
      <div className="order-export-container"></div>
      <div className="order-export">
        <div className="order-table">
          <div className="header-row">
            <h5 className="text-heading1">Bulk Orders</h5>
            <div className="add-new-button">
              <input
                type="file"
                accept=".csv, .xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="fileUpload"
                className="file-upload1"
              />
              <ButtonComponent
                text="Upload File"
                onClick={() => document.getElementById("fileUpload").click()}
                icon={<FaUpload />}
                className="upload-button"
              />
              <ButtonComponent
                text={isSaving ? "Saving..." : "Save"}
                onClick={handleSaveClick}
                className="save-button"
                disabled={isSaving || orderData.length === 0}
              />
            </div>
          </div>
          <div className="table-container">
            {orderData.length > 0 ? (
              <table className="custom-table">
                <thead>
                  <tr>
                    {columns.map((col) => (
                      <th key={col}>{col}</th>
                    ))}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((order, index) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td key={col}>
                          {editableFields.includes(col) ? (
                            col === "Courier Name" || col === "Order Status" ? (
                              <DropdownButton
                                options={
                                  col === "Courier Name"
                                    ? courierNameOptions
                                    : orderStatusOptions
                                }
                                label={order[col]}
                                onSelect={(value) =>
                                  handleInputChange(index, col, value)
                                }
                              />
                            ) : (
                              <InputComponent
                                value={order[col]}
                                onChange={(e) =>
                                  handleInputChange(index, col, e.target.value)
                                }
                              />
                            )
                          ) : (
                            order[col]
                          )}
                        </td>
                      ))}
                      <td>
                        <button
                          className="icon-button action-icon delete-icon"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No orders available. Please upload a file.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderExportTable;
