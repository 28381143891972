import React, { useEffect, useState } from 'react';
import { useDispatch ,useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fetch-orders-popup.scss';

import orderImage from '../../../assets/images/order-image.png'; // Adjust the path based on your folder structure
import { addProcessDelivery } from '../../../redux/actions/ProcessDeliveriesActions';

const FetchOrderPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.adminLogin.token) || [];
  
  useEffect(() => {
    // Get today's date as a string (e.g., "2025-01-04")
    const todayDate = new Date().toISOString().split('T')[0];

    // Check the cookie for the popup display date
    const popupDate = Cookies.get('popupDate');

    if (popupDate !== todayDate) {
      // If the popup hasn't been shown today, display it and set the cookie
      setShowPopup(true);
      Cookies.set('popupDate', todayDate, { expires: 1 }); // Expires in 1 day
    }
  }, []);

  const handleFetchOrders = async () => {
    try {
      //console.log('Dispatching addProcessDelivery with token:', token);
      const response = await dispatch(addProcessDelivery(token)); // Pass only the token
  
      //console.log('Process delivery response:', response);
      setShowPopup(false); // Close the popup after success
    } catch (error) {
      //console.error('Error triggering process delivery:', error);
    }
  };
  
  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  if (!showPopup) {
    return null; // Do not render the popup if it shouldn't be shown
  }

  return (
    <div className="popup-backdrop">
      <div className="order-popup">
        <button className="close-popup-btn" onClick={handleClosePopup}>
          &times;
        </button>
        <div className="popup-content">
          <div className="popup-header">
            <img src={orderImage} alt="Order" className="popup-image" />
          </div>
          <button className="fetch-orders-btn" onClick={handleFetchOrders}>
            Fetch Orders
          </button>
        </div>
      </div>
    </div>
  );
};

export default FetchOrderPopup;
