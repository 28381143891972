import React, { useState } from 'react';
import creamImage from '../../../assets/images/cream.avif'; // Import the image
import './ImageProductCarousel.scss'; // Import styling for the carousel
import { useSelector } from 'react-redux';
import Noproduct from '../../../assets/images/Noproduct.webp'; 

const ImageProductCarousel = ({id}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const productUrls =useSelector((state) => state.image.urls.products) || [];
  // Carousel slides data
  const slides = [creamImage, creamImage, creamImage]; // All three slides use the same image

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="image-product-carousel">
      {/* Carousel */}
      <div className="carousel">
        {/* Display Current Slide */}
        <img src={productUrls[id]?productUrls[id]:Noproduct} alt={`Product Slide ${currentIndex + 1}`} className="carousel-image" />

        {/* Navigation Controls */}
        {/* <button className="carousel-control prev" onClick={handlePrev}>
          &#10094;
        </button>
        <button className="carousel-control next" onClick={handleNext}>
          &#10095;
        </button> */}
      </div>

      {/* Carousel Indicators */}
     
    </div>
  );
};

export default ImageProductCarousel;
