import { business_endpoints } from '../endPoints';
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';

// Fetch all business information
export const fetchAllBusinessInfoAPI = async (token) => {
    //console.log("Fetching all business info...");
    const response = await apiClient.get(
        business_endpoints.GET_BUSINESS_INFO,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    //console.log("Business Info Response:", response.data);
    if (!response.data) {
        throw new Error('Failed to fetch business information');
    }
    return response.data.business_info; // Assuming the API returns a "business_info" array
};

// Fetch single business information by ID
export const fetchBusinessInfoByIdAPI = async (businessId, token) => {
    //console.log("Fetching business info for ID:", businessId);
    
    const response = await apiClient.get(
        business_endpoints.GET_BUSINESS_INFO,
        {
            params:{client_id:businessId},
            headers: {
                
                Authorization: token,
            },
        }
    );
    //console.log('business responseee',response)
    if (!response.data) {
        throw new Error('Failed to fetch business information');
    }
    //console.log("Business Info Data:", response.data);
    return response.data;
};

// Create new business information
export const createBusinessInfoAPI = async (businessData, token) => {
    //console.log("Creating new business info:", businessData);

    const response = await apiClient.post(
        business_endpoints.GET_BUSINESS_INFO,
        {
            shop_id: businessData.shop_id,
            client_id: businessData.client_id,
            internal_id: businessData.internal_id,
            business_name: businessData.business_name,
            license_information: businessData.license_information,
            business_registration: businessData.business_registration,
            business_address: businessData.business_address,
            gst: businessData.gst,
            proof: businessData.proof,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 201) {
        throw new Error('Failed to create business information');
    }
    //console.log("Business Info Created:", response.data);
    return response.data;
};

// Update existing business information
export const updateBusinessInfoAPI = async (businessData, token) => {
    //console.log("Updating business info:", businessData);

    const response = await apiClient.put(
        `${business_endpoints.GET_BUSINESS_INFO}/${businessData.business_id}`,
        {
            shop_id: businessData.shop_id,
            client_id: businessData.client_id,
            internal_id: businessData.internal_id,
            business_name: businessData.business_name,
            license_information: businessData.license_information,
            business_registration: businessData.business_registration,
            business_address: businessData.business_address,
            gst: businessData.gst,
            proof: businessData.proof,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error(`Failed to update business information with ID: ${businessData.business_id}`);
    }
    //console.log("Business Info Updated:", response.data);
    return response.data;
};

// Delete single business information by ID
export const deleteBusinessInfoAPI = async (businessId, token) => {
    //console.log("Deleting business info for ID:", businessId);

    const response = await apiClient.delete(
        `${business_endpoints.GET_BUSINESS_INFO}/${businessId}`,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error(`Failed to delete business information with ID: ${businessId}`);
    }
    //console.log("Business Info Deleted:", response.data);
    return response.data;
};

