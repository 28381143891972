import {
  FETCH_ALLOCATED_COUPONS_REQUEST,
  FETCH_ALLOCATED_COUPONS_SUCCESS,
  FETCH_ALLOCATED_COUPONS_FAILURE,
  CLEAR_ALLOCATED_COUPONS,
  DELETE_ALLOCATED_COUPON_REQUEST,
  DELETE_ALLOCATED_COUPON_SUCCESS,
  DELETE_ALLOCATED_COUPON_FAILURE,
  UPDATE_ALLOCATED_COUPON_REQUEST,
  UPDATE_ALLOCATED_COUPON_SUCCESS,
  UPDATE_ALLOCATED_COUPON_FAILURE,
  CREATE_ALLOCATED_COUPON_REQUEST,
  CREATE_ALLOCATED_COUPON_SUCCESS,
  CREATE_ALLOCATED_COUPON_FAILURE,
  BULK_DELETE_ALLOCATED_COUPONS_REQUEST,
  BULK_DELETE_ALLOCATED_COUPONS_SUCCESS,
  BULK_DELETE_ALLOCATED_COUPONS_FAILURE,
  BULK_UPDATE_ALLOCATED_COUPONS_REQUEST,
  BULK_UPDATE_ALLOCATED_COUPONS_SUCCESS,
  BULK_UPDATE_ALLOCATED_COUPONS_FAILURE,
  BULK_CREATE_ALLOCATED_COUPONS_REQUEST,
  BULK_CREATE_ALLOCATED_COUPONS_SUCCESS,
  BULK_CREATE_ALLOCATED_COUPONS_FAILURE,
} from '../actions/couponAllocationAction'; // Adjust the import path as necessary

const initialState = {
  allocatedCoupons: [],
  timestamp: null,
  loading: false,
  error: null,
};

const couponAllocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALLOCATED_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_ALLOCATED_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: action.payload.allocatedCoupons,
        timestamp: action.payload.timestamp,
      };

    case FETCH_ALLOCATED_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_ALLOCATED_COUPONS:
      return { ...state, allocatedCoupons: [], timestamp: null };

    case DELETE_ALLOCATED_COUPON_REQUEST:
      return { ...state, loading: true, error: null };

    case DELETE_ALLOCATED_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: state.allocatedCoupons.filter(coupon => coupon.coupon_allocation_id !== action.payload),
      };

    case DELETE_ALLOCATED_COUPON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_ALLOCATED_COUPON_REQUEST:
      return { ...state, loading: true, error: null };

    case UPDATE_ALLOCATED_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: state.allocatedCoupons.map(coupon =>
          coupon.coupon_allocation_id === action.payload.coupon_allocation_id ? action.payload : coupon
        ),
      };

    case UPDATE_ALLOCATED_COUPON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CREATE_ALLOCATED_COUPON_REQUEST:
      return { ...state, loading: true, error: null };

    case CREATE_ALLOCATED_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: [...state.allocatedCoupons, action.payload],
      };

    case CREATE_ALLOCATED_COUPON_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case BULK_DELETE_ALLOCATED_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case BULK_DELETE_ALLOCATED_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: state.allocatedCoupons.filter(coupon => !action.payload.includes(coupon.coupon_allocation_id)),
      };

    case BULK_DELETE_ALLOCATED_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case BULK_UPDATE_ALLOCATED_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case BULK_UPDATE_ALLOCATED_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: state.allocatedCoupons.map(coupon =>
          action.payload.find(updated => updated.coupon_allocation_id === coupon.coupon_allocation_id) || coupon
        ),
      };

    case BULK_UPDATE_ALLOCATED_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case BULK_CREATE_ALLOCATED_COUPONS_REQUEST:
      return { ...state, loading: true, error: null };

    case BULK_CREATE_ALLOCATED_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allocatedCoupons: [...state.allocatedCoupons, ...action.payload],
      };

    case BULK_CREATE_ALLOCATED_COUPONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default couponAllocationReducer;
