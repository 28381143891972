import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss'
const Datepicker = ({ dateValue, onDateChange, className, placeholder, ...props }) => {
  return (
    <DatePicker
      selected={dateValue}
      onChange={onDateChange}
      className={className}
      placeholderText={placeholder}
        dateFormat="dd MMM yyyy"
      {...props}
    />
  );
};

export default Datepicker;
