import React, { useState } from "react";
import InputComponent from "../../components/common/InputComponent/InputComponent";
import CheckboxComponent from "../../components/common/CheckboxComponent/CheckboxComponent";
import RadioButtonComponent from "../../components/common/RadioButtonComponent/RadioButtonComponent";
import DropdownButton from "../../components/common/dropdown/DropdownButton";
import Textbox from "../../components/common/Textbox/Textbox";
import ButtonComponent from "../../components/common/buttons/ButtonComponent";
import "./FormListPage.scss";

const MenuItemListPage = () => {
  // State initialization
  const [formData, setFormData] = useState({
    category: "",
    name: "",
    shortCode: "",
    shortCode2: "",
    containerCharges: "",
    basePrice: "",
    profitMargin: "",
    weight: "",
    onlineDisplayName: "",
    dietary: { veg: false, nonVeg: false, egg: false },
    exposeIn: {
      onlineOrders: false,
      captainApp: false,
      kiosk: false,
      other: false,
    },
    orderType: { delivery: false, pickup: false, dineIn: false },
    gstType: "",
    allDays: false,
    allowDecimalQuantity: false,
    description: "",
  });

  

  const VariationForm = () => {
    // State to hold multiple variations
    const [variations, setVariations] = useState([
      {
        variationChecked: false,
        serves: '',
        setAsCombo: false,
        fields: {
          variation: '',
          price: '',
          containerCharges: '',
          sapCode: '',
          profitMargin: '',
        }
      }
    ]);
  
  }
  const formFields = [
    { label: 'Variation', type: 'input', key: 'variation', required: true },
    { label: 'Price', type: 'input', key: 'price', required: true },
    { label: 'Container Charges', type: 'input', key: 'containerCharges', required: true },
    { label: 'Sap Code', type: 'input', key: 'sapCode' },
    { label: 'Profit Margin (%)', type: 'input', key: 'profitMargin' },
  ];
  
  const checkboxOptions = [
    { key: "beverage", label: "Beverage" },
    { key: "spicy", label: "spicy" },
    { key: "seasonal", label: "seasonal" },
    { key: "chefSpecial", label: "chef-special" },
    { key: "zomatoTreatItem", label: "Zomato treat item" },
    { key: "meal", label: "meal" },
    { key: "cake", label: "cake" },
    { key: "southIndian", label: "South Indian" },
    { key: "partyOrder", label: "Party Order" },
    { key: "restaurantRecommended", label: "restaurant-recommended" },
    { key: "new", label: "new" },
    { key: "holiSpecial", label: "holi-special" },
    { key: "vegan", label: "vegan" },
    { key: "homeStyleMeal", label: "home-style-meal" },
    { key: "ham", label: "Ham" },
    { key: "bacon", label: "bacon" },
    { key: "glutenFree", label: "gluten-free" },
    { key: "dairyFree", label: "dairy-free" },
    { key: "customPhotoCake", label: "custom-photo-cake" },
  ];

  const InventoryForm = () => {
    const [formData, setFormData] = useState({
      hsnCode: '',
      sapCode: '',
      fsnCode: '',
      stockStatus: 'Do Not Track',
      createSelfItemRecipe: false,
      nutrition: '',
    });
  }
    const stockStatusOptions = [
      { label: 'Do Not Track', value: 'doNotTrack' },
      { label: 'Track', value: 'track' },
      { label: 'Track with Stock', value: 'trackWithStock' },
    ];
    
  
  // Categories for dropdown
  const categories = [
    { label: "Soup Veg", value: "SOUP_VEG" },
    { label: "Soup Non-Veg", value: "SOUP_NON_VEG" },
    { label: "Salad", value: "SALAD" },
  ];

  // Checkbox change handler (common for all checkboxes)
  const handleCheckboxChange = (event, section = null, key = null) => {
    // Ensure the event and target are not undefined
    if (!event || !event.target) {
      return;
    }

    const { checked } = event.target;

    if (section && key) {
      // For nested state properties (e.g., dietary.veg, orderType.delivery)
      setFormData((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: checked,
        },
      }));
    } else {
      // For flat state properties (e.g., allowDecimalQuantity)
      const checkboxKey = event.target.name;
      if (checkboxKey) {
        setFormData((prevState) => ({
          ...prevState,
          [checkboxKey]: checked,
        }));
      }
    }
  };

  // Handler for category dropdown select
  const handleCategorySelect = (selectedCategory) => {
    setFormData((prevData) => ({
      ...prevData,
      category: selectedCategory,
    }));
  };

  // Input change handler for text inputs
  const handleInputChange = (e) => {
    if (!e || !e.target) return; // Early return if event or target is undefined

    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCancel = () => {
    // Add your cancel logic here
    //console.log('Cancel clicked');
  };

  const handleSaveChange = () => {
    // Add your save logic here
    //console.log('Save Change clicked');
  };

  return (
    <div className="menu-item-list-page">
      <h6>Configuration</h6>
      <hr /> {/* Horizontal line after heading */}
      <form className="menu-item-form"> 
        {/* Row 1 */}
        <div className="form-row">
          <div className="form-column">
            <label>Category *</label>
            <DropdownButton
              label={formData.category || "Select Category"}
              options={categories.map((cat) => ({
                label: cat.label,
                onClick: () => handleCategorySelect(cat.label),
              }))}
              customStyle={{
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
              }}
            />
          </div>
          <div className="form-column">
            <label>Name *</label>
            <InputComponent
              field={{ name: "name" }}
              form={{ touched: {}, errors: {} }}
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-column">
            <label>Online Display Name</label>
            <InputComponent
              field={{ name: "onlineDisplayName" }}
              form={{ touched: {}, errors: {} }}
              value={formData.onlineDisplayName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Row 2 */}
        <div className="form-row">
          <div className="form-column">
            <label>Short Code *</label>
            <InputComponent
              field={{ name: "shortCode" }}
              form={{ touched: {}, errors: {} }}
              value={formData.shortCode}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-column">
            <label>Short Code 2</label>
            <InputComponent
              field={{ name: "shortCode2" }}
              form={{ touched: {}, errors: {} }}
              value={formData.shortCode2}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-column">
            <label>Item Base Price *</label>
            <InputComponent
              field={{ name: "basePrice" }}
              form={{ touched: {}, errors: {} }}
              value={formData.basePrice}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Row 3 */}
        <div className="form-row">
          <div className="form-column">
            <label>Container Charges *</label>
            <InputComponent
              field={{ name: "containerCharges" }}
              form={{ touched: {}, errors: {} }}
              value={formData.containerCharges}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-column">
            <label>Weight (In grams/ml)</label>
            <InputComponent
              field={{ name: "weight" }}
              form={{ touched: {}, errors: {} }}
              value={formData.weight}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-column">
            <label>Profit Margin (%)</label>
            <InputComponent
              field={{ name: "profitMargin" }}
              form={{ touched: {}, errors: {} }}
              value={formData.profitMargin}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Row 4 - Checkboxes */}
        <div className="form-row">
          <div className="form-column">
            <label>Dietary *</label>
            <div className="checkbox-group">
              <CheckboxComponent
                checked={formData.dietary.veg}
                onChange={(e) => handleCheckboxChange(e, "dietary", "veg")}
              />
              <label>Veg</label>
              <CheckboxComponent
                checked={formData.dietary.nonVeg}
                onChange={(e) => handleCheckboxChange(e, "dietary", "nonVeg")}
              />
              <label>Non-Veg</label>
              <CheckboxComponent
                checked={formData.dietary.egg}
                onChange={(e) => handleCheckboxChange(e, "dietary", "egg")}
              />
              <label>Egg</label>
            </div>
          </div>

          <div className="form-column">
            <label>Order Type</label>
            <div className="checkbox-group">
              <CheckboxComponent
                checked={formData.orderType.delivery}
                onChange={(e) =>
                  handleCheckboxChange(e, "orderType", "delivery")
                }
              />
              <label>Delivery</label>
              <CheckboxComponent
                checked={formData.orderType.pickup}
                onChange={(e) => handleCheckboxChange(e, "orderType", "pickup")}
              />
              <label>Pick Up</label>
              <CheckboxComponent
                checked={formData.orderType.dineIn}
                onChange={(e) => handleCheckboxChange(e, "orderType", "dineIn")}
              />
              <label>Dine In</label>
            </div>
          </div>

          <div className="form-column">
            <CheckboxComponent
              checked={formData.orderType.allowDecimalQuantity}
              onChange={(e) => handleCheckboxChange(e)}
              name="allowDecimalQuantity"
            />
            <label>Don't allow decimal value for quantity</label>
          </div>
        </div>

        {/* Row 5 */}
        <div className="form-row">
          <div className="form-column">
            <label>Expose This Items In</label>
            <div className="checkbox-group">
              <CheckboxComponent
                checked={formData.exposeIn.onlineOrders}
                onChange={(e) =>
                  handleCheckboxChange(e, "exposeIn", "onlineOrders")
                }
              />
              <label>Online Orders</label>
              <CheckboxComponent
                checked={formData.exposeIn.captainApp}
                onChange={(e) =>
                  handleCheckboxChange(e, "exposeIn", "captainApp")
                }
              />
              <label>Captain App</label>
              <CheckboxComponent
                checked={formData.exposeIn.kiosk}
                onChange={(e) => handleCheckboxChange(e, "exposeIn", "kiosk")}
              />
              <label>Kiosk</label>
            </div>
          </div>

          <div className="form-column">
            <label>GST Type *</label>
            <div className="radio-group">
              <RadioButtonComponent
                name="gstType"
                value="services"
                checked={formData.gstType === "services"}
                onChange={handleInputChange}
              />
              <label>Services</label>
              <RadioButtonComponent
                name="gstType"
                value="goods"
                checked={formData.gstType === "goods"}
                onChange={handleInputChange}
              />
              <label>Goods</label>
            </div>
          </div>

          <div className="form-column">
            <CheckboxComponent
              checked={formData.orderType.allDays}
              onChange={(e) => handleCheckboxChange(e)}
              name="allDays"
            />
            <label>All Days</label>
          </div>
        </div>

        {/* Row for Description */}
        <Textbox
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
        />

        {/* Item Suggestions */}
        <div className="item-suggestions-section">
          <h6>Item Suggestions</h6>
          <div className="suggestions-row">
            <div className="suggestions-column">
              <label>Assign suggested item(s)</label>
              <DropdownButton
                label={formData.assignCategory || "Select Category"}
                options={categories.map((cat) => ({
                  label: cat.label,
                  onClick: () => handleCategorySelect(cat.label),
                }))}
                customStyle={{
                  color: "#000",
                  width: "100%",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>

          <div className="suggestions-info">
            <label>Suggested Item(s)</label>
            <p>No item(s) assigned</p>
          </div>
        </div>

        <div className="tags-information-section">
          <h6 className="section-title">Tags Information</h6>
          <div className="general-container">
            <h6 className="general-title">General</h6>
            <h6 className="section-title1">Cuisine Tags</h6>
            <div className="form-row">
              <InputComponent
                field={{ name: "portionSizeQty" }}
                form={{ touched: {}, errors: {} }}
                value={formData.portionSizeQty}
                onChange={handleInputChange}
                placeholder="Enter Qty"
              />
            </div>

            <div className="checkbox-grid">
              {/* Row 1 */}
              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.ignoreTax}
                    onChange={() => handleCheckboxChange("ignoreTax")}
                  />
                  <label>Ignore Tax</label>
                </div>
                <span>
                  If enabled, tax will not be calculated in all the platform
                </span>
              </div>

              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.openQuantityPopup}
                    onChange={() => handleCheckboxChange("openQuantityPopup")}
                  />
                  <label>Open Quantity Popup</label>
                </div>
                <span>Used for the offline PoS billing only</span>
              </div>

              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.setAsFavorite}
                    onChange={() => handleCheckboxChange("setAsFavorite")}
                  />
                  <label>Set As Favorite</label>
                </div>
                <span>Used for the offline PoS billing only</span>
              </div>

              {/* Row 2 */}
              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.ignoreDiscount}
                    onChange={() => handleCheckboxChange("ignoreDiscount")}
                  />
                  <label>Ignore Discount</label>
                </div>
                <span>
                  If enabled, discount will not be applicable in offline PoS
                  billing
                </span>
              </div>

              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.ignoreAddon}
                    onChange={() => handleCheckboxChange("ignoreAddon")}
                  />
                  <label>Ignore Addon</label>
                </div>
                <span>Used only for Zomato</span>
              </div>

              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.mrpItem}
                    onChange={() => handleCheckboxChange("mrpItem")}
                  />
                  <label>MRP-item</label>
                </div>
                <span>This is applicable for online platforms only</span>
              </div>

              {/* Row 3 */}
              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.ignorePackingCharge}
                    onChange={() => handleCheckboxChange("ignorePackingCharge")}
                  />
                  <label>Ignore Packing Charge (Online)</label>
                </div>
                <span>This is applicable for online platforms only</span>
              </div>

              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.containsAlcohol}
                    onChange={() => handleCheckboxChange("containsAlcohol")}
                  />
                  <label>Contains Alcohol</label>
                </div>
                <span>Considered under liquor item</span>
              </div>

              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.removeFromDineInQR}
                    onChange={() => handleCheckboxChange("removeFromDineInQR")}
                  />
                  <label>Remove From Dine-in QR</label>
                </div>
              </div>

              {/* Row 4 */}
              <div className="checkbox-column">
                <div className="checkbox-row">
                  <CheckboxComponent
                    checked={formData.setAsCombo}
                    onChange={() => handleCheckboxChange("setAsCombo")}
                  />
                  <label>Set As Combo</label>
                </div>
                <span>Creates items combo for offline PoS billing</span>
              </div>
            </div>
          </div>

          <h6 className="section-title">Swiggy</h6>
          <div className="swiggy-container">
            {/* Row 1: Portion Size Value, Serves, Minimum Preparation Time */}
            <div className="form-row">
              <div className="form-column">
                <label>Portion Size Value</label>
                <div className="input-group">
                  <InputComponent
                    field={{ name: "portionSizeQty" }}
                    form={{ touched: {}, errors: {} }}
                    value={formData.portionSizeQty}
                    onChange={handleInputChange}
                    placeholder="Enter Qty"
                  />
                  <DropdownButton
                    label="Select Unit"
                    options={[
                      {
                        label: "Grams",
                        onClick: () => handleDropdownChange("unit", "Grams"),
                      },
                      {
                        label: "Liters",
                        onClick: () => handleDropdownChange("unit", "Liters"),
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="form-column">
                <label>Serves</label>
                <DropdownButton
                  label="Select serves"
                  options={[
                    {
                      label: "1",
                      onClick: () => handleDropdownChange("serves", "1"),
                    },
                    {
                      label: "2",
                      onClick: () => handleDropdownChange("serves", "2"),
                    },
                    {
                      label: "Family",
                      onClick: () => handleDropdownChange("serves", "Family"),
                    },
                  ]}
                />
              </div>

              <div className="form-column">
                <label>Minimum Preparation Time</label>
                <DropdownButton
                  label="Select Preparation time"
                  options={[
                    {
                      label: "15 mins",
                      onClick: () =>
                        handleDropdownChange("preparationTime", "15 mins"),
                    },
                    {
                      label: "30 mins",
                      onClick: () =>
                        handleDropdownChange("preparationTime", "30 mins"),
                    },
                  ]}
                />
              </div>
            </div>

            {/* Row 2: Spice Level, Sweet Level, Gravy Property */}
            <div className="form-row">
              <div className="form-column">
                <label>Spice Level</label>
                <DropdownButton
                  label="Not Applicable"
                  options={[
                    {
                      label: "Mild",
                      onClick: () => handleDropdownChange("spiceLevel", "Mild"),
                    },
                    {
                      label: "Medium",
                      onClick: () =>
                        handleDropdownChange("spiceLevel", "Medium"),
                    },
                    {
                      label: "Hot",
                      onClick: () => handleDropdownChange("spiceLevel", "Hot"),
                    },
                  ]}
                />
              </div>

              <div className="form-column">
                <label>Sweet Level</label>
                <DropdownButton
                  label="Not Applicable"
                  options={[
                    {
                      label: "Low",
                      onClick: () => handleDropdownChange("sweetLevel", "Low"),
                    },
                    {
                      label: "Medium",
                      onClick: () =>
                        handleDropdownChange("sweetLevel", "Medium"),
                    },
                    {
                      label: "High",
                      onClick: () => handleDropdownChange("sweetLevel", "High"),
                    },
                  ]}
                />
              </div>

              <div className="form-column">
                <label>Gravy Property</label>
                <DropdownButton
                  label="Not Applicable"
                  options={[
                    {
                      label: "Thick",
                      onClick: () =>
                        handleDropdownChange("gravyProperty", "Thick"),
                    },
                    {
                      label: "Medium",
                      onClick: () =>
                        handleDropdownChange("gravyProperty", "Medium"),
                    },
                    {
                      label: "Thin",
                      onClick: () =>
                        handleDropdownChange("gravyProperty", "Thin"),
                    },
                  ]}
                />
              </div>
            </div>

            {/* Row 3: Accompaniments and Checkboxes */}
            <div className="form-row">
              <div className="form-column">
                <label>Accompaniments</label>
                <InputComponent
                  field={{ name: "accompaniments" }}
                  form={{ touched: {}, errors: {} }}
                  value={formData.accompaniments}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-column">
                <div className="checkbox-group">
                  <CheckboxComponent
                    checked={formData.swiggyRecommended}
                    onChange={() => handleCheckboxChange("swiggyRecommended")}
                  />
                  <label>Swiggy-recommended</label>
                </div>
              </div>

              <div className="form-column">
                <div className="checkbox-group">
                  <CheckboxComponent
                    checked={formData.seasonalIngredients}
                    onChange={() => handleCheckboxChange("seasonalIngredients")}
                  />
                  <label>Seasonal Ingredients</label>
                </div>
              </div>
            </div>
          </div>

<h6 className="section-title">Zomato</h6>
<div className="zomato-checkbox-container">
  <div className="checkbox-row">
    {checkboxOptions.map((option) => (
      <div className="checkbox-item" key={option.key}>
        <CheckboxComponent
          checked={formData[option.key]}
          onChange={() => handleCheckboxChange(option.key)}
        />
        <label>{option.label}</label>
      </div>
    ))}
  </div>
</div>

    <h6 className="section-title">Variation</h6>
    <div className="variation-form-main-container">
    <div className="variation-form-container">
  <div className="form-header">
    <div className="variation-title-container">
      <CheckboxComponent
        checked={formData.variationChecked}
        onChange={() => handleCheckboxChange('variationChecked')}
      />
      <label className="variation-title">Variation</label>
    </div>

    <ButtonComponent
      text="Add Variation"
      onClick={() => alert('Variation added')}
      className="add-variation-btn"
      variant="danger"
    />
  </div>

  <div className="form-grid">
    {/* Dynamically rendering form fields */}
    {formFields.map((field) => (
      <div className="form-item" key={field.key}>
        <label>{field.label} {field.required && <span className="required">*</span>}</label>
        <InputComponent
          value={formData[field.key]}
          onChange={handleInputChange(field.key)}
          required={field.required}
        />
      </div>
    ))}

    {/* Serves Dropdown */}
    <div className="form-item serves-dropdown full-width">
      <label>Serves</label>
      <DropdownButton
        label={formData.serves || 'Select serves'}
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
        ]}
        onSelect={(value) => setFormData({ ...formData, serves: value })}
      />
    </div>

    {/* Set As Combo Checkbox */}
    <div className="form-item combo-checkbox full-width">
      <CheckboxComponent
        checked={formData.setAsCombo}
        onChange={() => handleCheckboxChange('setAsCombo')}
      />
      <label>Set As Combo</label>
    </div>
  </div>
</div>
<br/>
    <div className="variation-form-container1">
  <div className="form-header">
    <div className="variation-title-container">
     
    </div>

    <ButtonComponent
      text="Add Variation"
      onClick={() => alert('Variation added')}
      className="add-variation-btn"
      variant="danger"
    />
  </div>

  <div className="form-grid">
    {/* Dynamically rendering form fields */}
    {formFields.map((field) => (
      <div className="form-item" key={field.key}>
        <label>{field.label} {field.required && <span className="required">*</span>}</label>
        <InputComponent
          value={formData[field.key]}
          onChange={handleInputChange(field.key)}
          required={field.required}
        />
      </div>
    ))}

    {/* Serves Dropdown */}
    <div className="form-item serves-dropdown full-width">
      <label>Serves</label>
      <DropdownButton
        label={formData.serves || 'Select serves'}
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
        ]}
        onSelect={(value) => setFormData({ ...formData, serves: value })}
      />
    </div>

    {/* Set As Combo Checkbox */}
    <div className="form-item combo-checkbox full-width">
      <CheckboxComponent
        checked={formData.setAsCombo}
        onChange={() => handleCheckboxChange('setAsCombo')}
      />
      <label>Set As Combo</label>
    </div>
  </div>
</div>
</div>
<div className="inventory-form">
      <h6 className="section-title">Inventory</h6>
      <div className="form-grid">
        {/* HSN Code */}
        <div className="form-item">
          <label>HSN Code</label>
          <InputComponent
            field={{ name: 'hsnCode' }}
            value={formData.hsnCode}
            onChange={handleInputChange}
          />
        </div>

        {/* Sap Code */}
        <div className="form-item">
          <label>Sap Code</label>
          <InputComponent
            field={{ name: 'sapCode' }}
            value={formData.sapCode}
            onChange={handleInputChange}
          />
        </div>

        {/* FSN Code */}
        <div className="form-item">
          <label>FSN Code</label>
          <InputComponent
            field={{ name: 'fsnCode' }}
            value={formData.fsnCode}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-item full-width">
  <label>Stock Status</label>
  <div className="dropdown-checkbox-row">
    <DropdownButton
      label={formData.stockStatus || 'Do Not Track'}
      options={stockStatusOptions.map((option) => ({
        label: option.label,
        value: option.value,
        onClick: () => handleDropdownChange(option.value),
      }))}
    />
    
    {/* Create Self Item Recipe Checkbox */}
    <div className="checkbox-column">
      <CheckboxComponent
        checked={formData.createSelfItemRecipe}
        onChange={handleCheckboxChange}
      />
      <div className="checkbox-label-column">
        <label className="checkbox-label">Create Self Item Recipe</label>
        <small>
          Yes [Applicable only when menu item is Purchased but does not have any recipe.]
        </small>
      </div>
    </div>
  </div>
</div>


        {/* Nutrition Textbox */}
        <div className="form-item full-width">
          <label>Nutrition</label>
          <Textbox
            name="nutrition"
            value={formData.nutrition}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
   
        </div>
        
      {/* Button Section */}
      <div className="button-section">
        <ButtonComponent
          text="Cancel"
          onClick={handleCancel}
         
          className="cancel-button"
        />
        <ButtonComponent
          text="Save Change"
          onClick={handleSaveChange}
         
          className="save-button"
        />
      </div>
      </form>
    </div>
  );
};

export default MenuItemListPage;
