import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./user-management-navbar.scss";
import { useDispatch } from "react-redux";
import { setUserManageNavbarState } from "../../../redux/actions/usermanagenavbarActions";

const UserManagementNavbar = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);

  // Retrieve last selected tab from localStorage or default to 'delivery'
  const [activeButton, setActiveButton] = useState(localStorage.getItem("activeUserTab") || "delivery");
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0, width: "100%" });

  useEffect(() => {
    // Set initial Redux state based on stored tab
    handleUserState(activeButton);
  }, []); // Runs only on mount

  const handleModalToggle = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: buttonRect.bottom + window.scrollY,
        left: 0,
        width: "100%",
      });
    }
    setShowModal(!showModal);
  };

  const handleModalClose = () => setShowModal(false);

  const handleUserState = (field) => {
    const stateMapping = {
      delivery: { admin: false, delivery: true, user: false },
      user: { admin: false, delivery: false, user: true },
    };

    dispatch(setUserManageNavbarState(stateMapping[field]));
    setActiveButton(field); // Update active button
    localStorage.setItem("activeUserTab", field); // Store active tab in localStorage
    setShowModal(false);
  };

  return (
    <>
      {/* Main User Management Navbar */}
      <div className="secondary-navbar">
        <ul className="navbar-menu">
          <li className="menu-item items-menu-button">
            <button
              className={`menu-button ${showModal ? "active" : ""}`}
              onClick={handleModalToggle}
              ref={buttonRef}
            >
              Items Menu <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
            </button>
          </li>

          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "delivery" ? "active" : ""}`}
              onClick={() => handleUserState("delivery")}
            >
              Delivery
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "user" ? "active" : ""}`}
              onClick={() => handleUserState("user")}
            >
              User
            </button>
          </li>
        </ul>
      </div>

      {/* Modal Menu for smaller screens */}
      {showModal && (
        <div className="custom-modal" style={{ top: modalPosition.top }}>
          <div className="modal-content">
            <div className="modal-header">
              <span>Items Menu</span>
              <button className="close-button" onClick={handleModalClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <ul className="modal-list">
              <li
                className={`modal-list-item ${activeButton === "delivery" ? "active" : ""}`}
                onClick={() => handleUserState("delivery")}
              >
                Delivery
              </li>
              <li
                className={`modal-list-item ${activeButton === "user" ? "active" : ""}`}
                onClick={() => handleUserState("user")}
              >
                User
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default UserManagementNavbar;
