import React, { useEffect, useRef, useState } from 'react';
import './SearchableDropdown.scss';

const SearchableDropdown = ({ options, placeholder, onSelect, value }) => {
  const [searchTerm, setSearchTerm] = useState(value || ''); // Store the search term or initial value
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Toggle dropdown visibility
  const dropdownRef = useRef(null); // Ref to track the dropdown element

  // Update the search term if the parent component changes the value
  useEffect(() => {
    if (value) {
      setSearchTerm(value); // Set the input field to the preselected value
    }
  }, [value]); // Run effect when `value` prop changes

  // Filter the options based on the search term
  const filteredOptions = options.filter(option => 
    option.label?.toLowerCase().includes(searchTerm.toLowerCase()) // Safely check for label
  );
  

  // Handle selecting an option
  const handleOptionClick = (option) => {
    setSearchTerm(option.label); // Set the input field to the selected option's label
    setIsDropdownVisible(false); // Hide the dropdown after selection
    onSelect(option.id); // Call the onSelect prop with the selected option's id
  };

  // Handle input change (typing)
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term
    setIsDropdownVisible(true); // Show the dropdown when typing
  };

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false); // Hide the dropdown if clicked outside
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle adding a new option
  const handleAddOptionClick = () => {
    // Instead of passing the search term directly, you might want to handle it differently
    // If you want to send a specific id for adding, you could do that here
    onSelect(searchTerm); // Pass the search term as the selected value
    setSearchTerm(''); // Clear the input field after selection
    setIsDropdownVisible(false); // Hide dropdown
  };

  return (
    <div className="searchable-dropdown" ref={dropdownRef}>
      <input
  type="text"
  placeholder={placeholder}
  value={searchTerm}
  onChange={handleInputChange}
  onFocus={() => setIsDropdownVisible(true)} // Ensure dropdown stays open on focus
/>

      {isDropdownVisible && (
        <ul className="dropdown-list">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <li key={option.id} onClick={() => handleOptionClick(option)}>
                {option.label} {/* Display the label instead of name */}
              </li>
            ))
          ) : (
            <li className="add-option" onClick={handleAddOptionClick}>
              Add "{searchTerm}" {/* Add the typed search term */}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
