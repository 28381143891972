// Action Types
export const SET_FILE = "SET_FILE";
export const CLEAR_FILE = "CLEAR_FILE";

export const setFile = async (file) => {
  const arrayBuffer = await file.arrayBuffer(); // Convert file content to ArrayBuffer
  return {
    type: SET_FILE,
    payload: {
      name: file.name,
      size: file.size,
      type: file.type,
      content: arrayBuffer, // Store ArrayBuffer in Redux
    },
  };
};


export const clearFile = () => {
  return {
    type: CLEAR_FILE, // Clear the file
  };
};
