import React from 'react';
import { useSelector } from 'react-redux';
import SubscriptionManagementNavbar from '../../components/layout/subscription-management-navbar/subscription-management-navbar';
import DeliveryConfigDisplayPage from '../delivery-config/delivery-config-displaypage';
import ExpiryAlertDisplayPage from '../expiry-alert/expiry-alert-displaypage';
import SubscriptionDisplayPage from '../admin-subscription/admin-subscription-displaypage';
import NotificationCreationDisplayPage from '../admin-notification-creation/notification-creation-displaypage';
import InventoryDisplayPage from '../inventory-threshold/inventory-displaypage';
const AdminSubscriptionManagement = () => {
    // Get the navbar state from Redux
    const navbarState = useSelector((state) => state.subscriptionnavbarState || {});

    return (
        <div>
            <SubscriptionManagementNavbar />
            {navbarState.subscription && <SubscriptionDisplayPage />}
            {navbarState.inventory && <InventoryDisplayPage />}
            {navbarState.deliveryconfig && <DeliveryConfigDisplayPage />}
            {navbarState.expiry && <ExpiryAlertDisplayPage />}
            {navbarState.notification && < NotificationCreationDisplayPage/>}
        </div>
    );
};

export default AdminSubscriptionManagement;
