
import {
    fetchAllBusinessInfoAPI,
    fetchBusinessInfoByIdAPI,
    createBusinessInfoAPI,
    updateBusinessInfoAPI,
    deleteBusinessInfoAPI,
} from '../../api/services/businessService';
export const FETCH_ALL_BUSINESS_INFO = 'FETCH_ALL_BUSINESS_INFO';
export const FETCH_BUSINESS_INFO_BY_ID = 'FETCH_BUSINESS_INFO_BY_ID';
export const CREATE_BUSINESS_INFO = 'CREATE_BUSINESS_INFO';
export const UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO';
export const DELETE_BUSINESS_INFO = 'DELETE_BUSINESS_INFO';
export const BUSINESS_INFO_ERROR = 'BUSINESS_INFO_ERROR';

// Fetch all business information
export const fetchAllBusinessInfo = (token) => async (dispatch) => {
    try {
        const data = await fetchAllBusinessInfoAPI(token);
        dispatch({ type: FETCH_ALL_BUSINESS_INFO, payload: data });
    } catch (error) {
        dispatch({ type: BUSINESS_INFO_ERROR, payload: error.message });
    }
};

// Fetch single business information by ID
export const fetchBusinessInfoById = (businessId, token) => async (dispatch) => {
    try {
        const data = await fetchBusinessInfoByIdAPI(businessId, token);
        dispatch({ type: FETCH_BUSINESS_INFO_BY_ID, payload: data });
    } catch (error) {
        dispatch({ type: BUSINESS_INFO_ERROR, payload: error.message });
    }
};

// Create new business information
export const createBusinessInfo = (businessData, token) => async (dispatch) => {
    try {
        const data = await createBusinessInfoAPI(businessData, token);
        dispatch({ type: CREATE_BUSINESS_INFO, payload: data });
    } catch (error) {
        dispatch({ type: BUSINESS_INFO_ERROR, payload: error.message });
    }
};

// Update existing business information
export const updateBusinessInfo = (businessData, token) => async (dispatch) => {
    try {
        const data = await updateBusinessInfoAPI(businessData, token);
        dispatch({ type: UPDATE_BUSINESS_INFO, payload: data });
    } catch (error) {
        dispatch({ type: BUSINESS_INFO_ERROR, payload: error.message });
    }
};

// Delete single business information by ID
export const deleteBusinessInfo = (businessId, token) => async (dispatch) => {
    try {
        await deleteBusinessInfoAPI(businessId, token);
        dispatch({ type: DELETE_BUSINESS_INFO, payload: businessId });
    } catch (error) {
        dispatch({ type: BUSINESS_INFO_ERROR, payload: error.message });
    }
};
