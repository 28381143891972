import React from 'react';

const RadioButtonComponent = ({ name, value, checked, onChange }) => {
  return (
    <input 
      type="radio" 
      name={name}
      value={value}
      checked={checked}
      onChange={onChange} 
    />
  );
};

export default RadioButtonComponent;
