import React from 'react';
import './deletepopup.scss';

const DeletePopup = () => {
  return (
    <div className="remove-item-popup-backdrop">
      <div className="remove-item-popup">
        <div className="popup-content">
          <p className="popup-text">Deleting...</p>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
