// actions/offerAllocationActions.js
import {
    fetchAllocatedOffersFromAPI,
    createNewAllocatedOfferAPI,
    updateAllocatedOfferInAPI,
    deleteAllocatedOfferAPI,
    bulkCreateAllocatedOffersAPI,
    bulkUpdateAllocatedOffersAPI,
    bulkDeleteAllocatedOffersAPI
} from '../../api/services/AllocateDiscountService';

// Action Types
export const FETCH_ALLOCATED_OFFERS = 'FETCH_ALLOCATED_OFFERS';
export const CREATE_ALLOCATED_OFFER = 'CREATE_ALLOCATED_OFFER';
export const UPDATE_ALLOCATED_OFFER = 'UPDATE_ALLOCATED_OFFER';
export const DELETE_ALLOCATED_OFFER = 'DELETE_ALLOCATED_OFFER';
export const BULK_CREATE_ALLOCATED_OFFERS = 'BULK_CREATE_ALLOCATED_OFFERS';
export const BULK_UPDATE_ALLOCATED_OFFERS = 'BULK_UPDATE_ALLOCATED_OFFERS';
export const BULK_DELETE_ALLOCATED_OFFERS = 'BULK_DELETE_ALLOCATED_OFFERS';
export const OFFER_ALLOCATION_ERROR = 'OFFER_ALLOCATION_ERROR';

// Action Creators
export const fetchAllocatedOffers = (token) => async (dispatch) => {
    try {
        const allocations = await fetchAllocatedOffersFromAPI(token);
        dispatch({ type: FETCH_ALLOCATED_OFFERS, payload: allocations });
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
    }
};

export const createAllocatedOffer = (newAllocationData, token) => async (dispatch) => {
    try {
        const allocation = await createNewAllocatedOfferAPI(newAllocationData, token);
        dispatch({ type: CREATE_ALLOCATED_OFFER, payload: allocation });
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
    }
};

export const updateAllocatedOffer = (allocationId, updatedData, token) => async (dispatch) => {
    try {
        const allocation = await updateAllocatedOfferInAPI(allocationId, updatedData, token);
        dispatch({ type: UPDATE_ALLOCATED_OFFER, payload: allocation });
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
    }
};

export const deleteAllocatedOffer = (allocationId, token) => async (dispatch) => {
    try {
        await deleteAllocatedOfferAPI(allocationId, token);
        dispatch({ type: DELETE_ALLOCATED_OFFER, payload: allocationId });
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
    }
};

export const bulkCreateAllocatedOffers = (allocationsData, token) => async (dispatch) => {
    
    try {
        const allocations = await bulkCreateAllocatedOffersAPI(allocationsData, token);
        dispatch({ type: BULK_CREATE_ALLOCATED_OFFERS, payload: allocationsData });
        return true;
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
        return false;
    }
};

export const bulkUpdateAllocatedOffers = (allocationsData, token) => async (dispatch) => {
    try {
        const allocations = await bulkUpdateAllocatedOffersAPI(allocationsData, token);
        dispatch({ type: BULK_UPDATE_ALLOCATED_OFFERS, payload: allocationsData });
        return true;
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
        return false;
    }
};

export const bulkDeleteAllocatedOffers = (allocationIds, token) => async (dispatch) => {
    try {
        await bulkDeleteAllocatedOffersAPI(allocationIds, token);
        dispatch({ type: BULK_DELETE_ALLOCATED_OFFERS, payload: allocationIds });
        return true;
    } catch (error) {
        dispatch({ type: OFFER_ALLOCATION_ERROR, payload: error.message });
        return false;
    }
};
