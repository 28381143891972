// notificationCreationReducer.js
import {
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_ERROR,
    CREATE_NOTIFICATION_SUCCESS,
    CREATE_NOTIFICATION_ERROR,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_ERROR,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_ERROR,
    BULK_CREATE_NOTIFICATIONS_SUCCESS,
    BULK_CREATE_NOTIFICATIONS_ERROR,
    BULK_FETCH_NOTIFICATIONS_SUCCESS,
    BULK_FETCH_NOTIFICATIONS_ERROR,
    BULK_UPDATE_NOTIFICATIONS_SUCCESS,
    BULK_UPDATE_NOTIFICATIONS_ERROR,
    BULK_DELETE_NOTIFICATIONS_SUCCESS,
    BULK_DELETE_NOTIFICATIONS_ERROR,
  } from '../actions/NotificationCreationAction';
  
  const initialState = {
    notifications: [],
    error: null,
  };
  
  const notificationCreationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_NOTIFICATIONS_SUCCESS:
      case BULK_FETCH_NOTIFICATIONS_SUCCESS:
        return { ...state, notifications: action.payload, error: null };
  
      case CREATE_NOTIFICATION_SUCCESS:
        return { ...state, notifications: [...state.notifications, action.payload], error: null };
  
      case BULK_CREATE_NOTIFICATIONS_SUCCESS:
        return { ...state, notifications: [...state.notifications, ...action.payload], error: null };
  
      case UPDATE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          notifications: state.notifications.map((notification) =>
            notification.notification_id === action.payload.notification_id ? action.payload : notification
          ),
          error: null,
        };
  
      case BULK_UPDATE_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          notifications: state.notifications.map((notification) =>
            action.payload.some((updated) => updated.notification_id === notification.notification_id)
              ? action.payload.find((updated) => updated.notification_id === notification.notification_id)
              : notification
          ),
          error: null,
        };
  
      case DELETE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          notifications: state.notifications.filter(
            (notification) => notification.notification_id !== action.payload
          ),
          error: null,
        };
  
      case BULK_DELETE_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          notifications: state.notifications.filter(
            (notification) => !action.payload.includes(notification.notification_id)
          ),
          error: null,
        };
  
      case FETCH_NOTIFICATIONS_ERROR:
      case CREATE_NOTIFICATION_ERROR:
      case UPDATE_NOTIFICATION_ERROR:
      case DELETE_NOTIFICATION_ERROR:
      case BULK_CREATE_NOTIFICATIONS_ERROR:
      case BULK_FETCH_NOTIFICATIONS_ERROR:
      case BULK_UPDATE_NOTIFICATIONS_ERROR:
      case BULK_DELETE_NOTIFICATIONS_ERROR:
        return { ...state, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default notificationCreationReducer;
  