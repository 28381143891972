// inventoryThresholdActions.js
import {
  fetchInventoryThreshold,
  createInventoryThreshold,
  updateInventoryThreshold,
  deleteInventoryThreshold,
  bulkCreateInventoryThresholds,
  bulkFetchInventoryThresholds,
  bulkUpdateInventoryThresholds,
  bulkDeleteInventoryThresholds,
} from '../../api/services/inventoryThresholdService';
import { toast } from 'react-hot-toast';
export const FETCH_INVENTORY_THRESHOLD_SUCCESS = 'FETCH_INVENTORY_THRESHOLD_SUCCESS';
export const FETCH_INVENTORY_THRESHOLD_ERROR = 'FETCH_INVENTORY_THRESHOLD_ERROR';
export const CREATE_INVENTORY_THRESHOLD_SUCCESS = 'CREATE_INVENTORY_THRESHOLD_SUCCESS';
export const CREATE_INVENTORY_THRESHOLD_ERROR = 'CREATE_INVENTORY_THRESHOLD_ERROR';
export const UPDATE_INVENTORY_THRESHOLD_SUCCESS = 'UPDATE_INVENTORY_THRESHOLD_SUCCESS';
export const UPDATE_INVENTORY_THRESHOLD_ERROR = 'UPDATE_INVENTORY_THRESHOLD_ERROR';
export const DELETE_INVENTORY_THRESHOLD_SUCCESS = 'DELETE_INVENTORY_THRESHOLD_SUCCESS';
export const DELETE_INVENTORY_THRESHOLD_ERROR = 'DELETE_INVENTORY_THRESHOLD_ERROR';
export const BULK_CREATE_INVENTORY_THRESHOLD_REQUEST='BULK_CREATE_INVENTORY_THRESHOLD_REQUEST';
export const BULK_CREATE_INVENTORY_THRESHOLD_SUCCESS = 'BULK_CREATE_INVENTORY_THRESHOLD_SUCCESS';
export const BULK_CREATE_INVENTORY_THRESHOLD_ERROR = 'BULK_CREATE_INVENTORY_THRESHOLD_ERROR';
export const BULK_FETCH_INVENTORY_THRESHOLD_SUCCESS = 'BULK_FETCH_INVENTORY_THRESHOLD_SUCCESS';
export const BULK_FETCH_INVENTORY_THRESHOLD_ERROR = 'BULK_FETCH_INVENTORY_THRESHOLD_ERROR';
export const BULK_UPDATE_INVENTORY_THRESHOLD_SUCCESS = 'BULK_UPDATE_INVENTORY_THRESHOLD_SUCCESS';
export const BULK_UPDATE_INVENTORY_THRESHOLD_ERROR = 'BULK_UPDATE_INVENTORY_THRESHOLD_ERROR';
export const BULK_DELETE_INVENTORY_THRESHOLD_SUCCESS = 'BULK_DELETE_INVENTORY_THRESHOLD_SUCCESS';
export const BULK_DELETE_INVENTORY_THRESHOLD_ERROR = 'BULK_DELETE_INVENTORY_THRESHOLD_ERROR';

// Fetch inventory thresholds
export const getInventoryThresholds = (token) => async (dispatch) => {
  try {
    const data = await fetchInventoryThreshold(token);
    dispatch({ type: FETCH_INVENTORY_THRESHOLD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_INVENTORY_THRESHOLD_ERROR, payload: error.message });
  }
};

// Create inventory threshold
export const addInventoryThreshold = (thresholdData, token) => async (dispatch) => {
  try {
    const newThreshold = await createInventoryThreshold(thresholdData, token);
    dispatch({ type: CREATE_INVENTORY_THRESHOLD_SUCCESS, payload: newThreshold });
  } catch (error) {
    dispatch({ type: CREATE_INVENTORY_THRESHOLD_ERROR, payload: error.message });
  }
};

// Update inventory threshold
export const modifyInventoryThreshold = (thresholdId, thresholdData, token) => async (dispatch) => {
  try {
    const updatedThreshold = await updateInventoryThreshold(thresholdId, thresholdData, token);
    dispatch({ type: UPDATE_INVENTORY_THRESHOLD_SUCCESS, payload: updatedThreshold });
  } catch (error) {
    dispatch({ type: UPDATE_INVENTORY_THRESHOLD_ERROR, payload: error.message });
  }
};

// Delete inventory threshold
export const removeInventoryThreshold = (thresholdId, token) => async (dispatch) => {
  try {
    await deleteInventoryThreshold(thresholdId, token);
    dispatch({ type: DELETE_INVENTORY_THRESHOLD_SUCCESS, payload: thresholdId });
  } catch (error) {
    dispatch({ type: DELETE_INVENTORY_THRESHOLD_ERROR, payload: error.message });
  }
};

// Bulk create inventory thresholds

export const bulkAddInventoryThresholds = (thresholdData, token) => async (dispatch) => {
  dispatch({ type: BULK_CREATE_INVENTORY_THRESHOLD_REQUEST }); // Dispatch request action

  try {
    // Call the API to bulk create inventory thresholds
    const createdThresholds = await bulkCreateInventoryThresholds(thresholdData, token);

    dispatch({
      type: BULK_CREATE_INVENTORY_THRESHOLD_SUCCESS,
      payload: thresholdData, // Pass the created inventory thresholds data as payload
    });

    toast.success("Inventory thresholds created successfully!");
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_CREATE_INVENTORY_THRESHOLD_ERROR,
      payload: error.message,
    });

    toast.error(`Failed to create inventory thresholds: ${error.message}`);
    return { success: false };
  }
};

// Bulk fetch inventory thresholds
export const bulkGetInventoryThresholds = (token) => async (dispatch) => {
  try {
    const data = await bulkFetchInventoryThresholds(token);
    dispatch({ type: BULK_FETCH_INVENTORY_THRESHOLD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BULK_FETCH_INVENTORY_THRESHOLD_ERROR, payload: error.message });
  }
};

// Bulk update inventory thresholds
export const bulkModifyInventoryThresholds = (thresholdData, token) => async (dispatch) => {
  try {
    //console.log("Bulk Update Inventory Data:", thresholdData); // Log the data being sent to the API

    // Call the API for bulk update
    const data = await bulkUpdateInventoryThresholds(thresholdData, token);

    // Dispatch success action with API response as payload
    dispatch({ type: BULK_UPDATE_INVENTORY_THRESHOLD_SUCCESS, payload:thresholdData });

    //console.log("Bulk Inventory Update Success:", data); // Log success response
    return true;
  } catch (error) {
    //console.error("Error in Bulk Update:", error.message); // Log the error

    // Dispatch failure action with error message
    dispatch({
      type: BULK_UPDATE_INVENTORY_THRESHOLD_ERROR,
      payload: error.message,
    });
    return false;
  }
};


// Bulk delete inventory thresholds
export const bulkRemoveInventoryThresholds = (ids, token) => async (dispatch) => {
  try {
    await bulkDeleteInventoryThresholds(ids, token);
    dispatch({ type: BULK_DELETE_INVENTORY_THRESHOLD_SUCCESS, payload: ids });
  } catch (error) {
    dispatch({ type: BULK_DELETE_INVENTORY_THRESHOLD_ERROR, payload: error.message });
  }
};