import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateTokenAction } from '../../redux/actions/authActions';
import { generateTokenAction as admintoken } from '../../redux/actions/adminLoginActions';

const TokenProvider = () => {
  const dispatch = useDispatch();
  const isAdminLoggedIn = useSelector((state) => state.adminLogin.isLoggedIn);
  const token = useSelector((state) => state.auth.token);

  // Create asynchronous functions with useCallback to prevent recreation in each interval loop
  const generateUserToken = useCallback(async () => {
    await dispatch(generateTokenAction());
  }, [dispatch]);

  const generateAdminToken = useCallback(async () => {
    await dispatch(admintoken());
  }, [dispatch]);

  useEffect(() => {
    const initializeToken = async () => {
      await generateUserToken();
    };

    initializeToken();

    const interval = setInterval(async () => {
      if (isAdminLoggedIn) {
        await generateAdminToken();
      } else {
        //console.log("Inside loop");
        await generateUserToken();
      }
    }, 20 * 60 * 1000);

    return () => clearInterval(interval);
  }, [isAdminLoggedIn, generateUserToken, generateAdminToken]);

  return null; // This component doesn't render anything
};

export default TokenProvider;
