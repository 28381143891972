import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import Textbox from '../../components/common/Textbox/Textbox';
import Datepicker from '../../components/common/datepicker/datepicker';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProducts, updateSingleProductWorkflow, createSingleProductWorkflow } from '../../redux/actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import DropdownButton from '../../components/common/dropdown/DropdownButton';

const CustomDetails = ({ urls, onClose }) => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.products.customProducts);
  const data = useLocation().state || null;
  const location = useLocation();
  const basicInfo = location.state || {};
  const token = useSelector(state => state.adminLogin.token);
  
  const [formData, setFormData] = useState({
    prescription_required: '',
    dosage_information: '',
    form: '',
    strength: '',
    active_ingredients: '',
    side_effects: '',
    contraindications: '',
    instructions: '',
    license_information: '',
    expiry_date: null,
    batch_number: '',
    manufacturing_date: null,
    manufacturer_license_number: '',
    material_type: '',
    size: '',
    intended_use: '',
    weight_capacity: '',
    adjustability: '',
    mobility_features: '',
    care_instructions: '',
    warranty: '',
    certifications: ''
  });

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  
  useEffect(() => {
    if (basicInfo.product_id) {
      const productIdToCheck = basicInfo.product_id;
      const product = products.find(p => p.product_id === productIdToCheck);
      if (product) {
        setFormData(product);
      }
    }
  }, [products, basicInfo.product_id]);

  const navigate = useNavigate();

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const validateRequiredFields = () => {
    const requiredFields = ['prescription_required', 'expiry_date', 'manufacturing_date'];
    const allFieldsFilled = requiredFields.every(field => formData[field] !== null && formData[field] !== '');
    //console.log(allFieldsFilled);
    setIsSaveButtonEnabled(allFieldsFilled);
  };

  useEffect(() => {
    validateRequiredFields();
  }, [formData]);

  const handleSaveClick = async () => {
    const combinedData = { ...basicInfo, ...formData };
    
    if (basicInfo.product_id) {
      const response = await dispatch(updateSingleProductWorkflow(basicInfo, formData, token));
      if (response){
        toast.success("product updated successfully");
        navigate('/admin');
      }
      else{
        toast.error("product not updated ");
      }
    } else {
      const response =await dispatch(createSingleProductWorkflow(basicInfo, formData, token));
      if (response){
        toast.success("product created successfully");
        if (urls) {
          onClose(); // Close the sidebar
        } else {
          navigate('/admin'); // Full navigation
        }
      }
      else{
        toast.error("product not created");
      }
    }
   
    
  };

  const renderRequiredLabel = (label) => (
    <span>
      {label} <span style={{ color: 'red' }}>*</span>
    </span>
  );

  return (
    <div className="details-table">
          <div className="breadcrumb-container">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Product List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text"> Custom Details</span>
      </div>
      <div className="custom-details-container"></div>
      <div className="details-table-container">
        <div className='text-heading1'>Custom Details</div>
        <form className="details-form">
          {/* Prescription Required */}
          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel("Prescription Required")}</label>
              <DropdownButton
                label={
                  formData.prescription_required === true
                    ? 'Yes'
                    : formData.prescription_required === false
                    ? 'No'
                    : 'Select'
                }
                options={['Yes', 'No']}
                onSelect={(selectedOption) =>
                  handleInputChange('prescription_required', selectedOption === 'Yes')
                }
              />


            </div>
            <div className="form-group">
              <label>Dosage Information</label>
              <Textbox
                value={formData.dosage_information}
                onChange={(e) => handleInputChange('dosage_information', e.target.value)}
              />
            </div>
          </div>

          {/* Form and Strength */}
          <div className="form-row">
            <div className="form-group">
              <label>Form</label>
              <Textbox
                value={formData.form}
                onChange={(e) => handleInputChange('form', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Strength</label>
              <Textbox
                value={formData.strength}
                onChange={(e) => handleInputChange('strength', e.target.value)}
              />
            </div>
          </div>

          {/* Active Ingredients and Side Effects */}
          <div className="form-row">
            <div className="form-group">
              <label>Active Ingredients</label>
              <Textbox
                value={formData.active_ingredients}
                onChange={(e) => handleInputChange('active_ingredients', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Side Effects</label>
              <Textbox
                value={formData.side_effects}
                onChange={(e) => handleInputChange('side_effects', e.target.value)}
              />
            </div>
          </div>

          {/* Contraindications and Instructions */}
          <div className="form-row">
            <div className="form-group">
              <label>Contraindications</label>
              <Textbox
                value={formData.contraindications}
                onChange={(e) => handleInputChange('contraindications', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Instructions</label>
              <Textbox
                value={formData.instructions}
                onChange={(e) => handleInputChange('instructions', e.target.value)}
              />
            </div>
          </div>

          {/* License Information and Expiry Date */}
          <div className="form-row">
            <div className="form-group">
              <label>License Information</label>
              <Textbox
                value={formData.license_information}
                onChange={(e) => handleInputChange('license_information', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel("Manufacturing Date")}</label>
              <Datepicker
                className="date-picker1"
                dateValue={formData.manufacturing_date}
                onDateChange={(date) => handleInputChange('manufacturing_date', date)}
              />
            </div>
          </div>

          {/* Batch Number and Manufacturing Date */}
          <div className="form-row">
            <div className="form-group">
              <label>Batch Number</label>
              <InputComponent
                type="number"
                placeholder="Enter Batch Number"
                value={formData.batch_number}
                onChange={(e) => handleInputChange('batch_number', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel("Expiry Date")}</label>
              <Datepicker
                className="date-picker1"
                dateValue={formData.expiry_date}
                onDateChange={(date) => handleInputChange('expiry_date', date)}
              />
            </div>
            
          </div>

          {/* Manufacturer License Number and Material Type */}
          <div className="form-row">
            <div className="form-group">
              <label>Manufacturer License Number</label>
              <InputComponent
                type="number"
                placeholder="Enter Manufacturer License Number"
                value={formData.manufacturer_license_number}
                onChange={(e) => handleInputChange('manufacturer_license_number', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Material Type</label>
              <InputComponent
                placeholder="Enter Material Type"
                value={formData.material_type}
                onChange={(e) => handleInputChange('material_type', e.target.value)}
              />
            </div>
          </div>

          {/* Size and Intended Use */}
          <div className="form-row">
            <div className="form-group">
              <label>Size</label>
              <InputComponent
                type="number"
                placeholder="Enter Size"
                value={formData.size}
                onChange={(e) => handleInputChange('size', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Intended Use</label>
              <Textbox
                value={formData.intended_use}
                onChange={(e) => handleInputChange('intended_use', e.target.value)}
              />
            </div>
          </div>

          {/* Weight Capacity and Adjustability */}
          <div className="form-row">
            <div className="form-group">
              <label>Weight Capacity</label>
              <InputComponent
                placeholder="Enter Weight Capacity"
                value={formData.weight_capacity}
                onChange={(e) => handleInputChange('weight_capacity', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Adjustability</label>
              <Textbox
                value={formData.adjustability}
                onChange={(e) => handleInputChange('adjustability', e.target.value)}
              />
            </div>
          </div>

          {/* Mobility Features and Care Instructions */}
          <div className="form-row">
            <div className="form-group">
              <label>Mobility Features</label>
              <Textbox
                value={formData.mobility_features}
                onChange={(e) => handleInputChange('mobility_features', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Care Instructions</label>
              <Textbox
                value={formData.care_instructions}
                onChange={(e) => handleInputChange('care_instructions', e.target.value)}
              />
            </div>
          </div>

          {/* Warranty and Certifications */}
          <div className="form-row">
            <div className="form-group">
              <label>Warranty</label>
              <Textbox
                value={formData.warranty}
                onChange={(e) => handleInputChange('warranty', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Certifications</label>
              <Textbox
                value={formData.certifications}
                onChange={(e) => handleInputChange('certifications', e.target.value)}
              />
            </div>
          </div>

          {/* Save Button */}
          <div className="button-row">
            <ButtonComponent
              text="Save"
              onClick={()=>handleSaveClick() }                                   
              className="next-button"
              disabled={!isSaveButtonEnabled}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomDetails;
