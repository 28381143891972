// actions/offerActions.js
import {
  fetchOffersFromAPI,
  createNewOfferAPI,
  updateOfferInAPI,
  deleteOfferAPI,
  createOffersBulkAPI,
  updateOffersBulkAPI,
  deleteOffersBulkAPI
} from '../../api/services/discountService';

// Action Types
export const FETCH_OFFERS = 'FETCH_OFFERS';
export const CREATE_OFFER = 'CREATE_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';
export const BULK_CREATE_OFFERS = 'BULK_CREATE_OFFERS';
export const BULK_UPDATE_OFFERS = 'BULK_UPDATE_OFFERS';
export const BULK_DELETE_OFFERS = 'BULK_DELETE_OFFERS';
export const OFFER_ERROR = 'OFFER_ERROR';

// Action Creators
export const fetchOffers = (token) => async (dispatch) => {
  try {
      const offers = await fetchOffersFromAPI(token);
      dispatch({ type: FETCH_OFFERS, payload: offers });
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
  }
};

export const createOffer = (newOffer, token) => async (dispatch) => {
  try {
      const offer = await createNewOfferAPI(newOffer, token);
      let data=  {
       
        offer_type: newOffer.offer_type,
        discount_value: newOffer.discount_value,
        conditions: newOffer.conditions,
        start_date: newOffer.start_date,
        expiry_date: newOffer.expiry_date,
        status: newOffer.status,
    }
      dispatch({ type: CREATE_OFFER, payload: data });
      return {success:true};
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
      return {success:false};
  }
};

export const updateOffer = (offerId, updatedData, token) => async (dispatch) => {
  try {
      const offer = await updateOfferInAPI(offerId, updatedData, token);
      let data=  {
        offer_id: offerId,
        offer_type: updatedData.offer_type,
        discount_value: updatedData.discount_value,
        conditions: updatedData.conditions,
        start_date: updatedData.start_date,
        expiry_date: updatedData.expiry_date,
        status: updatedData.status,

    }
      dispatch({ type: UPDATE_OFFER, payload: data });
      return {success:true};
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
      return {success:false};
  }
};

export const deleteOffer = (offerId, token) => async (dispatch) => {
  try {
      await deleteOfferAPI(offerId, token);
      dispatch({ type: DELETE_OFFER, payload: offerId });
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
  }
};

export const bulkCreateOffers = (offersData, token) => async (dispatch) => {
  try {
      const offers = await createOffersBulkAPI(offersData, token);
      dispatch({ type: BULK_CREATE_OFFERS, payload: offersData});
      return true;
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
      return false;
  }
};

export const bulkUpdateOffers = (offersData, token) => async (dispatch) => {
  try {
      const offers = await updateOffersBulkAPI(offersData, token);
      dispatch({ type: BULK_UPDATE_OFFERS, payload: offersData });
      return true;
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
      return false;
  }
};

export const bulkDeleteOffers = (offerIds, token) => async (dispatch) => {
  try {
      await deleteOffersBulkAPI(offerIds, token);
      dispatch({ type: BULK_DELETE_OFFERS, payload: offerIds });
  } catch (error) {
      dispatch({ type: OFFER_ERROR, payload: error.message });
  }
};
