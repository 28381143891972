// notificationCreationActions.js
import {
    fetchNotifications,
    createNotification,
    updateNotification,
    deleteNotification,
    bulkCreateNotifications,
    bulkFetchNotifications,
    bulkUpdateNotifications,
    bulkDeleteNotifications,
  } from '../../api/services/NotificationCreationService';
  import { toast } from 'react-hot-toast';
  
  export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
  export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
  export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
  export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR';
  export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
  export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';
  export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
  export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';
  export const BULK_CREATE_NOTIFICATIONS_REQUEST = 'BULK_CREATE_NOTIFICATIONS_REQUEST';
  export const BULK_CREATE_NOTIFICATIONS_SUCCESS = 'BULK_CREATE_NOTIFICATIONS_SUCCESS';
  export const BULK_CREATE_NOTIFICATIONS_ERROR = 'BULK_CREATE_NOTIFICATIONS_ERROR';
  export const BULK_FETCH_NOTIFICATIONS_SUCCESS = 'BULK_FETCH_NOTIFICATIONS_SUCCESS';
  export const BULK_FETCH_NOTIFICATIONS_ERROR = 'BULK_FETCH_NOTIFICATIONS_ERROR';
  export const BULK_UPDATE_NOTIFICATIONS_SUCCESS = 'BULK_UPDATE_NOTIFICATIONS_SUCCESS';
  export const BULK_UPDATE_NOTIFICATIONS_ERROR = 'BULK_UPDATE_NOTIFICATIONS_ERROR';
  export const BULK_DELETE_NOTIFICATIONS_SUCCESS = 'BULK_DELETE_NOTIFICATIONS_SUCCESS';
  export const BULK_DELETE_NOTIFICATIONS_ERROR = 'BULK_DELETE_NOTIFICATIONS_ERROR';
  
  // Fetch notifications
  export const getNotifications = (token) => async (dispatch) => {
    try {
      const data = await fetchNotifications(token);
      dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FETCH_NOTIFICATIONS_ERROR, payload: error.message });
    }
  };
  
  // Create a notification
  export const addNotification = (notificationData, token) => async (dispatch) => {
    try {
      const newNotification = await createNotification(notificationData, token);
      dispatch({ type: CREATE_NOTIFICATION_SUCCESS, payload: newNotification });
    } catch (error) {
      dispatch({ type: CREATE_NOTIFICATION_ERROR, payload: error.message });
    }
  };
  
  // Update a notification
  export const modifyNotification = (notificationId, notificationData, token) => async (dispatch) => {
    try {
      const updatedNotification = await updateNotification(notificationId, notificationData, token);
      dispatch({ type: UPDATE_NOTIFICATION_SUCCESS, payload: notificationData });
    } catch (error) {
      dispatch({ type: UPDATE_NOTIFICATION_ERROR, payload: error.message });
    }
  };
  
  // Delete a notification
  export const removeNotification = (notificationId, token) => async (dispatch) => {
    try {
      await deleteNotification(notificationId, token);
      dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: notificationId });
    } catch (error) {
      dispatch({ type: DELETE_NOTIFICATION_ERROR, payload: error.message });
    }
  };
  
  // Bulk create notifications
  export const bulkAddNotifications = (notificationData, token) => async (dispatch) => {
    dispatch({ type: BULK_CREATE_NOTIFICATIONS_REQUEST });
  
    try {
      const createdNotifications = await bulkCreateNotifications(notificationData, token);
      dispatch({ type: BULK_CREATE_NOTIFICATIONS_SUCCESS, payload: notificationData });
      toast.success('Notifications created successfully!');
      return { success: true };
    } catch (error) {
      dispatch({ type: BULK_CREATE_NOTIFICATIONS_ERROR, payload: error.message });
      toast.error(`Failed to create notifications: ${error.message}`);
      return { success: false };
    }
  };
  
// Bulk fetch notifications
export const bulkGetNotifications = (token) => async (dispatch) => {
  //console.log("Starting bulkGetNotifications..."); // Log start of the action
  try {
    //console.log("Fetching notifications with token:", token); // Log token
    const data = await bulkFetchNotifications(token);
    //console.log("Fetched notifications data:", data); // Log the fetched data
    dispatch({ type: BULK_FETCH_NOTIFICATIONS_SUCCESS, payload: data });
    //console.log("Dispatched BULK_FETCH_NOTIFICATIONS_SUCCESS with payload:", data); // Log success dispatch
  } catch (error) {
    //console.error("Error fetching notifications:", error.message); // Log error
    dispatch({ type: BULK_FETCH_NOTIFICATIONS_ERROR, payload: error.message });
    //console.error("Dispatched BULK_FETCH_NOTIFICATIONS_ERROR with payload:", error.message); // Log error dispatch
  }
};

  // Bulk update notifications
  export const bulkModifyNotifications = (notificationData, token) => async (dispatch) => {
    try {
      //console.log('Bulk Update Notification Data:', notificationData);
      const data = await bulkUpdateNotifications(notificationData, token);
      dispatch({ type: BULK_UPDATE_NOTIFICATIONS_SUCCESS, payload: notificationData });
      //console.log('Bulk Notifications Update Success:', data);
      return true;
    } catch (error) {
      //console.error('Error in Bulk Update:', error.message);
      dispatch({ type: BULK_UPDATE_NOTIFICATIONS_ERROR, payload: error.message });
      return false;
    }
  };
  
  // Bulk delete notifications
  export const bulkRemoveNotifications = (ids, token) => async (dispatch) => {
    try {
      await bulkDeleteNotifications(ids, token);
      dispatch({ type: BULK_DELETE_NOTIFICATIONS_SUCCESS, payload: ids });
    } catch (error) {
      dispatch({ type: BULK_DELETE_NOTIFICATIONS_ERROR, payload: error.message });
    }
  };
  