// services/ordersService.js

import apiClient from '../apiClient';

// Single Order Operations
export const createOrder = async (data, token) => {
    //console.log(data, '---createOrder data');
    const response = await apiClient.post('/auth/orders', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getOrder = async (orderId, token) => {
    const response = await apiClient.get(`/auth/orders?order_id=${orderId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateOrder = async (data, token) => {
    //console.log("Updating order:", data);
    const response = await apiClient.put('/auth/orders', data, {
        headers: { Authorization: token }
    });
    //console.log(response, '---updateOrder response');
    return response.data;
};

export const deleteOrder = async (orderId, token) => {
    //console.log(orderId,"id")
    const response = await apiClient.delete('/auth/orders', {
        params: { order_id: orderId },
        headers: { Authorization: token }
    });
    //console.log(response,'responseeeee')
    return response.data;
};

// Single Order Item Operations
export const createOrderItem = async (data, token) => {
    const response = await apiClient.post('/auth/order-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const getOrderItem = async (orderItemId, token) => {
    const response = await apiClient.get(`/auth/order-items?order_item_id=${orderItemId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateOrderItem = async (data, token) => {
    //console.log("Updating order item:", data);
    const response = await apiClient.put('/auth/order-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteOrderItem = async (orderItemId, token) => {
    const response = await apiClient.delete(`/auth/order-items?order_item_id=${orderItemId}`, {
        headers: { Authorization: token }
    });
    return response.data;
};

// Bulk Order Operations
export const getBulkOrders = async (token) => {
    const response = await apiClient.get('/auth/bulk-orders', {
        headers: { Authorization: token }
    });
   
    return response.data.orders;
};

export const getBulkOrderItems = async (token) => {
    const response = await apiClient.get('/auth/bulk-order-items', {
        headers: { Authorization: token }
    });
    //console.log(response,'response-------------');

    return response.data.order_items;
};

export const createBulkOrderItems = async (data, token) => {
    const response = await apiClient.post('/auth/bulk-order-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const updateBulkOrderItems = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-order-items', data, {
        headers: { Authorization: token }
    });
    return response.data;
};

export const deleteBulkOrderItems = async (data, token) => {
    const response = await apiClient.delete('/auth/bulk-order-items', {
        headers: { Authorization: token },
        data: data
    });
    return response.data;
};

// Bulk Delete for Orders
export const deleteBulkOrders = async (orderIds, token) => {
    const response = await apiClient.delete('/auth/bulk-orders', {
        headers: { Authorization: token },
        data: orderIds 
    });
    return response.data;
};
export const updateBulkOrders = async (data, token) => {
    const response = await apiClient.put('/auth/bulk-orders', data, {
        headers: { Authorization: token }
    });
    return response.data;
};