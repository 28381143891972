import React from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './RefundPage.scss';

const RefundPage = () => {
  return (
    <div className="refundpage-section">
      <div className="refundpage-container">
       
        <div className='return-container'>
        {/* Return Management Section */}
        <h3 className="refundpage-subheading">Return Management</h3>
        <hr/>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="return_updated_by">Updated By</label>
            <InputComponent type="text" id="return_updated_by" name="return_updated_by" />
          </div>
          <div className="form-group">
            <label htmlFor="return_approved_at">Return Approved At</label>
            <InputComponent type="datetime-local" id="return_approved_at" name="return_approved_at" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="return_completed_at">Return Completed At</label>
            <InputComponent type="datetime-local" id="return_completed_at" name="return_completed_at" />
          </div>
          <div className="form-group">
            <label htmlFor="return_comments">Comments</label>
            <InputComponent type="text" id="return_comments" name="return_comments" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="return_created_at">Created At</label>
            <InputComponent type="datetime-local" id="return_created_at" name="return_created_at" />
          </div>
          <div className="form-group">
            <label htmlFor="return_updated_at">Updated At</label>
            <InputComponent type="datetime-local" id="return_updated_at" name="return_updated_at" />
          </div>
        </div>
        </div>

        {/* Refund Management Section */}
        <div className='refund-container'>
        <h3 className="refundpage-subheading">Refund Management</h3>
        <hr/>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="refund_updated_by">Updated By</label>
            <InputComponent type="text" id="refund_updated_by" name="refund_updated_by" />
          </div>
          <div className="form-group">
            <label htmlFor="refund_processed_at">Refund Processed At</label>
            <InputComponent type="datetime-local" id="refund_processed_at" name="refund_processed_at" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="refund_completed_at">Refund Completed At</label>
            <InputComponent type="datetime-local" id="refund_completed_at" name="refund_completed_at" />
          </div>
          <div className="form-group">
            <label htmlFor="refund_created_at">Created At</label>
            <InputComponent type="datetime-local" id="refund_created_at" name="refund_created_at" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="refund_updated_at">Updated At</label>
            <InputComponent type="datetime-local" id="refund_updated_at" name="refund_updated_at" />
          </div>
          <div className="form-group">
            <label htmlFor="return_comments">Comments</label>
            <InputComponent type="text" id="return_comments" name="return_comments" />
          </div>
        </div>
        </div>

        <div className="form-row save-button-container">
            <ButtonComponent text="Save" className="save-button" />
        </div>

      </div>
    </div>
  );
};

export default RefundPage;
