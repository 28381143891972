import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import './SecondaryNavbar.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setNavbarState } from '../../../redux/actions/secondarynavbarActions';

const SecondaryNavbar = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  
  // Retrieve last selected tab from localStorage or default to 'category'
  const [activeItem, setActiveItem] = useState(localStorage.getItem('activeTab') || 'category');
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0, width: '100%' });

  useEffect(() => {
    handleNavigationState(activeItem); // Set active state from localStorage on mount
  }, []);

  const handleModalToggle = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: buttonRect.bottom + window.scrollY,
        left: 0,
        width: '100%',
      });
    }
    setShowModal(!showModal);
  };

  const handleNavigationState = (field) => {
    const newState = {
      category: false,
      brand: false,
      product: false,
      coupon: false,
      offer: false,
    };
    newState[field] = true;
    
    dispatch(setNavbarState(newState)); // Update Redux state
    setActiveItem(field); // Update active state
    localStorage.setItem('activeTab', field); // Store in localStorage
    setShowModal(false);
  };

  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <div className="secondary-navbar">
        <ul className="navbar-menu">
          <li className="menu-item items-menu-button">
            <button
              className={`menu-button ${showModal ? 'active' : ''}`}
              onClick={handleModalToggle}
              ref={buttonRef}
            >
              Items Menu <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeItem === 'category' ? 'active' : ''}`}
              onClick={() => handleNavigationState('category')}
            >
              Categories
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeItem === 'brand' ? 'active' : ''}`}
              onClick={() => handleNavigationState('brand')}
            >
              Brands
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeItem === 'product' ? 'active' : ''}`}
              onClick={() => handleNavigationState('product')}
            >
              Products
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeItem === 'coupon' ? 'active' : ''}`}
              onClick={() => handleNavigationState('coupon')}
            >
              Coupon
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeItem === 'offer' ? 'active' : ''}`}
              onClick={() => handleNavigationState('offer')}
            >
              Discounts
            </button>
          </li>
        </ul>
      </div>

      {showModal && (
        <div className="custom-modal" style={{ top: modalPosition.top }}>
          <div className="modal-content">
            <div className="modal-header">
              <span>Items Menu</span>
              <button className="close-button" onClick={handleModalClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <ul className="modal-list">
              <li onClick={() => handleNavigationState('category')}>Categories</li>
              <li onClick={() => handleNavigationState('brand')}>Brands</li>
              <li onClick={() => handleNavigationState('product')}>Products</li>
              <li onClick={() => handleNavigationState('coupon')}>Coupon</li>
              <li onClick={() => handleNavigationState('offer')}>Discounts</li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SecondaryNavbar;
