import {
    EXPIRY_ALERTS_FETCH_SUCCESS,
    EXPIRY_ALERTS_FETCH_ERROR,
    EXPIRY_ALERT_CREATE_SUCCESS,
    EXPIRY_ALERT_CREATE_ERROR,
    EXPIRY_ALERT_UPDATE_SUCCESS,
    EXPIRY_ALERT_UPDATE_ERROR,
    EXPIRY_ALERT_DELETE_SUCCESS,
    EXPIRY_ALERT_DELETE_ERROR,
    EXPIRY_ALERTS_BULK_FETCH_SUCCESS,
    EXPIRY_ALERTS_BULK_FETCH_ERROR,
    EXPIRY_ALERTS_BULK_CREATE_SUCCESS,
    EXPIRY_ALERTS_BULK_CREATE_ERROR,
    EXPIRY_ALERTS_BULK_UPDATE_SUCCESS,
    EXPIRY_ALERTS_BULK_UPDATE_ERROR,
    EXPIRY_ALERTS_BULK_DELETE_SUCCESS,
    EXPIRY_ALERTS_BULK_DELETE_ERROR,
  } from "../actions/expiryAlertActions";
  
  const initialState = {
    allExpiryAlerts: [], // Stores all expiry alerts
    error: null, // Stores any error messages
  };
  
  const expiryAlertReducer = (state = initialState, action) => {
    switch (action.type) {
      // Fetch all expiry alerts
      case EXPIRY_ALERTS_FETCH_SUCCESS:
        return { ...state, allExpiryAlerts: action.payload, error: null };
      case EXPIRY_ALERTS_FETCH_ERROR:
        return { ...state, error: action.payload };
  
      // Create a new expiry alert
      case EXPIRY_ALERT_CREATE_SUCCESS:
        return { ...state, allExpiryAlerts: [...state.allExpiryAlerts, action.payload], error: null };
      case EXPIRY_ALERT_CREATE_ERROR:
        return { ...state, error: action.payload };
  
      // Update an existing expiry alert
      case EXPIRY_ALERT_UPDATE_SUCCESS:
        return {
          ...state,
          allExpiryAlerts: state.allExpiryAlerts.map((alert) =>
            alert.alert_id === action.payload.alert_id ? action.payload : alert
          ),
          error: null,
        };
      case EXPIRY_ALERT_UPDATE_ERROR:
        return { ...state, error: action.payload };
  
      // Delete an expiry alert
      case EXPIRY_ALERT_DELETE_SUCCESS:
        return {
          ...state,
          allExpiryAlerts: state.allExpiryAlerts.filter(
            (alert) => alert.alert_id !== action.payload
          ),
          error: null,
        };
      case EXPIRY_ALERT_DELETE_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk fetch expiry alerts
      case EXPIRY_ALERTS_BULK_FETCH_SUCCESS:
        return { ...state, allExpiryAlerts: action.payload, error: null };
      case EXPIRY_ALERTS_BULK_FETCH_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk create expiry alerts
      case EXPIRY_ALERTS_BULK_CREATE_SUCCESS:
        return { ...state, allExpiryAlerts: [...state.allExpiryAlerts, ...action.payload], error: null };
      case EXPIRY_ALERTS_BULK_CREATE_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk update expiry alerts
      case EXPIRY_ALERTS_BULK_UPDATE_SUCCESS:
        return {
          ...state,
          allExpiryAlerts: state.allExpiryAlerts.map((alert) =>
            action.payload.find((updatedAlert) => updatedAlert.alert_id === alert.alert_id) || alert
          ),
          error: null,
        };
      case EXPIRY_ALERTS_BULK_UPDATE_ERROR:
        return { ...state, error: action.payload };
  
      // Bulk delete expiry alerts
      case EXPIRY_ALERTS_BULK_DELETE_SUCCESS:
        return {
          ...state,
          allExpiryAlerts: state.allExpiryAlerts.filter(
            (alert) => !action.payload.includes(alert.alert_id)
          ),
          error: null,
        };
      case EXPIRY_ALERTS_BULK_DELETE_ERROR:
        return { ...state, error: action.payload };
  
      // Default case
      default:
        return state;
    }
  };
  
  export default expiryAlertReducer;
  