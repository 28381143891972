import React, { useState } from 'react';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import Pagination from '../../components/common/Pagination/Pagination'
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent'; // Assuming CheckboxComponent exists
import { FaFileAlt, FaEdit,  FaSyncAlt,  FaGlobe, FaAsterisk, FaColumns } from 'react-icons/fa'; // Example icons
import './purchase_order_list.scss'; // Custom SCSS for this component

const PurchaseOrderList = () => {
  // State for the inputs
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderId, setOrderId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [grandTotal, setGrandTotal] = useState('');

  // Get current date
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }); // Format as 03 Oct 2024

  // Dropdown options
  const orderTypeOptions = ['All', 'Type 1', 'Type 2'];
  const subOrderTypeOptions = ['All', 'Sub Type 1', 'Sub Type 2'];
  const paymentTypeOptions = ['All', 'Paid', 'Unpaid'];
  const orderStatusOptions = ['All', 'Pending', 'Completed', 'Cancelled'];
  const otherStatusOptions = ['All', 'Shipped', 'Delivered', 'Returned'];
  const gstinOptions = ['All', 'GSTIN 1', 'GSTIN 2'];

  // Dropdown states
  const [orderType, setOrderType] = useState('All');
  const [subOrderType, setSubOrderType] = useState('All');
  const [paymentType, setPaymentType] = useState('All');
  const [orderStatus, setOrderStatus] = useState('All');
  const [otherStatus, setOtherStatus] = useState('All');
  const [gstin, setGstin] = useState('All');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 5; // Set your total pages here dynamically based on API or data

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      // Handle pagination logic such as fetching new data for the selected page
    }
  };

  return (
    <div className="purchase-order-list-page">
      <div className="purchase-order-list-container">
        <div className="filter-section">
          {/* First row */}
          <div className="row">
            <div className="filter-item">
              <label>Start Date</label>
              <Datepicker
              className={"date-picker"}
                dateValue={startDate}
                onDateChange={setStartDate}
                placeholder={formattedCurrentDate} // Display current date as placeholder
              />
            </div>
            <div className="filter-item">
              <label>End Date</label>
              <Datepicker
              className={"date-picker"}
                dateValue={endDate}
                onDateChange={setEndDate}
                placeholder={formattedCurrentDate} // Display current date as placeholder
              />
            </div>
            <div className="filter-item">
              <label>Order ID</label>
              <InputComponent
                placeholder="Order ID"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>
            <div className="filter-item">
              <label>Customer Name</label>
              <InputComponent
                placeholder="Customer Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
            <div className="filter-item">
              <label>Customer Phone</label>
              <InputComponent
                placeholder="Customer Phone"
                value={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
              />
            </div>
          

            <div className="filter-item">
              <label>All Order Type</label>
              <DropdownButton 
                options={orderTypeOptions} 
                label={orderType} 
                onSelect={(selectedOption) => setOrderType(selectedOption)} 
              />
            </div>
            <div className="filter-item">
              <label>Sub Order Type</label>
             <DropdownButton 
                options={subOrderTypeOptions} 
                label={subOrderType} 
                onSelect={(selectedOption) => setSubOrderType(selectedOption)} 
              />
            </div>
            <div className="filter-item">
              <label>All Payment Type</label>
              <DropdownButton 
                options={paymentTypeOptions} 
                label={paymentType} 
                onSelect={(selectedOption) => setPaymentType(selectedOption)} 
              />
            </div>
            <div className="filter-item">
              <label>Order Status</label>
              <DropdownButton 
                options={orderStatusOptions} 
                label={orderStatus} 
                onSelect={(selectedOption) => setOrderStatus(selectedOption)} 
              />
            </div>
            <div className="filter-item">
              <label>Other Status</label>
              <DropdownButton 
                options={otherStatusOptions} 
                label={otherStatus} 
                onSelect={(selectedOption) => setOtherStatus(selectedOption)} 
              />
            </div>
            
        
      
          <div className="filter-item">
              <label>GSTIN</label>
              <DropdownButton 
                options={gstinOptions} 
                label={gstin} 
                onSelect={(selectedOption) => setGstin(selectedOption)} 
              />
            </div>
            <div className="filter-item">
                
              <label>Grand Total</label>
              <InputComponent
                placeholder="Grand Total"
                value={grandTotal}
                onChange={(e) => setGrandTotal(e.target.value)}
              />
            </div>
            <div className="filter-item">
              <label>GSTIN</label>
              <DropdownButton options={gstinOptions} label="All" />
            </div>
            <div className="filter-item">
            <div className="search-show-all-buttons">
                <ButtonComponent text="Search" variant="danger" className="search-button" />
                <ButtonComponent text="Show All" variant="secondary" className="show-all-button" />
            </div>
            </div>

          </div>

        </div>

        {/* Display selected dates and Clear All button */}
        <div className="summary-section">
          <div className="selected-dates">
            <span className='selected-date'>Start Date: {startDate ? startDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : formattedCurrentDate}</span>
            <span className='selected-date'>End Date: {endDate ? endDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : formattedCurrentDate}</span>
          </div>
          <ButtonComponent text="Clear All" variant="secondary" className="clear-all-button" />
        </div>
        {/* Table */}
        <div className="purchase-order-table-section">
          <table className="purchase-order-table">
            <thead>
              <tr>
                <th><CheckboxComponent /></th>
                <th>Order No.</th>
                <th>Order Type</th>
                <th>Customer Name</th>
                <th>Assign To</th>
                <th>Items</th>
                <th>My Amount (₹)</th>
                <th>Tax (₹)</th>
                <th>Discount (₹)</th>
                <th>Grand Total [Round Off] (₹)</th>
                <th>Arriving on</th>
                <th>Order Placed</th>
                <th>Payment</th>
                <th>Status</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><CheckboxComponent /></td>
                <td>3104</td>
                <td>Dine In (7) <br /><span className="order-subtype">(Dine In)</span></td>
                <td>John Doe</td>
                <td>Assigned Staff</td>
                <td>Iodex Balm 40g</td>
                <td>3,195.00</td>
                <td>159.76</td>
                <td>(0.00)</td>
                <td>14 Sept 2024</td>
                <td>On 10 Sept 2024</td>
                <td>3,355.00 [0.24]</td>
                <td>Other [UPI]</td>
                <td><span className="status printed">Printed</span></td>
                <td>3 Oct 2024 <br /> 15:41:46</td>
                <td className="actions-column">
                  <FaFileAlt className="action-icon" title="View Order" />
                  
                  <FaEdit className="action-icon" title="Edit Order" />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
     {/* Pagination and Additional Info Section */}
     <div className="pagination-section">
  <Pagination 
    className="pagination-buttons" 
    currentPage={currentPage} 
    totalPages={totalPages} 
    onPageChange={handlePageChange} 
  />
        <div className="pagination-icons-section">
          <div className="pagination-item">
            <FaSyncAlt className="pagination-icon" title="Settlement Amount" />
            <span className="pagination-text">Settlement Amount</span>
          </div>
          <div className="pagination-item">
            <FaFileAlt className="pagination-icon" title="Updated After Save & Print" />
            <span className="pagination-text">Updated After Save & Print</span>
          </div>
          <div className="pagination-item">
            <FaGlobe className="pagination-icon" title="Online Order" />
            <span className="pagination-text">Online Order</span>
          </div>
          <div className="pagination-item">
            <FaAsterisk className="pagination-icon" title="Advance Order" />
            <span className="pagination-text">Advance Order</span>
          </div>
          <div className="pagination-item">
            <FaColumns className="pagination-icon" title="Split Bill" />
            <span className="pagination-text">Split Bill</span>
          </div>
        </div>
      </div>

       
      </div>
    </div>
  );
};

export default PurchaseOrderList;

