// actions/locationActions.js
import * as locationService from '../../api/services/locationService';

// Action Types
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const BULK_CREATE_LOCATIONS_SUCCESS = 'BULK_CREATE_LOCATIONS_SUCCESS';
export const BULK_UPDATE_LOCATIONS_SUCCESS = 'BULK_UPDATE_LOCATIONS_SUCCESS';
export const BULK_DELETE_LOCATIONS_SUCCESS = 'BULK_DELETE_LOCATIONS_SUCCESS';

// Action Creators

// Fetch single location by ID
export const fetchLocation = (locationId, token) => async (dispatch) => {
    try {
        const data = await locationService.fetchLocationFromAPI(locationId, token);
        dispatch({ type: FETCH_LOCATION_SUCCESS, payload: data });
    } catch (error) {
        //console.error('Failed to fetch location:', error);
    }
};

// Fetch all locations or multiple locations by IDs
export const fetchLocations = (token) => async (dispatch) => {
    try {
        const data = await locationService.fetchBulkLocationsAPI(token);
        dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: data });
    } catch (error) {
        //console.error('Failed to fetch locations:', error);
    }
};

// Create new location
export const createLocation = (newLocation, token) => async (dispatch) => {
    try {
        //console.log(newLocation,'mewlocation---')

        const data = await locationService.createNewLocationAPI(newLocation, token);
        const newdata ={
          
            location_name: newLocation.locationName,
            serviceable: newLocation.serviceable,
            base_charge: newLocation.baseCharge,
            additional_charge_per_km: newLocation.additionalChargePerKm,
        }
        dispatch({ type: CREATE_LOCATION_SUCCESS, payload: newdata });
        return true;
    } catch (error) {
        //console.error('Failed to create location:', error);
        return false;
    }
};

// Update existing location
export const updateLocation = (locationId, updatedData, token) => async (dispatch) => {
    try {
        //console.log(updatedData,'updated dta--------')
        const data = await locationService.updateLocationInAPI(locationId, updatedData, token);
        const newdata ={
            location_id: locationId,
            location_name: updatedData.locationName,
            serviceable: updatedData.serviceable,
            base_charge: updatedData.baseCharge,
            additional_charge_per_km: updatedData.additionalChargePerKm,
        }
        dispatch({ type: UPDATE_LOCATION_SUCCESS, payload: newdata });
        return true;
    } catch (error) {
        //console.error('Failed to update location:', error);
        return false;
    }
};

// Delete location by ID
export const deleteLocation = (locationId, token) => async (dispatch) => {
    try {
        await locationService.deleteLocationAPI(locationId, token);
        dispatch({ type: DELETE_LOCATION_SUCCESS, payload: locationId });
    } catch (error) {
        //console.error('Failed to delete location:', error);
    }
};

// Bulk create locations
export const bulkCreateLocations = (locationsData, token) => async (dispatch) => {
    try {
        const data = await locationService.createBulkLocationsAPI(locationsData, token);
        dispatch({ type: BULK_CREATE_LOCATIONS_SUCCESS, payload: data });
        return true;
    } catch (error) {
        //console.error('Failed to create locations in bulk:', error);
        return false;
    }
};

// Bulk update locations
export const bulkUpdateLocations = (locationsData, token) => async (dispatch) => {
    try {
        //console.log(locationsData,'location data---')
        const data = await locationService.updateBulkLocationsAPI(locationsData, token);
        dispatch({ type: BULK_UPDATE_LOCATIONS_SUCCESS, payload: locationsData});
        return true;
    } catch (error) {
        //console.error('Failed to update locations in bulk:', error);
        return false;
    }
};

// Bulk delete locations
export const bulkDeleteLocations = (locationIds, token) => async (dispatch) => {
    try {
        await locationService.deleteBulkLocationsAPI(locationIds, token);
        dispatch({ type: BULK_DELETE_LOCATIONS_SUCCESS, payload: locationIds });
    } catch (error) {
        //console.error('Failed to delete locations in bulk:', error);
    }
};
