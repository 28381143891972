// redux/actions/orderActions.js

import * as orderService from '../../api/services/orderService';

// Action Types
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const GET_BULK_ORDERS = 'GET_BULK_ORDERS';
export const DELETE_BULK_ORDERS = 'DELETE_BULK_ORDERS';
export const UPDATE_BULK_ORDERS = 'UPDATE_BULK_ORDERS'; 

export const CREATE_ORDER_ITEM = 'CREATE_ORDER_ITEM';
export const GET_ORDER_ITEM = 'GET_ORDER_ITEM';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM';
export const GET_BULK_ORDER_ITEMS = 'GET_BULK_ORDER_ITEMS';

export const CREATE_BULK_ORDER_ITEMS = 'CREATE_BULK_ORDER_ITEMS';
export const UPDATE_BULK_ORDER_ITEMS = 'UPDATE_BULK_ORDER_ITEMS';
export const DELETE_BULK_ORDER_ITEMS = 'DELETE_BULK_ORDER_ITEMS';
export const CLEAR_ORDER_STATE = 'CLEAR_ORDER_STATE';

// Single Order Actions
export const createOrder = (data, token) => async (dispatch) => {
    try{
        const response = await orderService.createOrder(data, token);
    dispatch({ type: CREATE_ORDER, payload:data });
    return response;
    }
    catch{}
    
};

export const getOrder = (orderId, token) => async (dispatch) => {
    try{
        const response = await orderService.getOrder(orderId, token);
    dispatch({ type: GET_ORDER, payload: response });
    }
    catch{}
    
};

export const updateOrder = (data, token) => async (dispatch) => {
    try{
        //console.log(data, 'Updating order data');
    const response = await orderService.updateOrder(data, token);
    dispatch({ type: UPDATE_ORDER, payload: data });
    }
    catch{}
    
};

export const deleteOrder = (orderId, token) => async (dispatch) => {
    //console.log(orderId,'---id');
    try{
        const response = await orderService.deleteOrder(orderId, token);
    dispatch({ type: DELETE_ORDER, payload: orderId  });
    }
    catch{}
    
};

// Bulk Order Actions
export const getBulkOrders = (token) => async (dispatch) => {
    try{
        const response = await orderService.getBulkOrders(token);
    dispatch({ type: GET_BULK_ORDERS, payload: response });
    return true;
    }
    catch{
        return false;
    }
};

export const deleteBulkOrders = (orderIds, token) => async (dispatch) => {
    try{
        const response = await orderService.deleteBulkOrders(orderIds, token);
        dispatch({ type: DELETE_BULK_ORDERS, payload: { deletedOrderIds: orderIds } });
    }
  catch{

  }
};

// Single Order Item Actions
export const createOrderItem = (data, token) => async (dispatch) => {
    try{
        const response = await orderService.createOrderItem(data, token);
        dispatch({ type: CREATE_ORDER_ITEM, payload: data });
    }
    catch{

    }
   
    
};

export const getOrderItem = (orderItemId, token) => async (dispatch) => {
    try{
        const response = await orderService.getOrderItem(orderItemId, token);
    dispatch({ type: GET_ORDER_ITEM, payload: response });
    }
    catch{}
    
};

export const updateOrderItem = (data, token) => async (dispatch) => {
    try{
        const response = await orderService.updateOrderItem(data, token);
    dispatch({ type: UPDATE_ORDER_ITEM, payload: data });
    }
    catch{

    }
    
};

export const deleteOrderItem = (orderItemId, token) => async (dispatch) => {
    try{
        const response = await orderService.deleteOrderItem(orderItemId, token);
        dispatch({ type: DELETE_ORDER_ITEM, payload: { orderItemId } });
    }
  catch{

  }
};

// Bulk Order Item Actions
export const getBulkOrderItems = (token) => async (dispatch) => {
    try{
        const response = await orderService.getBulkOrderItems(token);
        dispatch({ type: GET_BULK_ORDER_ITEMS, payload: response });
        return true;
    }
   catch{
    return false;

   }
};

export const createBulkOrderItems = (data, token) => async (dispatch) => {
    try{
        //console.log(data, 'Creating bulk order items');
        const response = await orderService.createBulkOrderItems(data, token);
        dispatch({ type: CREATE_BULK_ORDER_ITEMS, payload: data });
    }
   catch{

   }
};

export const updateBulkOrderItems = (data, token) => async (dispatch) => {
    try{
        const response = await orderService.updateBulkOrderItems(data, token);
        dispatch({ type: UPDATE_BULK_ORDER_ITEMS, payload: data });}
    catch{

    }
    
};

export const deleteBulkOrderItems = (data, token) => async (dispatch) => {
    try{
        const response = await orderService.deleteBulkOrderItems(data, token);
    dispatch({ type: DELETE_BULK_ORDER_ITEMS, payload: { deletedItems: data } });
    }
    catch{
        //console.log('')
    }
    
};
export const updateBulkOrders = (data, token) => async (dispatch) => {
    try{
        const response = await orderService.updateBulkOrders(data, token);
    dispatch({ type: UPDATE_BULK_ORDERS, payload: data });
    return response;
    }
    catch{
        
    }
};
export const clearOrderState = () => ({
    type: CLEAR_ORDER_STATE,
  });
  