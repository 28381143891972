// services/locationService.js
import { location_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch single location by ID
export const fetchLocationFromAPI = async (locationId, token) => {
    const response = await apiClient.get(location_endpoints.GET_LOCATION_CONFIG, {
        params: { location_id: locationId },
        headers: { Authorization: token },
    });

    //console.log("Location response:", response.data);
    if (!response.data) {
        throw new Error('Failed to fetch location');
    }
    return response.data; // Return the location data
};

// Create a new location
export const createNewLocationAPI = async (newLocation, token) => {
    //console.log("Adding new location:", newLocation);

    const response = await apiClient.post(
        location_endpoints.GET_LOCATION_CONFIG,
        {
            location_name: newLocation.locationName,
            serviceable: newLocation.serviceable,
            base_charge: newLocation.baseCharge,
            additional_charge_per_km: newLocation.additionalChargePerKm,
        },
        {
            headers: { Authorization: token },
        }
    );

    if (!response.data) {
        throw new Error('Failed to create new location');
    }

    //console.log('New location created successfully', response.data);
    return response.data; // Return the created location data
};

// Update a location by ID
export const updateLocationInAPI = async (locationId, updatedData, token) => {
    //console.log("Updating location data:", updatedData);

    const response = await apiClient.put(
        location_endpoints.GET_LOCATION_CONFIG,
        {
            location_id: locationId,
            location_name: updatedData.locationName,
            serviceable: updatedData.serviceable,
            base_charge: updatedData.baseCharge,
            additional_charge_per_km: updatedData.additionalChargePerKm,
        },
        {
            headers: { Authorization: token },
        }
    );

    if (!response.data) {
        throw new Error(`Failed to update location with ID: ${locationId}`);
    }

    //console.log('Location updated successfully', response.data);
    return response.data; // Return the updated location data
};

// Delete a location by ID
export const deleteLocationAPI = async (locationId, token) => {
    //console.log("Deleting location with ID:", locationId);

    const response = await apiClient.delete(location_endpoints.GET_LOCATION_CONFIG, {
        params: { location_id: locationId },
        headers: { Authorization: token },
    });

    if (response.status !== 200) {
        throw new Error(`Failed to delete location with ID: ${locationId}`);
    }

    //console.log('Location deleted successfully', response.data);
    return response.data; // Optionally return the deleted location data
};

// Bulk fetch locations by IDs
export const fetchBulkLocationsAPI = async (token) => {
    const response = await apiClient.get(location_endpoints.GET_BULK_LOCATIONS_CONFIG, {
     
        headers: { Authorization: token },
    });

    //console.log("Bulk locations response:", response.data);
    if (!response.data) {
        throw new Error('Failed to fetch bulk locations');
    }
    return response.data.locations; // Return the list of locations
};

// Bulk create locations
export const createBulkLocationsAPI = async (locationsData, token) => {
    //console.log("Bulk location data for adding:", locationsData);

    const response = await apiClient.post(
        location_endpoints.GET_BULK_LOCATIONS_CONFIG,
        locationsData,
        {
            headers: { Authorization: token },
        }
    );

    if (!response.data) {
        throw new Error('Failed to create locations in bulk');
    }

    //console.log('Bulk locations created successfully', response.data);
    return response.data; // Return created locations data
};

// Bulk update locations
export const updateBulkLocationsAPI = async (locationsData, token) => {
    //console.log("Bulk location data for update:", locationsData);

    const response = await apiClient.put(
        location_endpoints.GET_BULK_LOCATIONS_CONFIG,
        locationsData,
        {
            headers: { Authorization: token },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to update locations in bulk');
    }

    //console.log('Bulk locations updated successfully', response.data);
    return response.data; // Return updated locations data
};

// Bulk delete locations by IDs
export const deleteBulkLocationsAPI = async (locationIds, token) => {
    //console.log("Bulk delete locations:", locationIds);

    const response = await apiClient.delete(
        location_endpoints.GET_BULK_LOCATIONS_CONFIG,
        {
            data: locationIds, // Send array of location IDs in the request body
            headers: { Authorization: token },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to delete locations in bulk');
    }

    //console.log('Bulk locations deleted successfully', response.data);
    return response.data; // Return deleted locations data
};
