import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
 
  removeInventoryThreshold,

  bulkGetInventoryThresholds,
  bulkRemoveInventoryThresholds,
} from '../../redux/actions/inventoryThresholdActions';


import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
 faPencilAlt,
  faTrash,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import './inventory-displaypage.scss';

const InventoryDisplayPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  
    const token = useSelector((state) => state.adminLogin.token);
    const inventoryThresholds = useSelector((state) => state.inventoryThreshold.inventoryThresholds || []);
    //console.log(inventoryThresholds,'---threshhhhhhh')
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    useEffect(() => {
        const fetchInitialInventory = () => {
          if (token) {
            dispatch(bulkGetInventoryThresholds(token)); // Fetch inventory thresholds
          }
        };
      
        fetchInitialInventory(); // Fetch on mount
      
        const intervalId = setInterval(fetchInitialInventory, 180000); // Fetch every 3 minutes (180,000 ms)
      
        return () => clearInterval(intervalId); // Cleanup interval on unmount
      }, [dispatch, token]);
      
      const basicProducts = useSelector((state) => state.products.basicProducts) || [];
      const getInventoryThresholds = (productId) => {
          const inventoryData = basicProducts.find((item) => item.product_id === productId);
          if (inventoryData) {
            return inventoryData.product_name; // Replace `product_name` with the relevant property
          }
          
        };

      const filteredInventory = (Array.isArray(inventoryThresholds) ? inventoryThresholds : []).filter((item) =>
        searchTerm
          ? item.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.product_id?.toString().includes(searchTerm) ||
            getInventoryThresholds(item.product_id)?.toLowerCase().includes(searchTerm.toLowerCase())
          : true
      );
    
      const handleCheckboxChange = (id) => {
        if (selectedIds.includes(id)) {
          setSelectedIds((prev) => prev.filter((selectedId) => selectedId !== id));
        } else {
          setSelectedIds((prev) => [...prev, id]);
        }
      };
      
      const handleSelectAll = () => {
        if (selectAll) {
          setSelectedIds([]);
        } else {
          const allIds = filteredInventory.map((item) => item.threshold_id);
          setSelectedIds(allIds);
        }
        setSelectAll(!selectAll);
      };
    
      useEffect(() => {
        if (selectedIds.length === filteredInventory.length) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
        }
      }, [selectedIds, filteredInventory.length]);

    const handleActionSelect = (action) => {
        if (selectedIds.length === 0) {
          alert("Please select at least one product to proceed.");
          return;
        }

        if (action === "edit") {
          //console.log("---///---",selectedIds);
            navigate('/admin/bulk-inventory', { state: selectedIds });

            setSelectedIds([]);
          }else if (action === "delete") {
                  if (window.confirm(`Are you sure you want to delete the selected categories?`)) {
                      //console.log("Deleting inventory:", selectedIds);
                      dispatch(bulkRemoveInventoryThresholds(selectedIds,token));
                      setSelectedIds([]);
                  }
              }
        };



    const handleDelete = async (id) => {
      if (window.confirm('Are you sure you want to delete this inventory threshold?')) {
        try {
          await dispatch(removeInventoryThreshold(id, token));
          toast.success('Inventory threshold deleted successfully.');
        } catch (error) {
          toast.error('Error deleting inventory threshold.');
        }
      }
    };



    const handleEditClick = (id) => {
      navigate('/admin/inventory-threshold', { state: id });
    };
  
    return (
      <div className="inventory-page">
        <div className="breadcrumb-container">
          <Link to="/admin/dashboard" className="breadcrumb-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <Link to="/admin" className="breadcrumb-text">
            Inventory List
          </Link>
        </div>
        <div className="inventory-list-container"></div>
        <div className="inventory-page-class">
          <div className="right-section">
            <h5 className="text-heading1">Inventory List</h5>
            <div className="toolbar">
              <div className="search-wrapper">
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search by product name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            
          <div className="item-controls">
            <ButtonComponent text="+ Add Items" className="add-items-button"  onClick={() => navigate('/admin/inventory-threshold')}/>
            <div className="actions-toolbar">
              <Dropdown>
                <Dropdown.Toggle variant="primary" className="dropdown-toggle" id="actions-dropdown">Actions</Dropdown.Toggle>
                <Dropdown.Menu>
              
                  <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
            </div>
           
            <div className="table-container">
              {filteredInventory.length === 0 ? (
                <div className="no-data-message">No inventory thresholds found.</div>
              ) : (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <CheckboxComponent checked={selectAll} onChange={handleSelectAll} />
                      </th>
                      <th>ID</th>
                      <th>Product Name</th>
                      <th>Min Threshold</th>
                      <th>Max Threshold</th>
                      <th>Auto Threshold</th>
                      <th>Current Stock</th>
                      <th>Status</th>
                     <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                  {filteredInventory.map((item, index) => {
                        //console.log(item, 'Current Item');
                        return (
                            <tr key={item.id}>
                            <td>
                                <CheckboxComponent
                                checked={selectedIds.includes(item.threshold_id)}
                                onChange={() => handleCheckboxChange(item.threshold_id)}
                                />
                            </td>
                            <td>{index + 1}</td>
                            <td>{getInventoryThresholds(item.product_id)}</td>
                            <td>{item.min_threshold}</td>
                            <td>{item.max_threshold}</td>
                            <td>{item.auto_threshold}</td>
                            <td>{item.current_stock}</td>
                            <td>{item.applies_to_all ? 'Applies to All' : 'Specific'}</td>
                            <td className="actions-column">
                            <button className="icon-button" onClick={() => handleEditClick(item.threshold_id)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                                </button>
                              </td>
                              <td className="actions-column">
                                <button className="icon-button" onClick={() => handleDelete(item.threshold_id)}>
                                <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                            </tr>
                        );
                        })}

                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  

  

export default InventoryDisplayPage;
