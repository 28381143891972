import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./subscription-management-navbar.scss";
import { useDispatch } from "react-redux";
import { setSubscriptionManageNavbarState } from "../../../redux/actions/subscriptionManagementActions";

const SubscriptionManagementNavbar = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);

  // Retrieve last selected tab from localStorage or default to 'subscription'
  const [activeButton, setActiveButton] = useState(localStorage.getItem("activeSubscriptionTab") || "subscription");
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0, width: "100%" });

  useEffect(() => {
    // Set initial Redux state based on stored tab
    handleSubscriptionState(activeButton);
  }, []); // Runs once after mounting

  const handleModalToggle = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: buttonRect.bottom + window.scrollY,
        left: 0,
        width: "100%",
      });
    }
    setShowModal(!showModal);
  };

  const handleModalClose = () => setShowModal(false);

  const handleSubscriptionState = (field) => {
    const stateMapping = {
      subscription: { subscription: true, deliveryconfig: false, expiry: false, notification: false, inventory: false },
      deliveryconfig: { subscription: false, deliveryconfig: true, expiry: false, notification: false, inventory: false },
      expiry: { subscription: false, deliveryconfig: false, expiry: true, notification: false, inventory: false },
      notification: { subscription: false, deliveryconfig: false, expiry: false, notification: true, inventory: false },
      inventory: { subscription: false, deliveryconfig: false, expiry: false, notification: false, inventory: true },
    };

    dispatch(setSubscriptionManageNavbarState(stateMapping[field]));
    setActiveButton(field); // Update active button
    localStorage.setItem("activeSubscriptionTab", field); // Store active tab in localStorage
    setShowModal(false);
  };

  return (
    <>
      <div className="secondary-navbar">
        <ul className="navbar-menu">
          <li className="menu-item items-menu-button">
            <button
              className={`menu-button ${showModal ? "active" : ""}`}
              onClick={handleModalToggle}
              ref={buttonRef}
            >
              Items Menu <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
            </button>
          </li>

          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "subscription" ? "active" : ""}`}
              onClick={() => handleSubscriptionState("subscription")}
            >
              Subscription
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "inventory" ? "active" : ""}`}
              onClick={() => handleSubscriptionState("inventory")}
            >
              Inventory
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "deliveryconfig" ? "active" : ""}`}
              onClick={() => handleSubscriptionState("deliveryconfig")}
            >
              Delivery Config
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "expiry" ? "active" : ""}`}
              onClick={() => handleSubscriptionState("expiry")}
            >
              Expiry Alert
            </button>
          </li>
          <li className="menu-item">
            <button
              className={`menu-button ${activeButton === "notification" ? "active" : ""}`}
              onClick={() => handleSubscriptionState("notification")}
            >
              Notification
            </button>
          </li>
        </ul>
      </div>

      {showModal && (
        <div className="custom-modal" style={{ top: modalPosition.top }}>
          <div className="modal-content">
            <div className="modal-header">
              <span>Items Menu</span>
              <button className="close-button" onClick={handleModalClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <ul className="modal-list">
              <li
                className={`modal-list-item ${activeButton === "subscription" ? "active" : ""}`}
                onClick={() => handleSubscriptionState("subscription")}
              >
                Subscription
              </li>
              <li
                className={`modal-list-item ${activeButton === "inventory" ? "active" : ""}`}
                onClick={() => handleSubscriptionState("inventory")}
              >
                Inventory
              </li>
              <li
                className={`modal-list-item ${activeButton === "deliveryconfig" ? "active" : ""}`}
                onClick={() => handleSubscriptionState("deliveryconfig")}
              >
                Delivery Config
              </li>
              <li
                className={`modal-list-item ${activeButton === "expiry" ? "active" : ""}`}
                onClick={() => handleSubscriptionState("expiry")}
              >
                Expiry Alert
              </li>
              <li
                className={`modal-list-item ${activeButton === "notification" ? "active" : ""}`}
                onClick={() => handleSubscriptionState("notification")}
              >
                Notification
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionManagementNavbar;
