import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import store from './redux/store'; // Import the Redux store
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Create the root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Wrap the App component with Provider to provide the Redux store to your app
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Provide the store to your app */}
      <App />
    </Provider>
  </React.StrictMode>
);
