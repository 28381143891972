// src/reducers/userReducer.js
import { SET_USER_MANAGE_NAVBAR_STATE } from '../actions/usermanagenavbarActions'; // Adjust the import path as necessary

const initialState = {
    admin: false,
    delivery: true,
    user: false,
};

const usernavReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_MANAGE_NAVBAR_STATE:
            return {
                ...state,
                ...action.payload, // Merges the new state with the existing state
            };
        default:
            return state;
    }
};

export default usernavReducer;
