import React, { useState, useEffect } from 'react';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './AddDeliveryPerson.scss';
import { faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, updateUser, createUser } from '../../redux/actions/adminuserActions';
import { uploadDocumentAPI, generatePresignedUrlAPI } from '../../api/services/filestorageService';
import { toast } from 'react-hot-toast';

const AddDeliveryPerson = ({urls}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    mobile_number: '',
    internal_id: '',
    password: '',
    proof: '',
    role: 'delivery',
    
  });
  const [initialFormData, setInitialFormData] = useState(null); // For comparison
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [isModified, setIsModified] = useState(false);

  const deliverylist = useSelector((state) => state.usermanage.users);
  const token = useSelector((state) => state.adminLogin.token);
  const location = useLocation();
  const navigate = useNavigate();
  const delivery_Id = location.state || null;
  const [isSaving,setIsSaving] = useState(false)

  useEffect(() => {
    if (delivery_Id) {
      const deliveryData = deliverylist.find((data) => data.user_id === delivery_Id);
      if (deliveryData) {
        setFormData(deliveryData);
        setInitialFormData(deliveryData);
        if (deliveryData.proof) {
          generatePresignedUrl(deliveryData.proof);
        }
      }
    } else {
      setInitialFormData({
        username: '',
        email: '',
        mobile_number: '',
        internal_id: '',
  
        proof: '',
        role: 'delivery',
        password:''
      });
    }
  }, [delivery_Id, deliverylist]);

  const generatePresignedUrl = async (imageUrl) => {
    try {
      const presignedUrlData = await generatePresignedUrlAPI(imageUrl, token);
      if (presignedUrlData.presigned_url) {
        setFileUrl(presignedUrlData.presigned_url);
        setFileType(imageUrl.split('.').pop().toLowerCase());
      }
    } catch (error) {
      // //console.error('Error generating presigned URL:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
  
    if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
      toast.error('Only JPG, JPEG, PNG, and PDF formats are supported.');
      return;
    }
  
    if (selectedFile) {
      setFile(selectedFile);
      setFileType(selectedFile.type);
      setFileUrl(URL.createObjectURL(selectedFile));
      setIsModified(true);
    }
  };
  

  const handleFileUpload = async () => {
    if (file) {
      try {
        const uploadedUrl = await uploadDocumentAPI(file, 'proof/', token);
        if (uploadedUrl && uploadedUrl.file_url) {
          setFileUrl(uploadedUrl.file_url);
          setFormData((prevData) => ({
            ...prevData,
            proof: uploadedUrl.file_url,
          }));
          return { ...formData, proof: uploadedUrl.file_url };
        } else {
          toast.error('File upload failed. Please try again.');
        }
      } catch (error) {
        // //console.error('Error uploading file:', error);
      }
    }
    return formData;
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl('');
    setFileType('');
    setFormData((prevData) => ({ ...prevData, proof: '' }));
    setIsModified(true);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, [field]: value };
      checkIfModified(updatedData, file);
      return updatedData;
    });
  };

  const checkIfModified = (updatedData, updatedFile) => {
    if (initialFormData) {
      const isDataModified = Object.keys(initialFormData).some(
        (key) => initialFormData[key] !== updatedData[key]
      );
      const isFileModified = updatedFile !== null;
      setIsModified(isDataModified || isFileModified);
    }
  };

  const isDuplicate = () => {
    return deliverylist.some((user) => 
      (user.email === formData.email && user.user_id !== delivery_Id) ||
      (user.mobile_number === formData.mobile_number && user.user_id !== delivery_Id) ||
      (user.username === formData.username && user.user_id !==delivery_Id)
    );
  };

  const handleSubmit = async () => {
    // Validation: Check if any required field is empty
    ////console.log("delivery----",formData);
    setIsSaving(true);
    let updatedFormData = formData;
    if (file) {
      updatedFormData = await handleFileUpload();
    }
    const requiredFields = ['username', 'email', 'mobile_number', 'internal_id', 'proof'];
    for (const field of requiredFields) {
      if (!updatedFormData[field] || updatedFormData[field].trim() === '') {
        toast.error(`${field.replace('_', ' ')} is required.`);
        setIsSaving(false);
        return;
      }
    }
    ////console.log(updatedFormData,'----updateeeee');
    if (delivery_Id) {
      const response = dispatch(updateUser(delivery_Id, updatedFormData, token));
      if (response){
        toast.success('Delivery person updated successfully!');
        if(!urls){
          navigate('/admin/user-management');
        }
        else{
          await dispatch(fetchUsers(token));
        }
       
      }
      setIsSaving(false);
      
    } else {
      const response = dispatch(createUser(updatedFormData, token));
      if (response){
        await dispatch(fetchUsers(token));
        toast.success('Delivery person added successfully!');
        navigate('/admin/user-management');
      }
      setIsSaving(false);
    }
  
  };

  return (
    <div className="add-delivery-person-section">
      {!urls && (
        <div className="breadcrumb-container">
        <Link to="/admin/dashboard" className="breadcrumb-text">
          <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin/user-management" className="breadcrumb-text">
          Delivery List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Add Info</span>
      </div>
      )}
      
      <div className="add-delivery-person-container"></div>
      <div className="add-delivery-person">
        <h5 className="text-heading1">Delivery Person Information</h5>
        <div className="form-row">
          <div className="form-group">
            <label className="required">Name</label>
            <InputComponent
              placeholder="Enter Name"
              name="name"
              value={formData.username}
              onChange={(e) => handleInputChange('username', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="required">Email ID</label>
            <InputComponent
              placeholder="Enter Email"
              name="email"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
         
          <div className="form-group">
            <label className="required">Mobile Number</label>
            <InputComponent
              placeholder="Enter Phone"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={(e) => handleInputChange('mobile_number', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="required">Shop Code</label>
            <InputComponent
              placeholder="Enter Shop Code"
              name="internal_id"
              value={formData.internal_id}
              onChange={(e) => handleInputChange('internal_id', e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
        
          <div className="form-group">
            <label className="required">Proof (JPG, JPEG, PNG, PDF)</label>
            {fileUrl ? (
              <div className="image-container">
              {fileType === 'application/pdf' ? (
                  <embed src={fileUrl} type="application/pdf" className="uploaded-image" />
                ) : (
                  <img src={fileUrl} alt="Proof" style={{ width: '200px', height: '150px' }} />
                )}
                <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={handleFileRemove} />
              </div>
            ) : (
              <input type="file" className="input" accept=".jpg,.jpeg,.png,.pdf" onChange={handleFileChange} />
            )}
          </div>
          {!delivery_Id && (
  <div className="form-group">
    <label className="required">Password</label>
    <InputComponent
      placeholder="Enter Password"
      name="password"
      value={formData.password}
      onChange={(e) => handleInputChange('password', e.target.value)}
    />
  </div>
)}

        </div>
        <div className="action-buttons">
          <ButtonComponent
            text={isSaving?"Saving...":"Save"}
            variant="primary"
            type="submit"
            className="submit-button"
            onClick={handleSubmit}
            disabled={!isModified}
          />
        </div>
      </div>
    </div>
  );
};

export default AddDeliveryPerson;
