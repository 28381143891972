import React from 'react';

const CheckboxComponent = ({ checked, onChange }) => {
  return (
    <input 
      type="checkbox" 
      checked={checked}
      onChange={onChange} 
    />
  );
};

export default CheckboxComponent;
