import apiClient from '../apiClient';
import { deliveryConfigEndpoints } from '../endPoints';
// Fetch all delivery configurations
export const fetchAllConfigs = async (token) => {
  
  try {
    
    const response = await apiClient.get(deliveryConfigEndpoints.GET_ALL_CONFIGS, {
      headers: { Authorization: token },
    });
    //console.log('fhfhf-------------',response.data)
    return response.data;
  } catch (error) {
    //console.error('Error fetching all configs:', error.response?.data || error.message);
    throw error;
  }
};
// Create a new delivery configuration
export const createConfig = async (configData, token) => {
    try {
      //console.log("Data Passed to API:", configData); // Log the data being sent
      const response = await apiClient.post(
        deliveryConfigEndpoints.CREATE_CONFIG,
        configData,
        { headers: { Authorization: token } }
      );
      //console.log("API Response:", response.data); // Log the API response
      return response.data;
    } catch (error) {
      
      throw error;
    }
  };
  
// Update an existing delivery configuration
export const updateConfig = async (configId, configData, token) => {
  try {
    const response = await apiClient.put(
      deliveryConfigEndpoints.UPDATE_CONFIG, // Endpoint URL
     
      configData,
      {
        params:{ config_id: configId},
        headers: { Authorization: token }, // Authorization header
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    //console.error("Error updating config:", error.response?.data || error.message);
    throw error; // Rethrow error for handling in the calling code
  }
};
  
// Delete a delivery configuration
export const deleteConfig = async (configId, token) => {
  try {
    //console.log('deleteeeeeee',configId)
    const response = await apiClient.delete
    (deliveryConfigEndpoints.DELETE_CONFIG, {
     
      params: { config_id: configId },
      headers: { Authorization: token },
     
    });
    return response.data;
  } catch (error) {
    //console.error('Error deleting config:', error.response?.data || error.message);
    throw error;
  }
};
// Bulk create delivery configurations
export const createBulkConfigs = async (configData, token) => {
  try {
    const response = await apiClient.post(deliveryConfigEndpoints.CREATE_BULK_CONFIGS,configData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    //console.error('Error bulk creating configs:', error.response?.data || error.message);
    throw error;
  }
};
// Bulk fetch delivery configurations
export const fetchBulkConfigs = async (token) => {
  try {
    const response = await apiClient.get(deliveryConfigEndpoints.GET_BULK_CONFIGS, {
      headers: { Authorization: token },
    });
    //console.log('fhfhf-------------',response.data)
    return response.data.configurations;
  } catch (error) {
    //console.error('Error bulk fetching configs:', error.response?.data || error.message);
    throw error;
  }
};
// Bulk update delivery configurations
export const updateBulkConfigs = async (configData, token) => {
  try {
    const response = await apiClient.put(deliveryConfigEndpoints.UPDATE_BULK_CONFIGS, configData, {
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    //console.error('Error bulk updating configs:', error.response?.data || error.message);
    throw error;
  }
};
// Bulk delete delivery configurations
export const deleteBulkConfigs = async (configId, token) => {
  try {
    const response = await apiClient.delete(deliveryConfigEndpoints.DELETE_BULK_CONFIGS, {
      headers: { Authorization: token },
      data: configId,
    });
    return response.data;
  } catch (error) {
    //console.error('Error bulk deleting configs:', error.response?.data || error.message);
    throw error;
  }
};