import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCoupons, deleteCoupon, clearCoupons } from '../../redux/actions/couponAction';

import ButtonComponent from '../../components/common/buttons/ButtonComponent';

import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faSearch,faHome, faPencilAlt,  faBars, faTrash } from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './couponAllocationListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllocatedCoupons, deleteAllocatedCoupon, bulkDeleteAllocatedCoupons } from '../../redux/actions/couponAllocationAction';
import { bulkDeleteAllocatedCouponsAPI } from '../../api/services/couponAllocationService';
import Dropdown from 'react-bootstrap/Dropdown';

const CouponAllocationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const token = useSelector(state => state.adminLogin.token);
  const allocated = useSelector((state) => state.allocateCoupon.allocatedCoupons) || [];
  const couponlist = useSelector((state) => state.coupon.couponList);
  const categoryOptions = useSelector((state) => state.category.categoryList.categories);
  const productlist = useSelector((state) => state.products.basicProducts ||[]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showSearchFilters, setShowSearchFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 4;
  const totalRecords = 199;

  const navigationOptions = {
    gridPath: '/admin/coupon/add-allocate', // Modify as needed
    sheetPath: '/admin/uploadpage',   // Modify as needed
    from:'Allocatecoupon',
  };
  // Search filter state
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch allocated coupons
  useEffect(() => {
    const fetchInitialadmin = () => {
      dispatch(fetchAllocatedCoupons(token));
    };
    fetchInitialadmin();
    console.log(allocated,'---allocated')
    const intervalId = setInterval(fetchInitialadmin, 180000);
    return () => clearInterval(intervalId);
  }, [dispatch, token]);

  // Get coupon name by ID
  const getCouponNameById = (couponId) => {
    const coupon = couponlist.find((c) => c.coupon_id === couponId);
    return coupon ? coupon.code : '';
  };

  // Get entity name by type and ID
  const getEntity = (entity_type, id) => {
    if (entity_type === "category") {
      const category = categoryOptions.find((c) => c.category_id === id);
      // console.log(category,productlist,'cattttttt',id,entity_type,allocated);
      return category ? category.name : '';
    } else if (entity_type === "product") {
      const product = productlist.find((c) => c.product_id === id);
      // console.log(product,'cattttttt');
      return product ? product.product_name : '';
    }
   
  };

  // Filter allocated coupons based on search query
  const filteredAllocated = allocated.filter((item) => {
    const couponName = getCouponNameById(item.coupon_id) || ''; // Ensure it's a string
    const entityName = getEntity(item.entity_type, item.entity_id) || ''; // Ensure it's a string
    const entityType = item.entity_type ? item.entity_type.toLowerCase() : ''; // Handle undefined case
    
    // Check if search query matches any relevant field
    const searchTerm = searchQuery.toLowerCase();
    return (
      couponName.toLowerCase().includes(searchTerm) ||
      entityName.toLowerCase().includes(searchTerm) ||
      entityType.includes(searchTerm)
    );
});

  const handleCheckboxChange = (categoryId) => {
    if (selectedIds.includes(categoryId)) {
      setSelectedIds(prev => prev.filter(id => id !== categoryId));
    } else {
      setSelectedIds(prev => [...prev, categoryId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = allocated.map(item => item.coupon_allocation_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (allocated.length > 0 && selectedIds.length === allocated.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, allocated]);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleOffcanvasOpen = () => setShowOffcanvas(true);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const toggleSearchFilters = () => setShowSearchFilters(!showSearchFilters);

  const handleEditClick = (id) => {
    navigate('/admin/coupon/add-allocate', { state: id });
  };

  const handleDelete = async (couponId) => {
    if (window.confirm('Are you sure you want to delete this coupon?')) {
      dispatch(deleteAllocatedCoupon(couponId, token));
    }
  };

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one coupon to proceed.");
      return;
    }
    if (action === "edit") {
      navigate('/admin/bulk-allocate-coupon', { state: selectedIds });
      setSelectedIds([]);
    } else if (action === "delete") {
      if (window.confirm(`Are you sure you want to delete the selected allocated coupons?`)) {
        dispatch(bulkDeleteAllocatedCoupons(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };

  return (
    <div className="admin-page-layout">
       <div className="breadcrumb-container">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
         Coupon List
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <span className="breadcrumb-text">Allocate Coupon List</span>
      </div>
      <div className="allocate-container"></div>
      <div className="admin-page-container">
        <div className="right-section">
        <h5 className='text-heading1'>Add Allocate Items</h5>
          <div className="toolbar">
            <button className="justify-button" onClick={handleOffcanvasOpen}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search by coupon, category, product or entity type"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          <div className="item-controls">
            <ButtonComponent text="+ Allocate coupon" variant="dark" className="add-items-button" onClick={handleModalShow} />
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="actions-dropdown">
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
          <div className="table-container">
             { filteredAllocated.length === 0 ? (
    <tr>
       <div className="no-categories-message">
        No Alloted categories found.
    </div>
    </tr>
  ) : (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>
                    <CheckboxComponent
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>ID</th>
                  <th>Coupon Code</th>
                  <th>Entity Type</th>
                  <th>Entity Name</th>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredAllocated.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <CheckboxComponent
                        checked={selectedIds.includes(item.coupon_allocation_id)}
                        onChange={() => handleCheckboxChange(item.coupon_allocation_id)}
                      />
                    </td>
                    <td  className="table-input">{index+1}</td>
                    <td  className="table-input">{getCouponNameById(item.coupon_id)}</td>
                    <td  className="table-input">{item.entity_type}</td>
                    <td  className="table-input">{getEntity(item.entity_type, item.entity_id)}</td>
                    <td  className="table-input">{item.status}</td>
                    <td className="actions-column">
                      <button className="icon-button"  onClick={() => handleEditClick(item.coupon_allocation_id)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </td>
                    <td className="actions-column">
                      <button className="icon-button" onClick={() => handleDelete(item.coupon_allocation_id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>)}
          </div>

      
        </div>
      </div>

      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
      <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} />

    </div>
  );
};

export default CouponAllocationList;
