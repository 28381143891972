// actions/addressActions.js

import * as AddressService from '../../api/services/AddressService';

// Action Types
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const BULK_CREATE_ADDRESSES_SUCCESS = 'BULK_CREATE_ADDRESSES_SUCCESS';
export const BULK_UPDATE_ADDRESSES_SUCCESS = 'BULK_UPDATE_ADDRESSES_SUCCESS';
export const BULK_DELETE_ADDRESSES_SUCCESS = 'BULK_DELETE_ADDRESSES_SUCCESS';
export const FETCH_BULK_ADDRESSES_SUCCESS = 'FETCH_BULK_ADDRESSES_SUCCESS';
export const ADDRESS_ERROR = 'ADDRESS_ERROR';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_ERROR = 'FETCH_LOCATION_ERROR';
export const CLEAR_ADDRESS_STATE = 'CLEAR_ADDRESS_STATE';


// Single address actions
export const fetchAddress = (addressId, token) => async (dispatch) => {
  try {
    const address = await AddressService.fetchAddressFromAPI(addressId, token);
    dispatch({ type: FETCH_ADDRESS_SUCCESS, payload: address });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};
export const fetchBulkAddresses = (userId, token) => async (dispatch) => {
  try {
    const bulkAddresses = await AddressService.fetchAddressesByUserId(userId, token);
    dispatch({ type: FETCH_BULK_ADDRESSES_SUCCESS, payload: bulkAddresses });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};

export const createAddress = (addressData, token) => async (dispatch) => {
  try {
    const newAddress = await AddressService.createNewAddressAPI(addressData, token);
    dispatch({ type: CREATE_ADDRESS_SUCCESS, payload: newAddress });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};

export const updateAddress = (updatedData, token) => async (dispatch) => {
  try {
    const updatedAddress = await AddressService.updateAddressInAPI(updatedData, token);
    dispatch({ type: UPDATE_ADDRESS_SUCCESS, payload: updatedAddress });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};

export const deleteAddress = (addressId, token) => async (dispatch) => {
  try {
    await AddressService.deleteAddressAPI(addressId, token);
    dispatch({ type: DELETE_ADDRESS_SUCCESS, payload: addressId });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};

// Bulk actions
export const createBulkAddresses = (addressesData, token) => async (dispatch) => {
  try {
    const bulkAddresses = await AddressService.createAddressesBulkAPI(addressesData, token);
    dispatch({ type: BULK_CREATE_ADDRESSES_SUCCESS, payload: bulkAddresses });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};

export const updateBulkAddresses = (addressesData, token) => async (dispatch) => {
  try {
    const updatedBulkAddresses = await AddressService.updateAddressesBulkAPI(addressesData, token);
    dispatch({ type: BULK_UPDATE_ADDRESSES_SUCCESS, payload: updatedBulkAddresses });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};

export const deleteBulkAddresses = (addressIds, token) => async (dispatch) => {
  try {
    const deletedBulkAddresses = await AddressService.deleteAddressesBulkAPI(addressIds, token);
    dispatch({ type: BULK_DELETE_ADDRESSES_SUCCESS, payload: addressIds });
  } catch (error) {
    dispatch({ type: ADDRESS_ERROR, payload: error.message });
  }
};


// Action to set the selected address ID
export const setSelectedAddress = (addressId) => ({
  type: SET_SELECTED_ADDRESS,
  payload: addressId,
});
export const fetchLocationDetails = (lat, lng,token) => async (dispatch) => {
  try {
    const data = await AddressService.getLocationDetails(lat, lng,token);

    dispatch({
      type: FETCH_LOCATION_SUCCESS,
      payload: data, // Contains address, city, state, and pincode
    });
  } catch (error) {
    //console.error('Error fetching location details:', error);
    dispatch({
      type: FETCH_LOCATION_ERROR,
      payload: error.message,
    });
  }
};
export const clearAddressState = () => ({
  type: CLEAR_ADDRESS_STATE,
});
