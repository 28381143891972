// services/couponService.js
import { coupon_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch bulk coupons
export const fetchCouponsFromAPI = async (token) => {
    const response = await apiClient.get(coupon_endpoints.GET_BULK_COUPONS, {
        headers: {
            Authorization: token,
        },
    });

    //console.log("Coupons response:", response.data.coupons);
    if (!response.data) {
        throw new Error('Failed to fetch coupons');
    }
    return response.data.coupons; // Return the list of coupons
};

// Create a new coupon
export const createNewCouponAPI = async (newCoupon, token) => {
    //console.log("Adding new coupon:", newCoupon.code);

    const response = await apiClient.post(
        coupon_endpoints.GET_COUPON,
        {
            code:newCoupon.code,
            discount_type: newCoupon.discountType,
            discount_value: newCoupon.discountValue,
            min_purchase_value: newCoupon.minPurchaseValue,
            max_discount_amount: newCoupon.maxDiscountAmount,
            start_date: newCoupon.startDate,
            expiry_date: newCoupon.expiryDate,
            usage_limit: newCoupon.usageLimit,
            usage_per_user: newCoupon.usagePerUser,
            status: newCoupon.status,
        },
        {
            headers: {
                Authorization: token, // Replace with a dynamic token
            },
        }
    );

    if (response.status !== 201) {
        throw new Error('Failed to create new coupon');
    }

    //console.log('New coupon created successfully', response.data);
    return response.data; // Return the created coupon data
};

// Delete a coupon
export const deleteCouponAPI = async (couponId, token) => {
    //console.log('Deleting coupon with ID:', couponId);

    const response = await apiClient.delete(
        coupon_endpoints.GET_COUPON,
        {
            params: { coupon_id: couponId }, // Send coupon_id as a query parameter
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error(`Failed to delete coupon with ID: ${couponId}`);
    }

    //console.log('Coupon deleted successfully', response.data);
    return response.data; // Optionally return the deleted coupon data
};

// Update a coupon by ID
export const updateCouponInAPI = async (couponId, updatedData, token) => {
    //console.log("Updating coupon data:", updatedData);

    const response = await apiClient.put(
        coupon_endpoints.GET_COUPON,
        {
            coupon_id: couponId,
            code: updatedData.code,
            discount_type: updatedData.discountType,
            discount_value: updatedData.discountValue,
            min_purchase_value: updatedData.minPurchaseValue,
            max_discount_amount: updatedData.maxDiscountAmount,
            start_date: updatedData.startDate,
            expiry_date: updatedData.expiryDate,
            usage_limit: updatedData.usageLimit,
            usage_per_user: updatedData.usagePerUser,
            status: updatedData.status,
        },
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error(`Failed to update coupon with ID: ${couponId}`);
    }

    //console.log('Coupon updated successfully', response.data);
    return response.data; // Return the updated coupon data
};

// Bulk create coupons
export const createCouponsBulkAPI = async (couponsData, token) => {
    //console.log("Bulk Coupon Data Adding:", couponsData);

    const response = await apiClient.post(
        coupon_endpoints.GET_BULK_COUPONS,
        couponsData, 
        {
            headers: {
                Authorization: token,
            },
        }
    );
    //console.log(response,"response")
    if (!response.data) {
        throw new Error('Failed to create coupons in bulk');
    }

    //console.log('Bulk coupons created successfully', response.data);
    return response.data; // Return created coupons data
};

// Bulk update coupons
export const updateCouponsBulkAPI = async (couponsData, token) => {
    //console.log("Bulk Coupon Data Update:", couponsData);

    const response = await apiClient.put(
        coupon_endpoints.GET_BULK_COUPONS,
        couponsData,
        {
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to update coupons in bulk');
    }

    //console.log('Bulk coupons updated successfully', response.data);
    return response.data; // Return updated coupons data
};

// Bulk delete coupons
export const deleteCouponsBulkAPI = async (couponIds, token) => {
    //console.log("Bulk Delete Coupons:", couponIds);

    const response = await apiClient.delete(
        coupon_endpoints.GET_BULK_COUPONS,
        {
            data: couponIds, // Send array of coupon IDs in the request body
            headers: {
                Authorization: token,
            },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to delete coupons in bulk');
    }

    //console.log('Bulk coupons deleted successfully', response.data);
    return response.data; // Return deleted coupons data
};
