import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSubscriptions, modifySubscription , } from '../../redux/actions/subscriptionAction';
import SearchableDropdown from '../../components/common/SearchableDropdown/SearchableDropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './add-admin-subscription.scss';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import Datepicker from '../../components/common/datepicker/datepicker';
import DropdownButton from '../../components/common/dropdown/DropdownButton';

const AddSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const subscriptionId = location.state || null;

  const subscriptions = useSelector((state) => state.subscription?.subscriptionList || []);
  const token = useSelector((state) => state.adminLogin.token) || [];
  const users = useSelector((state) => state.usermanage.users || []);
  const products = useSelector((state) => state.products.basicProducts || []);

  const [filteredSubscription, setFilteredSubscription] = useState({});
  const [status, setStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');


  const statusOptions = ['active', 'paused', 'cancelled','cancel_request','active','expired','resume'];
  const paymentStatusOptions = ['paid', 'pending', 'failed'];


 useEffect(() => {
    const fetchConfigs = () => {
      if (token) {
        //console.log("Fetching subscription configurations with token:", token);
        dispatch(getSubscriptions(token));
      }
    };

    fetchConfigs();

    const intervalId = setInterval(() => {
      fetchConfigs();
    }, 180000); // Fetch every 3 minutes

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, token]);



  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (subscriptionId) {
      const currentSubscription = subscriptions.find((sub) => sub.subscription_id === subscriptionId);

      if (currentSubscription) {
        setFilteredSubscription({
          ...currentSubscription,
          items: currentSubscription.items || [], // Ensure `items` is always an array
        });
        setStatus(currentSubscription?.status || '');
        setPaymentStatus(currentSubscription?.payment_status || '');
      }
    }
  }, [subscriptionId, subscriptions]);

  const filteredSubscriptions = Array.isArray(subscriptions) ? subscriptions : [];

  //console.log('filtrrrrrrrrrrrrrr',filteredSubscriptions); // Logs all subscriptions as is


  /* eslint-enable react-hooks/exhaustive-deps */

  const handleSave = async () => {
    const updatedFields = {
      subscription_id: subscriptionId,
      status,
      payment_status: paymentStatus,
    };
 console.log(updatedFields,'------updateeeeeeee')
    try {
      await dispatch(modifySubscription(subscriptionId,updatedFields, token));
      toast.success('Subscription updated successfully.');
      navigate('/admin/subscription-management');
    } catch (error) {
      toast.error('Failed to update subscription.');
    }
  };

    return (
        <div className="add-subscription">
            <div className="breadcrumb-container">

                    <Link to="/admin/dashboard" className="breadcrumb-text">
                    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>
                    <Link to="/admin/subscription-management" className="breadcrumb-text">

                    <span className="breadcrumb-text">Subscription List</span>
                    </Link>
                    <span className="breadcrumb-separator">{'>'}</span>

                    <span className="breadcrumb-text">Add Subscription List</span>
                </div>
                <div className="subscription-list-container"></div>
            <div className="add-subscription-page">
                <div className="add-subscription-header-container">
                    <h6 className="add-subscription-header">Subscription Details</h6>
                    <hr />
                    <div className="add-subscription-form">
                    <div className="form-row">
          <div className="form-group">
            <label>User ID</label>
            <InputComponent type="text" value={filteredSubscription.user_id || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Product ID</label>
            <InputComponent type="text" value={filteredSubscription.product_id || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Status</label>
            <DropdownButton
                label={status}
                options={statusOptions}
                placeholder="Select Status"
                value={status}
                onSelect={(selectedOption) => setStatus(selectedOption)}
            />
          
          </div>
          <div className="form-group">
            <label>Frequency</label>
            <InputComponent type="text" value={filteredSubscription.frequency || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Start Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.start_date || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>End Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.end_date || ''}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Quantity</label>
            <InputComponent type="text" value={filteredSubscription.quantity || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Pause Reason</label>
            <InputComponent type="text" value={filteredSubscription.pause_reason || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Resume Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.resume_date || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Cancel Reason</label>
            <InputComponent type="text" value={filteredSubscription.cancel_reason || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Cancel Request Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.cancel_request_date || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Cancel Approved Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.cancel_approved_date || ''}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Pause Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.pause_date || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Daily Time</label>
            <InputComponent type="text" value={filteredSubscription.daily_time || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Weekly Day</label>
            <InputComponent type="text" value={filteredSubscription.weekly_day || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Monthly Date</label>
            <InputComponent type="text" value={filteredSubscription.monthly_date || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Yearly Date</label>
            <InputComponent type="text" value={filteredSubscription.yearly_date || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Transaction ID</label>
            <InputComponent type="text" value={filteredSubscription.transaction_id || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Reference ID</label>
            <InputComponent type="text" value={filteredSubscription.reference_id || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Next Delivery Date</label>
            <Datepicker
              className="form-control"
              value={filteredSubscription.next_delivery_date || ''}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Payment Status</label>
            <DropdownButton
            label={paymentStatus}
            options={paymentStatusOptions}
            placeholder="Select Payment Status"
            value={paymentStatus}
            onSelect={(selectedOption) => setPaymentStatus(selectedOption)}
        />

          
          </div>
          <div className="form-group">
            <label>Payment Method</label>
            <InputComponent type="text" value={filteredSubscription.payment_method || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Shipping Address ID</label>
            <InputComponent type="text" value={filteredSubscription.shipping_address_id || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Billing Address ID</label>
            <InputComponent type="text" value={filteredSubscription.billing_address_id || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Total Amount</label>
            <InputComponent type="text" value={filteredSubscription.total_amount || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Tax Amount</label>
            <InputComponent type="text" value={filteredSubscription.tax_amount || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Discount Amount</label>
            <InputComponent type="text" value={filteredSubscription.discount_amount || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Shipping Fee</label>
            <InputComponent type="text" value={filteredSubscription.shipping_fee || ''} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Coupon ID</label>
            <InputComponent type="text" value={filteredSubscription.coupon_id || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Discount ID</label>
            <InputComponent type="text" value={filteredSubscription.discount_id || ''} readOnly />
          </div>
        </div>
        </div>

      </div>

      <div className="item-details">
  <div className="header">
    <h6 className="add-subscription-header">Subscription Item Details</h6>
  </div>
  <div className="table-container">
  {filteredSubscriptions.length === 0 ? (
    <div className="no-data-message">No subscription items available.</div>
  ) : (
<table className="table table-bordered">
  <thead>
    <tr>

      <th>Status</th>
      <th>Quantity</th>
      <th>Transaction ID</th>
      <th>Payment Status</th>
      <th>Payment Method</th>
      <th>Shipping Address ID</th>
      <th>Billing Address ID</th>
      <th>Total Amount</th>
      <th>Tax Amount</th>
      <th>Discount Amount</th>
      <th>Shipping Fee</th>
      <th>Coupon ID</th>
      <th>Discount ID</th>
    </tr>
  </thead>
  <tbody>
    <tr>

      <td>{filteredSubscription.status || "N/A"}</td>
      <td>{filteredSubscription.quantity || "N/A"}</td>
      <td>{filteredSubscription.transaction_id || "N/A"}</td>

      <td>{filteredSubscription.payment_status || "N/A"}</td>
      <td>{filteredSubscription.payment_method || "N/A"}</td>
      <td>{filteredSubscription.shipping_address_id || "N/A"}</td>
      <td>{filteredSubscription.billing_address_id || "N/A"}</td>
      <td>{filteredSubscription.total_amount || "N/A"}</td>
      <td>{filteredSubscription.tax_amount || "N/A"}</td>
      <td>{filteredSubscription.discount_amount || "N/A"}</td>
      <td>{filteredSubscription.shipping_fee || "N/A"}</td>
      <td>{filteredSubscription.coupon_id || "N/A"}</td>
      <td>{filteredSubscription.discount_id || "N/A"}</td>
    </tr>
  </tbody>
</table>

  )}
</div>

  <div className="form-actions">
    <ButtonComponent text="Save Changes" onClick={handleSave} className="btn-save" />
  </div>
</div>

      </div>
    </div>


    );
};

export default AddSubscription;