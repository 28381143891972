import React, { useState, useRef, useEffect } from "react";
import "./multi-select-dropdown.scss";

const MultiSearchableDropdown = ({ options, placeholder, value, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const [selectedItems, setSelectedItems] = useState(value || []); // Selected items
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Dropdown visibility
  const dropdownRef = useRef(null); // Ref for detecting clicks outside

  useEffect(() => {
    if (JSON.stringify(selectedItems) !== JSON.stringify(value || [])) {
      setSelectedItems(value || []);
    }
  }, [value, selectedItems]);

  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedItems.some((item) => item.id === option.id) // Exclude selected items
  );

  const handleOptionSelect = (option) => {
    if (!selectedItems.some((item) => item.id === option.id)) {
      const updatedItems = [...selectedItems, option];
      setSelectedItems(updatedItems);
      onSelect(updatedItems.map((item) => item.id));
    }
  };

  const handleRemoveItem = (id) => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updatedItems);
    onSelect(updatedItems.map((item) => item.id));
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownVisible(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="multi-select-dropdown" ref={dropdownRef}>
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => setIsDropdownVisible(true)}
      />

      <div className="selected-items">
        {selectedItems.map((item) => (
          <div key={item.id} className="selected-item">
            {item.label}
            <button className="remove-button" onClick={() => handleRemoveItem(item.id)}>
              x
            </button>
          </div>
        ))}
      </div>

      {isDropdownVisible && (
        <ul className="dropdown-list">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <li
                key={option.id}
                onClick={() => handleOptionSelect(option)}
                className={selectedItems.some((item) => item.id === option.id) ? "selected" : ""}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li className="no-options">No matching results</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default MultiSearchableDropdown;
