import {
    FETCH_LOCATIONS_SUCCESS,
    FETCH_LOCATION_SUCCESS,
    CREATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_SUCCESS,
    DELETE_LOCATION_SUCCESS,
    BULK_CREATE_LOCATIONS_SUCCESS,
    BULK_UPDATE_LOCATIONS_SUCCESS,
    BULK_DELETE_LOCATIONS_SUCCESS,
} from '../actions/locationAction';

const initialState = {
    locations: [],
    location: null,
};

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.payload,
            };

        case FETCH_LOCATION_SUCCESS:
            return {
                ...state,
                location: action.payload,
            };

        case CREATE_LOCATION_SUCCESS:
            return {
                ...state,
                locations: [...state.locations, action.payload],
            };

        case UPDATE_LOCATION_SUCCESS:
            //console.log(action.payload,'--location')
            return {
                ...state,
                locations: state.locations.map((location) =>
                    location.location_id === action.payload.location_id ? action.payload : location
                ),
            };

        case DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                locations: state.locations.filter(
                    (location) => location.location_id !== action.payload
                ),
            };

        case BULK_CREATE_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: [...state.locations, ...action.payload],
            };

        case BULK_UPDATE_LOCATIONS_SUCCESS:
            const updatedLocations = action.payload || [];
            return {
                ...state,
                locations: state.locations.map((location) => {
                    const updated = updatedLocations.find(
                        (updatedLocation) => updatedLocation.location_id === location.location_id
                    );
                    return updated ? updated : location;
                }),
            };

        case BULK_DELETE_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: state.locations.filter(
                    (location) => !action.payload.includes(location.location_id)
                ),
            };

        default:
            return state;
    }
};

export default locationReducer;
