import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'; 
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import "./bulk-supplier.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkUpdateSuppliers, bulkCreateSuppliers } from "../../redux/actions/supplierActions";


const SupplierTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const suppliers = useSelector((state) => state.supplier.suppliers || []);

  const token = useSelector((state) => state.adminLogin.token);
  const initialSuppliers = location.state || [];
  const [supplierData, setSupplierData] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const statusOptions = ["active", "inactive"];
  const typeOptions = ["Both", "Purchase", "Sale"];
  const cityOptions = ["active", "inactive"];
  const stateOptions = ["Both", "Purchase", "Sale"];

  useEffect(() => {
    const processSuppliers = () => {
      if (!initialSuppliers || initialSuppliers.length === 0) {
        setSupplierData([{
          name: '',
          company: '',
          email: '',
          phone: '',
          address: '',
          state: '',
          city: '',
          gst_no: '',
          fssai_lic_no: '',
          type: '',
          tcs_percent: '',
          status: '',
        }]);
      } else {
        if (typeof initialSuppliers[0] === 'object') {
          setSupplierData(initialSuppliers);
        } else if (typeof initialSuppliers[0] === 'string' || typeof initialSuppliers[0] === 'number') {
          const fetchedSuppliers = initialSuppliers.map(id => {
            const supplier = suppliers.find(supplier => supplier.supplier_id === id);
            return supplier ? { ...supplier, supplier_id: id } : { supplier_id: id, name: '', company: '', email: '', phone: '', address: '', state: '', city: '', gst_no: '', fssai_lic_no: '', type: '', tcs_percent: '', status: '' };
          });
          setSupplierData(fetchedSuppliers);
        }
      }
    };

    processSuppliers();
  }, [initialSuppliers, suppliers]);

  const handleInputChange = (index, field, value) => {
    const updatedSuppliers = [...supplierData];
    updatedSuppliers[index][field] = value;
    setSupplierData(updatedSuppliers);

    if (field === 'name') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false;
      setDuplicateErrors(updatedErrors);
    }
  };

  const handleAddRow = () => {
    setSupplierData(prevSuppliers => [
      ...prevSuppliers,
      { name: '', company: '', email: '', phone: '', address: '', state: '', city: '', gst_no: '', fssai_lic_no: '', type: '', tcs_percent: '', status: '' }
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]);
  };

  const handleDelete = (index) => {
    const newSuppliers = [...supplierData];
    newSuppliers.splice(index, 1);
    setSupplierData(newSuppliers);

    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1);
    setDuplicateErrors(newErrors);
  };

  const checkForDuplicateNames = () => {
    const existingNames = suppliers.map(supplier => supplier.name.trim().toLowerCase());
    const updatedErrors = supplierData.map(supplier => {
      return !supplier.supplier_id && existingNames.includes(supplier.name.trim().toLowerCase()) && supplier.name.trim() !== '';
    });
    setDuplicateErrors(updatedErrors);
    return updatedErrors.some(error => error);
  };

  const handleSaveClick = async () => {

    if (typeof initialSuppliers[0] === "object"){
        if (checkForDuplicateNames()) {
            return;
          }
    }
    

    setIsSaving(true);
    let responseStatus = false;

    if (typeof initialSuppliers[0] === 'object') {
      responseStatus = await dispatch(bulkCreateSuppliers(supplierData, token));
    } else {
      responseStatus = await dispatch(bulkUpdateSuppliers(supplierData, token));
    }

    setIsSaving(false);

    // if (responseStatus) {
      navigate('/admin/supplier');
    // } else {
    //   //console.error("Failed to save suppliers");
    // }
  };

  return (
    <div className="supplier-main-page">
      <div className="breadcrumb-container">
    <Link to="/admin/dashboard" className="breadcrumb-text">
    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
      </Link>
      
      <span className="breadcrumb-separator">{'>'}</span>
      <Link to="/admin" className="breadcrumb-text">
        Product List
      </Link>
      <span className="breadcrumb-separator">{'>'}</span>
      <Link to="/admin/supplier" className="breadcrumb-text">
      Supplier List
      </Link>
      <span className="breadcrumb-separator">{'>'}</span>
      <span className="breadcrumb-text">
      Supplier List
      </span>
      </div>
      <div className="bulk-supplier-container"></div>
 <div className="bulk-supplier">
    <div className="supplier-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Supplier</h5>
    
     
   
      <div className="add-new-button">
        {typeof initialSuppliers[0] === 'object' && (
          <ButtonComponent 
            text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
            onClick={handleAddRow} 
            className="add-button" 
          />
        )}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving}
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              
              <th>GST No.</th>
              <th>FSSAI Lic No.</th>
              <th>Type</th>
              <th>TCS %</th>
     
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {supplierData.map((supplier, index) => (
              <tr key={index}>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                  />
                  {duplicateErrors[index] && (
                    <div className="error-message">Already exists</div>
                  )}
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.company}
                    onChange={(e) => handleInputChange(index, 'company', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.email}
                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.phone}
                    onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.address}
                    onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                  />
                </td>
                {/* <td>
                  <DropdownButton
                    options={stateOptions}
                    label={supplier.state || 'Select State'}
                    onSelect={(value) => handleInputChange(index, 'state', value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    options={cityOptions}
                    label={supplier.city || 'Select City'}
                    onSelect={(value) => handleInputChange(index, 'city', value)}
                  />
                </td> */}
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.gst_no}
                    onChange={(e) => handleInputChange(index, 'gst_no', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.fssai_lic_no}
                    onChange={(e) => handleInputChange(index, 'fssai_lic_no', e.target.value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    options={typeOptions}
                    label={supplier.type || 'Select Type'}
                    onSelect={(value) => handleInputChange(index, 'type', value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={supplier.tcs_percent}
                    onChange={(e) => handleInputChange(index, 'tcs_percent', e.target.value)}
                  />
                </td>
                
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
   
 
  );
};

export default SupplierTable;