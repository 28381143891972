import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import './LineChart.scss';

const PaymentBarChart = ({ data }) => {
  //console.log(data, 'line chart');
  return (
    <div className="payment-bar-chart">
      <div className="chart-container">
        <ResponsiveBar
          data={data}
          keys={['value']}
          indexBy="paymentType"
          layout="horizontal"
          margin={{ top: 20, right: 80, bottom: 50, left: 120 }}
          padding={0.5}
          colors={(bar) => (bar.value > 0 ? '#73d2f1' : '#e0e0e0')}
          borderRadius={5}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            format: (value) => value,
          }}
          enableGridY={false}
          enableLabel={false}
          tooltip={({ indexValue, value }) => (
            <div
              style={{
                padding: '5px 10px',
                background: '#fff',
                border: '1px solid #ccc',
              }}
            >
              <strong>{indexValue}</strong>: ₹ {value}
            </div>
          )}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fill: '#333',
                },
              },
            },
          }}
        />

        <div className="custom-labels">
          {data.map((d) => (
            <div key={d.paymentType} className="custom-label">
              <span className="payment-type">{d.paymentType}</span>
              <div className="bar-container">
                {d.value > 0 && (
                  <div
                    className="filled-bar"
                    style={{
                      width: `${(d.value / Math.max(...data.map((item) => item.value))) * 100}%`,
                    }}
                  ></div>
                )}
              </div>
              <span className="payment-value">₹ {d.value.toLocaleString()}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentBarChart;
