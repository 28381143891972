// reducers/productReducer.js
import {
  FETCH_SINGLE_PRODUCT_SUCCESS,
  CREATE_SINGLE_PRODUCT_SUCCESS,
  UPDATE_SINGLE_PRODUCT_SUCCESS,
  DELETE_SINGLE_PRODUCT_SUCCESS,
  FETCH_BULK_PRODUCTS_SUCCESS,
  CREATE_BULK_PRODUCTS_SUCCESS,
  UPDATE_BULK_PRODUCTS_SUCCESS,
  DELETE_BULK_PRODUCTS_SUCCESS,
  ERROR,
} from '../actions/productActions';

const initialState = {
  basicProducts: [],
  customProducts: [],
  error: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch All (Bulk) Products Success
    case FETCH_BULK_PRODUCTS_SUCCESS:
      return {
        ...state,
        basicProducts: action.payload.basicProducts,
        customProducts: action.payload.customProducts,
        error: null,
      };

    // Create Single Product Success
    case CREATE_SINGLE_PRODUCT_SUCCESS:
  //console.log('Current customProducts:', state.customProducts);
  return {
    ...state,
    basicProducts: state.basicProducts.some(product => product.product_id === action.payload.basicProduct.product_id)
      ? state.basicProducts
      : [...state.basicProducts, action.payload.basicProduct],
    customProducts: state.customProducts.some(product => product.product_id === action.payload.customProduct.product_id)
      ? state.customProducts
      : [...state.customProducts, action.payload.customProduct],
    error: null,
  };

    

    // Create Bulk Products Success
    case CREATE_BULK_PRODUCTS_SUCCESS:
      return {
        ...state,
        basicProducts: [...state.basicProducts, ...action.payload.basicProducts],
        error: null,
      };

    // Update Single Product Success
    case UPDATE_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        basicProducts: state.basicProducts.map((product) =>
          product.product_id === action.payload.basicProduct.product_id
            ? action.payload.basicProduct
            : product
        ),
        customProducts: state.customProducts.map((product) =>
          product.product_id === action.payload.customProduct.product_id
            ? action.payload.customProduct
            : product
        ),
        error: null,
      };

    // Update Bulk Products Success
    case UPDATE_BULK_PRODUCTS_SUCCESS:
      return {
        ...state,
        error: null, // Assuming bulk updates don't return individual product updates
      };

    // Delete Single Product Success
    case DELETE_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        basicProducts: state.basicProducts.filter(
          (product) => product.product_id !== action.payload
        ),
        customProducts: state.customProducts.filter(
          (product) => product.product_id !== action.payload
        ),
        error: null,
      };

    // Delete Bulk Products Success
    case DELETE_BULK_PRODUCTS_SUCCESS:
      return {
        ...state,
        basicProducts: state.basicProducts.filter(
          (product) => !action.payload.includes(product.product_id)
        ),
        customProducts: state.customProducts.filter(
          (product) => !action.payload.includes(product.product_id)
        ),
        error: null,
      };

    // Error Case
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default productReducer;
