import React from "react";
import { Offcanvas } from "react-bootstrap";
import "./SubscribedProductOffcanvas.scss";

const SubscribedProductOffcanvas = ({ show, handleClose, selectedStatus, handleStatusChange }) => {
  const statuses = ["All", "active", "expired", "paused", "cancelled","cancel_request"];

  const handleRadioChange = (status) => {
    handleStatusChange(status); // Update the status
    handleClose(); // Close the offcanvas
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" className="subscribed-product-offcanvas">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Status</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="filter-options">
          {statuses.map((status) => (
            <div key={status} className="filter-option">
              <label>
                <input
                  type="radio"
                  name="status"
                  value={status}
                  checked={selectedStatus === status}
                  onChange={() => handleRadioChange(status)}
                />
                <span>{status}</span>
              </label>
            </div>
          ))}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SubscribedProductOffcanvas;
