// services/addressService.js
import { address_endpoints } from '../endPoints';
import apiClient from '../apiClient';

// Fetch single address by address_id
export const fetchAddressFromAPI = async (addressId, token) => {
  const response = await apiClient.get(`${address_endpoints.GET_ADDRESS}`, {
    params: { address_id: addressId },
    headers: { Authorization: token },
  });
  if (!response.data) throw new Error('Failed to fetch address');
  return response.data;
};

// Fetch multiple addresses by user_id
export const fetchAddressesByUserId = async (userId, token) => {
  //console.log("Fetching addresses for user_id:", userId);

  const response = await apiClient.get(`${address_endpoints.GET_BULK_ADDRESS}`, {
    params: { user_ids: userId }, // Pass user_id as a query parameter
    headers: { Authorization: token },
  });

  if (response.status !== 200 || !response.data) throw new Error('Failed to fetch addresses');
  //console.log('Fetched addresses successfully', response.data);
  return response.data.addresses; // Return the list of addresses
};



// Create a new address
export const createNewAddressAPI = async (addressData, token) => {
  //console.log("Address data adding:", addressData);

  const response = await apiClient.post(
    address_endpoints.GET_ADDRESS,
    {
      user_id: addressData.user_id,
      address: addressData.address,
      address_type: addressData.address_type,
      city: addressData.city,
      state: addressData.state,
      pincode: addressData.pincode
    },
    {
      headers: { Authorization: token },
    }
  );
  if (response.status !== 201) throw new Error('Failed to create address');
  //console.log('Address created successfully', response.data);
  return response.data;
};

// Update an existing address
export const updateAddressInAPI = async (updatedData, token) => {
  //console.log("Updating address data:", updatedData);

  const response = await apiClient.put(
    address_endpoints.GET_ADDRESS,
    {
      address_id: updatedData.address_id,
      user_id:updatedData.user_id,
      address: updatedData.address,
      address_type: updatedData.address_type,
      city: updatedData.city,
      state: updatedData.state,
      pincode: updatedData.pincode
    },
    {
      headers: { Authorization: token },
    }
  );
  if (response.status !== 200) throw new Error('Failed to update address');
  //console.log('Address updated successfully', response.data);
  return response.data;
};

// Delete a single address by address_id
export const deleteAddressAPI = async (addressId, token) => {
  //console.log("Deleting address with ID:", addressId);

  const response = await apiClient.delete(`${address_endpoints.GET_ADDRESS}`, {
    params: { address_id: addressId },
    headers: { Authorization: token },
  });
  if (response.status !== 200) throw new Error('Failed to delete address');
  //console.log('Address deleted successfully', response.data);
  return response.data;
};

// Create addresses in bulk
export const createAddressesBulkAPI = async (addressesData, token) => {
  //console.log("Bulk Address Data Adding:", addressesData);

  const response = await apiClient.post(
    address_endpoints.GET_BULK_ADDRESS,
    addressesData, // Directly send array of address data
    {
      headers: { Authorization: token },
    }
  );
  if (response.status !== 201) throw new Error('Failed to create addresses in bulk');
  //console.log('Bulk addresses created successfully', response.data);
  return response.data;
};

// Update addresses in bulk
export const updateAddressesBulkAPI = async (addressesData, token) => {
  //console.log("Bulk Address Data Update:", addressesData);

  const response = await apiClient.put(
    address_endpoints.GET_BULK_ADDRESS,
    addressesData,
    {
      headers: { Authorization: token },
    }
  );
  if (response.status !== 200) throw new Error('Failed to update addresses in bulk');
  //console.log('Bulk addresses updated successfully', response.data);
  return response.data;
};

// Delete addresses in bulk
export const deleteAddressesBulkAPI = async (addressIds, token) => {
  //console.log("Bulk Delete Addresses:", addressIds);

  const response = await apiClient.delete(
    address_endpoints.GET_BULK_ADDRESS,
    {
      data: addressIds, // Send array of address IDs in the request body
      headers: { Authorization: token },
    }
  );
  if (response.status !== 200) throw new Error('Failed to delete addresses in bulk');
  //console.log('Bulk addresses deleted successfully', response.data);
  return response.data;
};
export const getLocationDetails = async (lat, lng,token) => {
  try {
    const response =  await apiClient.get('/auth/get-location', {
      params: { lat, lng },
      headers: { Authorization: token },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error fetching location details');
  }
};