// src/redux/actions/adminActions.js

import { 
    fetchAdminFromAPI, 
    updateAdminInAPI, 
    deleteAdminAPI, 
    createNewAdminAPI 
  } from '../../api/services/adminService'; // Assuming you have this API function
  import { toast } from 'react-hot-toast';
  
  export const FETCH_ADMINS_REQUEST = 'FETCH_ADMINS_REQUEST';
  export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
  export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE';
  export const CLEAR_ADMINS = 'CLEAR_ADMINS';
  
  export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
  export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
  export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
  
  export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
  export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
  export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';
  
  export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
  export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
  export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
  
  // Fetch admins with a caching mechanism
  export const fetchAdmin = () => async (dispatch, getState) => {
    const { adminList = [] } = getState().admin || {}; // Guard for undefined admins
    const timestamp = getState().admin.timestamp;
    const FIVE_MINUTES = 5 * 60 * 1000;
  
    // If admins exist and are within the 5-minute limit, skip fetch
    if (adminList.length > 0 && timestamp) {
      const now = Date.now();
      if (now - timestamp < FIVE_MINUTES) {
        return; // Data is still valid, no need to fetch
      } else {
        // Clear admins if data is older than 5 minutes
        dispatch(clearAdmins());
      }
    }
  
    dispatch({ type: FETCH_ADMINS_REQUEST });
    try {
      const fetchedAdmins = await fetchAdminFromAPI(); // Replace with your API call
      dispatch({
        type: FETCH_ADMINS_SUCCESS,
        payload: { admins: fetchedAdmins, timestamp: Date.now() }, // Update with new admins and timestamp
      });
  
    } catch (error) {
      dispatch({ type: FETCH_ADMINS_FAILURE, payload: error.message });

    }
  };
  
  // Action to clear admins manually
  export const clearAdmins = () => ({
    type: CLEAR_ADMINS,
  });
  
  // Action to delete an admin
  export const deleteAdmin = (adminId) => async (dispatch) => {
    dispatch({ type: DELETE_ADMIN_REQUEST });
  
    try {
      await deleteAdminAPI(adminId); // Replace with your API call
      dispatch({
        type: DELETE_ADMIN_SUCCESS,
        payload: adminId, // Pass the deleted admin ID to the reducer
      });
      toast.success('Admin deleted successfully!');
    } catch (error) {
      dispatch({
        type: DELETE_ADMIN_FAILURE,
        payload: error.message,
      });
      toast.error(`Failed to delete admin: ${error.message}`);
    }
  };
  
  // Action to update an admin
  export const updateAdmin = (adminId, updatedData) => async (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_REQUEST });
  
    try {
      const updatedAdmin = await updateAdminInAPI(adminId, updatedData); // Replace with your API call
      dispatch({
        type: UPDATE_ADMIN_SUCCESS,
        payload: updatedAdmin, // Return the updated admin to update the state
      });
      toast.success('Admin updated successfully!');
    } catch (error) {
      dispatch({ type: UPDATE_ADMIN_FAILURE, payload: error.message });
      toast.error('Failed to update admin.');
    }
  };
  
  // Action to create an admin
  export const createAdmin = (newAdminData) => async (dispatch) => {
    dispatch({ type: CREATE_ADMIN_REQUEST });
  
    try {
      const createdAdmin = await createNewAdminAPI(newAdminData); // Replace with your API call
      dispatch({
        type: CREATE_ADMIN_SUCCESS,
        payload: createdAdmin, // Return the newly created admin to update the state
      });
      toast.success('Admin created successfully!');
    } catch (error) {
      dispatch({ type: CREATE_ADMIN_FAILURE, payload: error.message });
      toast.error('Failed to create admin.');
    }
  };
  