import { 
  fetchBrandFromAPI, 
  updateBrandInAPI, 
  deleteBrandAPI, 
  createNewBrandAPI,
createBulkBrandsAPI,
deleteBulkBrandsAPI,
updateBulkBrandsAPI
} from '../../api/services/brandService';
import toast from 'react-hot-toast';
export const FETCH_BRANDS_REQUEST = 'FETCH_BRANDS_REQUEST';
export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_FAILURE = 'FETCH_BRANDS_FAILURE';
export const CLEAR_BRANDS = 'CLEAR_BRANDS';

export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAILURE = 'DELETE_BRAND_FAILURE';

export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'UPDATE_BRAND_FAILURE';

export const CREATE_BRAND_REQUEST = 'CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_FAILURE = 'CREATE_BRAND_FAILURE';

export const BULK_DELETE_BRANDS_REQUEST = 'BULK_DELETE_BRANDS_REQUEST';
export const BULK_DELETE_BRANDS_SUCCESS = 'BULK_DELETE_BRANDS_SUCCESS';
export const BULK_DELETE_BRANDS_FAILURE = 'BULK_DELETE_BRANDS_FAILURE';

export const BULK_UPDATE_BRANDS_REQUEST = 'BULK_UPDATE_BRANDS_REQUEST';
export const BULK_UPDATE_BRANDS_SUCCESS = 'BULK_UPDATE_BRANDS_SUCCESS';
export const BULK_UPDATE_BRANDS_FAILURE = 'BULK_UPDATE_BRANDS_FAILURE';

export const BULK_CREATE_BRANDS_REQUEST = 'BULK_CREATE_BRANDS_REQUEST';
export const BULK_CREATE_BRANDS_SUCCESS = 'BULK_CREATE_BRANDS_SUCCESS';
export const BULK_CREATE_BRANDS_FAILURE = 'BULK_CREATE_BRANDS_FAILURE';
// Fetch brands with a caching mechanism similar to categories
export const fetchBrand = (token) => async (dispatch, getState) => {
  const { brands = [] } = getState().brand.brandList || {}; // Guard for undefined brands
  const timestamp = getState().brand.timestamp;
  const FIVE_MINUTES = 5 * 60 * 1000;

  // If brands exist and are within the 5-minute limit, skip fetch
  if (brands.length > 0 && timestamp) {
    const now = Date.now();
    if (now - timestamp < FIVE_MINUTES) {
      return; // Data is still valid, no need to fetch
    } else {
      // Clear brands if data is older than 5 minutes
      dispatch({ type: CLEAR_BRANDS });
    }
  }


  dispatch({ type: FETCH_BRANDS_REQUEST });
  try {
    const fetchedBrands = await fetchBrandFromAPI(token); // Replace with your API call
    dispatch({
      type: FETCH_BRANDS_SUCCESS,
      payload: { brands: fetchedBrands, timestamp: Date.now() }, // Update with new brands and timestamp
    });

  } catch (error) {
    dispatch({ type: FETCH_BRANDS_FAILURE, payload: error.message });
    
  }
};

// Action to clear brands manually
export const clearBrands = () => ({
  type: CLEAR_BRANDS,
});

// Action to delete a brand
export const deleteBrand = (brandId,token) => async (dispatch) => {
  dispatch({ type: DELETE_BRAND_REQUEST });

  try {
    await deleteBrandAPI(brandId,token); // Replace with your API call
    dispatch({
      type: DELETE_BRAND_SUCCESS,
      payload: brandId, // Pass the deleted brand ID to the reducer
    });
    toast.success('Brand deleted successfully!');
  } catch (error) {
    dispatch({
      type: DELETE_BRAND_FAILURE,
      payload: error.message,
    });
    toast.error(`Failed to delete brand: ${error.message}`);
  }
};

// Action to update a brand
export const updateBrand = (brandId, updatedData,token) => async (dispatch) => {
  dispatch({ type: UPDATE_BRAND_REQUEST });

  try {
    const updatedBrand = await updateBrandInAPI(brandId, updatedData,token); // Replace with your API call
    dispatch({
      type: UPDATE_BRAND_SUCCESS,
      payload: updatedData, // Return the updated brand to update the state
    });
    toast.success('Brand updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: UPDATE_BRAND_FAILURE, payload: error.message });
    toast.error('Failed to update brand.');
    return { success: false };
  }
};

// Action to create a brand
export const createBrand = (newBrandData,token) => async (dispatch) => {
  dispatch({ type: CREATE_BRAND_REQUEST });

  try {
    const createdBrand = await createNewBrandAPI(newBrandData,token); // Replace with your API call
    dispatch({
      type: CREATE_BRAND_SUCCESS,
      payload: newBrandData, // Return the newly created brand to update the state
    });
    toast.success('Brand created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({ type: CREATE_BRAND_FAILURE, payload: error.message });
    toast.error('Failed to create brand.');
    return { success: false };
  }
};

export const bulkDeleteBrands = (brandIds, token) => async (dispatch) => {
  dispatch({ type: BULK_DELETE_BRANDS_REQUEST });

  try {
    await deleteBulkBrandsAPI(brandIds, token); // Replace with your API call
    dispatch({
      type: BULK_DELETE_BRANDS_SUCCESS,
      payload: brandIds, // Pass the deleted brand IDs as payload
    });
    toast.success('Brands deleted successfully!');
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_DELETE_BRANDS_FAILURE,
      payload: error.message,
    });
    toast.error(`Failed to delete brands: ${error.message}`);
    return { success: false };
  }
};

// Action to bulk update brands
export const bulkUpdateBrands = (brandsData, token) => async (dispatch) => {
  dispatch({ type: BULK_UPDATE_BRANDS_REQUEST });

  try {
    const updatedBrands = await updateBulkBrandsAPI(brandsData, token); // Call API to bulk update brands
    dispatch({
      type: BULK_UPDATE_BRANDS_SUCCESS,
      payload: brandsData, // Pass the updated brands data as payload
    });
    toast.success('Brands updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_UPDATE_BRANDS_FAILURE,
      payload: error.message,
    });
    toast.error(`Failed to update brands: ${error.message}`);
    return { success: false };
  }
};

// Action to bulk create brands
export const bulkCreateBrands = (newBrandsData, token) => async (dispatch) => {
  dispatch({ type: BULK_CREATE_BRANDS_REQUEST });

  try {
    const createdBrands = await createBulkBrandsAPI(newBrandsData, token); // Call the API to bulk create brands
    dispatch({
      type: BULK_CREATE_BRANDS_SUCCESS,
      payload: newBrandsData, // Pass the created brands data as payload
    });
    toast.success('Brands created successfully!');
    return { success: true };
  } catch (error) {
    dispatch({
      type: BULK_CREATE_BRANDS_FAILURE,
      payload: error.message,
    });
    toast.error(`Failed to create brands: ${error.message}`);
    return { success: false };
  }
};