import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./subscription-details.scss";
import BillDetailsCard from "../../components/billSubscribed/billSubscriped";
import SubscriptionPauseOffcanvas from "../../components/common/SubscriptionPauseOffcanvas/SubscriptionPauseOffcanvas";
import { bulkGetSubscriptions } from "../../redux/actions/subscriptionAction";
import { modifySubscription } from "../../redux/actions/subscriptionAction";
import { useNavigate } from "react-router-dom";
const SubscriptionDetails = () => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [actionType, setActionType] = useState("Pause");
  const [activeItem, setActiveItem] = useState(null);
  const dispatch=useDispatch();
 const token = useSelector((state)=>state.auth.token)
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      dispatch(bulkGetSubscriptions(token));
    }
  }, [dispatch, token]); // Add dispatch and token to the dependency array
  

  const subscriptions = useSelector((state) => state.subscription.subscriptionList || []);
  const basicProducts = useSelector((state) => state.products.basicProducts || []);
  const addresses = useSelector((state) => state.address.addresses || []);
  const mostRecentSubscription = subscriptions.reduce((latest, current) => {
    return !latest || new Date(current.created_at) > new Date(latest.created_at)
      ? current
      : latest;
  }, null);

  const subscriptionId = location.state
  ? location.state // Use subscriptionId from location.state if available
  : mostRecentSubscription?.subscription_id; // Fallback to most recent subscription ID


 const pause=()=>{

 //console.log("pasused successfully");
 navigate('/subscribed-product')

 }

  // Find the subscription by ID
  const subscription = subscriptions.find((sub) => sub.subscription_id === subscriptionId);

  // If subscription is not found, display fallback
  if (!subscription) {
    return (
      <div className="subscription-details-page1">
        <h3>Subscription not found</h3>
      </div>
    );
  }

  // Find associated product
  const product = basicProducts.find((prod) => prod.product_id === subscription.product_id);
  const productName = product?.product_name || "Unknown Product";


 
  // Find shipping address
  const shippingAddress = addresses.find(
    (address) => address.address_id === subscription.shipping_address_id
  );

  // Handle Offcanvas actions
  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false);
    setActiveItem(null);
    document.body.style.overflow = "auto"; // Restore background scroll
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div className="subscription-details-page1">
      <div className="subscription-container">
        <div className="left-section1">
          <div className="subscription-cancelled-details1">
            <h4 className="subscription-main-header">Subscription Details</h4>
            <hr />
            <div className="subscription-detail-info1">
              <div className="subscription-order-details1">
                <strong>Subscription ID:</strong> {subscription.subscription_id || "N/A"}
              </div>
              <div className="subscription-order-details2">
                <strong>Date:</strong> {formatDate(subscription.start_date)}
              </div>
            </div>
            <hr className="subscription-line1" />
          </div>


          <div className="subscription-details-box2">
            <div className="subscription-bordered-box2">
              <p className="subscription-address-details2">
                <strong>Address</strong>
              </p>
              {shippingAddress ? (
                <p>
                  <span className="subscription-address-label2">
                    {shippingAddress.address_type || "N/A"}
                  </span>{" "}
                  {shippingAddress.address}, {shippingAddress.city}, {shippingAddress.state} -{" "}
                  {shippingAddress.pincode}
                </p>
              ) : (
                <p>Address Not Available</p>
              )}
            </div>
          </div>

          {/* Product Details */}
          <div className="new-card1">
  <div className="card-header1">
    <h4 className="product-name1">{productName}</h4>
  </div>
  <div className="card-body1">
    <p>
      <span className="label">Next Delivery:</span>{" "}
      <span className="value">{formatDate(subscription.next_delivery_date)}</span> {" | "}
      <span className="label">Quantity:</span>{" "}
      <span className="value">{subscription.quantity}</span> {" | "}
      <span className="label">Frequency:</span>{" "}
      <span className="value">{subscription.frequency}</span>
    </p>

    {/* Daily Frequency */}
    {subscription.frequency === "daily" && subscription.daily_time?.length > 0 && (
      <div className="time-dates-row1">
        <div className="time-slots1">
          <span className="label">Time Slots:</span>{" "}
          <span className="value">{subscription.daily_time.join(", ")}</span>
        </div>
      </div>
    )}

    {/* Weekly Frequency */}
    {subscription.frequency === "weekly" && subscription.weekly_day?.length > 0 && (
      <div className="selected-days-row1">
        <div className="days-section1">
          <span className="label1">Days:</span>
          <div className="days-container1">
            {subscription.weekly_day.map((day, index) => (
              <div key={index} className="day1">
                {day[0].toUpperCase()}
              </div>
            ))}
          </div>
        </div>
        {subscription.daily_time?.length > 0 && (
          <div className="time-slots1">
            <span className="label">Time Slots:</span>{" "}
            <span className="value">{subscription.daily_time.join(", ")}</span>
          </div>
        )}
      </div>
    )}

    {/* Monthly Frequency */}
    {subscription.frequency === "monthly" && subscription.selected_dates?.length > 0 && (
      <div className="selected-dates1">
        <span className="label">Selected Dates:</span>
        <div className="dates-container1">
          {subscription.selected_dates.map((date, index) => (
            <div key={index} className="date1">
              {date}
            </div>
          ))}
        </div>
      </div>
    )}

    <p>
      <span className="label1">Start Date:</span>{" "}
      <span className="value1">{formatDate(subscription.start_date)}</span> {" | "}
      <span className="label1">End Date:</span>{" "}
      <span className="value1">{formatDate(subscription.end_date)}</span>
    </p>

    {/* Action Buttons */}
    <div className="subscription-action-buttons">
      {subscription.status === "active" && (
        <>
          <button
            className="btn-pause"
            onClick={() => {
              setActionType("Pause");
              setIsOffcanvasOpen(true);
              setActiveItem(subscription);
            }}
          >
            Pause
          </button>
          <button
            className="btn-cancel"
            onClick={() => {
              setActionType("Cancel");
              setIsOffcanvasOpen(true);
              setActiveItem(subscription);
            }}
          >
            Cancel
          </button>
        </>
      )}
      {subscription.status === "paused" && (
        <button
          className="btn-resume"
          onClick={() => {
            setActionType("Resume");
            setIsOffcanvasOpen(true);
            setActiveItem(subscription);
          }}
        >
          Resume
        </button>
      )}
      {subscription.status === "expired" && (
        <button
          className="btn-resume"
          onClick={() => {
            setActionType("Resume");
            setIsOffcanvasOpen(true);
            setActiveItem(subscription);
          }}
        >
          Resume
        </button>
      )}
    </div>
  </div>
</div>

        </div>

        {/* Right Section */}
        <div className="right-section1">
          <BillDetailsCard subscriptionId={subscriptionId} />
        </div>
      </div>

      {/* Offcanvas for Pause or Close Action */}
      {isOffcanvasOpen && (
        <SubscriptionPauseOffcanvas
          isOpen={isOffcanvasOpen}
          onClose={closeOffcanvas}
          actionType={actionType}
          onConfirm={pause}
          subscriptionDetails={subscription}
        />
      )}
    </div>
  );
};

export default SubscriptionDetails;
