import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import './DeliveryLocationConfiguration.scss'; // Custom styles

const statusOptions = ['Active', 'Inactive'];
const deliveryPersonOptions = ['Person 1', 'Person 2'];

const DeliveryLocationConfiguration = () => {
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  // Handle adding a new location
  const handleAddLocation = () => {
    navigate('/delivery/add-location');
  };

  // If there are selected locations from the AddLocation page, add them to the rows
  useEffect(() => {
    if (location.state && location.state.selectedLocations) {
      const newRows = location.state.selectedLocations.map((district) => ({
        location: district,
        deliveryPerson: '',
        deliveryCharge: '',
        status: ''
      }));
      setRows([...rows, ...newRows]);
    }
  }, [location.state]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  return (
    <div className="delivery-location-configuration-container">
      <div className="delivery-location-configuration">
        {/* Add Location Button */}
        <div className="add-location-button-container">
          <ButtonComponent text="Add Location" className="add-location-button" onClick={handleAddLocation} />
        </div>

        {/* Table */}
        <div className="table-container">
          <table className="location-table">
            <thead>
              <tr>
                <th><CheckboxComponent /></th>
                <th>Location</th>
                <th>Delivery Person</th>
                <th>Delivery Charge</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td><CheckboxComponent /></td>
                  <td>
                    <InputComponent
                      placeholder="Location"
                      value={row.location}
                      readOnly
                    />
                  </td>
                  <td>
                    <DropdownButton
                      options={deliveryPersonOptions}
                      label={row.deliveryPerson || 'Select Person'}
                      onSelect={(selectedOption) => handleInputChange(index, 'deliveryPerson', selectedOption)}
                    />
                  </td>
                  <td>
                    <InputComponent
                      placeholder="Enter Delivery Charge"
                      value={row.deliveryCharge}
                      onChange={(e) => handleInputChange(index, 'deliveryCharge', e.target.value)}
                    />
                  </td>
                  <td>
                    <DropdownButton
                      options={statusOptions}
                      label={row.status || 'Select Status'}
                      onSelect={(selectedOption) => handleInputChange(index, 'status', selectedOption)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Save Button */}
        <div className="save-button-container">
          <ButtonComponent text="Save" className="save-button" />
        </div>
      </div>
    </div>
  );
};

export default DeliveryLocationConfiguration;
