// src/api/services/adminRegisterService.js
import { toast } from 'react-hot-toast';
import apiClient from '../apiClient';
import { register_endpoints } from '../endPoints'; // Correctly import from endpoints.js

// Function to handle token generation
export const generateToken = async () => {
  const response = await apiClient.post(register_endpoints.GENERATE_TOKEN, {
    client_key: "jhasbd81e1dasdasd23jh21jh1",
    client_secret: "asdasasdasddasdasdasd212w123",
  });

  if (response && response.data.access_token) {
    //console.log(response.data);
    // toast.success('Token generated successfully');
    return response.data.access_token;
  } else {
    throw new Error('Token generation failed.');
  }
};

// Function to handle admin registration with file upload
export const adminRegister = async (formData) => {
  try {
    

  
    const response = await apiClient.post(register_endpoints.REGISTER,
    formData,
      
        
    ); 
    // Log the full response from the API
    //console.log("API Response:", response.data);
    toast.success('Registration successful!');

    return response.data; // Return the response data

  } catch (error) {
    // Check if the error has a response from the server
    if (error.response) {
      // Log the full error response
      //console.error('Error during registration:', error.response.data);
  
      // Display a specific error message from the server if available
      const errorMessage = error.response.data.message || 'Registration failed. Please try again.';
      toast.error(errorMessage);
    } else if (error.request) {
      // Request was made but no response was received
      //console.error('No response received:', error.request);
      toast.error('No response from the server. Please try again later.');
    } else {
      // Other errors like network issues or unexpected errors
      //console.error('Error during registration:', error.message);
      toast.error('An unexpected error occurred. Please try again.');
    }
  
    // Re-throw the error to handle it in the calling code (optional)
    throw error;
  }
  
};
