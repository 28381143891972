import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import InputComponent from '../../components/common/InputComponent/InputComponent'; 
import ButtonComponent from '../../components/common/buttons/ButtonComponent'; 
import { FaTrash, FaPlus } from 'react-icons/fa';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import "./coupontable.scss"; // Assuming you will create a specific stylesheet for coupon table
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import { bulkUpdateCoupons, bulkCreateCoupons } from "../../redux/actions/couponAction"; // Adjust this import based on your action structure
import toast from "react-hot-toast";

const CouponTable = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coupons = useSelector(state => state.coupon.couponList || []); // Adjust according to your Redux state structure

  const token = useSelector(state => state.adminLogin.token);
  
  const initialCoupons = location.state || [];
  const [couponData, setCouponData] = useState([]);
  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const statusOptions = ["active", "inactive","expired"];

  const normalizeDate = (dateString) => {
    if (!dateString) return '';
    const dateParts = dateString.match(/(\d{2})[-\/](\d{2})[-\/](\d{4})/);
    if (dateParts) {
      return `${dateParts[3]}-${dateParts[2]}-${dateParts[1]}`; // Convert to YYYY-MM-DD
    }
    const date = new Date(dateString);
    return !isNaN(date) ? date.toISOString().split('T')[0] : ''; // Fallback to default date parsing
  };
  
  useEffect(() => {
    //console.log(initialCoupons,"couponss")
    const processCoupons = () => {
      if (!initialCoupons || initialCoupons.length === 0) {
        // If no coupons are present, initialize with default values
        setCouponData([{
          code: '',
          discount_type: '',      // Default value for discount_type
          discount_value: '',     // Default value for discount_value
          min_purchase_value: '', // Default value for min_purchase_value
          max_discount_amount: '',// Default value for max_discount_amount
          start_date: '',         // Default value for start_date
          expiry_date: '',        // Default value for expiry_date
          usage_limit: '',        // Default value for usage_limit
          usage_per_user: '',     // Default value for usage_per_user
          status: '',             // Default value for status
        }]);
      } else {
        if (typeof initialCoupons[0] === 'object') {
          // If coupons are objects, directly set them
          setCouponData(initialCoupons);
        } else if (typeof initialCoupons[0] === 'string' || typeof initialCoupons[0] === 'number') {
          // If coupons are IDs (strings or numbers), fetch details from a source
          const fetchedCoupons = initialCoupons.map(id => {
            const coupon = coupons.find(coupon => coupon.coupon_id === id);
            return coupon ? { 
              ...coupon, 
              coupon_id: id 
            } : {
              coupon_id: id,
              code: '',
              discount_type: '',      // Default value for discount_type
              discount_value: '',     // Default value for discount_value
              min_purchase_value: '', // Default value for min_purchase_value
              max_discount_amount: '',// Default value for max_discount_amount
              start_date: '',         // Default value for start_date
              expiry_date: '',        // Default value for expiry_date
              usage_limit: '',        // Default value for usage_limit
              usage_per_user: '',     // Default value for usage_per_user
              status: '',             // Default value for status
            };
          });
          setCouponData(fetchedCoupons);
        }
      }
    };
  
    processCoupons();
  }, [initialCoupons, coupons]); // Use dependencies for initialCoupons and couponList
  
  const handleInputChange = (index, field, value) => {
    const updatedCoupons = [...couponData];
  
    // Ensure the value is converted to an integer if it's a numeric field
    if (['discount_value', 'min_purchase_value', 'max_discount_amount', 'usage_limit', 'usage_per_user'].includes(field)) {
      updatedCoupons[index][field] = parseInt(value) || 0;  // Ensure integer conversion
    } else {
      updatedCoupons[index][field] = value;
    }
  
    setCouponData(updatedCoupons);
  
    // Reset error if code is modified
    if (field === 'code') {
      const updatedErrors = [...duplicateErrors];
      updatedErrors[index] = false; // Reset duplicate error for this row
      setDuplicateErrors(updatedErrors);
    }
  };

  const handleAddRow = () => {
    setCouponData(prevCoupons => [
      ...prevCoupons,
      { code: '', discount_type: '', discount_value: '', min_purchase_value: '', max_discount_amount: '', start_date: '', expiry_date: '', usage_limit: '', usage_per_user: '', status: '' }
    ]);
    setDuplicateErrors(prevErrors => [...prevErrors, false]); // Add a new error state
  };

  const handleDelete = (index) => {
    const newCoupons = [...couponData];
    newCoupons.splice(index, 1);
    setCouponData(newCoupons);

    const newErrors = [...duplicateErrors];
    newErrors.splice(index, 1); // Remove the corresponding error state
    setDuplicateErrors(newErrors);
  };

  // Check for duplicate codes in the existing coupons
  const checkForDuplicateCodes = () => {
    const existingCodes = coupons.map(coupon => coupon.code.trim().toLowerCase());
    const updatedErrors = couponData.map(coupon => {
      return !coupon.coupon_id && existingCodes.includes(coupon.code.trim().toLowerCase()) && coupon.code.trim() !== '';
    });
    setDuplicateErrors(updatedErrors);
    return updatedErrors.some(error => error); // Returns true if any duplicate is found
  };

  const handleSaveClick = async () => {
    const hasEmptyFields = couponData.some(coupon => 
      !coupon.code.trim() || !coupon.status
    );

    if (hasEmptyFields) {
      toast.error("Please fill in all mandatory fields (Code and Status).");
      return; // Prevent saving if there are empty fields
    }

    if (checkForDuplicateCodes()) {
      return; // Prevent saving if duplicate codes are found
    }

    setIsSaving(true); // Set to 'Saving...' state
    let responseStatus = false;

    if (typeof initialCoupons[0] === 'object') {
      responseStatus = await dispatch(bulkCreateCoupons(couponData, token)); // Adjust according to your action structure
    } else {
      responseStatus = await dispatch(bulkUpdateCoupons(couponData, token)); // Adjust according to your action structure
    }

    setIsSaving(false); // Reset to normal state after saving

    if (responseStatus) {
      navigate('/admin'); // Navigate if save was successful
    } else {
      // console.error("Failed to save coupons");
    }
  };

  return (
    <div className="bulk-coupon-main-page">
    

        <div className="breadcrumb-container">
         
            <Link to="/admin" className="breadcrumb-text">
            <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
            </Link>
            <span className="breadcrumb-separator">{'>'}</span>
            <Link to="/admin" className="breadcrumb-text">
            <span className="breadcrumb-text">Coupon List</span>
            </Link>
            <span className="breadcrumb-separator">{'>'}</span>
            <span className="breadcrumb-text">Bulk Coupon</span>
       </div>
       <div className="bulk-coupon-container"></div>
       <div className="bulk-coupon">
    <div className="coupon-table">
    <div className="header-row">
    <h5 className="text-heading1">Bulk Coupon</h5>
      <div className="add-new-button">
      {typeof initialCoupons[0] === 'object' && (
        <ButtonComponent 
          text={<><FaPlus style={{ marginRight: '8px' }} />Add New</>} 
          onClick={handleAddRow} 
          className="add-button" 
        />)}
        <ButtonComponent 
          text={isSaving ? "Saving..." : "Save"} 
          onClick={handleSaveClick} 
          className="save-button" 
          disabled={isSaving} 
        />
      </div>
      </div>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Discount Type</th>
              <th>Discount Value</th>
              <th>Min Purchase Value</th>
              <th>Max Discount Amount</th>
              <th>Start Date</th>
              <th>Expiry Date</th>
              <th>Usage Limit</th>
              <th>Usage Per User</th>
              <th>Status</th>
              <th className="small-column">Delete</th>
            </tr>
          </thead>
          <tbody>
            {couponData.map((coupon, index) => (
              <tr key={index}>
                <td>
                <InputComponent
                    className="table-input"
                    value={coupon.code}
                    onChange={(e) => handleInputChange(index, 'code', e.target.value)}
                  />
                </td>
                <td>
                <DropdownButton
                label={coupon.discount_type || "Select Type"} // Display selected type or placeholder
                options={["fixed_amount", "percentage"]} // Dropdown options: fixed_amount and percentage
                onSelect={(selectedOption) => handleInputChange(index, 'discount_type', selectedOption)} // Update the code field in the state
              />
                  
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    value={coupon.discount_value}
                    type='number'
                    onChange={(e) => handleInputChange(index, 'discount_value', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type='number'
                    value={coupon.min_purchase_value}
                    onChange={(e) => handleInputChange(index, 'min_purchase_value', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type='number'
                    value={coupon.max_discount_amount}
                    onChange={(e) => handleInputChange(index, 'max_discount_amount', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type="date"
                    value={normalizeDate(coupon.start_date)}
                    onChange={(e) => handleInputChange(index, 'start_date', e.target.value)}
                  />
                </td>
                <td>
                    <InputComponent
                        className="table-input"
                        type="date"
                        value={normalizeDate(coupon.expiry_date)} // Date is already treated as string
                        onChange={(e) => handleInputChange(index, 'expiry_date', e.target.value)}
                    />
                    </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type='number'
                    value={coupon.usage_limit}
                    onChange={(e) => handleInputChange(index, 'usage_limit', e.target.value)}
                  />
                </td>
                <td>
                  <InputComponent
                    className="table-input"
                    type='number'
                    value={coupon.usage_per_user}
                    onChange={(e) => handleInputChange(index, 'usage_per_user', e.target.value)}
                  />
                </td>
                <td>
                  <DropdownButton
                    options={statusOptions}
                    label={coupon.status || 'Select Status'}
                    onSelect={(value) => handleInputChange(index, 'status', value)}
                  />
                </td>
                <td className="actions1">
                  <FaTrash className="action-icon1" onClick={() => handleDelete(index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
    
  );
};

export default CouponTable;
