// reducers/offerReducer.js
import {
    FETCH_OFFERS,
    CREATE_OFFER,
    UPDATE_OFFER,
    DELETE_OFFER,
    BULK_CREATE_OFFERS,
    BULK_UPDATE_OFFERS,
    BULK_DELETE_OFFERS,
    OFFER_ERROR
} from '../actions/discountAction';

const initialState = {
    offerList: [],
    error: null
};

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_OFFERS:
            return { ...state, offerList: action.payload, error: null };
        
        case CREATE_OFFER:
            return { ...state, offerList: [...state.offerList, action.payload], error: null };

        case UPDATE_OFFER:
            return {
                ...state,
                offerList: state.offerList.map(offer =>
                    offer.offer_id === action.payload.offer_id ? action.payload : offer
                ),
                error: null
            };

        case DELETE_OFFER:
            return {
                ...state,
                offerList: state.offerList.filter(offer => offer.offer_id !== action.payload),
                error: null
            };

        case BULK_CREATE_OFFERS:
            return { ...state, offerList: [...state.offerList, ...action.payload], error: null };

        case BULK_UPDATE_OFFERS:
            return {
                ...state,
                offerList: state.offerList.map(offer => {
                    const updatedOffer = action.payload.find(uOffer => uOffer.offer_id === offer.offer_id);
                    return updatedOffer ? updatedOffer : offer;
                }),
                error: null
            };

        case BULK_DELETE_OFFERS:
            return {
                ...state,
                offerList: state.offerList.filter(offer => !action.payload.includes(offer.offer_id)),
                error: null
            };

        case OFFER_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default offerReducer;
