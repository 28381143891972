// src/components/CategoriesOffcanvas/CategoriesOffcanvas.jsx
import React, { useState } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import './CategoriesOffcanvas.scss';

const CategoriesOffcanvas = ({
  show,
  handleClose,
  categories,
  brands,
  selectedCategory,
  selectedBrands,
  onCategoryChange,
  onBrandChange,
  onClearCategory,
  onClearBrands,
}) => {
  const [activeTab, setActiveTab] = useState('Category'); // Default to Category tab

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="bottom"
      className="categories-offcanvas"
      backdrop={true}
      scroll={true}
    >
      <Offcanvas.Header>
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="offcanvas-content">
          <div className="tabs">
            <button
              className={activeTab === 'Category' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Category')}
            >
              Categories
            </button>
            <button
              className={activeTab === 'Brands' ? 'tab active' : 'tab'}
              onClick={() => setActiveTab('Brands')}
            >
              Brands
            </button>
          </div>
          <div className="tab-content">
            {activeTab === 'Category' && (
              <div className="filter-options">
                {categories.map((category) => (
                  <div className="filter-option" key={category.category_id}>
                    <label>
                      <input
                        type="radio"
                        name="category"
                        checked={selectedCategory === category.category_id}
                        onChange={() => onCategoryChange(category.category_id)}
                      />
                      <span>{category.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
            {activeTab === 'Brands' && (
              <div className="filter-options">
                {brands.map((brand) => (
                  <div className="filter-option" key={brand.brand_id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedBrands.includes(brand.brand_id)}
                        onChange={() => onBrandChange(brand.brand_id)}
                      />
                      <span>{brand.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="offcanvas-footer">
          <Button variant="link" className="clear-button" onClick={() => {
            onClearCategory();
            onClearBrands();
          }}>
            Clear
          </Button>
          <Button variant="primary" className="close-button" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CategoriesOffcanvas;
