// actions/bannerActions.js
import {
    fetchAllBanners,
    fetchBannerById,
    createBanner,
    createBulkBanners,
    updateBanner,
    updateBulkBanners,
    deleteBanner,
    deleteBulkBanners
} from '../../api/services/bannerService';

// Action Types
export const FETCH_BANNERS_REQUEST = 'FETCH_BANNERS_REQUEST';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_FAILURE = 'FETCH_BANNERS_FAILURE';

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';

export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE';

export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_FAILURE = 'DELETE_BANNER_FAILURE';

// Action Creators

// Fetch all banners
export const fetchBanners = (token) => async (dispatch) => {
    dispatch({ type: FETCH_BANNERS_REQUEST });
    try {
        const banners = await fetchAllBanners(token);
        dispatch({ type: FETCH_BANNERS_SUCCESS, payload: banners });
    } catch (error) {
        dispatch({ type: FETCH_BANNERS_FAILURE, error: error.message });
    }
};

// Fetch a single banner by ID
export const fetchBanner = (bannerId, token) => async (dispatch) => {
    dispatch({ type: FETCH_BANNERS_REQUEST });
    try {
        const banner = await fetchBannerById(bannerId, token);
        dispatch({ type: FETCH_BANNERS_SUCCESS, payload: banner });
    } catch (error) {
        dispatch({ type: FETCH_BANNERS_FAILURE, error: error.message });
    }
};

// Create a new banner
export const createNewBanner = (bannerData, token) => async (dispatch) => {
    dispatch({ type: CREATE_BANNER_REQUEST });
    try {
        const banner = await createBanner(bannerData, token);
        dispatch({ type: CREATE_BANNER_SUCCESS, payload: [bannerData] });
        return{success:true}
    } catch (error) {
        dispatch({ type: CREATE_BANNER_FAILURE, error: error.message });
        return{success:false}
    }
};

// Create multiple banners in bulk
export const createBulkBannersAction = (bannersData, token) => async (dispatch) => {
    dispatch({ type: CREATE_BANNER_REQUEST });
    try {
        const banners = await createBulkBanners(bannersData, token);
        dispatch({ type: CREATE_BANNER_SUCCESS, payload: banners });
        
    } catch (error) {
        dispatch({ type: CREATE_BANNER_FAILURE, error: error.message });
    }
};

// Update a single banner by ID
export const updateExistingBanner = (bannerData, token) => async (dispatch) => {
    dispatch({ type: UPDATE_BANNER_REQUEST });
    try {
        const updatedBanner = await updateBanner(bannerData, token);
        dispatch({ type: UPDATE_BANNER_SUCCESS, payload: bannerData });
        return{success:true}
    } catch (error) {
        dispatch({ type: UPDATE_BANNER_FAILURE, error: error.message });
        return{success:false}
    }
};

// Update multiple banners in bulk
export const updateBulkBannersAction = (bannersData, token) => async (dispatch) => {
    dispatch({ type: UPDATE_BANNER_REQUEST });
    try {
        const updatedBanners = await updateBulkBanners(bannersData, token);
        dispatch({ type: UPDATE_BANNER_SUCCESS, payload: updatedBanners });
    } catch (error) {
        dispatch({ type: UPDATE_BANNER_FAILURE, error: error.message });
    }
};

// Delete a single banner by ID
export const deleteExistingBanner = (bannerId, token) => async (dispatch) => {
    dispatch({ type: DELETE_BANNER_REQUEST });
    try {
        await deleteBanner(bannerId, token);
        dispatch({ type: DELETE_BANNER_SUCCESS, payload: bannerId });
    } catch (error) {
        dispatch({ type: DELETE_BANNER_FAILURE, error: error.message });
    }
};

// Delete multiple banners in bulk
export const deleteBulkBannersAction = (bannerIds, token) => async (dispatch) => {
    dispatch({ type: DELETE_BANNER_REQUEST });
    try {
        await deleteBulkBanners(bannerIds, token);
        dispatch({ type: DELETE_BANNER_SUCCESS, payload: bannerIds });
    } catch (error) {
        dispatch({ type: DELETE_BANNER_FAILURE, error: error.message });
    }
};
