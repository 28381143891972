import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllocatedOffers, deleteAllocatedOffer, bulkDeleteAllocatedOffers } from '../../redux/actions/discountAllocateAction';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faSearch, faPencilAlt, faTrash,  faHome} from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './discountallocationdisplay.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

const OfferAllocationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const token = useSelector(state => state.adminLogin.token);
  const allocatedOffers = useSelector((state) => state.allocateOffer.allocatedOffers) || [];
  const offerList =  useSelector(state => state.discount.offerList) || [];

  const categoryOptions = useSelector((state) => state.category.categoryList.categories);
  const productList = useSelector((state) => state.products.basicProducts || []);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showSearchFilters, setShowSearchFilters] = useState(false);


  const navigationOptions = {
    gridPath: '/admin/add-offer-allocation', // Modify as needed
    sheetPath: '/admin/uploadpage',   // Modify as needed
    from: 'AllocateOffer',
  };
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch allocated offers
  useEffect(() => {
    const fetchInitialOffers = () => {
      dispatch(fetchAllocatedOffers(token));
    };
    fetchInitialOffers();
    const intervalId = setInterval(fetchInitialOffers, 180000);
    return () => clearInterval(intervalId);
  }, [dispatch, token]);

  // Get offer name by ID
  const getOfferNameById = (offerId) => {
    const offer = offerList.find((o) => o.offer_id === offerId);
    return offer ? offer.conditions : '';
  };

  // Get entity name by type and ID
  const getEntity = (entity_type, id) => {
    if (entity_type === "category") {
      const category = categoryOptions.find((c) => c.category_id === id);
      return category ? category.name : '';
    } else if (entity_type === "product") {
      const product = productList.find((c) => c.product_id === id);
      return product ? product.product_name : '';
    }
  };

  // Filter allocated offers based on search query
  const filteredAllocatedOffers = allocatedOffers.filter((item) => {
      const offerName = getOfferNameById(item.offer_id) || '';
    const entityName = getEntity(item.entity_type, item.entity_id) || '';
    const entityType = item.entity_type || '';
    
    const searchTerm = searchQuery?.toLowerCase() || ''; // Safe access for search query
    return (
      (offerName?.toLowerCase() || '').includes(searchTerm) ||
      (entityName?.toLowerCase() || '').includes(searchTerm) ||
      (entityType?.toLowerCase() || '').includes(searchTerm)
    );
    
  });

  const handleCheckboxChange = (allocationId) => {
    if (selectedIds.includes(allocationId)) {
      setSelectedIds(prev => prev.filter(id => id !== allocationId));
    } else {
      setSelectedIds(prev => [...prev, allocationId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = allocatedOffers.map(item => item.offer_allocation_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectAll(allocatedOffers.length > 0 && selectedIds.length === allocatedOffers.length);
  }, [selectedIds, allocatedOffers]);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleOffcanvasOpen = () => setShowOffcanvas(true);
  const handleOffcanvasClose = () => setShowOffcanvas(false);


  const handleEditClick = (id) => {
    navigate('/admin/add-offer-allocation', { state: id });
  };

  const handleDelete = async (offerAllocationId) => {
    if (window.confirm('Are you sure you want to delete this offer allocation?')) {
      dispatch(deleteAllocatedOffer(offerAllocationId, token));
    }
  };

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one offer to proceed.");
      return;
    }
    if (action === "edit") {
      navigate('/admin/bulk-allocate-offer', { state: selectedIds });
      setSelectedIds([]);
    } else if (action === "delete") {
      if (window.confirm(`Are you sure you want to delete the selected allocated offers?`)) {
        dispatch(bulkDeleteAllocatedOffers(selectedIds, token));
        setSelectedIds([]);
      }
    }
  };

  return (
    <div className="admin-discount-page-layout">

       <div className="breadcrumb-container">
       <Link to="/admin/dashboard" className="breadcrumb-text">
        <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
        <span className="breadcrumb-text">Offer list</span>
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <Link to="/admin" className="breadcrumb-text">
          Offer Allocation List
        </Link>
      </div>
      <div className="offer-allocation-container"></div>
      <div className="admin-discount-page-container">
        <div className="right-section">
        <h5 className="text-heading1"> Offer Allocation List</h5>
          <div className="toolbar">
        
            
            <div className="search-wrapper">
              <FontAwesomeIcon icon={faSearch} className="icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search by offer, category, product, or entity type"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
        

          <div className="item-controls">
            <ButtonComponent text="+ Allocate Offer" variant="dark" className="add-items-button" onClick={handleModalShow} />
            <Dropdown>
              <Dropdown.Toggle variant="primary" className='dropdown-button1' id="actions-dropdown">
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
          <div className="table-container">
            {filteredAllocatedOffers.length === 0 ? (
              <div className="no-offers-message">No allocated offers found.</div>
            ) : (
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>
                      <CheckboxComponent
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>ID</th>
                    <th>Offer Code</th>
                    <th>Entity Type</th>
                    <th>Entity Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAllocatedOffers.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <CheckboxComponent
                          checked={selectedIds.includes(item.offer_allocation_id)}
                          onChange={() => handleCheckboxChange(item.offer_allocation_id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{getOfferNameById(item.offer_id)}</td>
                      <td>{item.entity_type}</td>
                      <td>{getEntity(item.entity_type, item.entity_id)}</td>
                      <td>{item.status}</td>
                      <td className="actions-column">
                        <button className="icon-button" onClick={() => handleEditClick(item.offer_allocation_id)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      </td>
                      <td className="actions-column">
                        <button className="icon-button" onClick={() => handleDelete(item.offer_allocation_id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

    
        </div>
      </div>

      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
          {/* <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} /> */}
          <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions} />
    </div>
  );
};

export default OfferAllocationList;
