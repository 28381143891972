import { fetchCoupons, deleteCoupon, bulkDeleteCoupons,bulkUpdateCoupons } from '../../redux/actions/couponAction';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faSearch, faPencilAlt,  faTrash ,faHome} from '@fortawesome/free-solid-svg-icons';
import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import './coupondisplayPage.scss';
import { useDispatch, useSelector } from 'react-redux';

const CouponDisplayPage = () => {
  const dispatch = useDispatch(); // Access the dispatch function
  const navigate = useNavigate();

  const navigationOptions = {
    gridPath: '/admin/add-coupon', // Modify as needed
    sheetPath: '/admin/uploadpage',   // Modify as needed
    from:'coupon',
  };


  const token = useSelector(state => state.adminLogin.token);
  const coupons = useSelector(state => state.coupon.couponList) || [];

  useEffect(() => {
    const fetchInitialCoupons = () => {
      dispatch(fetchCoupons(token));
    };

    fetchInitialCoupons(); // Fetch on mount

    const intervalId = setInterval(fetchInitialCoupons, 180000); // 180,000 ms = 3 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [dispatch]);

  const [tableData, setTableData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvasOpen = () => setShowOffcanvas(true);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [selectedIds, setSelectedIds] = useState([]);

  const handleActionSelect = (action) => {
    if (selectedIds.length === 0) {
      alert("Please select at least one coupon to proceed.");
      return;
    }

    if (action === "delete") {
      if (window.confirm(`Are you sure you want to delete the selected coupons?`)) {
        dispatch(bulkDeleteCoupons(selectedIds, token));
        setSelectedIds([]);
      }
    }
    else if (action === "edit") {

      navigate('/admin/bulk-coupon', { state: selectedIds } )
      setSelectedIds([]);
      // Implement edit functionality here
  }
  };
  const handleDelete = async (couponId) => {
    if (window.confirm('Are you sure you want to delete this coupon?')) {
      try {
        dispatch(deleteCoupon(couponId,token)); 
       
       
      } catch (error) {
      
      }
    }
  };
  const filteredCoupons = coupons.filter(coupon =>
    (coupon.code && coupon.code.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (coupon.description && coupon.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  
  const handleCheckboxChange = (couponId) => {
    if (selectedIds.includes(couponId)) {
      setSelectedIds(prev => prev.filter(id => id !== couponId));
    } else {
      setSelectedIds(prev => [...prev, couponId]);
    }
  };

  const handleEditClick = (id) => {
    navigate('/admin/add-coupon', { state: id });
  };

  const navigateAllocate =()  => {
  navigate('/admin/coupon/allocate');
  }
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = coupons.map(item => item.coupon_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll); // Toggle selectAll state
  };

  useEffect(() => {
    if (selectedIds.length === coupons.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedIds, coupons.length]);

  const handleRefresh = () => {
    const currentDate = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format
    const couponsToUpdate = coupons
    .filter(coupon => 
      new Date(coupon.expiry_date) <= new Date(currentDate) && // Check for expired or expiring today
      coupon.status !== "expired" // Ensure status is not already "expired"
    )
    .map(coupon => ({
      coupon_id: coupon.coupon_id, // Include only coupon_id
      status: "expired",           // Update status to "expired"
    }));
  
    if (couponsToUpdate.length > 0) {
      dispatch(bulkUpdateCoupons(couponsToUpdate, token)); // Dispatch the action with only the required data
      dispatch(fetchCoupons(token));
      alert(`${couponsToUpdate.length} coupons updated to expired.`);
    } else {
      alert("No coupons to update.");
    }
  };
  

  return (
    <div>
      <div className="admin-coupon-list-layout">
      <div className="breadcrumb-container">
      <Link to="/admin/dashboard" className="breadcrumb-text">
      <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
        </Link>
        
        <span className="breadcrumb-separator">{'>'}</span>
        <div className="breadcrumb-text">
          Coupon List
        </div>
      </div>
      <div className="coupon-container"></div>
        <div className="admin-coupon-page-container">
          <div className="right-section">
          <h5 className='text-heading1'>Coupon List</h5>
            <div className="toolbar">
           
              <div className="search-wrapper">
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="item-controls">
                <ButtonComponent text="+ coupon" variant="dark" className="add-items-buttons" onClick={handleModalShow} />
              
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" className='dropdown-toggle4' id="actions-dropdown">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleActionSelect("edit")}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleActionSelect("delete")}>Delete</Dropdown.Item>
                     
                    </Dropdown.Menu>
                  </Dropdown>
              
                <ButtonComponent text="Allocate" variant="dark" className="add-items-button" onClick={navigateAllocate} />

                <ButtonComponent 
                    text="Refresh" 
                    variant="dark" 
                    className="add-items-button" 
                    onClick={handleRefresh} 
                  />

            
              </div>
            </div>

            <div className="table-container">
              {filteredCoupons.length === 0 ? (
                <div className="no-coupons-message">No coupons found.</div>
              ) : (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <CheckboxComponent
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>Coupon ID</th>
                    <th>Code</th>
                    <th>Discount Type</th>
                    <th>Discount Value</th>
                    <th>Minimum purchase value</th>
                    <th>Maximum Discount Amount</th>
                    <th>Start Date</th>
                    <th>Expiry Date</th>
                    <th>Usage Limit</th>
                    <th>Usage Per User</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredCoupons.map((item, index) => (
                        <tr key={`${index}-${item.coupon_id}`}>
                          <td>
                            <CheckboxComponent
                              checked={selectedIds.includes(item.coupon_id)}
                              onChange={() => handleCheckboxChange(item.coupon_id)}
                            />
                          </td>
                          <td>
                           {index+1}
                             
                          </td>
                          <td>
                           {item.code}
                  
                          </td>
                          <td>
                          
                            {item.discount_type}
                            
                          </td>
                          <td>
                           {item.discount_value}
                              
                          </td>
                          <td>
                            {item.min_purchase_value}
                             
                          </td>
                          <td>
                          {item.max_discount_amount}
                             
                          </td>
                          <td>
                           {item.start_date}
                            
                          </td>
                          <td>
                           {item.expiry_date}
                          
                          </td>
                          <td>
                            {item.usage_limit}
                            
                          </td>
                          <td>
                            {item.usage_per_user}
                             
                          </td>
                          <td>
                            {item.status}
                            
                          </td>
                          <td className="actions-column">
                            <button
                              className="icon-button"
                              onClick={() => handleEditClick(item.coupon_id)}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                          </td>
                          <td className="actions-column">
                            <button
                              className="icon-button"
                              onClick={() => handleDelete(item.coupon_id)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )}
            </div>

            <AddItemsAdminModal show={showModal} handleClose={handleModalClose} navigationOptions={navigationOptions}/>
            {/* <div className="showing-records-pagination">
              <div className="showing-records">
                <p>Showing {1} to {filteredCoupons.length} of {filteredCoupons.length} records</p>
              </div>
              <div className="pagination-section">
                <Pagination
                  currentPage={1}
                  totalPages={1} // Adjust based on your pagination logic
                  onPageChange={() => {}}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <AdminCategoryOffcanvas show={showOffcanvas} onClose={handleOffcanvasClose} />
    </div>
  );
};

export default CouponDisplayPage;
