import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faShoppingCart,
  faBoxOpen,
  faMapMarkerAlt,
  faChevronRight,
  faShoppingBag

} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAction } from '../../../../redux/actions/authActions';
import PharmaModal from '../../../sections/LoginModal/LoginModal';
import pharmaLogo from '../../../../assets/images/freshpure.png';
import googlePlayImage from '../../../../assets/images/googleplay.svg';
import barcode from '../../../../assets/images/barcode.gif';
import './IconsGroup.scss';
import { fetchSingleUser } from '../../../../redux/actions/adminuserActions';
import { clearAddressState } from '../../../../redux/actions/AddressAction';
import { clearOrderState } from '../../../../redux/actions/orderActions';
import { clearCookieCart } from '../../../../utils/schema/cookieCart';
import { clearCart } from '../../../../redux/actions/cartActions';
import { logoutAction as adminLogout } from '../../../../redux/actions/adminRegisterActions';

const IconsGroup = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.user);
  const userId = useSelector((state) => state.auth.user);
  const adminUser = useSelector((state) => state.adminLogin.user);
  const userdata = useSelector((state) => state.usermanage.users) || [];
  const userdetail = userdata[0] || {};
  const token = useSelector((state) => state.auth.token);
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Calculate cart item count
  const cartItemCount = cartItems.reduce(
    (total, item) => total + (item.quantity || 0),
    0
  );

  useEffect(() => {
    dispatch(fetchSingleUser(userId, token));
  }, [dispatch, userId]);

  // Show and hide modals
  const handleLoginClick = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleDownloadAppClick = () => setShowDownloadAppModal(true);
  const handleCloseDownloadAppModal = () => setShowDownloadAppModal(false);

  // Logout and navigation handlers
  const handleLogoutClick = () => {
    if (userId && adminUser) {
      if (location.pathname.startsWith('/admin')) {
        dispatch(adminLogout());
        navigate('/admin/login');
      } else {
        ////console.log("inside useeeeeeeeeerrr")
        dispatch(logoutAction());
        dispatch(clearAddressState());
        dispatch(clearOrderState());
        // dispatch(clearCart());
        clearCookieCart();
        navigate('/');
      }
    } else if (userId) {

      ////console.log("inside useeeeeeeeeerrr")
      dispatch(logoutAction());
      dispatch(clearAddressState());
      dispatch(clearOrderState());
      // dispatch(clearCart());
      clearCookieCart();
      navigate('/');
    } else if (adminUser) {
      dispatch(adminLogout());
      navigate('/admin/login');
    }
  };
  const handleLoginModalOpen = () => {
    setShowLoginModal(true); // Open the modal
    navigate("/login", { replace: true }); // Update URL without navigation
  };

  const handleLoginModalClose = () => {
    setShowLoginModal(false); // Close the modal
    navigate("/", { replace: true }); // Reset URL to home
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      setShowLoginModal(true);
    } else {
      setShowLoginModal(false);
    }
  }, [location]);


  const handleCartClick = () => navigate('/cart');

  // Popover content for user menu
  const loginPopover = (
    <Popover id="login-popover" className="login-popover">
      <Popover.Body>
        <div className="login-popup-content">
          <div className="login-header">
            <h4 className="username">{userdetail?.username || 'User'}</h4>
            <p className="user-phone">{userdetail?.mobile_number || 'N/A'}</p>
            <a href="/myprofile" className="user-details-link">
              Add more user details &gt;
            </a>
          </div>
          <div className="login-menu">
            <div className="menu-item" onClick={() => navigate('/orders')}>
              <FontAwesomeIcon icon={faBoxOpen} className="menu-icon" />
              <span>My Orders</span>
              <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
            </div>
            <div className="menu-item" onClick={() => navigate('/subscribed-product')}>
            <FontAwesomeIcon icon={faShoppingBag} className="menu-icon" />
              <span>Subscription</span>
              <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
            </div>
            <div className="menu-item" onClick={() => navigate('/myprofile')}>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="menu-icon" />
              <span>Manage Addresses</span>
              <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
            </div>

            <div className="menu-item" onClick={() => handleLogoutClick()}>
              
              <span>Logout</span>
              
            </div>
            
          </div>
          <div className="login-footer">
            <a onClick={()=>navigate('/terms_conditions')} className="footer-link">
              Terms and Conditions
            </a>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  if (
    location.pathname === '/admin/login' ||
    location.pathname === '/admin/register'
  ) {
    return null; // Render nothing for these paths
  }

  return (
    <div className="iconsGroup">
      <span className="iconsGroupDownloadApp" onClick={handleDownloadAppClick}>
        Download App
      </span>
      {isLoggedIn ? (
        <>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={loginPopover}
            rootClose
          >
            <FontAwesomeIcon
              icon={faUser}
              style={{ cursor: 'pointer' }}
             
              className="iconsGroupProfileIcon"
            />
          </OverlayTrigger>
         
        </>
      ) : (
        <span className="Login-text" onClick={handleLoginModalOpen}>
          Login/Signup
        </span>
      )}
      <div className="iconsGroupCart" onClick={handleCartClick}>
        <FontAwesomeIcon
          icon={faShoppingCart}
          className="iconsGroupCartIcon"
        />
        {cartItemCount > 0 && (
          <span className="cart-badge">{cartItemCount}</span>
        )}
      </div>
     
      <PharmaModal show={showLoginModal} handleClose={handleLoginModalClose} />
      {showDownloadAppModal && (
        <div
          className="download-app-modal-overlay"
          onClick={handleCloseDownloadAppModal}
        >
          <div
            className="download-app-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close-button"
              onClick={handleCloseDownloadAppModal}
            >
              ×
            </button>
            <div className="download-app-modal-content">
              <div className="download-app-left">
                <img
                  src={pharmaLogo}
                  alt="Pharma Logo"
                  className="pharma-logo"
                />
                <h6>Manage your health with ease. Download Freshpure today.</h6>
                <span className="download-app-description">
  Get easy access to farm-fresh dairy, organic produce, and more. Download now and enjoy natural goodness at your doorstep.
</span>
                <img
                  src={googlePlayImage}
                  alt="Get it on Google Play"
                  className="google-play-button"
                />
              </div>
              <div className="download-app-right">
                <img src={barcode} alt="QR Code" className="qr-code" />
                <p className="scan-text">Scan to Download</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IconsGroup;
