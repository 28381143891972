
import 'bootstrap/dist/css/bootstrap.min.css';

import './RemoveItemPopup.scss';

const RemoveItemPopup = ({ onConfirm, onCancel }) => {


  
  return (
    <div className="remove-item-popup-backdrop">
      <div className="remove-item-popup">
        <div className="popup-content">
         
            <p className='popup-text'>
              Are you sure you want to remove the item
            </p>
        

          <div className="popup-actions">
           
                <button className="btn " onClick={onConfirm}>Yes</button>
                <button className="btn " onClick={onCancel}>No</button>
            
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveItemPopup;
