import React from 'react';
import { Button } from 'react-bootstrap';
import './ButtonComponent.scss';

const ButtonComponent = ({ text, onClick, variant, className, type = 'button', disabled }) => {
    return (
        <Button
            variant={variant}
            className={`button-component ${className}`}
            onClick={onClick}
            type={type} // Ensure this is explicitly set to 'button'
            disabled={disabled}
           
        >
            {text}
        </Button>
    );
};

export default ButtonComponent;
