// src/reducers/navbarReducer.js
import { SET_NAVBAR_STATE } from "../actions/secondarynavbarActions";

const initialState = {
    category: false,
    brand: false,
    product: true,
    coupon: false,
    offer: false,
};

const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAVBAR_STATE:
            return {
                ...state,
                ...action.payload, // Merges the new state with the existing state
            };
        default:
            return state;
    }
};

export default navbarReducer;
