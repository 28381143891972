// inventoryThresholdReducer.js
import {
  FETCH_INVENTORY_THRESHOLD_SUCCESS,
  FETCH_INVENTORY_THRESHOLD_ERROR,
  CREATE_INVENTORY_THRESHOLD_SUCCESS,
  CREATE_INVENTORY_THRESHOLD_ERROR,
  UPDATE_INVENTORY_THRESHOLD_SUCCESS,
  UPDATE_INVENTORY_THRESHOLD_ERROR,
  DELETE_INVENTORY_THRESHOLD_SUCCESS,
  DELETE_INVENTORY_THRESHOLD_ERROR,
  BULK_CREATE_INVENTORY_THRESHOLD_SUCCESS,
  BULK_CREATE_INVENTORY_THRESHOLD_ERROR,
  BULK_FETCH_INVENTORY_THRESHOLD_SUCCESS,
  BULK_FETCH_INVENTORY_THRESHOLD_ERROR,
  BULK_UPDATE_INVENTORY_THRESHOLD_SUCCESS,
  BULK_UPDATE_INVENTORY_THRESHOLD_ERROR,
  BULK_DELETE_INVENTORY_THRESHOLD_SUCCESS,
  BULK_DELETE_INVENTORY_THRESHOLD_ERROR,
} from '../actions/inventoryThresholdActions';

const initialState = {
  inventoryThresholds: [],
  error: null,
};

const inventoryThresholdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_THRESHOLD_SUCCESS:
    case BULK_FETCH_INVENTORY_THRESHOLD_SUCCESS:
      return { ...state, inventoryThresholds: action.payload, error: null };

    case CREATE_INVENTORY_THRESHOLD_SUCCESS:
      return { ...state, inventoryThresholds: [...state.inventoryThresholds, action.payload], error: null };

    case BULK_CREATE_INVENTORY_THRESHOLD_SUCCESS:
      return { ...state, inventoryThresholds: [...state.inventoryThresholds, ...action.payload], error: null };

    case UPDATE_INVENTORY_THRESHOLD_SUCCESS:
      return {
        ...state,
        inventoryThresholds: state.inventoryThresholds.map((threshold) =>
          threshold.threshold_id === action.payload.threshold_id ? action.payload : threshold
        ),
        error: null,
      };

    case BULK_UPDATE_INVENTORY_THRESHOLD_SUCCESS:
      return {
        ...state,
        inventoryThresholds: state.inventoryThresholds.map((threshold) =>
          action.payload.some((updated) => updated.threshold_id === threshold.threshold_id)
            ? action.payload.find((updated) => updated.threshold_id === threshold.threshold_id)
            : threshold
        ),
        error: null,
      };

    case DELETE_INVENTORY_THRESHOLD_SUCCESS:
      return {
        ...state,
        inventoryThresholds: state.inventoryThresholds.filter((threshold) => threshold.threshold_id !== action.payload),
        error: null,
      };

    case BULK_DELETE_INVENTORY_THRESHOLD_SUCCESS:
      return {
        ...state,
        inventoryThresholds: state.inventoryThresholds.filter(
          (threshold) => !action.payload.includes(threshold.thrwshold_id)
        ),
        error: null,
      };

    case FETCH_INVENTORY_THRESHOLD_ERROR:
    case CREATE_INVENTORY_THRESHOLD_ERROR:
    case UPDATE_INVENTORY_THRESHOLD_ERROR:
    case DELETE_INVENTORY_THRESHOLD_ERROR:
    case BULK_CREATE_INVENTORY_THRESHOLD_ERROR:
    case BULK_FETCH_INVENTORY_THRESHOLD_ERROR:
    case BULK_UPDATE_INVENTORY_THRESHOLD_ERROR:
    case BULK_DELETE_INVENTORY_THRESHOLD_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default inventoryThresholdReducer;
