import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import InputComponent from '../../components/common/InputComponent/InputComponent';
import DropdownButton from '../../components/common/dropdown/DropdownButton';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import Sidebar from '../../components/common/HalfSideBar/halfsidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateOffer, createOffer, fetchOffers } from '../../redux/actions/discountAction';
import { toast } from 'react-hot-toast';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './OfferPage.scss';

const OfferPage = ({ urls, onClose }) => {
  const offers = useSelector((state) => state.discount.offerList) || [];
  const token = useSelector((state) => state.adminLogin.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [offerType, setOfferType] = useState('');
  const [discountValue, setDiscountValue] = useState('');
  const [conditions, setConditions] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [originalData, setOriginalData] = useState(null);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [addType, setAddType] = useState('');

  const offerId = location.state || null;
  const statusOptions = ['active', 'inactive', 'expired'];

  // Add required fields for validation
  const requiredFields = {
    offerType: 'Offer Type',
    discountValue: 'Discount Value',
    startDate: 'Start Date',
    expiryDate: 'Expiry Date',
    status: 'Status',
  };

  useEffect(() => {
    if (offerId) {
      const offerData = offers.find((offer) => offer.offer_id === offerId);
      if (offerData) {
        setOfferType(offerData.offer_type);
        setDiscountValue(offerData.discount_value);
        setConditions(offerData.conditions);
        setStartDate(offerData.start_date);
        setExpiryDate(offerData.expiry_date);
        setStatus(offerData.status);
        setOriginalData(offerData);
      }
    }
  }, [offerId, offers]);

  useEffect(() => {
    const isFormModified =
      offerType !== originalData?.offer_type ||
      discountValue !== originalData?.discount_value ||
      conditions !== originalData?.conditions ||
      startDate !== originalData?.start_date ||
      expiryDate !== originalData?.expiry_date ||
      status !== originalData?.status;

    setIsModified(isFormModified);
  }, [offerType, discountValue, conditions, startDate, expiryDate, status, originalData]);

  const validateFields = () => {
    const missingFields = Object.entries(requiredFields).filter(
      ([key]) => !eval(key)
    );

    if (missingFields.length > 0) {
      const fieldNames = missingFields.map(([, name]) => name).join(', ');
      toast.error(`Fill all mandatory fields: ${fieldNames}`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const offerData = {
      offer_type: offerType,
      discount_value: discountValue,
      conditions: conditions,
      start_date: startDate,
      expiry_date: expiryDate,
      status: status,
    };

    setLoading(true);
    let actionResult;

    if (offerId) {
      actionResult = await dispatch(updateOffer(offerId, offerData, token));
    } else {
      actionResult = await dispatch(createOffer(offerData, token));
    }

    setLoading(false);
    if (actionResult && actionResult.success) {
      toast.success(`Offer ${offerId ? 'updated' : 'created'} successfully`);
      if (urls) {
        onClose();
      } else {
        navigate('/admin');
      }
    } else {
      toast.error(`Offer not ${offerId ? 'updated' : 'created'}. Please try again.`);
    }
  };

  const handleSidebarClose = () => {
    setIsSidebarVisible(false);
    dispatch(fetchOffers(token));
  };

  const renderRequiredLabel = (label) => (
    <span>
      {label} <span style={{ color: 'red' }}>*</span>
    </span>
  );

  return (
    <div className="offer-page-section">
      {/* Conditionally render breadcrumbs */}
      {!urls && (
        <div className="breadcrumb-container">
         <Link to="/admin/dashboard" className="breadcrumb-text">
           <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <Link to="/admin" className="breadcrumb-text">
            Discount List
          </Link>
          <span className="breadcrumb-separator">{'>'}</span>
          <span className="breadcrumb-text">Add Discount</span>
        </div>
      )}
      <div className="add-discount-person-container"></div>
      <div className="offer-page">
        <h5 className="text-heading1">Add Discount Items</h5>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel('Offer Type')}</label>
              <DropdownButton
                label={offerType || 'Select Offer Type'}
                options={['fixed', 'percentage']}
                onSelect={(selectedOption) => setOfferType(selectedOption)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel('Discount Value')}</label>
              <InputComponent
                type="number"
                placeholder="Enter Discount Value"
                value={discountValue}
                onChange={(e) => setDiscountValue(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Conditions</label>
              <InputComponent
                placeholder="Enter Conditions"
                value={conditions}
                onChange={(e) => setConditions(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel('Start Date')}</label>
              <InputComponent
                type="date"
                placeholder="Select Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>{renderRequiredLabel('Expiry Date')}</label>
              <InputComponent
                type="date"
                placeholder="Select Expiry Date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>{renderRequiredLabel('Status')}</label>
              <DropdownButton
                options={statusOptions}
                label={status || 'Select Status'}
                onSelect={(selectedOption) => setStatus(selectedOption)}
              />
            </div>
          </div>
          <div className="form-row save-button-container">
            <ButtonComponent
              text={loading ? 'Saving...' : 'Save'}
              onClick={handleSubmit}
              className="submit-button"
              disabled={!isModified || loading}
            />
          </div>
        </form>
      </div>
      {isSidebarVisible && <Sidebar type={addType} urls={urls} onClose={handleSidebarClose} />}
    </div>
  );
};

export default OfferPage;
