import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';

const BarChart = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Format the date to make it shorter
  const formatDate = (date) => {
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      const options = { day: '2-digit', month: 'short' }; // Example: "05 Dec"
      return parsedDate.toLocaleDateString('en-GB', options);
    }
    return date;
  };

  return (
    <div
      style={{
        height: '400px',
        width: '100%',
        overflowX: isMobile ? 'auto' : 'hidden', // Enable horizontal scrolling for mobile
        paddingBottom: isMobile ? '5px' : '0', // Add extra padding for scroll
      }}
    >
      <ResponsiveBar
        data={data}
        keys={['otherData', 'sales']}
        indexBy="timeRange"
        margin={{
          top: 50,
          right: 10,
          bottom: isMobile ? 100 : 120, // Add extra margin for rotated labels
          left: 70,
        }}
        padding={0.3}
        colors={['#73d2f1', '#f5c342']}
        borderRadius={4}
        axisBottom={{
          tickSize: 0,
          tickPadding: 3,
          tickRotation: isMobile ? -45 : 45, // Adjust rotation for visibility
          tickValues: data.map((d) => d.timeRange),
          format: (value) => formatDate(value),
          legend: 'Time Range',
          legendPosition: 'middle',
          legendOffset: isMobile ? 40 : 50, // Reduced for better visibility
        }}
        
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          format: (value) => `₹ ${value}`,
          legend: 'Amount',
          legendPosition: 'middle',
          legendOffset: -60,
        }}
        enableGridY={false}
        enableGridX={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        theme={{
          labels: {
            text: {
              fontSize: 14,
              fill: '#333',
            },
          },
          axis: {
            legend: {
              text: {
                fontSize: 14,
                fontWeight: 600,
                fill: '#333',
              },
            },
            ticks: {
              text: {
                fontSize: isMobile ? 4 : 8,
                fill: '#888888',
              },
            },
          },
        }}
        tooltip={({ id, value }) => (
          <span>
            {id}: ₹{value}
          </span>
        )}
        enableLabel={false}
      />
    </div>
  );
};

export default BarChart;
