import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers, deleteSupplier,bulkDeleteSuppliers } from '../../redux/actions/supplierActions';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/common/buttons/ButtonComponent';
import CheckboxComponent from '../../components/common/CheckboxComponent/CheckboxComponent';
import AddItemsAdminModal from '../../components/sections/AddItems-adminModal/AddItems-adminModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPencilAlt,faHome,  faTrash} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import AdminCategoryOffcanvas from '../../components/common/AdminCategoryOffcanvas/AdminCategoryOffcanvas';
import SkeletonLoader from '../../components/common/SkeletonLoader/SkeletonLoader';
import './supplierlistPage.scss';

import Dropdown from 'react-bootstrap/Dropdown';
const SupplierDisplayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

   const navigationOptions = {
        gridPath: '/admin/add-supplier', // Modify as needed
        sheetPath: '/admin/uploadpage',   // Modify as needed
        from:'supplier',
      };

  const supplierList = useSelector((state) => state.supplier.suppliers) || [];
  ////console.log(supplierList,'supplier list')
  const loading = useSelector((state) => state.supplier.loading);
  const handleModalShow = () => setShowModal(true);
  const [tableData, setTableData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const token = useSelector((state)=>state.adminLogin.token);
  useEffect(() => {
    dispatch(fetchSuppliers('',token));
  }, [dispatch]);

  useEffect(() => {
    
    setTableData(supplierList);
  }, [supplierList]);

  const handleDelete = async (supplierId,token) => {
    if (window.confirm('Are you sure you want to delete this supplier?')) {
      dispatch(deleteSupplier(supplierId,token));
      // dispatch(fetchSuppliers()); // Refresh supplier list after deletion
    }
  };

  const handleBulkDelete = () => {
    if (selectedIds.length === 0) return alert('Please select at least one supplier to delete.');
    if (window.confirm('Are you sure you want to delete the selected suppliers?')) {
     dispatch(bulkDeleteSuppliers(selectedIds,token));
      setSelectedIds([]);
      setSelectAll(false);
    
    }
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedIds(newSelectAll ? tableData.map((supplier) => supplier.supplier_id) : []);
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id) ? prevSelectedIds.filter((i) => i !== id) : [...prevSelectedIds, id]
    );
  };

  const handleEditClick = (id) => {
    navigate('/admin/add-supplier', { state: { id } });
  };

  const handleBulkEdit = () => {
    if (selectedIds.length === 0) return alert('Please select at least one supplier to edit.');
    navigate('/admin/bulk-supplier', { state: selectedIds });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filteredSuppliers = supplierList.filter(
      (supplier) =>
        supplier.name.toLowerCase().includes(value.toLowerCase()) ||
        supplier.email.toLowerCase().includes(value.toLowerCase()) ||
        supplier.phone.toLowerCase().includes(value.toLowerCase())
    );
    setTableData(filteredSuppliers);
  };

  return (
    <div className="admin-supplier-list-layout">
    <div className="breadcrumb-container">
    <Link to="/admin/dashboard" className="breadcrumb-text">
    <FontAwesomeIcon icon={faHome} className="breadcrumb-icon" />
      </Link>
      
      <span className="breadcrumb-separator">{'>'}</span>
      <Link to="/admin" className="breadcrumb-text">
        Product List
      </Link>
      <span className="breadcrumb-separator">{'>'}</span>
      <Link to="/admin/supplier" className="breadcrumb-text">
      Supplier List
      </Link>
     
    </div>
    <div className="supplier-list-container"></div>
      <div className="supplier-list-page-container">
      <div className="right-section">
      <h5 className='text-heading1'>Supplier List</h5>
        <div className="toolbar">
          <div className="search-wrapper">
            <FontAwesomeIcon icon={faSearch} className="icon" />
            <input
              type="text"
              className="search-bar"
              placeholder="Search by name, email, or phone"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
       

        <div className="item-controls">
            <ButtonComponent text="+ Add Suppliers" variant="dark" className="add-items-button" onClick={handleModalShow} />
            
            <div className="actions-toolbar">
        <Dropdown>
          <Dropdown.Toggle variant="primary"  className='dropdown-toggle3' id="actions-dropdown">
            Actions
          </Dropdown.Toggle>
          <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleBulkEdit()}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() =>  handleBulkDelete()}>Delete</Dropdown.Item>
           
          </Dropdown.Menu>
        </Dropdown>
      </div>
          </div>
          </div>
        <div className="table-container">
          {loading ? (
            <SkeletonLoader />
          ) : tableData.length === 0 ? (
            <div className="no-data-message">No suppliers found</div>
          ) : (
            <table className="custom-table">
              <thead>
                <tr>
                  <th>
                    <CheckboxComponent checked={selectAll} onChange={handleSelectAllChange} />
                  </th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>City</th>
                  <th>GST No.</th>
                  <th>FSSAI License No.</th>
                  <th>Type</th>
                  <th>Both Purchase & Sale</th>
                  <th>TCS Percent</th>
                  <th>Actions</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((supplier,index) => (
                  <tr key={supplier.supplier_id}>
                    <td>
                      <CheckboxComponent
                        checked={selectedIds.includes(supplier.supplier_id)}
                        onChange={() => handleCheckboxChange(supplier.supplier_id)}
                      />
                    </td>
                    <td>{index+1}</td>
                    <td>{supplier.name}</td>
                    <td>{supplier.company}</td>
                    <td>{supplier.email}</td>
                    <td>{supplier.phone}</td>
                    <td>{supplier.address}</td>
                    <td>{supplier.state}</td>
                    <td>{supplier.city}</td>
                    <td>{supplier.gst_no}</td>
                    <td>{supplier.fssai_lic_no}</td>
                    <td>{supplier.type}</td>
                    <td>{supplier.both_purchase_sale ? 'Yes' : 'No'}</td>
                    <td>{supplier.tcs_percent}</td>
                    <td>
                      <button className="icon-button" onClick={() => handleEditClick(supplier.supplier_id)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </td>
                    <td>
                      <button className="icon-button" onClick={() => handleDelete(supplier.supplier_id,token)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <AddItemsAdminModal show={showModal} handleClose={() => setShowModal(false)} navigationOptions={navigationOptions}/>
        
      

        <AdminCategoryOffcanvas show={showOffcanvas} onClose={() => setShowOffcanvas(false)} />
      </div>
      </div>
    </div>
  );
};

export default SupplierDisplayPage;
