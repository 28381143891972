import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCoupon, removeCoupon } from "../../../redux/actions/setCouponAction";
import "./OffersCard.scss";

const OffersCard = ({
  offerCode,
 
 
  description,
  termsAndConditions,
  isApplied,
  offerDetails,
}) => {
  const [applied, setApplied] = useState(isApplied || false);
  const dispatch = useDispatch();
  
  const minPurchase = parseFloat(offerDetails.min_purchase_value)
  const maxPurchase = parseFloat(offerDetails.max_discount_amount)
  const  discountValue = offerDetails.discount_value;
  const allocateCoupon = useSelector((state)=>state.allocateCoupon.allocatedCoupons)
  const coupon = allocateCoupon.find((cop)=>cop.coupon_id === offerDetails.coupon_id)
  const categories = useSelector((state)=>state.category.categoryList.categories)
  const discountType =offerDetails.discount_type;
  // Selectors for cart items and products
  const cartItems = useSelector((state) => state.cart.cartItems || []);
  const basicProducts = useSelector((state) => state.products.basicProducts || []);

  // Filter eligible items based on product_id or category_id
  const eligibleCartItems = cartItems.filter((cartItem) => {
    // Check if product matches directly with offer entity_ids
    const isProductMatch =
      coupon.entity_type === "product" &&
      coupon.entity_id === cartItem.product_id;
  
    // Check if the product's category matches with offer entity_ids
    const product = basicProducts.find((prod) => prod.product_id === cartItem.product_id);
    const isCategoryMatch =
      coupon.entity_type === "category" &&
      product &&
      coupon.entity_id === product.category_id;
  
    return isProductMatch || isCategoryMatch;
  });
  
  // Calculate total price for eligible items
  const totalPrice = eligibleCartItems.reduce(
    (sum, item) => sum + item.price_per_unit * item.quantity,
    0
  );
  
  let name = '';
  if (coupon.entity_type === 'product') {
    const product = basicProducts.find((prod) => prod.product_id === coupon.entity_id);
    name = product ? product.product_name : 'Unknown Product';
  } else if (coupon.entity_type === 'category') {
    // Assuming `categories` contains all category data
    const category = categories.find((cat) => cat.category_id === coupon.entity_id);
    name = category ? category.name : 'Unknown Category';
  }
  const isEligibleForOffer =
    totalPrice >= minPurchase && (!maxPurchase || totalPrice <= maxPurchase);

  const handleApply = () => {
    dispatch(setCoupon(offerCode, discountValue));
    setApplied(true);
  };

  const handleRemove = () => {
    dispatch(removeCoupon());
    setApplied(false);
  };

  return (
    <div className="offer-card">
      <div className="offer-header">
        <h6 className="offer-code">{offerCode}</h6>
        <button
          className={`apply-button ${applied ? "remove" : ""}`}
          onClick={applied ? handleRemove : handleApply}
          disabled={!isEligibleForOffer && !applied}
        >
          {applied ? "Remove" : "Apply"}
        </button>
      </div>
      <div className="offer-details">
        <p className="min-purchase">
          {isEligibleForOffer ? (
            applied ? (
              <span>Offer Applied</span>
            ) : (
              "You're eligible for this offer!"
            )
          ) : (
`Add items worth ₹${((minPurchase - totalPrice).toFixed(2))} more to avail this offer for ${name}`
          )}
        </p>
        <p className="offer-description">{description}</p>
        <ul className="offer-info">
          <li>
            {discountType === "percentage"
              ? `Flat ${discountValue}% off`
              : `Flat ₹${discountValue} off`}
          </li>
          <li>Minimum order value ₹{minPurchase}</li>
          {maxPurchase && <li>Maximum order value ₹{maxPurchase}</li>}
        </ul>
      </div>
      <a href={termsAndConditions} className="terms-link" target="_blank" rel="noopener noreferrer">
        Terms and Conditions
      </a>
    </div>
  );
};

export default OffersCard;
